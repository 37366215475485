import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable, MatDialog, MatDialogConfig } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { getToken, getUser, isPrivateServer, getApiAURL } from 'src/app/share/utils';
import { User } from 'src/app/share/user';
import { Report } from 'src/app/share/report';
import { ReportsService } from 'src/app/services/reports.service';
import { baseDomain } from '../../share/baseurl';
import { StoreService } from 'src/app/services/store.service';
import { MessageBoxComponent } from '../message-box/message-box.component';

const ELEMENT_DATA: Report[] = [];

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  domain: string = baseDomain;
  user: User;
  displayedColumns: string[] = ['select', 'name', 'actions'];

  dataSource = new MatTableDataSource<Report>(ELEMENT_DATA);
  selection = new SelectionModel<Report>(true, []);
  searchValue: string;

  @ViewChild(MatTable, {static: true}) reporttable: MatTable<any>;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  constructor(
    private dialog: MatDialog,
    private reportService: ReportsService,
    public store: StoreService,
  ) { }

  ngOnInit() {
    this.user = getUser();
    const token = getToken();
    if (isPrivateServer()) {
      if (getApiAURL() !== null) {
        this.domain = getApiAURL();
      }
    }
    this.store.showLoading();
    this.reportService.getreports(this.user.id, token).subscribe(res => {
      let data = this.dataSource.data;
      data = [];
      for (const report of res) {
        data.push(report);
      }
      this.dataSource.data = data;
      this.store.hideLoading();
    },
    err => {
      this.showErroMessage(err);
      this.store.hideLoading();
    });
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  /**
   * This method allow to download a report
   */
  downloadReport() {
    const token = getToken();
    this.store.showLoading();

    this.reportService.getPublicReport(token).subscribe(res => {
      this.store.hideLoading();
    },
    err => {
      this.store.hideLoading();

      this.showErroMessage(err);
    });
  }

  OpenDeleteReportConfirmation(element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: `This report will be definitely removed.`,
        title: `Delete report "${element.filename}"`
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();

        this.store.showLoading();
        this.reportService.deleteReport(element, token).subscribe(
          res => {
            const data = this.dataSource.data;
            const newData = data.filter(report => report.id !== res.id);
            this.dataSource.data = newData;
            this.reporttable.renderRows();
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.showErroMessage(errmes);
          }
        );
      }
    });
  }

  removeSelectedReport() {
    if (this.selection.selected.length <= 0) {
      const message = 'Select at least one report to delete.';
      this.showErroMessage(message);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: 'Do you want to delete the selected reports?.',
        title: 'Delete reports'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {

        const token = getToken();
        this.store.showLoading();
        this.selection.selected.forEach(element => {
          this.reportService.deleteReport(element, token).subscribe(
            res => {
              const data = this.dataSource.data;
              const newData = data.filter(report => report.id !== res.id);
              this.dataSource.data = newData;
              this.reporttable.renderRows();
              this.store.hideLoading();
            },
            errmes => {
              this.store.hideLoading();
              this.showErroMessage(errmes);
            }
          );
        });
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Report): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.filename + 1}`;
  }
}

