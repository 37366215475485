import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { TemplateService } from 'src/app/services/template.service';
import { UserService } from 'src/app/services/user.service';
import { Project } from 'src/app/share/projects';
import { Template } from 'src/app/share/template';
import { Token } from 'src/app/share/token';
import { getGeomFormToShow, getToken, persistGeomFormToShow, removeGeomFormToShow } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

export class Field {
  id: string;
  label: string;
}

const ELEMENT_DATA = [];
@Component({
  selector: 'app-geom-forms-list',
  templateUrl: './geom-forms-list.component.html',
  styleUrls: ['./geom-forms-list.component.scss']
})
export class GeomFormsListComponent implements OnInit {
  template: Template;
  templates: Template[] = [];
  displayedColumns: string[] = ['select', 'label'];
  fields: Field[];
  dataSource = new MatTableDataSource<Field>(ELEMENT_DATA);
  selection = new SelectionModel<Field>(true, []);
  searchValue: string;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private userService: UserService,
    private templateService: TemplateService,
    private dialogRef: MatDialogRef<GeomFormsListComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.templates = this.store.templates;
      // this.templates = this.store.templates.filter((template) =>
      //   {
      //     return template.feature.geometry_type !== null &&  template.feature.geometry_type !== undefined;
      //   });
      // console.log(this.templates);
  }

  ngOnInit() {
    const token = getToken();
    const project: Project = JSON.parse(localStorage.getItem('project'));
    this.store.showLoading();
    this.templateService.getTemplates(token, project.id).subscribe(
      (result) => {
        this.templates = result;
        console.log(this.templates);
        this.fields = [];
        this.store.showLoading();
        this.constructHeaderList();
        this.dataSource.data = this.fields;
        this.store.hideLoading();
      }, (err) => {
        this.store.hideLoading();
      });
    // this.fields = [];
    // this.store.showLoading();
    // this.constructHeaderList();
    // this.dataSource.data = this.fields;
    // this.store.hideLoading();
  }

  constructHeaderList() {
    const token: Token = JSON.parse(localStorage.getItem('token'));
    const project: Project = JSON.parse(localStorage.getItem('project'));

    if(this.templates.length === 0) {
      this.store.showLoading();
      this.templateService.getTemplates(token, project.id).subscribe(
        res => {
          this.templates = res;
          this.store.readTemplates(res);
          this.store.hideLoading();
          this.fillDatatable();
        },
        errmess => {
          this.store.hideLoading();
          this.showErroMessage(errmess);
      });
    } else {
      this.fillDatatable();
    }
  }

  fillDatatable() {
    const savedfields = getGeomFormToShow();
    const tempsFields = [];
    let index = 0;

    for (const templItem of this.templates) {
      const label = {
        id: templItem['id'],
        label: templItem['name']
      };

      if(savedfields && index < Object.keys(savedfields).length) {
        const choosedTempl = savedfields[templItem['id']];
        if (choosedTempl) {
          index++;
          this.selection.selected.push(label);
          this.selection.toggle(label);
         // break;
        }
      }
      tempsFields.push(label);
    }
    this.fields = tempsFields;
  }

  isTemplateSelected(row) {
    const savedfields = getGeomFormToShow();
    // console.log(savedfields);
    const id = row['id'];
    // console.log(id);
    if(savedfields) {
      const al_sel = savedfields[id];
      if(al_sel){
        this.selection.select(row);
        return al_sel;
      }
    }
    // console.log(row);
    return false;
  }

  submit() {
    const fields = this.selection.selected;
    //// console.log(fields);
    const labels = [];
    for (const field of fields) {
      //const value = fields[key];
      //const templateId = field['id'];
      labels.push(field['id']);
    }
    // this.store.setFieldToShowOnMap(labels);
    // const choosenTemplates = [];
    const obj = {};
    if(labels && labels.length > 0) {

      for(const templName of labels) {
        obj[templName] = true;
      }
      if(obj) {
        persistGeomFormToShow(obj);
      }
      this.store.changedMultipleformsonmap.next(labels.length);
    } else {
      removeGeomFormToShow();
    }

    this.dialogRef.close(obj);
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Field): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.label + 1}`;
  }
}
