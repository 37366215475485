import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { User } from 'src/app/share/user';
import { getUser } from 'src/app/share/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';

export const USER_INFORMATION   = 'USER_INFORMATION';
export const RESET_PASSWORD     = 'RESET_PASSWORD';
export const MANAGE_MEMBER      = 'MANAGE_MEMBER';
export const MANAGE_USER        = 'MANAGE_USER';

@Component({
  selector: 'app-system-admin-page',
  templateUrl: './system-admin-page.component.html',
  styleUrls: ['./system-admin-page.component.scss']
})
export class SystemAdminPageComponent implements OnInit {
  searchValue: string;
  users;
  userAdminInfo = true;
  selectedUsers: object[];
  moreUsers: object;
  openType: string;
  userType: string;
  user: User;
  permissionType: string;
  projUsers: object[];
  tempProjects: object[];
  isAdminUser: boolean;
  selectedMenu: string = USER_INFORMATION;

  /**
   * Variable allowing to hide or show page
   */
  showUsersPage: boolean;
  showResetPasswordPage: boolean;
  showAdminInfoPage: boolean;
  showUserProjectsPage: boolean;

  constructor(
    private route: ActivatedRoute,
    public store: StoreService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = getUser();
    this.hideAllPages();

    const admin = getUser();
    if (admin.user_type !== 'collaborator' && admin.user_type !== null && admin.user_type !== undefined) {
      this.isAdminUser = true;
      this.showUsersPage = true;
    } else {
      this.isAdminUser = false;
      this.showUsersPage = false;
    }

    this.route.queryParamMap.subscribe(queryParams => {
      const type = queryParams.get('type');
      this.hideAllPages();
      if (type === 'user-info') {
        this.showAdminInfoPage = true;
        this.selectedMenu = USER_INFORMATION;
      } else if (type === 'reset-password') {
        this.showResetPasswordPage = true;
        this.selectedMenu = RESET_PASSWORD;
      } else if (type === 'projects-members') {
        this.showUserProjectsPage = true;
        this.selectedMenu = MANAGE_MEMBER;
      } else if (type === 'collaborator') {
        this.showUsersPage = true;
        this.selectedMenu = MANAGE_USER;
      } else {
        this.showAdminInfoPage = true;
        this.selectedMenu = USER_INFORMATION;
      }
    });
  }

  checkUserType(user) {
    const adminInfo = {
      user_type: ''
    };
    if ( user === 'admin' && adminInfo.user_type !== 'super_user' || adminInfo.user_type === 'collaborator') {
      return true;
    }
    return false;
  }

  isWhichUser(userType) {
    const userInfo = {
      user_type: ''
    };
    if (userType === 'userColl' || userInfo.user_type === 'collaborator' || userInfo.user_type === 'report_template_administrator' ) {
      return false;
    }
    return true;
  }

  checkUser(user) {
    return user !== 'admin';
  }

  displayUsers() {
    this.hideAllPages();
    if (!this.showUsersPage) {
      this.showUsersPage = true;
      this.handleClick(MANAGE_USER);
      this.router.navigate(['/users'], { queryParams: {type: 'collaborator' } });
    }
  }

  handleClick(activeMenu) {
    let $items = $('.menu');
    const classHighlight = "selected-admin";
    $items.removeClass(classHighlight);
    this.selectedMenu = activeMenu;
  }

  displayProjects() {
    if (!this.showUserProjectsPage) {
      this.hideAllPages();
      this.handleClick(MANAGE_MEMBER);
      this.showUserProjectsPage = true;
      this.router.navigate(['/users'], { queryParams: { type: 'projects-members' } });
    }
  }

  displayAdminInfo() {
    if (!this.showAdminInfoPage) {
      this.hideAllPages();
      this.handleClick(USER_INFORMATION);
      this.showAdminInfoPage = true;
      this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
    }
  }


  displayResetPassword() {
    if (!this.showResetPasswordPage) {
      this.hideAllPages();
      this.handleClick(RESET_PASSWORD);
      this.showResetPasswordPage = true;
      this.router.navigate(['/users'], { queryParams: { type: 'reset-password' } });
    }
    // this.showResetPasswordPage = true;
  }

  hideUsers() {
    this.hideAllPages();
    this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
    // this.showAdminInfoPage = true;
  }

  hideProjects() {
    this.hideAllPages();
    this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
    // this.showAdminInfoPage = true;
  }

  hideAdminInfo() {
    this.hideAllPages();
    // this.showAdminInfoPage = true;
  }

  hideResetPassword(event) {
    event.preventDefault();
    this.hideAllPages();
    this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
    // this.showAdminInfoPage = true;
    event.stopPropagation();
  }

  hideAllPages() {
    this.showUsersPage = false;
    this.showResetPasswordPage = false;
    this.showAdminInfoPage = false;
    this.showUserProjectsPage = false;
  }
}
