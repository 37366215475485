import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectService } from 'src/app/services/project.service';
import { getAdminToken, getToken, equalsIgnoringCase, getProject } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { FileServiceService } from 'src/app/services/file-service.service';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { TemplateService } from 'src/app/services/template.service';
import { Template } from 'src/app/share/template';

@Component({
  selector: 'app-import-template-from-domain',
  templateUrl: './import-template-from-domain.component.html',
  styleUrls: ['./import-template-from-domain.component.scss']
})
export class ImportTemplateFromDomainComponent implements OnInit {
  @Input() domain: Project;
  formDialogForm: FormGroup;

  domain_id: string;
  user_id: string;
  project_class: string;
  description: string;
  domains: Project[];
  forms: Template[];
  users: User[];
  visibility: string = "public";
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = 'Create';
  @ViewChild('pjtIcon', {static: false}) img;
  @ViewChild('fform', { static: false}) formFormDirective;

  metadata: any;
  moreUsers: any;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fileService: FileServiceService,
    private mcUserService: SysAdminUserServiceService,
    private projectService: ProjectService,
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ImportTemplateFromDomainComponent>
  ) {
    this.visibility = "public";
    this.domains = [];
    this.forms = [];
    this.users = [];
    this.project_class = this.store.proClass;
    this.createForm();
  }

  ngOnInit() {
    this.getDomainsOnVisibilityChange(null);
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  initializeFormData(p: Project) {
    this.domain_id = p.id;
    this.user_id = p.created_by;
    this.project_class = p.project_class;
  }

  createForm() {
    this.formDialogForm = this.fb.group({
      visibility: this.visibility,
      domain_id: ['', Validators.required],
      form_id: ['']
    });
  }

  onSubmit() {

    const temp = this.formDialogForm.value;
    const formId = temp['form_id'];
    let selectedForm = {
      name: '',
      description: ''
    };
    if(formId !== null && formId !== undefined) {
      selectedForm = this.forms.find(f => f.id == formId);
    }
    const project = getProject();
    let token = getToken();
    const form = {
      user_id: token.user_id,
      project_id: project.id,
      template_id: temp.form_id,
    };
    console.log(JSON.stringify(form));
    this.store.showLoading();
    this.templateService.copyFromDomain(form, token).subscribe(
      res => {

        this.store.createTemplate(res);
        this.store.showTemplateInfo();
        this.store.hideLoading();
        this.dialogRef.close();
      },
      errmess => {
        this.showErroMessage(errmess);
        this.store.hideLoading();
    });
  }

  getDomainsOnVisibilityChange(obj) {

    this.visibility = obj !== null && obj !== undefined ? obj.value : 'public';

    if(this.visibility === null || this.visibility === undefined) {
      this.visibility = "public";
    }
    let isMyCumulusAdministrator = false;
    let token = getToken();
    let proToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    let proClass = "domain";
    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator = true;
        proToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
      }
    }

    const archived = false;
    this.store.showLoading();
    this.projectService.getProjects(proToken, proClass, this.visibility, archived, isMyCumulusAdministrator).subscribe(
      res => {
        if(res !== null) {
          this.domains = res.sort(
            (a, b) => {
              return equalsIgnoringCase(a.name, b.name);
            });
        }
        this.store.hideLoading();
      },
      errmess => {
        this.showErroMessage(errmess);
        this.store.hideLoading();
    });
  }

  getFormOnChange(obj) {
    const formId = obj.value;
    if(formId !== null && formId !== undefined){
      const form = this.forms.find(f => f.id === formId);
      this.description = form.description;
    }
  }

  getDomainsOnChange(obj) {

    const domainId = obj.value;
    if(domainId !== null && domainId !== undefined) {


      let isMyCumulusAdministrator = false;
      let token = getToken();

      let proClass = "domain";
      if(token === null || token === undefined) {
        token = getAdminToken();
        if(token !== null && token !== undefined) {
          isMyCumulusAdministrator = true;

        }
      }
      this.store.showLoading();
      this.templateService.getTemplates(token, domainId, isMyCumulusAdministrator).subscribe(
        res => {

          if(res !== null) {
            this.forms = res.sort(
              (a, b) => {
                return equalsIgnoringCase(a.name, b.name);
              });
          }
          this.store.hideLoading();
        },
        errmess => {
          this.showErroMessage(errmess);
          this.store.hideLoading();
      });
    }
  }

  openCreateProject() {
    this.dialog.open(ImportTemplateFromDomainComponent, {width: '500px', height: 'auto'});
  }

}
