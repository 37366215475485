import { Component, OnInit } from '@angular/core';
import { Project } from '../../share/projects';
import { ProjectService, PROJECTS } from '../../services/project.service';
import { Token } from '../../share/token';
import { Template } from 'src/app/share/template';

import { TemplateService, TEMPLATES} from '../../services/template.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  projects: Project[];
  project: Project;
  templates: Template[];
  projectImageUrl: string;
  /*
  model: Project = {
    id: '',
    description: '',
    name: '',
    imageFileUrl: '',
    createBy: '',
    templateIds: []
  };
  */
  constructor(
    private store: StoreService,
    private projectService: ProjectService
    ) { }

  ngOnInit() {
    const token: Token = JSON.parse(localStorage.getItem('token'));
    this.projects = PROJECTS;
    this.projectImageUrl = this.getImageFileUrl('P');

  }

  onSubmit() {
    this.store.project = this.project;
  }

  updateProject(project) {
    this.store.project = this.project;
  }

  removeProject(project) {
    // this.store. = this.project;
  }

  getProject(id) {
    this.store.project = this.store.projects.filter(p => p.id === id)[0];
  }

  toggleTodo(p) {
    console.log(p);
  }

  removeTodo(p) {
    console.log(p);
  }

  onSelected(p) {
    this.project = p;
    this.templates = TEMPLATES;
  }

  getImageFileUrl(initial) {
    return 'https://placehold.it/70/009688/ffffff&text=' + initial;

    /*
    if (this.project !== null && this.project !== undefined) {
      if (this.project.image_file_url !== null && this.project.image_file_url !== undefined && this.project.image_file_url !== ""){
        var userId = getAuthToken().user_id;
        return url(this.project.image_file_url);
      } else {
        var initial = (this.project.name)[0];
        return "https://placehold.it/70/009688/ffffff&text=" + initial;
      }
    }
    */
  }

  /*getProjects(token) {
    this.projectService.getProjects(token).subscribe(
      projects => {
        this.projects = projects;
      },
      errmsg => {
      }
    );
  }*/
}
