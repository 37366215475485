import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTable} from '@angular/material/table';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, symbol: 'H', name: 'Hydrogen', weight: 1.0079},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, weight: 6.941, name: 'Lithium',  symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7,  weight: 14.0067, name: 'Nitrogen', symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10,  weight: 20.1797, name: 'Neon', symbol: 'Ne'},
];

@Component({
  selector: 'app-mat-table-span',
  templateUrl: './mat-table-span.component.html',
  styleUrls: ['./mat-table-span.component.scss']
})
export class MatTableSpanComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit() {
  }

}
