import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatSortModule } from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { MatNativeDateModule} from '@angular/material';
import { MatDatepickerModule  } from '@angular/material/datepicker';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatVideoModule } from 'mat-video';

// import { NgxMatFileInputModule } from '@angular-material-components/file-input';

import 'hammerjs';
import { baseURL } from './share/baseurl';
import { LoginComponent } from './components/login/login.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectItemComponent } from './components/project-item/project-item.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { TemplateItemComponent } from './components/template-item/template-item.component';
import { FeatureDataComponent } from './components/feature-data/feature-data.component';
import { UsersComponent } from './components/users/users.component';
import { HeaderComponent } from './components/header/header.component';

import { UserService } from './services/user.service';
import { ProjectService } from './services/project.service';
import { TemplateService } from './services/template.service';
import { FeatureService } from './services/feature.service';
import { ProjectMemberService } from './services/project-member.service';
import { CreateProjectComponent } from './components/dialogs/create-project/create-project.component';
import { CreateTemplateComponent } from './components/dialogs/create-template/create-template.component';
import { CreateFieldsetComponent } from './components/dialogs/create-fieldset/create-fieldset.component';
import { CreateFieldsetArrayComponent } from './components/dialogs/create-fieldset-array/create-fieldset-array.component';
import { CreateIntegerFieldComponent } from './components/dialogs/create-integer-field/create-integer-field.component';
import { CreateStringFieldComponent } from './components/dialogs/create-string-field/create-string-field.component';
import { CreateDecimalFieldComponent } from './components/dialogs/create-decimal-field/create-decimal-field.component';
import { CreateAutoincrementFieldComponent } from './components/dialogs/create-autoincrement-field/create-autoincrement-field.component';
import { CreatePhotoFieldComponent } from './components/dialogs/create-photo-field/create-photo-field.component';
import { CreateVideoFieldComponent } from './components/dialogs/create-video-field/create-video-field.component';
import { CreateAudioFieldComponent } from './components/dialogs/create-audio-field/create-audio-field.component';
import { CreateBooleanFieldComponent } from './components/dialogs/create-boolean-field/create-boolean-field.component';
import { CreateDateFieldComponent } from './components/dialogs/create-date-field/create-date-field.component';
import { CreateTimeFieldComponent } from './components/dialogs/create-time-field/create-time-field.component';
import { CreateNoteFieldComponent } from './components/dialogs/create-note-field/create-note-field.component';
import { CreateBarcodeFieldComponent } from './components/dialogs/create-barcode-field/create-barcode-field.component';
import { CreateArrayFieldComponent } from './components/dialogs/create-array-field/create-array-field.component';
import { ProjectMemberDialogComponent } from './components/dialogs/project-member-dialog/project-member-dialog.component';
import { CreateGpsCoordinatesFieldComponent } from './components/dialogs/create-gps-coordinates-field/create-gps-coordinates-field.component';
import { TemplatesPermissionsDialogComponent } from './components/dialogs/templates-permissions-dialog/templates-permissions-dialog.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { FieldsetComponent } from './components/schema/fieldset/fieldset.component';
import { FieldComponent } from './components/schema/field/field.component';
import { SignupComponent } from './components/signup/signup.component';
import { FormFieldsetComponent } from './components/schema/form-fieldset/form-fieldset.component';
import { TemplateSchemaComponent } from './components/schema/template-schema/template-schema.component';
import { ImageDropComponent } from './components/image-drop/image-drop.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ProjectMemberComponent } from './components/project-member/project-member.component';
import { SystemAdminPageComponent } from './components/system-admin-page/system-admin-page.component';
import { TemplatesReportsPageComponent } from './components/templates-reports-page/templates-reports-page.component';
import { CollaboratorsDialogComponent } from './components/dialogs/collaborators-dialog/collaborators-dialog.component';
import { UsersPageComponent } from './components/users-page/users-page.component';
import { ResetPasswordPageComponent } from './components/reset-password-page/reset-password-page.component';
import { AdminInfoPageComponent } from './components/admin-info-page/admin-info-page.component';
import { UserProjectsPageComponent } from './components/user-projects-page/user-projects-page.component';
import { AddMemberDialogComponent } from './components/dialogs/add-member-dialog/add-member-dialog.component';
import { ReportTemplateDialogComponent } from './components/dialogs/report-template-dialog/report-template-dialog.component';
import { ReportTemplatesComponent } from './components/report-templates/report-templates.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SettingCoordinatesReferenceDialogComponent } from
'./components/dialogs/setting-coordinates-reference-dialog/setting-coordinates-reference-dialog.component';
import { MarxactLoginPageComponent } from './components/dialogs/marxact-login-page/marxact-login-page.component';
import { MarxactPageComponent } from './components/marxact-page/marxact-page.component';
import { ExportDataCriteriaDialogComponent } from './components/dialogs/export-data-criteria-dialog/export-data-criteria-dialog.component';
import { AttributesetarrayDialogComponent } from './components/dialogs/attributesetarray-dialog/attributesetarray-dialog.component';
import { GeomTypeCoordinatesDialogComponent } from './components/dialogs/geom-type-coordinates-dialog/geom-type-coordinates-dialog.component';
import { MediaShowingDialogComponent } from './components/dialogs/media-showing-dialog/media-showing-dialog.component';
import { EditFeatureValueComponent } from './components/dialogs/edit-feature-value/edit-feature-value.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ConstraintListDialogComponent } from './components/dialogs/constraint-list-dialog/constraint-list-dialog.component';
import { ConstraintDialogComponent } from './components/dialogs/constraint-dialog/constraint-dialog.component';
import { FeatureMapItemDialogComponent } from './components/dialogs/feature-map-item-dialog/feature-map-item-dialog.component';
import { EmailSenderComponent } from './components/email-sender/email-sender.component';
import { ConfigServerUrlComponent } from './components/dialogs/config-server-url/config-server-url.component';
import { ActivateAccountDialogComponent } from './components/dialogs/activate-account-dialog/activate-account-dialog.component';
import { McadminLoginDialogComponent } from './components/dialogs/mcadmin-login-dialog/mcadmin-login-dialog.component';
import { McadminPageComponent } from './components/mcadmin-page/mcadmin-page.component';
import { CustomersPageComponent } from './components/customers-page/customers-page.component';
import { MycumulusadministratorsPageComponent } from './components/mycumulusadministrators-page/mycumulusadministrators-page.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { AssignmarxactdevicesComponent } from './components/dialogs/assignmarxactdevices/assignmarxactdevices.component';
import { McUsersPageComponent } from './components/mc-users-page/mc-users-page.component';
import { MatDividerModule } from '@angular/material/divider';
import { ChooseLabelDialogComponent } from './components/dialogs/choose-label-dialog/choose-label-dialog.component';
import { environment } from 'src/environments/environment';
import { MatStepperModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollaboratorListDialogComponent } from './components/dialogs/collaborator-list-dialog/collaborator-list-dialog.component';
import { ImportFileDialogComponent } from './components/dialogs/import-file-dialog/import-file-dialog.component';
import { FieldDataComponent } from './components/record/field-data/field-data.component';
import { FieldsetDataComponent } from './components/record/fieldset-data/fieldset-data.component';
import { RecordDataFormComponent } from './components/record/record-data-form/record-data-form.component';
import { ImportErrorDlgComponent } from './components/dialogs/import-error-dlg/import-error-dlg.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { MatTableSpanComponent } from './components/mat-table-span/mat-table-span.component';
import { ImportProjectComponent } from './components/dialogs/import-project/import-project.component';
import { ImportProjectFromDomainComponent } from './components/dialogs/import-project-from-domain/import-project-from-domain.component';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ImportTemplateFromDomainComponent } from './components/dialogs/import-template-from-domain/import-template-from-domain.component';
import { CreateStyleComponent } from './components/dialogs/create-style/create-style.component';
import { ProjectToProjectTemplateComponent } from './components/dialogs/project-to-project-template/project-to-project-template.component';
import { ProjectToDomaineComponent } from './components/dialogs/project-to-domaine/project-to-domaine.component';
import { StyleNewComponent } from './components/dialogs/style-new/style-new.component';
import { TemplateStyleListComponent } from './components/template-style-list/template-style-list.component';
import { ViewDataCriteriaDialogComponent } from './components/dialogs/view-data-criteria-dialog/view-data-criteria-dialog.component';
import { GeomFormsListComponent } from './components/dialogs/geom-forms-list/geom-forms-list.component';
import { FeatureMultipleFormDataMapComponent } from './components/feature-multiple-form-data-map/feature-multiple-form-data-map.component';
import { EditStyleComponent } from './components/dialogs/edit-style/edit-style.component';
import { ImportImklFileComponent } from './components/dialogs/import-imkl-file/import-imkl-file.component';
import { FoundProjectsComponent } from './components/found-projects/found-projects.component';
import { FoundFeaturesComponent } from './components/found-features/found-features.component';
import { EnexisExportCritDialogComponent } from './components/dialogs/enexis-export-crit-dialog/enexis-export-crit-dialog.component';
import { EditGeomOrCoordValuesComponent } from './components/dialogs/edit-geom-or-coord-values/edit-geom-or-coord-values.component';
import { ImportExcelFileComponent } from './components/dialogs/import-excel-file/import-excel-file.component';
import { ExportStedinCritDialogComponent } from './components/dialogs/export-stedin-crit-dialog/export-stedin-crit-dialog.component';
import { MapItemDialogComponent } from './components/dialogs/map-item-dialog/map-item-dialog.component';
import {ActivatedComponent} from './components/activated-page/activated.component';
import { ImportShapeFileComponent } from './components/dialogs/import-shape-file/import-shape-file.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    ActivatedComponent,
    ProjectComponent,
    ProjectItemComponent,
    TemplatesComponent,
    TemplateItemComponent,
    FeatureDataComponent,
    UsersComponent,
    HeaderComponent,
    CreateProjectComponent,
    CreateTemplateComponent,
    CreateFieldsetComponent,
    CreateFieldsetArrayComponent,
    CreateIntegerFieldComponent,
    CreateStringFieldComponent,
    CreateDecimalFieldComponent,
    CreateAutoincrementFieldComponent,
    CreatePhotoFieldComponent,
    CreateVideoFieldComponent,
    CreateAudioFieldComponent,
    CreateBooleanFieldComponent,
    CreateDateFieldComponent,
    CreateTimeFieldComponent,
    CreateNoteFieldComponent,
    CreateBarcodeFieldComponent,
    CreateArrayFieldComponent,
    CreateGpsCoordinatesFieldComponent,
    ImportProjectComponent,
    ProjectsComponent,
    FieldsetComponent,
    FieldComponent,
    FormFieldsetComponent,
    TemplateSchemaComponent,
    ImageDropComponent,
    ConfirmationDialogComponent,
    ProjectMemberComponent,
    ProjectMemberDialogComponent,
    SystemAdminPageComponent,
    TemplatesPermissionsDialogComponent,
    TemplatesReportsPageComponent,
    CollaboratorsDialogComponent,
    UsersPageComponent,
    ResetPasswordPageComponent,
    AdminInfoPageComponent,
    UserProjectsPageComponent,
    AddMemberDialogComponent,
    ReportTemplateDialogComponent,
    ReportTemplatesComponent,
    ReportsComponent,
    SettingCoordinatesReferenceDialogComponent,
    MarxactLoginPageComponent,
    MarxactPageComponent,
    ExportDataCriteriaDialogComponent,
    AttributesetarrayDialogComponent,
    GeomTypeCoordinatesDialogComponent,
    MediaShowingDialogComponent,
    EditFeatureValueComponent,
    FooterComponent,
    PageNotFoundComponent,
    ConstraintListDialogComponent,
    ConstraintDialogComponent,
    FeatureMapItemDialogComponent,
    EmailSenderComponent,
    ConfigServerUrlComponent,
    ActivateAccountDialogComponent,
    McadminLoginDialogComponent,
    McadminPageComponent,
    CustomersPageComponent,
    MycumulusadministratorsPageComponent,
    MessageBoxComponent,
    AssignmarxactdevicesComponent,
    McUsersPageComponent,
    ChooseLabelDialogComponent,
    CollaboratorListDialogComponent,
    ImportFileDialogComponent,
    FieldDataComponent,
    FieldsetDataComponent,
    RecordDataFormComponent,
    ImportErrorDlgComponent,
    ForgotPasswordPageComponent,
    MatTableSpanComponent,
    ImportProjectComponent,
    ImportProjectFromDomainComponent,
    ImportTemplateFromDomainComponent,
    CreateStyleComponent,
    ProjectToProjectTemplateComponent,
    ProjectToDomaineComponent,
    StyleNewComponent,
    TemplateStyleListComponent,
    ViewDataCriteriaDialogComponent,
    GeomFormsListComponent,
    FeatureMultipleFormDataMapComponent,
    EditStyleComponent,
    ImportImklFileComponent,
    FoundProjectsComponent,
    FoundFeaturesComponent,
    EnexisExportCritDialogComponent,
    EditGeomOrCoordValuesComponent,
    ImportExcelFileComponent,
    ExportStedinCritDialogComponent,
    MapItemDialogComponent,
    ImportShapeFileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatVideoModule,
    OverlayModule,
    DragDropModule,
    MatRadioModule,
    MatDividerModule,
    MatStepperModule,
    MatTooltipModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
      libraries: ['places', 'drawing', 'geometry']
    })
  ],
  providers: [
    UserService,
    ProjectService,
    TemplateService,
    FeatureService,
    ProjectMemberService,
    TitleCasePipe,
    MatDatepickerModule,
    {
      provide: 'BaseURL', useValue: baseURL
    }
  ],
  entryComponents: [
    CreateProjectComponent,
    CreateTemplateComponent,
    CreateFieldsetComponent,
    CreateFieldsetArrayComponent,
    CreateIntegerFieldComponent,
    CreateStringFieldComponent,
    CreateDecimalFieldComponent,
    CreateAutoincrementFieldComponent,
    CreatePhotoFieldComponent,
    CreateVideoFieldComponent,
    CreateAudioFieldComponent,
    CreateBooleanFieldComponent,
    CreateDateFieldComponent,
    CreateTimeFieldComponent,
    CreateNoteFieldComponent,
    CreateBarcodeFieldComponent,
    CreateArrayFieldComponent,
    CreateGpsCoordinatesFieldComponent,
    FieldComponent,
    ConfirmationDialogComponent,
    ProjectMemberDialogComponent,
    TemplatesPermissionsDialogComponent,
    CollaboratorsDialogComponent,
    AddMemberDialogComponent,
    ReportTemplateDialogComponent,
    SettingCoordinatesReferenceDialogComponent,
    MarxactLoginPageComponent,
    ExportDataCriteriaDialogComponent,
    AttributesetarrayDialogComponent,
    GeomTypeCoordinatesDialogComponent,
    MediaShowingDialogComponent,
    EditFeatureValueComponent,
    ConstraintListDialogComponent,
    ConstraintDialogComponent,
    FeatureMapItemDialogComponent,
    ConfigServerUrlComponent,
    ActivateAccountDialogComponent,
    AssignmarxactdevicesComponent,
    MarxactPageComponent,
    ChooseLabelDialogComponent,
    CollaboratorListDialogComponent,
    ImportErrorDlgComponent,
    ImportProjectComponent,
    ImportProjectFromDomainComponent,
    ImportTemplateFromDomainComponent,
    CreateStyleComponent,
    ProjectToProjectTemplateComponent,
    ProjectToDomaineComponent,
    StyleNewComponent,
    ViewDataCriteriaDialogComponent,
    GeomFormsListComponent,
    EditStyleComponent,
    EnexisExportCritDialogComponent,
    EditGeomOrCoordValuesComponent,
    ExportStedinCritDialogComponent,
    MapItemDialogComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() { }
}
