import { Component, OnInit, HostListener } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { ProjectService, PROJECTS, ProjectToken } from 'src/app/services/project.service';
import { Token } from 'src/app/share/token';
import { TemplateService } from 'src/app/services/template.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CreateProjectComponent } from '../dialogs/create-project/create-project.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { getToken, persistProject, removeProject,
  isTokenValid, logoutUser, getTemplate, removeTemplate, getFormUpdating,
  setFormUpdating,
  getUser, isCollaborator,
  removeFieldToShowOnMap, removeSearchObject, 
  removeSearchValue, removeCurrentFeaturesUri, getProject, 
  getAdminToken,
  persistTemplate,
  removeGeomFormToShow,
  persistGeomFormToShow} from 'src/app/share/utils';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  updatingForm: boolean;
  message = ' ';
  currentProject: Project;
  projectImageUrl: string;
  fieldset;
  selectedProject: string;
  newProjName: string;
  scrHeight: any;
  scrWidth: any;
  proToken: ProjectToken;
  proClass: string = null;
  visibility: string = null;
  archived: boolean = false;

  constructor(
    public store: StoreService,
    private router: Router,
    private projectService: ProjectService,
    private templateService: TemplateService,
    private dialog: MatDialog
    ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight - 127;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit() {
    this.getScreenSize();
    this.store.readProjects([]);
    this.currentProject = this.store.project;
    this.selectedProject = 'nothing';
    const user = this.store.user;
    
    if (isCollaborator(user)) {
      this.getCollaboratorProjects();
    } else {
      this.getProjectOnProClassChange();
      this.store.readTemplates([]);
    }
    this.store.visibleProject.subscribe(pc => {
   
      this.setProject(null);
      this.store.readTemplates([]);
      if(isCollaborator(this.store.user) ) {
        this.getCollaboratorProjects();
      } else {
        this.getProjectOnProClassChange();
      }
    });

    this.store.showOrHideProjectsContainer(false);
    this.store.showOrHideTemplatesContainer(false);
  }

  getCollaboratorProjects() {
    let token: Token = getToken();
    this.proClass = this.store.proClass;
    this.visibility = this.store.visibility;
    this.archived = this.store.archived;
    this.store.showLoading();
    this.projectService.getCollaboratorProjects(token, this.proClass, this.visibility, this.archived).subscribe(
      res => {
        if(this.proClass === 'template' || this.proClass === 'domain') {
          const filteredList = this.filterProjects(token.user_id, res);
          this.store.readProjects(filteredList);
        } else {
          this.store.readProjects(res);
        }
        this.store.show_Projects();
        this.store.showOrHideProjectsContainer(false);
        this.store.hideLoading();
      },
      errmess => {
        this.showMessageDialog(errmess);
        this.store.readProjects(PROJECTS);
        this.store.hideLoading();
      }
    );
  }

  getProjectOnProClassChange() {
 
    let token: Token = getToken();
    this.store.showLoading();
    let isUserOrMCAdminConnected;
    let isMyCumulusAdministrator;
    isMyCumulusAdministrator = false;

    this.proClass = this.store.proClass;
    this.visibility = this.store.visibility;
    this.archived = this.store.archived;
    if (token === null || token === undefined) {
      token = getAdminToken();
      if (token === null || token === undefined) {
        isUserOrMCAdminConnected = false
      }  else {
        if(isTokenValid(token)) {
          isUserOrMCAdminConnected = true;
          isMyCumulusAdministrator = true;
          this.store.hideTemplate();
        } else {
          isUserOrMCAdminConnected = false
        }
      }
    } else {
      if(isTokenValid(token)) {
        isUserOrMCAdminConnected = true;
      } else {
        isUserOrMCAdminConnected = false
      }
    }

    if (!isUserOrMCAdminConnected) {
      logoutUser();
      this.store.logout();
      this.store.token = null;
      this.store.hideLoading();
      this.store.hideProjects();
      this.showMessageDialog('You activation token is expired or you are not connected');
      this.router.navigate(['/login']);
      return;
    } 
    const user = this.store.user;
    this.proToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    this.projectService.getProjects(this.proToken, this.proClass, this.visibility, this.archived, isMyCumulusAdministrator).subscribe(
      res => {
        if(this.proClass === 'template' || this.proClass === 'domain') {
          const filteredList = this.filterProjects(user.id, res);
          this.store.readProjects(filteredList);
        } else {
          this.store.readProjects(res);
        }
        
        this.store.show_Projects();
        this.store.hideLoading();
        this.store.showOrHideProjectsContainer(false);
        const currentProject = getProject();
        if (currentProject) {
          this.setProject(currentProject);
        }
      },
      errmess => {
        this.showMessageDialog(errmess);
        this.store.readProjects(PROJECTS);
        this.store.hideLoading();
    });
  }

  filterProjects(userId: string, res : Project[]){
    return res.filter(p => p.created_by === userId);
  }

  showMessageDialog(msg) {
    this.message = msg;
    this.store.showMessage = true;
    setTimeout(() => {
      this.store.showMessage = false;
    }, (10000));
  }

  setFieldSet() {
    this.fieldset = {
      _class: 'fieldset',
      name: 'username',
      label: 'Username',
      description: 'This is the description of the username',
      constraints: [],
      fields: [
        {
          _class: 'string-field',
          name: 'username',
          label: 'Username',
          description: 'This is the description of the username',
          constraints: []
        },
        {
          _class: 'string-field',
          name: 'username',
          label: 'Username',
          description: 'This is the description of the username',
          constraints: []
        },
        {
          _class: 'string-field',
          name: 'username',
          label: 'Username',
          description: 'This is the description of the username',
          constraints: []
        },
        {
          _class: 'string-field',
          name: 'username',
          label: 'Username',
          description: 'This is the description of the username',
          constraints: []
        }
      ]
    };
  }

  onSubmit() {
    // this.store.createProject(this.project);
  }

  openCreateProjectDialog() {
    this.testIfUpdatingForm(() => {
      this.openCreateProjectDialogAfterCheck();
    });
  }

  openCreateProjectDialogAfterCheck() {
    this.dialog.open(CreateProjectComponent, {width: '500px', height: 'auto'});
  }

  showProjectTemplates() {
    this.testIfUpdatingForm(() => {
      this.showProjectTemplatesAfterCheck();
    });
  }

  showProjectTemplatesAfterCheck() {
    if (this.currentProject === undefined || this.currentProject === null) {
      this.showMessageDialog('No Project is selected, please select the project to be deleted');
      return;
    }
    this.getTemplates(this.currentProject);
  }

  openUpdateProjectDialog() {
    this.testIfUpdatingForm(() => {
      this.openUpdateProjectDialogAfterCheck();
    });
  }

  openUpdateProjectDialogAfterCheck() {
    if (this.currentProject === undefined || this.currentProject === null) {
      this.showMessageDialog('No Project is selected, please select the project to be updated');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project: this.currentProject,
      mode: 'Update'
    };
    this.dialog.open(CreateProjectComponent, dialogConfig);
  }

  removeProject() {
    this.testIfUpdatingForm(() => {
      this.removeProjectAfterCheck();
    });
  }

  removeProjectAfterCheck() {
    if (this.currentProject === undefined || this.currentProject === null) {
      this.showMessageDialog('No Project is selected, please select the project to be deleted?');

      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: `Do you confirm the deletion of the project "${this.currentProject.name}"?`,
        title: 'Confirm deleting'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        this.store.showLoading();
        this.projectService.deleteProject(this.currentProject, token).subscribe(
          res => {
            this.store.deleteProject(res);
            const projectName = this.currentProject.name;
            this.currentProject = null;
            this.store.hideLoading();
            this.showMessageDialog(`Project ${projectName} deleted with success`);
          },
          errmes => {
            this.store.hideLoading();
            this.showMessageDialog('Error while deleting the project' + errmes);
          }
        );
      }
    });
  }

  duplicateProject() {
    this.testIfUpdatingForm(() => {
      this.duplicateProjectAfterCheck();
    });
  }

  /**
   *
   */
  duplicateProjectAfterCheck() {
    // Do you really want to duplicate the project?
    // If yes, a new project will be made containing the same forms as the original project.
    // The records in the forms will not be copied!
    if (this.currentProject === undefined || this.currentProject === null) {
      this.showMessageDialog('No Project is selected, please select the project to be deleted');
      return;
    }

 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: `Do you really want to duplicate the project "${this.currentProject.name}"? ` +
        `If yes, a new project will be made containing the same forms as the original project. ` +
        `The records in the forms will not be copied!`,
        title: 'Duplicate Project?'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.createProjectAndTemplates();
      }
    });
  }

  /**
   *
   */
  createProjectAndTemplates() {
    this.testIfUpdatingForm(() => {
      this.createProjectAndTemplatesAfterCheck();
    });
  }

  createProjectAndTemplatesAfterCheck() {
    this.newProjName = this.currentProject.name + ' Copy 1';
    const project = {
      name: this.newProjName,
      description: this.currentProject.description,
      image_file_url: this.currentProject.image_file_url
    };
    const token = getToken();
    const oldProject = this.currentProject;
    this.store.showLoading();
    this.projectService.createProject(project, token).subscribe(
      res => {
        const newProject = res;
        // this.store.createProject(res);
        this.templateService.getTemplates(token, this.currentProject.id).subscribe(
          res2 => {
            const templates = res2;
            if (templates.length > 0) {
              for (const template of templates) {
                const newTemplate = {
                  name: template.name,
                  description: template.description,
                  image_file_url: template.image_file_url,
                  project_id: newProject.id,
                  feature: template.feature,
                  form: template.form,
                  map_style: template.map_style
                };
                this.store.showLoading();
                this.templateService.createTemplate(newTemplate, newProject.id, token).subscribe(
                  res3 => {
                    this.store.createTemplate(res3);
                    this.store.hideLoading();
                  },
                  err3 => {
                    this.showMessageDialog(`Error while duplicating the template "${template.name}"`);
                    this.store.hideLoading();
                    return;
                  }
                );
              }
              this.store.createProject(res);
            } else {
              this.store.hideLoading();
            }
          },
          err2 => {
            this.showMessageDialog(`Error while getting the templates of the old project`);
            this.store.hideLoading();
            return;
          }
        );
      },
      err => {
        if(err.includes("409")){
          const names = this.newProjName.split('Copy');
          let num = +names[names.length - 1] + 1;
          const project = {
            name: this.currentProject.name + ' Copy ' + num,
            description: this.currentProject.description,
            image_file_url: this.currentProject.image_file_url
          };
          const token = getToken();
          const oldProject = this.currentProject;
          this.store.showLoading();
          this.projectService.createProject(project, token).subscribe(
            res3 => {
              const newProject = res3;
              // this.store.createProject(res);
              this.templateService.getTemplates(token, this.currentProject.id).subscribe(
                res4 => {
                  const templates = res4;
                  if (templates.length > 0) {
                    for (const template of templates) {
                      const newTemplate = {
                        name: template.name,
                        description: template.description,
                        image_file_url: template.image_file_url,
                        project_id: newProject.id,
                        feature: template.feature,
                        form: template.form,
                        map_style: template.map_style
                      };
                      this.store.showLoading();
                      this.templateService.createTemplate(newTemplate, newProject.id, token).subscribe(
                        res5 => {
                          this.store.createTemplate(res5);
                          this.store.hideLoading();
                        },
                        err4 => {
                          this.showMessageDialog(`Error while duplicating the template "${template.name}"`);
                          this.store.hideLoading();
                          return;
                        }
                      );
                    }
                    this.store.createProject(res3);
                  } else {
                    this.store.hideLoading();
                  }
                },
                err5 => {
                  this.showMessageDialog(`Error while getting the templates of the old project`);
                  this.store.hideLoading();
                  return;
                }
              );
            },
            err4 => {
              if (err4.includes("409")) {
                this.showMessageDialog(`You or one of your collaborator has a project with the same name. `);
                this.store.hideLoading();
              }
              this.showMessageDialog(`Error while creaing the new project, please retry later `);
              this.store.hideLoading();
            });
        }
        this.showMessageDialog(`Error while creaing the new project, please retry later `);
        this.store.hideLoading();
        return;
      });
  }

  getProject(id) {
    setTimeout(() => {
      // console.log('Commint within 2 seconds');
    },
    2000);
    this.store.readProjectById(id);
  }

  testIfUpdatingForm(action) {
    this.updatingForm = getFormUpdating();
    if (this.updatingForm !== undefined &&
        this.updatingForm !== null &&
        this.updatingForm === true) {
        const templ = getTemplate();
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message:
            `Some changes to the form "${templ.name}" have been made. \nDo you want to continue without saving the changes?`,
            title: 'Save changes?'
          }
        });

        dialogRef.afterClosed().subscribe(result  => {
          if (result) {
            setFormUpdating(false);
            action();
          }
        });
    } else {
      action();
    }
  }

  setProject(p) {
 
    const templ = this.store.template.getValue();
    if ((templ !== null && templ !== undefined) && (templ.form === null || templ.form === undefined)) {
      const message = `The form "${templ.name}" should contain at least one field. \nContinue?`;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: 'MyCumulus',
          message
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          this.openTemplatePage(p);
        } else {
          const olp = this.store.project;
          this.store.selectedProject.next(olp.name);
        }
      });
    } else {
      this.updatingForm = this.store.updatingForm;
      if (this.updatingForm) {
        if (templ === null || templ === undefined) {
          // const mes = `Do you really want to stop import of CSV?`;
          const mes = ``;
          const dlgRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: {
              title: 'Cancel Import?',
              message: mes
            }
          });
          dlgRef.afterClosed().subscribe(result  => {
            if (result) {
              setFormUpdating(false);
              this.store.updatingForm = false;
              this.store.changeFormUpdatingState(false);
              this.openTemplatePage(p);
            } else {
              return;
            }
          });
          return;
        } else {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: {
              message:
              `Some changes to the form "${templ.name}" have been made. \nDo you want to continue without saving the changes?`,
              title: 'Save changes?'
            }
          });
          dialogRef.afterClosed().subscribe(result  => {
            if (result) {
              setFormUpdating(false);
              this.store.updatingForm = false;
              this.store.changeFormUpdatingState(false);
              this.openTemplatePage(p);
            } else {
              const olp = this.store.project;
              this.store.selectedProject.next(olp.name);
            }
          });
        }
      } else {
        this.openTemplatePage(p);
      }
    }
  }

  onSelected(p, event) {
    event.preventDefault();
    this.setProject(p);
    if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
      this.store.destinationProject = p;
    }
    event.stopPropagation();
  }

  openTemplatePage(p) {
    removeProject();
    removeFieldToShowOnMap();
    removeSearchObject();
    removeSearchValue();
    removeCurrentFeaturesUri();
    this.store.features = null;
    this.currentProject = p;
    this.store.readProject(p);
    persistProject(this.currentProject);
    this.store.template.next(null);
    persistTemplate(null);
    this.store.selectedTemplate.next('');
    this.store.selectedFieldSet.next('');
    // this.store.hideTemplate();
    if(p !== null && p !== undefined) {
      this.getTemplates(p);
      this.store.showTemplates();
    } else {
      this.store.hideTemplate();
    }
  }

  imklFileImported(a){
    const project = getProject();
    if(project !== null && project !== undefined) {
      this.getTemplates(project);
      this.store.showTemplates();
    } else {
      this.store.hideTemplate();
    }
  }

  getTemplates(p) {
    let isMyCumulusAdministrator = false;
    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    } 
    
    this.store.showLoading();
    this.templateService.getTemplates(token, p.id, isMyCumulusAdministrator).subscribe(
      res => {
        // this.templates = res;
        this.store.readTemplates(res);
        this.saveDefaultFormsToShow();
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.showMessageDialog(errmess);
    });
  }

  saveDefaultFormsToShow() {
    const labels = [];
    
    for (const key of this.store.templates) {
        labels.push(key['id']);
    }
    const obj = {};
    if(labels && labels.length > 0) {
      for(const templName of labels) {
        obj[templName] = true;
      }
      removeGeomFormToShow();
      if(obj) {
        persistGeomFormToShow(obj);
      }
    }
  }

  onSelectedTemplate(t) {
    this.store.template = t;
    // this.setFieldSet();
  }

  isActive(p) {
    if (this.currentProject !== null && this.currentProject !== undefined) {
      return this.currentProject.name = p.name;
    }
    return false;
  }



  /*
  url(endpoint)  {
    return baseURL + endpoint;
  }
  */

  /*getProjects(token) {
    this.projectService.getProjects(token, ).subscribe(
      projects => {
        this.store.readProjects(projects);
      },
      errmsg => {
        this.store.readProjects(PROJECTS);
        this.showMessageDialog(errmsg);
      }
    );
  } */

  onProjectCreated(p) {
    // this.projects.push(p);
  }

  onProjectUpdated(p) {
    // this.projects.push(p);
  }

  onProjectDeleted(p) {
    // this.projects.push(p);
  }

  onTemplateCreated(t) {
    // this.templates.push(t);
  }

  fieldCreated(t) {

  }

}
