import { Injectable } from '@angular/core';
import { User } from '../share/user';
import { Token } from '../share/token';
import { Template, TemplateToDuplicate } from '../share/template';
import { Observable, of, pipe } from 'rxjs';
import { delay, map, catchError  } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { TemplatesResult, TemplateResult } from '../share/result';
import { getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  deleteTemplate(template, projectId, token, isMyCumulusAdministrator = false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.key
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.key
        })
      };
    }

    return this.http.delete<TemplateResult>(getPrivateApiAURL() + 'templates/' + template.id, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateTemplate(template, projectId, token: TemplateToken, isMyCumulusAdministrator=false): Observable<Template> {
    // // console.log(JSON.stringify(template));
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    return this.http.put<TemplateResult>(getPrivateApiAURL() + 'templates/' + template.id, template, httpOptions)
    .pipe(
      map(res => {
        //// console.log(res);
        return res.template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createTemplate(template, projectId, token: TemplateToken, isMyCumulusAdministrator=false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'templates', template, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  importDataInTemplate(data, token, projectId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'imports/data', data, httpOptions).pipe(
      map(res => {
        if (res['template']) {
          return res['template'];
        } else if (res['features']) {
          return res['features'];
        }
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  importShapeInTemplate(data, token, projectId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'imports/shape', data, httpOptions).pipe(
      map(res => {
        if (res['template']) {
          return res['template'];
        } else if (res['features']) {
          return res['features'];
        }
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }


  importIKMLFile(data, token, projectId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.post<any>(getPrivateApiAURL() + 'imports/imkl', data, httpOptions).pipe(
      map(res => {
        if (res['template']) {
          return res['template'];

        } else if (res['features']) {
          return res['data'];
        }
        return res;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getTemplate(template, projectId, token, isMyCumulusAdministrator=false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    // httpOptions.headers.append(token.key, token.value);

    return this.http.get<TemplateResult>(getPrivateApiAURL() + 'templates/' + template.id, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }


  getTemplateById(templateId, projectId, token, isMyCumulusAdministrator=false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    // httpOptions.headers.append(token.key, token.value);

    return this.http.get<TemplateResult>(getPrivateApiAURL() + 'templates/' + templateId, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getTemplates(token, projectId, isMyCumulusAdministrator = false): Observable<Template[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.key
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.key
        })
      };
    }

    return this.http.get<TemplatesResult>(getPrivateApiAURL() + 'projects/' + projectId + '/templates', httpOptions)
    .pipe(map(result => {
      return result.templates.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getTemplatesByMCAdmin(): Observable<Template[]> {
    const t = {};
    const u = {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<Template[]>(getPrivateApiAURL() + 'templates', httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  duplicateTemplate(template: TemplateToDuplicate, token: TemplateToken, isMyCumulusAdministrator = false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Working-Project-Id': template.project_id,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Working-Project-Id': template.project_id,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    // httpOptions.headers.append(token.key, token.value);

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'template/duplicate', template, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  copyFromDomain(form, token): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'form/template/copy', form, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}

export const TEMPLATES: Template[] = [];

export interface TemplateToken {
  key: string;
  value: string;
}
