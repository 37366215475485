import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';
import { LogoutResult, TokenResult, MyCumulusAdminResult, MyCumulusUsersResult, UsersResult, ListUser, UserResult } from '../share/result';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { map, catchError } from 'rxjs/operators';
import { Token } from '../share/token';
import { User, MyCumulusUser } from '../share/user';
import { getPrivateApiAURL } from '../share/utils';
import { environment } from 'src/environments/environment';

export const SIGNUP_ADMIN_LOGIN = environment.SignupAdministrator.username;
export const SIGNUP_ADMIN_PASS = environment.SignupAdministrator.password;

@Injectable({
  providedIn: 'root'
})
export class SysAdminUserServiceService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService
  ) { }

  logout(token): Observable<string> {
    console.log(token);

    const httpOptions = {
      headers: new HttpHeaders({
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.post<LogoutResult>(getPrivateApiAURL() + 'mycumulusadmins/logout', null, httpOptions)
    .pipe(map(result => {
      return result.message;
    }))
    .pipe(
      map(error => {
        return error;
      })
    );
  }

  loginUser(user): Observable<Token> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const loginBody = {
      credentials: {
        name: user.name,
        password: user.password,
      },
      remember: user.remember
    };
    return this.http.post<TokenResult>(getPrivateApiAURL() + 'mycumulusadmins/login', loginBody, httpOptions)
    .pipe(map(result => {
      return result.token;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  login(username, userpassword, remember = false): Observable<Token> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const loginBody = {
      credentials: {
        name: username,
        password: userpassword,
      },
      remember
    };
    return this.http.post<TokenResult>(getPrivateApiAURL() + 'mycumulusadmins/login', loginBody, httpOptions)
    .pipe(map(result => {
      return result.token;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  signupUser(user, adminToken): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': adminToken.key
      })
    };
    return this.http.post<any>(getPrivateApiAURL() + 'users/signup', user, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteMyCumulusAdmin(userId, token): Observable<MyCumulusUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.delete<MyCumulusAdminResult>(getPrivateApiAURL() + 'mycumulusadmins/' + userId, httpOptions)
    .pipe(map(res => {
      return res.mycumulus_admin;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }


  getAdminUser(userId, token): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Admin-Auth-Token': token.key
      })
    };
    const url = getPrivateApiAURL()+ 'users/'+userId;
    return this.http.get<UserResult>(getPrivateApiAURL()+ 'users/'+userId, httpOptions)
      .pipe(map(res => {
        return res.user;
      }))
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getApiVersion(): Observable<any> {
    
    
    return this.http.get<any>(getPrivateApiAURL()+ 'version')
      .pipe(map(res => {
        return res.version;
      }))
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateMCUser(user, token): Observable<MyCumulusUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };

    return this.http.put<MyCumulusAdminResult>(getPrivateApiAURL() + 'mycumulusadmins/' + user.id + '/update', user, httpOptions)
    .pipe(map(
      res => {
        return res.mycumulus_admin;
      }
    ))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateMCNameAndEmail(user, token): Observable<MyCumulusUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    const body = {
      email: user.email,
      name: user.name
    };
    return this.http.put<MyCumulusAdminResult> (getPrivateApiAURL() + 'mycumulusadmins/' + user.id + '/update', body, httpOptions)
    .pipe(map(
      res => {
        return res.mycumulus_admin;
      }
    ))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  changePassword(password, new_password, token): Observable<MyCumulusUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    const data = {
      password,
      new_password
    };

    return this.http.put<MyCumulusAdminResult>(getPrivateApiAURL() + 'mycumulusadmins/changepassword', data, httpOptions)
    .pipe(map(result => {
      return result.mycumulus_admin;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteAdminUser(user_id, token): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.delete<UserResult>(getPrivateApiAURL() + 'users/' + user_id, httpOptions)
    .pipe(map(result => {
      return result.user;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  // changeCollaboratorPassword(collaboratorName, newPassword, token): Observable<User> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'X-Auth-Token': token.key
  //     })
  //   };
  //   const data = {
  //     collaborator_name: collaboratorName,
  //     new_password: newPassword
  //   };

  //   return this.http.post<UserResult>(getPrivateApiAURL() + 'users/changecollaboratorpassword', data, httpOptions)
  //   .pipe(map(result => {
  //     return result.user;
  //   }))
  //   .pipe(catchError(this.processHttpMsgService.handleError));
  // }

  createUser(user, token): Observable<MyCumulusUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    const body = {
      email: user.email,
      name: user.name,
      password: user.password,
      admin_type: user.admin_type
    };

    return this.http.post<MyCumulusAdminResult>(getPrivateApiAURL() + 'mycumulusadmins', body, httpOptions)
    .pipe(map(result => {
      return result.mycumulus_admin;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getMyCumulusUser(token): Observable<MyCumulusUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.get<MyCumulusAdminResult>(getPrivateApiAURL() + 'mycumulusadmins/' + token.user_id, httpOptions)
    .pipe(map(result => {
      return result.mycumulus_admin;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getMyCumulusUsers(token): Observable<MyCumulusUser[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.get<MyCumulusUsersResult>(getPrivateApiAURL() + 'mycumulusadmins', httpOptions)
    .pipe(map(result => {
      return result.mycumulus_admins.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getAdminUsers(token, uri): Observable<ListUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      }),
      params: new HttpParams()
        .set('perPage', '500')
    };
    return this.http.get<UsersResult>(getPrivateApiAURL() + uri, httpOptions)
    .pipe(map(result => {
      return result.users;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
