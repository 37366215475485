import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { StyleServiceService } from 'src/app/services/style-service.service';
import { Style } from 'src/app/share/style';
import { User } from 'src/app/share/user';
import { equalsIgnoringCase, getAdminToken, getToken } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-edit-style',
  templateUrl: './edit-style.component.html',
  styleUrls: ['./edit-style.component.scss']
})
export class EditStyleComponent implements OnInit {

  @Input() style: Style;
  styleDialogForm: FormGroup;
  style_id: string;
  template: any;
  name: string;
  loading: boolean = false;
  visibility: string = "public";
  publicVisibility: boolean = false;
  privateVisibility: boolean = false;
  
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = 'Create';
  
  @ViewChild('fform', { static: false}) styleFormDirective;
  private messageBox: MessageBoxComponent;
  styles = [];
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;
  

  constructor(private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private styleService: StyleServiceService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<EditStyleComponent>) { }

  ngOnInit() {
  }


  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }


  createForm() {
    let name = '';
    this.visibility = "public";
    if (this.style !== undefined && this.style !== null) {
      name = this.style.name;
      this.visibility = this.style.visibility;
      this.style_id = this.style.id;
    }
    this.styleDialogForm = this.fb.group({
      visibility: this.visibility,
      style_id: this.style_id
    });
  }

  onSubmit() {
    const temp = this.styleDialogForm.value;
    let changeStyle = true;
    
    this.style = {
      ...temp,
      changeStyle: changeStyle
    };
    this.dialogRef.close(this.style);
  }

  getTemplateStyleOnVisibilityChange(obj) {
    if((obj !== null && obj !== undefined)) {
      this.visibility = obj.value;
    } else {
      if(this.visibility === null || this.visibility === undefined || this.visibility === '') {
        this.visibility = 'public';
      }
    }
    
    let isMyCumulusAdministrator = false;
    let token = getToken();
    
    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator = true;
      }
    }
    
    this.store.showLoading();
    this.styleService.getCompatibleStyles(token, this.visibility, this.template.id, isMyCumulusAdministrator).subscribe(
      res => {
        if(res !== null) {
          this.styles = res.sort(
            (a, b) => {
              return equalsIgnoringCase(a.name, b.name);
            });
        }
        this.store.hideLoading();
      },
      errmess => {
        this.showErroMessage(errmess);
        this.store.hideLoading();
    });
    
  }

  openCreateStyle() {
    this.dialog.open(EditStyleComponent, {width: '500px', height: 'auto'});
  }

}
