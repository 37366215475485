import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Location } from '@angular/common';
import { Project } from 'src/app/share/projects';
import { ProjectService } from '../../services/project.service';
import { url, getUser } from 'src/app/share/utils';
import * as $ from 'jquery';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss'],
})

export class ProjectItemComponent implements OnInit {
  @Input() project: Project;
  projectIcon: string;
  projectForm: FormGroup;
  projectCopy: Project;
  errMsg: string;
  ownProject: boolean;

  selectedProject: any;

  @ViewChild('cform', {static: false}) projectFormDirectives: any;

  constructor(
    public store: StoreService,
    @Inject('BaseURL') private BaseURL) {

  }

  ngOnInit() {
    if (this.project !== null && this.project !== undefined) {
      this.projectIcon = this.getImageFileUrl();
      if (this.store.project !== null
          && this.store.project !== undefined
          && this.store.project.name === this.project.name) {
          this.handleClick(this.project);
      }
    }

    if(this.project !== null){
      const user = getUser();
      if(user.id === this.project.created_by){
        this.ownProject = true;
      } else {
        this.ownProject = false;
      }
    }
  }

  getImageFileUrl() {
    if (this.project !== null && this.project !== undefined) {
      if (this.project.image_file_url !== null && this.project.image_file_url !== undefined && this.project.image_file_url !== ""){
        return url(this.project.image_file_url);
      } else {
        const letter = (this.project.name)[0];
        switch(letter){
          case 'A':
          case 'a':
            return '/assets/Icons/UpperCase/A.png';
            break;
          case 'B':
          case 'b':
            return '/assets/Icons/UpperCase/B.png';
            break;  
          case 'C':
          case 'c':
              return '/assets/Icons/UpperCase/C.png';
              break;
          case 'D':
          case 'd':
              return '/assets/Icons/UpperCase/D.png';
              break;
          case 'E': 
          case 'e':
          case 'é':
          case 'è':
          case 'É':
          case 'È':
          case 'ë':
            return '/assets/Icons/UpperCase/E.png';
            break;
          case 'F':
          case 'f':
            return '/assets/Icons/UpperCase/F.png';
            break;  
          case 'G':
          case 'g':
            return '/assets/Icons/UpperCase/G.png';
            break;
          case 'H':
          case 'h':
            return '/assets/Icons/UpperCase/H.png';
            break;
          case 'I':
          case 'i':
            return '/assets/Icons/UpperCase/I.png';
            break;  
          case 'J':
          case 'j':
            return '/assets/Icons/UpperCase/J.png';
            break;    
          case 'K':
          case 'k':
            return '/assets/Icons/UpperCase/K.png';
            break;     
          case 'L':
          case 'l':
            return '/assets/Icons/UpperCase/L.png';
            break; 
          case 'M':
          case 'm':
            return '/assets/Icons/UpperCase/M.png';
            break; 
          case 'N':
          case 'n':
            return '/assets/Icons/UpperCase/N.png';
            break;     
          case 'O':
          case 'o':
          case 'ø':
          case 'Ø':
          case 'ö':
          case 'Ö':
            return '/assets/Icons/UpperCase/O.png';
            break; 
          case 'P':
          case 'p':
            return '/assets/Icons/UpperCase/P.png';
            break;  
          case 'Q':
          case 'q':
            return '/assets/Icons/UpperCase/Q.png';
            break;  
          case 'R':
          case 'r':
            return '/assets/Icons/UpperCase/R.png';
            break;   
          case 'S':
          case 's':
            return '/assets/Icons/UpperCase/S.png';
            break; 
          case 'T':
          case 't':
            return '/assets/Icons/UpperCase/T.png';
            break; 
          case 'U':
          case 'u':
          case 'ü':
            return '/assets/Icons/UpperCase/U.png';
            break; 
          case 'V':
          case 'v':
            return '/assets/Icons/UpperCase/V.png';
            break;     
          case 'W':
          case 'w':
            return '/assets/Icons/UpperCase/W.png';
            break; 
          case 'X':
          case 'x':
            return '/assets/Icons/UpperCase/X.png';
            break; 
          case 'Y':
          case 'y':
            return '/assets/Icons/UpperCase/Y.png';
            break;   
          case 'Z':
          case 'z':
            return '/assets/Icons/UpperCase/Z.png';
            break;  
          case '0':
            return '/assets/Icons/Digits/0.png';
            break; 
          case '1':
            return '/assets/Icons/Digits/1.png';
            break; 
          case '2':
            return '/assets/Icons/Digits/2.png';
            break; 
          case '3':
            return '/assets/Icons/Digits/3.png';
            break;     
          case '4':
            return '/assets/Icons/Digits/4.png';
            break; 
          case '5':
            return '/assets/Icons/Digits/5.png';
            break; 
          case '6':
            return '/assets/Icons/Digits/6.png';
            break;   
          case '7':
            return '/assets/Icons/Digits/7.png';
            break;    
          case '8':
            return '/assets/Icons/Digits/8.png';
            break; 
          case '9':
            return '/assets/Icons/Digits/9.png';
            break;                                                                                                                                                                                               
          default:
            return '/assets/Icons/UpperCase/F.png';
            break;
        }
      }
    }
  }

  deleteProject(p) {

  }

  updateProject(p) {

  }

  projectIconUrl(p): string {
    return '';
  }

  handleClick(proj) {
    // let $items = $('.project-item');
    // const classHighlight = "projecthighlight";
    // $items.removeClass(classHighlight);
    this.store.selectedProject.next(proj.name);
    // this.store.selectedTemplate.next('');
    // this.store.selectedFieldSet.next('');
    this.selectedProject = proj.name;
  }
}
