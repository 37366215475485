import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { FieldSet } from 'src/app/share/form/fieldset';
import { persistCurrentFieldset, persistSelectedFieldIndex } from 'src/app/share/utils';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CreateFieldsetComponent } from '../../dialogs/create-fieldset/create-fieldset.component';
import * as $ from 'jquery';
import { StoreService } from 'src/app/services/store.service';
import { CreateFieldsetArrayComponent } from '../../dialogs/create-fieldset-array/create-fieldset-array.component';

@Component({
  selector: 'app-fieldset',
  templateUrl: './fieldset.component.html',
  styleUrls: ['./fieldset.component.scss']
})
export class FieldsetComponent implements OnInit {
  @Input() fieldset;
  @Input() parent: FieldSet;
  @Input() notRootFieldset: boolean;
  @Input() index: number;
  selectedParent: string;
  selectedFSN: string;

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
  }

  getFieldTypeText(type) {
      switch (type) {
        case 'fieldset':
          return 'Fieldset';

        case 'arrayfieldset':
          return 'Fieldset Array';
      }
  }

  isEltField(field): boolean {
    if (field._class !== 'fieldset' && field._class !== 'fieldsetarray' && field._class !== 'arrayfieldset') {
      return true;
    }
    return false;
  }

  isEltFieldset(field): boolean {
    if (field._class === 'fieldset' || field._class === 'fieldsetarray' || field._class === 'arrayfieldset') {
      return true;
    }
    return false;
  }

  removeSelectedFieldset() {
    const items = $('.highlightParent');
    const classHighlight = 'highlightParent';
    items.removeClass(classHighlight);
  }

  /**
   * This method call the confirmation button before delete a fieldset
   * @param f The selected fieldset
   */
  openDeleteConfirmationDialog(f) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: 'Do you want to delete the selected fieldset and all it fields?',
        title: 'Delete selected Fieldset'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.deleteFieldset(f);
      }
    });
  }

  /**
   * This method delete the selected fieldset from the list of fields in parent
   * If the index is lower than 0 or egal the length of the parent fields, nothing happen
   * @param f The selected fieldset
   */
  deleteFieldset(f) {
    if (this.index < 0 || this.index > this.parent.fields.length) {
      return;
    }
    this.parent.fields.splice(this.index, 1);
    // this.deletedField.emit(f);
  }

  /**
   * This method move to the top the selected fieldset
   * If the index is egal to 0, nothing happen
   * @param f The selected fieldset
   */
  moveToTop(f) {
    if (this.index <= 0) {
      return;
    }
    this.parent.fields.splice(this.index, 1);
    this.parent.fields.unshift(f);
    // this.movedTop.emit(f);
  }

  /**
   * This method move to the bottom the selected fieldset
   * If the index is egal the length of the parent fields, nothing happen
   * @param f The selected fieldset
   */
  moveToBottom(f) {
    if (this.index >= this.parent.fields.length - 1) {
      return;
    }
    this.parent.fields.splice(this.index, 1);
    this.parent.fields.push(f);
    // this.movedBottom.emit(f);
  }

  /**
   * This method move up the selected fieldset
   * If the index is egal to 0, nothing happen
   * @param f The selected fieldset
   */
  moveUp(f) {
    if (this.index <= 0) {
      return;
    }
    const upField = this.parent.fields[this.index - 1];
    this.parent.fields[this.index - 1] = f;
    this.parent.fields[this.index] = upField;

    // this.movedUp.emit(f);
  }

  /**
   * This method move down the selected fieldset
   * If the index is egal the length of the parent fields, nothing happen
   * @param f The selected fieldset
   */
  moveDown(f) {
    if (this.index >= this.parent.fields.length - 1) {
      return;
    }
    const upField = this.parent.fields[this.index + 1];
    this.parent.fields[this.index + 1] = f;
    this.parent.fields[this.index] = upField;
    // this.movedDown.emit(f);
  }

  /**
   * This method create and return the MatDialogConfig setting for the dialog to open
   * for updating a selected field
   * @param f The current field element
   * @param parent The parent fieldset of the selected field
   */
  setUpdateDialogConfig(f, parent): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Update',
      fieldset: f,
      index: this.index,
      parent
    };
    persistSelectedFieldIndex(this.index);
    return dialogConfig;
  }

  setUpdateFSArrayDialogConfig(f, parent): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Update',
      fieldsetArray: f,
      index: this.index,
      parent
    };
    persistSelectedFieldIndex(this.index);
    return dialogConfig;
  }

  /**
   * This method open integer dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openUpdateField(f) {

    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    let dialogRef = null;

    if (f._class === 'fieldsetarray' || f._class === 'arrayfieldset') {
      dialogRef = this.dialog.open(CreateFieldsetArrayComponent, dialogConfig);
    } else  {
      dialogRef = this.dialog.open(CreateFieldsetComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        if (result.action === 'Update') {
          if (this.parent !== null && this.parent !== undefined) {
            this.parent.fields.splice(this.index, 1, result.field);
          }
        }
        this.applyUpdate(result);
        // this.activeFieldset.fields.push(result);
        // this.fields.push(result);
      }
    });
  }

  applyUpdate(result) {

  }


  handleFieldsetClicked(event, f) {
    // Emit this event as active fieldset
    // event.preventDefault();
    this.removeSelectedFieldset();
    // this.handleClick(f);
    // this.renderer.addClass(this.elementRef.nativeElement, 'highlightParent');
    persistCurrentFieldset(f);
    this.store.selectedFieldSet.next(f.name);
    this.selectedParent = f.name;
    this.selectedFSN = f.name;
    this.store.currentFieldSet = f;
    // event.stopPropagation();
  }

  handleClick(fs) {
    let $items = $('.fs-item');
    const classHighlight = 'selected-fs';
    const classParentHighlight = 'highlightParent';
    $items.removeClass(classHighlight);
    $items.removeClass(classParentHighlight);
    $items = null;
    this.selectedFSN = fs.name;
  }

  fieldMovedUp(event) {

  }

  fieldMovedDown(event) {

  }

  fieldMovedToTop(event) {

  }

  fieldMovedToBottom(event) {

  }

  fieldDeleted(event) {

  }

  fieldUpdated(event) {

  }
}
