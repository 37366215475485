import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { NoteField } from 'src/app/share/form/note-field';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-create-note-field',
  templateUrl: './create-note-field.component.html',
  styleUrls: ['./create-note-field.component.scss']
})
export class CreateNoteFieldComponent implements OnInit {
  noteField: NoteField;
  noteFieldForm: FormGroup;
  @Input() parent: FieldSet;
  @Input() mode = 'Create';

  @ViewChild('fform', { static: false}) noteFieldFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateNoteFieldComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.noteField = data.field;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    if (this.mode === 'Create') {
      this.noteFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        html: ['', Validators.required],
        enabled: true,
        required: false
      });
    } else {
      this.noteFieldForm = this.fb.group({
        name: [this.noteField.name, Validators.required],
        label: [this.noteField.label, Validators.required],
        description: this.noteField.description,
        html: [this.noteField.html, Validators.required],
        enabled: this.noteField.enabled,
        required: this.noteField.required
      });
    }

  }

  onNameChange() {
    if (this.noteFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.noteFieldForm.controls.name.setValue(removeSpecialCharacters(this.noteFieldForm.value.label));
    if (this.noteFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  closeDialog(event) {
    event.preventDefault();
    const result = {
      action: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  onSubmit() {
    if (this.noteField) {
      this.noteField = {
        ...this.noteField,
        ...this.noteFieldForm.value
      };
    } else {
      this.noteField = this.noteFieldForm.value;
    }

    // this.noteField = this.noteFieldForm.value;
    this.noteField._class = 'notefield';

    if (this.noteField.name === this.parent.name) {
      this.showErrorMessage('Field cannot take the same name as the active fieldset');
      return;
    }

    if (this.mode === 'Create') {
      this.store.addField(this.noteField);
    }
    const result = {
      action: 'Create',
      field: this.noteField
    };
    if (this.mode === 'Create') {
      if (isFieldAlreadyExist(this.noteField, this.parent)) {
        this.showErrorMessage('Another field with the same name/label already exists');
        return;
      }
      // this.parent.fields.push(this.stringField);
      this.store.addField(this.noteField);
    } else {
      result.action = 'Update';
      this.store.addField(this.noteField);
    }
    this.dialogRef.close(result);
  }
}
