import { Injectable } from '@angular/core';
import { User } from '../share/user';
import { Observable, of, pipe } from 'rxjs';
import { delay, map, catchError  } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { MarxactUser, MarxactDevice } from '../share/marxact_user';
import { MarxactUserResult, MarxactDevicesResult, UserResult } from '../share/result';
import { getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})

export class MarxactService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  loginToMarxactBackend(token, username, password): Observable<MarxactUser> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const data = {
      username,
      password
    };
    return this.http.post<MarxactUserResult>(getPrivateApiAURL() + 'marxact/' + 'user/login', data, httpOptions)
    .pipe(map( result => result.marxact_user))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getMarxactAccountDevices(token, username, password): Observable<MarxactDevice[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };

    return this.http.get<MarxactDevicesResult>(getPrivateApiAURL() + 'marxact/' + username + '/' + password + '/devices', httpOptions)
     .pipe(map(result => result.devices))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  assignMarxactDeviceToMCUser(token, user_id, username, password, devices: string[]): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const data = {
      user_id,
      password,
      devices
    };
    return this.http.post<UserResult>(getPrivateApiAURL() + 'marxact/' + username + '/user/assign', data, httpOptions)
    .pipe(map(result => result.user))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  removeMarxactDeviceFromMCUser(token, user_id, device_id): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      }),
      body: {
        user_id,
        device_id
      }
    };

    return this.http.delete<UserResult>(getPrivateApiAURL()  + 'marxact/user/remove', httpOptions)
    .pipe(map(result => result.user))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getMarxactDevicesAssignToMCUser(token): Observable<MarxactDevice[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<MarxactDevicesResult>(getPrivateApiAURL()  + 'marxact/devices', httpOptions)
    .pipe(map(result =>  result.devices))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
