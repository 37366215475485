import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTable, MatTableDataSource } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { StyleServiceService } from 'src/app/services/style-service.service';
import { Style } from 'src/app/share/style';
import { Token } from 'src/app/share/token';
import { User } from 'src/app/share/user';
import { getAdminToken, getToken, getUser } from 'src/app/share/utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { StyleNewComponent } from '../dialogs/style-new/style-new.component';
import { MessageBoxComponent } from '../message-box/message-box.component';

export interface ReportTemplate {
  id: string;
  name: string;
  created_at: string;
  place_holders: any[];
  orientation: string;
  default_page_size: string;
  template_preview_url: string;
  user_id: string;
  visible_to: string;
  description: string;
}

const ELEMENT_DATA: Style[] = [];
@Component({
  selector: 'app-template-style-list',
  templateUrl: './template-style-list.component.html',
  styleUrls: ['./template-style-list.component.scss']
})
export class TemplateStyleListComponent implements OnInit {

  user: User;
  isReportAdmin: boolean;
  displayedColumns: string[] = ['name', 'description', 'created_at', 'actions'];
  listStyles: Style[] = [];
  dataSource = new MatTableDataSource<Style>(ELEMENT_DATA);
  selection = new SelectionModel<Style>(true, []);
  searchValue: string;
  visibility: string = null;
  isPrivateStyle = false;
  templateNameWidth: number;
  messageBoxWidth: number;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;
  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private styleService: StyleServiceService,
  ) { }

  ngOnInit() {
    const admin = getUser();

    if(admin.admin_type === 'report_template_administrator'){
      this.isReportAdmin = true;
    } else {
      this.isReportAdmin = false;
    }
    this.visibility = null;
    this.getListeStyles();
  }

  openCreateTemplateStyleDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Create'
    };
    const dialogRef = this.dialog.open(StyleNewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        const templateStyleData = result.data;
        this.store.showLoading();
        const data = this.dataSource.data;
        data.push(templateStyleData);
        this.dataSource.data = data;
        this.table.renderRows();
        this.store.updatingForm = false;
        this.store.hideLoading();
      } else if (result.event === 'Cancel') {
        console.log('Style creation cancelled');
        this.store.hideLoading();
      }
    });
  }

  canEditOrDelete(element): boolean {
    const user = getUser();
    if(element.created_by === user.id) {
        return true;
    }

    return false;
  }

  getListeStyles(): void {
    let token = getToken();
    let isMyCumulusAdministrator = false;
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    }
    this.store.showLoading();
    this.styleService.getStyles(token, this.visibility, isMyCumulusAdministrator).subscribe(
      res => {
        this.listStyles = res;
        const data = this.listStyles.filter(style => style.created_by === token.user_id);
        this.dataSource.data = data;
        this.table.renderRows();
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.showErrorMessage(errmess);
    });
  }

  showOnlyPrivate(obj) {
    const val =  obj.value;
    if( val === null || val === undefined || val === 'all') {
      this.visibility = null;
    } else {
      this.visibility = val;
    }

    this.getListeStyles();
  }

  openUpdateTemplateStyleDialog(style) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Update',
      style: style
    };
    const dialogRef = this.dialog.open(StyleNewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Update') {
        const templateStyle = result.data;
        const data = this.dataSource.data;
        const index = data.findIndex(res => res.id === templateStyle.id);

        if (index >= 0) {
          const deletedData = data.splice(index, 1, templateStyle);
          this.dataSource.data = data;
          this.table.renderRows();
          this.store.updatingForm = false;
        }
        this.store.hideLoading();
      } else if (result.event === 'Cancel') {
        console.log('Style creation cancelled');
        this.store.hideLoading();
      }
    });
  }

  openDeleteTemplateStyleConfirmation(element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: `Delete the Style ${element.name} ?`,
        message: `Do you really want to delete the style '${element.name}' ?`
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        let token = getToken();
        let isMyCumulusAdministrator = false;
        if(token === null || token === undefined) {
          token = getAdminToken();
          isMyCumulusAdministrator = true;
        }
        this.store.showLoading();
        this.styleService.deleteStyle(element, token, isMyCumulusAdministrator).subscribe(
          res => {
            this.store.hideLoading();
            this.getListeStyles();
          },
          errmes => {
            this.store.hideLoading();
            this.showErrorMessage(errmes);
          }
        );
      }
    });
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    this.templateNameWidth = 20;
    this.messageBoxWidth = 80;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
    setTimeout(() => {
      this.templateNameWidth = 80;
      this.messageBoxWidth = 20;
    }, 11000);
    setTimeout(() => {
      this.message = '';
      this.showMessage = false;
    }, 13000);
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    event.stopPropagation();
  }

}
