import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { Field } from 'src/app/share/form/field';
import { MatTableDataSource, MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTable } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Constraint } from 'src/app/share/form/contraints/constraint';
import { ConstraintDialogComponent } from '../constraint-dialog/constraint-dialog.component';
import { FormBuilder } from '@angular/forms';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { StoreService } from 'src/app/services/store.service';

interface Item {
  type: string;
  values: string;
  message: string;
}

interface ConstraintAndIndex {
  index: number;
  constraint: Constraint;
}

const ELEMENT_DATA: Item[] = [];

@Component({
  selector: 'app-constraint-list-dialog',
  templateUrl: './constraint-list-dialog.component.html',
  styleUrls: ['./constraint-list-dialog.component.scss']
})
export class ConstraintListDialogComponent implements OnInit {
  field: Field;
  actions = ['Permissions', 'Remove Members'];
  displayedColumns: string[] = ['select', 'type', 'values', 'message'];
  dataSource = new MatTableDataSource<Item>(ELEMENT_DATA);
  selection = new SelectionModel<Item>(true, []);
  listConstaints: any[];

  constraintsChangedOrUpdated: boolean;

  @ViewChild(MatTable, {static: true}) constraintstable: MatTable<any>;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    public store: StoreService,
    private dialogRef: MatDialogRef<ConstraintListDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog
  ) {
    if (data !== undefined && data !== null) {
      this.field = data.field;
    }
  }

  ngOnInit() {
    if (this.field.constraints !== null && this.field.constraints !== undefined) {
      this.listConstaints = [...this.field.constraints];
    } else {
      this.listConstaints = [];
    }
    this.constraintsChangedOrUpdated = false;
    this.initializeConstraintList();
  }

  initializeConstraintList() {
    // const constraints = this.field.constraints;
    if (this.listConstaints !== null && this.listConstaints !== undefined && this.listConstaints.length > 0) {
      const data = [];
      for (const constraint of this.listConstaints) {
        let val = null;
        const keys = Object.keys(constraint);
        if (keys.indexOf('value') >= 0) {
          val = constraint['value'];
        } else if (keys.indexOf('values') >= 0) {
          val = constraint['values'].join(';');
        }
        const item = {
          type: constraint['_class'],
          values: val,
          message: constraint['error_message']
        };
        data.push(item);
      }
      this.dataSource.data = data;
      // this.constraintstable.renderRows();
    } else {
      this.dataSource.data = [];
    }
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  // remove a constraint from the page.
  removeConstraint() {
    if (this.selection.selected.length <= 0) {
      this.showErroMessage('No constraint is selected');
      return;
    }
    for (const item of this.selection.selected) {
      const index = this.listConstaints.findIndex(c => c['_class'] === item.type);
      if (index >= 0) {
        this.listConstaints.splice(index, 1);
        this.constraintsChangedOrUpdated = true;
      }
    }
    this.initializeConstraintList();
    this.constraintstable.renderRows();
  }

  // set the changes to constraint item after an update
  updateConstraint() {
    if (this.selection.selected.length <= 0) {
      this.showErroMessage('No constraint is selected');
      return;
    }
    if (this.selection.selected.length > 1) {
      this.showErroMessage('Only one constraint should be selected for update');
      return;
    }
    for (const item of this.selection.selected) {
      const index = this.listConstaints.findIndex(c => c['_class'] === item.type);
      this.addConstraintToField(index, 'Update');
    }
    // this.initializeConstraintList();
    // this.constraintstable.renderRows();
  }

  // check the constraint already in the list.
  isConstraintExist(constraint) {
    if (this.listConstaints === undefined || this.listConstaints === null) {
      // this.constraints = [];
      return false;
    }
    const size = this.listConstaints.length;
    if (size === 0) {
      return false;
    }
    for (let i = 0; i < size; i++) {
      const c = this.listConstaints[i];
      if (c['_class'] === constraint._class) {
        return true;
      }
    }
    return false;
  }

  cancelChanges() {
    if (this.constraintsChangedOrUpdated) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          message:
          `Some changes have been made to the field constraints. \nDo you want to continue without saving the changes?`,
          title: 'Save changes?'
        }
      });
      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          this.listConstaints = [];
          this.dialogRef.close();
        }
      });
    } else {
      this.listConstaints = [];
      this.dialogRef.close();
    }
  }

  saveChanges() {
    this.field.constraints = [];
    for (const constraint of this.listConstaints) {
      this.field.constraints.push(constraint);
    }
    if (this.field.constraints.length > 0) {
      this.store.changeFormUpdatingState(true);
    }
    this.dialogRef.close();
  }

  addConstraintToField(index = null, mode = 'Add') {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      field: this.field,
      constraints: this.listConstaints,
      constraintIndex: index,
      mode: mode
    };

    const dialogRef = this.dialog.open(ConstraintDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        const constraint = result.constraint;
        const keys = Object.keys(constraint);
        if (keys.indexOf('values') >= 0) {
          const items = constraint['values'].split(';');
          if (items && items.length > 0) {
            constraint['values'] = items.map(m =>  m.trim());
          }
          /*
          if (this.field._class === 'integerfield' || this.field._class === 'decimalfield') {
            const values = [];
            items.forEach(item => {
              if (isNaN(item)) {
                this.showErroMessage('The list should not contain strings');
                return;
              }
              if (this.field._class === 'integerfield') {
                values.push(parseInt(item));
              } else if (this.field._class === 'integerfield') {
                values.push(parseFloat(item));
              }
            });
            constraint['values'] = values;
          } else {
            constraint['values'] = constraint['values'].split(';');
          }
          */
        } else if (keys.indexOf('value') >= 0) {
          constraint['value'] = constraint['value'];
        }

        if (this.listConstaints !== null && this.listConstaints !== undefined) {
          if (result.event === 'Add') {
            if (this.isConstraintExist(constraint)) {
              this.showErroMessage('This constraint already exist ');
              return ;
            }
            this.listConstaints.push(constraint);
          } else if (result.event === 'Update') {
            this.listConstaints.splice(index, 1, constraint);
            this.constraintsChangedOrUpdated = true;
          }
        } else {
          this.listConstaints = [];
          this.listConstaints.push(constraint);
          this.constraintsChangedOrUpdated = true;
        }
        this.initializeConstraintList();
        this.constraintstable.renderRows();
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Item): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.type + 1}`;
  }

}
