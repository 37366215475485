import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectService } from 'src/app/services/project.service';
import { getAdminToken, getToken, equalsIgnoringCase, getUser } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { FileServiceService } from 'src/app/services/file-service.service';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
@Component({
  selector: 'app-import-project-from-domain',
  templateUrl: './import-project-from-domain.component.html',
  styleUrls: ['./import-project-from-domain.component.scss']
})
export class ImportProjectFromDomainComponent implements OnInit {
  @Input() project: Project;
  projectDialogForm: FormGroup;

  project_id: string;
  user_id: string;
  project_class: string;
  name: string;
  description: string;
  projects: Project[];
  users: User[];
  visibility: string = "public";
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = 'Create';
  @ViewChild('pjtIcon', {static: false}) img;
  @ViewChild('fform', { static: false}) projectFormDirective;

  metadata: any;
  moreUsers: any;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fileService: FileServiceService,
    private mcUserService: SysAdminUserServiceService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ImportProjectFromDomainComponent>
  ) {
    this.projects = [];
    this.users = [];
    this.visibility = "public";
    this.project_class = this.store.proClass;
    this.createForm();
  }

  ngOnInit() {
    this.getProjectOnVisibilityChange();
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  initializeFormData(p: Project) {
    this.project_id = p.id;
    this.user_id = p.created_by;
    this.project_class = p.project_class;
  }

  createForm() {
    this.projectDialogForm = this.fb.group({
      visibility: this.visibility,
      project_id: ['', Validators.required],
      name: "",
      description: ''
    });
  }

  onSubmit() {

    const temp = this.projectDialogForm.value;
    const projectId = temp['project_id'];
    let selectedProject = {
      name: '',
      description: ''

    };
    if(projectId !== null && projectId !== undefined) {
      selectedProject = this.projects.find(p => p.id == projectId);
    }
    let token = getToken();
    const proToken = {
      'key': 'key',
      'value': token.key
    }
    const user = getUser();
    const project = {
      user_id: user.id,
      project_id: temp.project_id,
      name: temp.name,
      description: temp.description
    };
    this.store.showLoading();
    this.projectService.copyProjectFromTemplate(project, proToken).subscribe(
      res => {

        this.store.createProject(res);
        this.store.templates = [];
        this.store.showTemplates();
        this.dialogRef.close(res);
        this.store.hideLoading();
      },
      errmess => {
        // console.log(errmess);
        this.showErroMessage(errmess);
        this.store.hideLoading();
    });
  }

  getProjectOnVisibilityChange(obj = null) {

    this.visibility = obj !== null ? obj.value : "public";

    if(this.visibility === null || this.visibility === undefined) {
      this.visibility = "private";
    }
    let isMyCumulusAdministrator = false;
    let token = getToken();
    let proToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    let proClass = "template";
    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator = true;
        proToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
      }
    }

    const archived = false;
    this.store.showLoading();
    this.projectService.getProjects(proToken, proClass, this.visibility, archived, isMyCumulusAdministrator).subscribe(
      res => {
        if(res !== null) {
          this.projects = res.sort(
            (a, b) => {
              return equalsIgnoringCase(a.name, b.name);
            });
        }
        this.store.hideLoading();
      },
      errmess => {
        this.showErroMessage(errmess);
        this.store.hideLoading();
    });
  }

  getProjectOnChange(obj) {

    const projectId = obj.value;
    let customerId = null;
    if(projectId !== null && projectId !== undefined) {
      const project = this.projects.find(p => p.id == projectId);
      if(project !== null && project !== undefined) {
        this.name = project.name;
        this.description = project.description;
        this.projectDialogForm.controls.name.setValue(project.name);
        this.projectDialogForm.controls.description.setValue(project.description);
      }
    }
  }

  displayUsers(res){
    this.metadata = res.metadata;
    if(this.metadata.uris.next !== null && this.metadata.uris.next !== undefined){
      this.moreUsers = false;
    } else {
      this.moreUsers = true;
    }
    for (const collaborator of  res.instances) {
      if (collaborator.managed_users_ids !== null && collaborator.managed_users_ids !== undefined) {
        this.users.push(collaborator);
      }
    }
    this.users = this.users.sort(
      (a, b) => {
        return equalsIgnoringCase(a.name, b.name);
      });
    this.store.hideLoading();
  }

  loadUsers(uri){

    const token = getAdminToken();
    this.store.showLoading();
    this.mcUserService.getAdminUsers(token, uri).subscribe(
      res => {
        this.displayUsers(res);

        if(this.moreUsers === false)
          this.loadMoreUsers();
      },
      err => {
        this.showErroMessage(err);
        this.store.hideLoading();
      });
  }

  loadMoreUsers(){
    if(this.metadata !== null && this.metadata !== undefined){
      const uris = this.metadata.uris;

      if(uris.next !== null && uris.next !== undefined){
        let linkValue = uris.next;
        for (let i = 0; i < 4; i++) {
          linkValue = linkValue.substring(1);
        }

        this.loadUsers(linkValue);
      }
    }
  }

  openCreateProject() {
    this.dialog.open(ImportProjectFromDomainComponent, {width: '500px', height: 'auto'});
  }

}
