import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { User } from '../../share/user';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { SignupComponent } from '../signup/signup.component';
import { Router } from '@angular/router';
import { getToken, isCollaborator, isTokenValid, logoutUser, persistToken, persistUser } from 'src/app/share/utils';
import { ConfigServerUrlComponent } from '../dialogs/config-server-url/config-server-url.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('fform', {static: false}) feedFormDirectives: any;

  errMsg: string;
  submitingForm: boolean;
  formreseted: boolean;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  user: User = {
    name: '',
    password: '',
    remember: false,
    email: '',
    managed_users_ids: [],
    manager_id: '',
    customer_id: '',
    user_type: '',
    is_activated: false,
    mc_marxact: [],
    temporary_user: false,
    id: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
  };

  formErrors = {
    username: '',
    password: ''
  };

  validationMessages = {
    username: {
      required: 'Username is required',
      minLength: 'Username must be at least 2 character long.',
      maxLength: 'Username cannot be more than 25 character long.'
    },
    password: {
      required: 'password is required',
      minLength: 'password must be at least 6 character long.'
    },
  };

  constructor(
    private store: StoreService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const token = getToken();
    console.log("Enter login");
    if (token && isTokenValid(token)) {
      this.router.navigate(['/projects']);
    } else {
      logoutUser();
      this.store.token = null;
    }
    this.message =  'password must be at least 6 character long.';
  }

  ngOnDestroy() {
  }

  onSubmit() {
    this.store.showLoading();
    this.userService.loginUser(this.user).subscribe(
      res => {
        persistToken(res);
        this.store.saveToken(res);
        this.store.isMCAdmin = false;
        this.getUser(res);
        // this.dialogRef.close();
      },
      errmess => {
        this.store.hideLoading();
        this.showMessage = true;
        if (errmess === 'invalid credentials.') {
          this.showErroMessage('Invalid username or password');
        } else {
          this.showErroMessage(errmess);
        }
      }
    );
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  getApiVersion() {
    return environment.apiVersion;
  }

  getWebAppVersion(){
    return environment.webAppVersion;
  }

  getUser(token) {
    this.userService.getUser(token, token.user_id).subscribe(
      res => {
        persistUser(res);
        this.store.isMCAdmin = false;
        this.user = res;
        if(isCollaborator(res) && this.isCollaboratorNoCreatePermission(this.user)){
          this.store.isCollaborator = true;
        } else {
          this.store.isCollaborator = false;
        }
        // Get admin user name if the connected user is a collaborator

        this.store.persistUser(res);
        this.store.proClass = 'project';
        this.store.changeProClassVisArchi('project', 'private', false);

        this.router.navigate(['/projects']);
        this.store.hideLoading();
      },
      err => {
        this.showErroMessage(err);
        this.store.hideLoading();
        return;
      }
    );
  }

  isCollaboratorNoCreatePermission(user) {
    const permission = user.permissions;
    if (permission !== null && permission !== undefined) {
      const projPerm = permission.projects;
      if (projPerm[0] === "create") {
        return false;
      }
    }
    return true;
  }

  updateUser(u) {
    this.store.user = this.user;
  }

  deleteUser(u) {
  }

  signupUser(u) {
  }

  /**
   * This method redirect the user to the resseting page
   */
  openPasswordResetingPage(event) {
    event.preventDefault();
    this.router.navigate(['/reset-password']);
    event.stopPropagation();
  }

  /**
   * This method redirect the user to the private server.
   */
  openPrivateServer(event) {
    event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };
    const dialogRef = this.dialog.open(ConfigServerUrlComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
      }
    });
    event.stopPropagation();
  }

  /**
   * This method redirect the user to the signup page
   */
  openSignUp(event) {
    event.preventDefault();
    this.router.navigate(['/signup']);
    // this.dialogRef.close();  // To be removed
    // this.dialog.open(SignupComponent, {width: '500px', height: 'auto'});
    event.stopPropagation();
  }

}
