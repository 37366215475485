import { Injectable } from '@angular/core';
import { User } from '../share/user';
import { Token } from '../share/token';
import { ReportTemplate } from '../share/report-template';
import { Observable, of, pipe } from 'rxjs';
import { delay, map, catchError  } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { ReportTemplatesResult, ReportTemplateResult } from '../share/result';
import { getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateService {
  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  deleteReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
      })
    };
    return this.http.delete<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + reportTemplate.id, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.put<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + reportTemplate.id, reportTemplate, httpOptions)
    .pipe(
      map(res => {
        console.log(res);
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.post<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates', reportTemplate, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + reportTemplate.id, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportTemplateById(templateId, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + templateId, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportTemplates(token): Observable<ReportTemplate[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportTemplatesResult>(getPrivateApiAURL() + 'reporttemplates', httpOptions)
    .pipe(map(result => {
      return result.report_templates.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getCompatibleReportTemplates(form_id:string, isPublic:boolean, target_format:string, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };

    const body = {
      template_id: form_id,
      public: isPublic,
      target_format: target_format,
    }
    return this.http.post<ReportTemplatesResult>(getPrivateApiAURL() + 'reports2/template_compatible_rts', body, httpOptions).pipe(map(result => {
      return result.report_templates.instances;
    }))
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  /*
  * MyCumulus Admin Report templates
  */
  getMCAdminReportTemplates(adminToken): Observable<ReportTemplate[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': adminToken.key
      })
    };
    // /v1/public/reporttemplates
    return this.http.get<ReportTemplatesResult>(getPrivateApiAURL() + 'public/reporttemplates', httpOptions)
    .pipe(map(result => {
      return result.report_templates.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createMCAdminReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.post<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates?visibleTo=all_mc_users',
    reportTemplate, httpOptions).pipe(map(res => {
        return res.report_template;
      })
    ).pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateMCAdminReportTemplate(id, reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.put<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + id, reportTemplate, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteMCAdminReportTemplates(reptempId, adminToken): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Admin-Auth-Token': adminToken.key
      })
    };
    return this.http.delete<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + reptempId, httpOptions)
    .pipe(map(result => {
      return result.report_template;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportPublicTemplates(token): Observable<ReportTemplate[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportTemplatesResult>(getPrivateApiAURL() + 'public/reporttemplates', httpOptions)
    .pipe(map(result => {
      return result.report_templates.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
