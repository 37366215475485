import { Component, OnInit, ViewChild, Inject, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { TemplateService } from 'src/app/services/template.service';
import { StoreService } from 'src/app/services/store.service';
import { getToken, getProject, setFormUpdating, getImportCSVFileSetting, persitImportCSVFileSetting, getChoosenCRSType, removeImportCSVFileSetting } from 'src/app/share/utils';
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatTableDataSource, MatTable, MatDialog } from '@angular/material';
import { Project } from 'src/app/share/projects';
import { User } from 'src/app/share/user';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { Token } from 'src/app/share/token';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ImportErrorDlgComponent } from '../import-error-dlg/import-error-dlg.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

const ELEMENT_DATA: any[] = [];

const IMPORT_DIALOG_TITLE = 'Choose file (KLIP OR KLIC)';

@Component({
  selector: 'app-import-imkl-file',
  templateUrl: './import-imkl-file.component.html',
  styleUrls: ['./import-imkl-file.component.scss']
})
export class ImportImklFileComponent implements OnInit {

  @Output('readProjectTemplate')
  readProjectTemplateEmitter = new EventEmitter<any>();

  importDialogTitle: string;
  project: Project;
  template: Template;
  newTemplate: any;
  @Input() user: User;
  @Input() mode = 'Create';
  @Input() typeOfFile: string;

  loading = false;
  loadingText = '';

  model: string;
  encoding = 'UTF8';
  type: string;
  fileInBase64: string;

  fileName: string;

  fileToUpload: File;
  importIMKL = false;
  importInExistingTemplate = false;
  isValidFile = true;
  importFileForm: FormGroup;
  validFile = false;
  importDataError: any;
  featureSize = 0;

  fileTypes = [
    {value: 'imkl_nl',  text: 'Klip'},
    {value: 'imkl_be', text: 'Klic'}
  ];

  fieldTypes = [
    {value: 'imkl_nl',  text: 'Klip'},
    {value: 'imkl_be', text: 'Klic'}
  ];
  fields = [];
  tempFields: any[] =  [];
  showFieldContainer = false;

  errMsg: string;
  submitingForm: boolean;
  formreseted: boolean;
  templates: any[];
  noFileIsSelected = false;


  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = 'Test of message';
  showMessage = false;

  @ViewChild('fileInput', { static: false}) fileInput: ElementRef;
  @ViewChild('fform', { static: false}) importFileFormDirective;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    private templateService: TemplateService,
    protected elementRef: ElementRef,
    private dialog: MatDialog) {
      this.isValidFile = false;
      if(this.store.typeOfIMKLFile.value === 'Klip') {
        this.importDialogTitle = 'Import KLIC/KLIP';
        this.model = 'imkl_vl';
      } else {
        this.importDialogTitle = 'Import KLIC/KLIP';
        this.model = 'imkl_nl';
      }
      this.store.typeOfIMKLFile.subscribe(val => {
        if(val === 'Klip') {
          this.importDialogTitle = 'Import KLIC/KLIP';
          this.model = 'imkl_vl';
        } else {
          this.importDialogTitle = 'Import KLIC/KLIP';
          this.model = 'imkl_nl';
        }
      });
  }


  ngOnInit() {
    if (this.store.importIKMLFile) {
      this.importIMKL = this.store.importIKMLFile;
    }

    this.project = getProject();
    this.createForm();
  }

  selectFile(): void {
    this.fileInput.nativeElement.click();
  }

  typeTemplateChange(event: MatRadioChange, data) {
    if (data === 'new_template') {
      this.importIMKL = false;
    } else {
      this.importIMKL = true;
    }
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    if (this.messageBox) {
      this.messageBox.showError();
    }
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      // this.messageBox.showSuccess();
    });
  }

  createForm() {
    if (this.store.typeOfIMKLFile) {
      if (this.store.typeOfIMKLFile.value === 'Klip') {
        this.model = 'imkl_vl';
      } else {
        this.model = 'imkl_nl';
      }
    }

    if (this.mode === 'Create') {
      this.importFileForm = this.fb.group({
        imklFile: ['', Validators.required]
      });
    }
  }

  closeDialog(event) {
    event.preventDefault();
    if (this.showFieldContainer) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          message: ``,
          title: 'Cancel import?'
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          this.fileToUpload = null;
          this.store.updatingForm = false;
          this.showFieldContainer = false;
          this.fields = null;
          this.fileInBase64 = null;
          this.store.hideTemplate();
        }
      });
    } else {
      this.fileToUpload = null;
      this.showFieldContainer = false;
      this.store.updatingForm = false;
      this.fields = null;
      this.fileInBase64 = null;
      this.store.hideTemplate();
    }
    event.stopPropagation();
  }

  closePanel() {
    this.showMessage = false;
  }


  showErrorDlg(): void {
    const dialogRef = this.dialog.open(ImportErrorDlgComponent, {
      width: '500px',
      data: {
        error: this.importDataError
      }
    });
    dialogRef.afterClosed().subscribe(result  => {

    });
  }

  onSubmit() {

    if (!this.fileToUpload && !this.fileInBase64) {
      this.noFileIsSelected = true;
      this.showErrorMessage('No file is selected');
      return;
    }
    this.showMessage = false;
    const importData = this.importFileForm.value;
    const fileNamesParts = importData.imklFile.split('.');
    let fileType = '';
    if (fileNamesParts && fileNamesParts.length > 0) {
      fileType = fileNamesParts[fileNamesParts.length - 1];
    }
    let p = getProject();

    const value = {
      project_id: this.project.id,
      base64_encoded_content: this.fileInBase64,
      model : this.model,
      encoding: 'UTF8',
      type: this.type
    };
    this.store.showLoading();
    this.loadingText = 'Sending importation data';
    const token = getToken();
    this.templateService.importIKMLFile(value, token, p.id).subscribe(
      res => {
        this.showSuccessMessage(`Importation succeed for form.` );
        setFormUpdating(false);
        this.store.updatingForm = false;
        this.fileToUpload = null;
        this.showFieldContainer = false;
        this.fileInBase64 = null;
        this.store.hideTemplate();
        this.getTemplates(p);
        console.log(res);
        this.store.hideLoading();
      },
      error => {
        this.loading = false;
        this.store.hideLoading();
        setFormUpdating(false);
        this.store.updatingForm = false;
        this.importDataError = error;

        let msg = `Importation failed, please retry later`;
        if(error && error['message']) {
          msg += ' : ' + error['message'];
        }
        this.importDataError = [msg];
        this.showErrorMessage(msg);
      }
    );
  }



  selectionChange(event: StepperSelectionEvent) {
    const val = this.importFileForm.value;
    this.model = val['model'];
  }


  handleData(data: any) {
    setTimeout(() => {
      // data.isCoordinate = !data.isCoordinate;
    });
  }

  getTemplates(p): void {
    const token: Token = JSON.parse(localStorage.getItem('token'));

    this.store.showLoading();
    this.templateService.getTemplates(token, p.id).subscribe(
      res => {
        this.store.readTemplates(res);
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.showErrorMessage(errmess);
    });
  }

  onlyDigits(str) {
    const pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  }

  setFileData(event): void {
    event.preventDefault();

    this.showFieldContainer = false;

    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];

      this.isValidFile = true;
      const filename = tempFile.name;
      this.fileName = tempFile.name;
      const filenameParts = filename ? filename.split('.') : [];
      if (filenameParts && filenameParts.length > 1) {
        this.type = filenameParts[filenameParts.length -1];
      }

      // setFormUpdating(true);
      this.store.updatingForm = true;
      this.fileToUpload = event.target.files[0];
      this.convertFileToBase64(this.fileToUpload);
      this.showFieldContainer = true;
    } else {
      this.isValidFile = false;
    }
    event.stopPropagation();
  }

  convertFileToBase64(file) {
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryString = reader.result;
            this.fileInBase64 = btoa(binaryString as string);
        };
        reader.readAsBinaryString(file);
    }
  }

  convertFileToString(): Observable<any> {
    return null;
  }
}
