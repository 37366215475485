import { Component, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileServiceService } from 'src/app/services/file-service.service';
import { StoreService } from 'src/app/services/store.service';
import { StyleServiceService } from 'src/app/services/style-service.service';
import { Style } from 'src/app/share/style';
import { User } from 'src/app/share/user';
import { getAdminToken, getToken, setFormUpdating } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-style-new',
  templateUrl: './style-new.component.html',
  styleUrls: ['./style-new.component.scss']
})
export class StyleNewComponent implements OnInit {

  @Input() style: Style;
  private addedStyle: Style;
  styleDialogForm: FormGroup;
  image: string;
  name: string;
  description: string;
  @Input() user: User;
  @Input() mode = 'Create';

  fileName: string;
  fileToUpload: File;
  isValidFile = true;
  validFile = false;
  showFieldContainer = false;

  errMsg: string;
  submitingForm: boolean;
  formreseted: boolean;
  noFileIsSelected = false;
  styleBody: any;

  @ViewChild('fform', { static: false}) styleFormDirective;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fileService: FileServiceService,
    private styleService: StyleServiceService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<StyleNewComponent>
  ) {
    if (data !== undefined && data !== null) {

      this.style = data.style;
      this.mode = data.mode;
    }
    this.createForm();
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  ngOnInit() {
  }

  initializeFormData(s: Style) {
    this.name = s.name;
    this.description = s.description;
  }

  createForm() {
    this.styleDialogForm = this.fb.group({
      userFile: ['', Validators.required]
    });
  }

  setFileData(event): void {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];
      this.noFileIsSelected = false;
      this.parseJson(tempFile).then(res =>{
        this.styleBody = res;
        this.fileToUpload = tempFile;
        this.noFileIsSelected = false;
      }, err => {
        this.noFileIsSelected = true;
      });
      this.store.updatingForm = true;
      event.stopPropagation();
    }
  }

  parseJson(file: File) {
    // Always return a Promise
    return new Promise((resolve, reject) => {
      let content = '';
      const reader = new FileReader();
      // Wait till complete
      reader.onloadend = function(e: any) {
        content = reader.result as string;
        resolve(content);
      };
      // Make sure to handle error states
      reader.onerror = function(e: any) {
        reject(e);
      };
      reader.readAsText(file);
    });
  }

  onSubmit() {
    if (!this.fileToUpload && !this.styleBody) {
      this.noFileIsSelected = true;
      this.showErroMessage('No file is selected');
      return;
    }
    this.showMessage = false;
    this.store.showLoading();
    let token = getToken();
    let isMyCumulusAdministrator = false;
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    }
    const proToken = {
      value: token.key
    }
    try{
      const body = JSON.parse(this.styleBody);
      if(this.mode === 'Create') {
        this.styleService.createStyle(body, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.showSuccessMessage(`Style created.` );
            const result = {
              event: 'Add',
              data: res
            };
            setTimeout(() => {
              this.dialogRef.close(result);
            }, 3000);
            console.log(res);
            this.store.hideLoading();
          },
          error => {
            this.store.hideLoading();
            setFormUpdating(false);
            this.store.updatingForm = false;
            let msg = error ? `Error: ${error}` : `Importation failed, please retry later`;
            if (error instanceof Array) {
              const record = error.length > 1 ? 'records' : 'record';
              msg = `${error.length} ${record} could not be imported. No data of CSV has been imported` ;
            }
            this.showErroMessage(msg);
          }
        );
      } else {
        this.styleService.updateStyle(body, this.style.id, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.showSuccessMessage(`Style updated` );
            const result = {
              event: 'Update',
              data: res
            };
            setTimeout(() => {
              this.dialogRef.close(result);
            }, 3000);
            this.store.hideLoading();
          },
          error => {
            this.store.hideLoading();
            setFormUpdating(false);
            this.store.updatingForm = false;
            let msg = error ? `Error: ${error}` : `Importation failed, please retry later`;
            if (error instanceof Array) {
              const record = error.length > 1 ? 'records' : 'record';
              msg = `${error.length} ${record} could not be imported. No data of CSV has been imported` ;
            }
            this.showErroMessage(msg);
          }
        );
      }
    } catch(e) {
      console.log(e);
      this.store.hideLoading();
      this.showErroMessage("Error while parsing the submited file, please correct your file");
    }

  }
}
