import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { getToken, getProject, WARNING, SUCCESS, ERROR, CRITICAL } from 'src/app/share/utils';

import { FileServiceService } from 'src/app/services/file-service.service';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-media-showing-dialog',
  templateUrl: './media-showing-dialog.component.html',
  styleUrls: ['./media-showing-dialog.component.scss']
})
export class MediaShowingDialogComponent implements OnInit {
  medialink: string;
  imageFile: boolean;
  audioFile: boolean;
  videoFile: boolean;
  extension: string;
  filetype: string;
  fileloading: boolean;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    public store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private fileService: FileServiceService,
    private dialogRef: MatDialogRef<MediaShowingDialogComponent>) {
      if (data !== null && data !== undefined) {
        this.fileloading = true;
        this.medialink = data.medialink;
      }
  }

  ngOnInit() {
    this.checkMediaType();
    this.filetype = this.getMime();
    const token = this.store.token;
    const project = this.store.project;

    this.store.showLoading();
    this.fileService.getPrivateFile(this.medialink, token, project).subscribe(
      res => {
        this.loadImage(res);
        this.store.hideLoading();
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.fileloading = false;
        this.showBoxMessage(err);
        this.store.hideLoading();
      });
  }

  showBoxMessage(msg, type = WARNING) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      if (type === SUCCESS) {
        this.messageBox.showSuccess();
      } else if (type === ERROR) {
        this.messageBox.showError();
      } else if (type === WARNING) {
        this.messageBox.showCritical();
      } else if (type === CRITICAL) {
        this.messageBox.showCritical();
      }
    });
  }

  getMediaFromAPI() {
    const token = getToken();
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
  }

  getMime(): string {
    let t = '';
    if (this.audioFile) {
      t = `audio/${this.extension}`;
    } else if (this.videoFile) {
      t = `video/${this.extension}`;
    }
    return t;
  }

  checkMediaType() {
    const fileAndType = this.medialink.split('.');
    if (fileAndType !== null && fileAndType.length > 1) {
      this.extension = fileAndType[fileAndType.length - 1];

      if (this.ifMediaIsImage(this.medialink)) {
        this.imageFile = true;
        this.audioFile = false;
        this.videoFile = false;
      } else if (this.ifMediaIsVideo(this.medialink)) {
        this.imageFile = false;
        this.audioFile = false;
        this.videoFile = true;
      } else if (this.ifMediaIsAudio(this.medialink)) {
        this.imageFile = false;
        this.audioFile = true;
        this.videoFile = false;
      }
    }
  }

  ifMediaIsImage(value) {
    const reg = value.match(/\.(jpeg|jpg|gif|png)$/);
    return(reg !== null);
  }

  ifMediaIsAudio(value) {
   const reg = value.match(/\.(mp3|wav|raw|ogg)$/);
   return(reg !== null);
  }

  ifMediaIsVideo(value) {
    const reg = value.match(/\.(mp4|3gp|avi|vob|flv|mpeg)$/);
    return(reg !== null);
  }

  createFile() {
    const file = new Blob(['hello world'], { type: 'text/csv;charset=utf-8' });
    // saveAs(file, 'helloworld.csv');
  }

  loadImage(response) {
    let image = null ;
    if (this.imageFile) {
      image = document.getElementById('myImage');
    } else if (this.audioFile) {
      image = document.getElementById('audioPlayer');
    } else if (this.videoFile) {
      image = document.getElementById('myVideo');
    }

    if (this.audioFile || this.videoFile) {

      image.src = window.URL.createObjectURL(response);
      image.load();
      image.loop = true;
      this.medialink = window.URL.createObjectURL(response);
    } else {
      const reader = new FileReader();
      reader.onload = ( (i) => {
        return function(e) {
          if (i != null ) {
            i.src = e.target.result;
            this.medialink = i.src;
          }
          // this.$.mediaDialog.style.height = i.height + 10;
          // this.$.mediaDialog.center();
        };
      })(image);
      reader.readAsDataURL(response);
    }
    this.fileloading = false;
  }
}
