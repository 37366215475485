import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { ReportsResult, ReportResult } from '../share/result';
import { Report } from '../share/report';
import { getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  createNewReport(token, requestBody) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };
    return this.http.post(getPrivateApiAURL() + 'reports2/generate_report', requestBody, httpOptions);
  }

  getOutputFormats(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };
    return this.http.get(getPrivateApiAURL() + 'reports2/get_report_formats', httpOptions);
  }

  /**
   * The method allow to generate a request by the user
   * @param token The user token
   * @param request the body request container of the request
   */
  createReport(token, request): Observable<Report> {
    console.log('Creating new report');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };
    return this.http.post<ReportResult>(getPrivateApiAURL() + 'reports/generate', request, httpOptions).pipe(
      map(res => {
        return res.file;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createEnexisReport(token, request): Observable<Report> {
    console.log('Creating new Enexis report');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };
    return this.http.post<ReportResult>(getPrivateApiAURL() + 'reports2/generate_report', request, httpOptions).pipe(
      map(res => {
        return res.file;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteReport(report, token): Observable<Report> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
      })
    };
    return this.http.delete<ReportResult>(getPrivateApiAURL() + 'reports/' + report.id, httpOptions).pipe(
      map(res => {
        return res.file;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportStatus(reportId, token): Observable<Report> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportResult>(getPrivateApiAURL() + 'report_status/' + reportId, httpOptions).pipe(
      map(res => {
        return res.file;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getreports(userId, token): Observable<Report[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportsResult>(getPrivateApiAURL() + 'reports/' + userId, httpOptions)
    .pipe(map(result => {
      return result.public_files.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getPublicReport(token): Observable<Report[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportsResult>(getPrivateApiAURL() + 'public/reports', httpOptions)
    .pipe(map(result => {
      return result.public_files.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
