import { Component, OnInit, ViewChild, Optional, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { getUser } from 'src/app/share/utils';

@Component({
  selector: 'app-collaborators-dialog',
  templateUrl: './collaborators-dialog.component.html',
  styleUrls: ['./collaborators-dialog.component.scss']
})
export class CollaboratorsDialogComponent implements OnInit {
  addCollaboratorForm: FormGroup;
  @ViewChild('fform', { static: false}) addCollaboratorFormDirective;

  action: string;
  local_data: any;
  isAdminUser: boolean;
  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    public dialogRef: MatDialogRef<CollaboratorsDialogComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.local_data = {...data};
      this.action = this.local_data.action;
      this.createForm();
  }

  createForm() {
      this.addCollaboratorForm = this.fb.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        password: ''
      });
  }

  ngOnInit() {
    const user = getUser();
    let userType = user.user_type;

    if (userType === undefined || userType === null) {
      userType = user.admin_type;
    }
    if (userType === 'admin_user') {
      this.isAdminUser = true;
    } else {
      this.isAdminUser = false;
    }
  }

  onSubmit() {
    this.local_data = this.addCollaboratorForm.value;
    this.action = 'Add';
    this.dialogRef.close({event: this.action, data: this.local_data});
  }

  closeDialog(event) {
    event.preventDefault();
    this.action = 'Cancel';
    this.dialogRef.close({event: this.action});
    event.stopPropagation();
  }
}
