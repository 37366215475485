import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Template } from 'src/app/share/template';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatTable, MatCheckbox } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SelectionModel } from '@angular/cdk/collections';
import { AttributeSet, AttributeSetArray } from 'src/app/share/feature/attributes';
import { getChoosenCRSType, getToken,
  persistChoosenCRSType, persistSearchObject,
  getSearchObject, getTemplateFieldsNameAndTypes, getCRSSetting, SUCCESS, ERROR, WARNING, CRITICAL, getTemplate, getProject, persistEnexisSetting, getEnexisSetting } from 'src/app/share/utils';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { TemplateService } from 'src/app/services/template.service';

export interface Criteria {
  criteria: string;
}

export interface PlaceHolder {
  name: string;
  value: string;
}

const ELEMENT_DATA: Criteria[] = [];
const RPH_ELEMENT_DATA: PlaceHolder[] = [];

@Component({
  selector: 'app-export-stedin-crit-dialog',
  templateUrl: './export-stedin-crit-dialog.component.html',
  styleUrls: ['./export-stedin-crit-dialog.component.scss']
})
export class ExportStedinCritDialogComponent implements OnInit {

  headers: string[] = [];
  templatelist: string[] = [];

  optrs: any[] = [
    {value: '=', text: '='},
    {value: '≠', text: '≠'},
    {value: '>', text: '>'},
    {value: '>=', text: '>='},
    {value: '<', text: '<'},
    {value: '<=', text: '<='},
    {value: 'contains', text: 'Contains'},
    {value: 'notcontain', text: 'not contain '}
  ];

  outputs: any[] = [
    {value: 'CSV', text: 'CSV'}
    // ,{value: 'pdf', text: 'PDF'},
    // {value: 'html', text: 'HTML'},
    // {value: 'ms-word', text: 'MS Word'}
  ];

  split_types: any[] = [
    {value: 'single_file', text: 'Single File'},
    {value: 'separate_files', text: 'Separate Files'},
  ]

  delimiters = [
    { value: ',', text: 'Comma' },
    { value: ';', text: 'Semi colon' }
  ];
  delimiter = ',';
  decimalSeparator = '.';


  crs: any[] = [
    {
      name: 'Geodetic',
      title: 'Geodetic',
      selected: false
    },
    {
      name: 'Lambert72',
      title: 'Lambert 72',
      selected: false
    },
    {
      name: 'Lambert2008',
      title: 'Lambert 2008',
      selected: false
    },
    {
      name: 'RDNAP',
      title: 'RD',
      selected: true
    },
    {
      name: 'UTM',
      title: 'UTM',
      selected: false
    },
    {
      name: 'BGS2005',
      title: 'BGS2005',
      selected: false
    }
  ];

  displayedColumns: string[] = ['select', 'criteria'];

  dataSource = new MatTableDataSource<Criteria>(ELEMENT_DATA);
  selection = new SelectionModel<Criteria>(true, []);
  @ViewChild(MatTable, {static: true}) criteriatable: MatTable<any>;

  rptdisplayedColumns: string[] = ['name', 'value'];
  rptdataSource = new MatTableDataSource<PlaceHolder>(RPH_ELEMENT_DATA);
  rptselection = new SelectionModel<PlaceHolder>(true, []);
  placeholdername: string;
  @ViewChild(MatTable, {static: true}) placeholderstable: MatTable<any>;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  template: Template;
  criteriaForm: FormGroup;
  fromDateEnabled = false;
  toDateEnabled = false;
  rep_temp: any;
  fromdate = '';
  criteriaList: string[];
  data1: any[];
  searchCriteria: any;
  columnItems: any[] = [];
  selectedColumns: any[] = [];
  criteriaObjects: any[] = [];
  columnsValues: any[] = [];
  selectedReportTemplateName: string;

  datesValues: any;
  criteriasArray: any[] = [] ;
  outPut: any =   'CSV';
  splitType: any = 'single_file';
  coordinateRef: any;
  fromDateValue: any;
  toDateValue: any;

  selectedSortColumn: any;
  sortingType: any;
  selectedSortColumn2: any;
  sortingType2: any;
  sortingObject: any;

  selectedCritColumn: any;
  selectedValColumn: any;
  selectedOperColumn: any;
  pendingStatus: number;
  templateId: string;
  publicValue: boolean;
  privateValue: boolean;
  selectedReportTemplate: any;
  reportsTemplates: any[];
  criteriaDelete: any[];
  value: any;
  descendingValue: boolean = false;
  ascendingValue: boolean = true;
  descendingValue2: boolean = false;
  ascendingValue2: boolean = true;
  pdfOrHtml: boolean;
  csvFile: boolean;
  templateloaded: boolean;
  templates = []
  selectOrDeselectAll: boolean = true;

  aanvraag_ingevuld_door: any;
  datum_ingevuld: any;
  ov_eigenaar: any;
  aanvrager: any;
  uitvoerende_aannemer: any;
  totaal_aantal_masten: any;
  publicEnexisReport: boolean = false;

  events: string[] = [];
  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private reportTempalteService: ReportTemplateService,
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ExportStedinCritDialogComponent>
  ) {
    if (data !== null) {
      this.publicEnexisReport = false;
      this.template = data.template;
    } else {
      const project = getProject();
      const token = getToken();
      this.publicEnexisReport = true;
      this.templateService.getTemplates(token, project.id).subscribe(
        (res) => {
          this.templates = res;
        },
        (error) => {
          this.templates = [];
        }
      );
    }
    this.createForm();
  }

  get criteriaValue() {
    let values = '';
    for (const item of this.criteriaList) {
      values = values.length > 0 ? values + '\n' + item : item;
    }
    return values;
  }

  createForm() {
    this.criteriaForm = this.fb.group({
      column_name: '',
      operator: '',
      column_value: '',
    });
  }

  fromDateChecked(val: boolean) {
    this.fromDateEnabled = val;
  }

  toDateChecked(val) {
    this.toDateEnabled = val;
  }

  showToDateValue(event) {
    this.toDateValue = event.value;
    if (this.toDateValue !== null && this.toDateValue !== undefined) {
      this.toDateValue.setUTCHours(23, 59, 59);
      //alert(this.toDateValue);
      this.toDateValue = this.toDateValue.toISOString();
    // alert(this.toDateValue);
    }
  }

  showFromDateValue(event) {
    this.fromDateValue = event.value;
    if (this.fromDateValue !== null && this.fromDateValue !== undefined) {
      this.fromDateValue = this.fromDateValue.toISOString();
    }
  }

  showBoxMessage(msg, type = WARNING) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      if (type === SUCCESS) {
        this.messageBox.showSuccess();
      } else if (type === ERROR) {
        this.messageBox.showError();
      } else if (type === WARNING) {
        this.messageBox.showCritical();
      } else if (type === CRITICAL) {
        this.messageBox.showCritical();
      }
    });
  }

  closeDialog() {
    this.resetDialog();
    this.dialogRef.close();
  }

  exportData() {

  }

  resetAllObjects() {
    this.datesValues = {};
    this.searchCriteria = {};
    this.criteriaObjects = [];
    this.criteriasArray = [];
    this.outPut = '';
    this.dataSource.data = [];
  }

  eventListerner(event) {
    const key = event.key; // Or const {key} = event; in ES6+

    if (key === 'Escape') {
      this.closeDialog();
    }
  }

  ngOnInit() {
    document.addEventListener('keyup', event => this.eventListerner(event));

    this.pdfOrHtml = true;
    this.getUserReportTemplateById('6601abffe91d95587f6c563d');
  }

  resetDialog() {
    this.dataSource.data = [];
    // this.criteriatable.renderRows();
    this.initializeDialog();
    this.selection.clear();
  }


  constructHeaderList(templ: Template) {
    if (templ !== null && templ !== undefined) {
      const name = 'name';
      const _class = '_class';
      const schema = templ.feature;
      const forms = templ.form;
      const geometry = schema.geometry_type;
      if (geometry !== null && geometry !== undefined) {
        const val = {
          check: true,
          value: 'Coordinates'
        };
        this.columnItems.push(val);

      }
      const attributes = schema.attributes;

      for (const attribute of attributes) {
        const typeAttr = attribute[_class];
        if (typeAttr === 'attribute' || typeAttr === 'arrayattributeset') {
          const val1 = {
            check: true,
            value: attribute[name]
          };
          this.columnItems.push(val1);
          this.headers.push(attribute[name]);
        } else if (typeAttr === 'attributeset') {
          this.constructAttributesetHeaderList(attribute as AttributeSet, attribute[name]);
        }
      }
      // this.selectedColumns = [...this.columnItems];
    } else {
      this.columnItems = [];
    }
  }

  constructAttributesetHeaderList(atributeset: AttributeSet, parent= '') {
    const name = 'name';
    const _class = '_class';

    for (const attribute of atributeset.attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute' || typeAttr === 'arrayattributeset') {
        const val = {
          check: true,
          value: `${parent}.${attribute[name]}`
        };
        this.columnItems.push(val);
        this.headers.push(`${parent}.${attribute[name]}`);
      } else if (typeAttr === 'attributeset') {
        const nested_parent =  `${parent}.${attribute[name]}`;
        this.constructAttributesetHeaderList(attribute as AttributeSet, nested_parent);
      }
    }
  }

  constructAttributesetArrayHeaderList(atributeset: AttributeSetArray, parent= '') {
    const name = 'name';
    const _class = '_class';

    for (const attribute of atributeset.attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const val = {
          check: true,
          value: `${parent}.${attribute[name]}`
        };
        this.columnItems.push(val);
        this.headers.push(`${parent}.${attribute[name]}`);
      } else if (typeAttr === 'attributeset') {
        const nested_parent =  parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        this.constructAttributesetHeaderList(attribute as AttributeSet, nested_parent);
      }
    }
  }

  initializeDialog() {
    // console.log('Reset criteria table');
    this.dataSource.data = [];

    if (this.criteriatable) {
      //this.dataSource.data = [];
      //this.criteriatable.renderRows();
      this.criteriaDelete = [];
    }
    this.criteriaObjects = [];
    this.criteriaList = [];
    this.criteriasArray = [];
    this.selection.clear();
  }

  // remove the criteria in the selecting list.
  removeCriteria() {
    // console.log('Remove criteria');
    if (this.criteriaDelete.length < 1) {
      // this.toastr.error(, 'MyCumulus');
      this.showBoxMessage('select criteria to remove');
      return;
    }
    const deleteLen = this.criteriaDelete.length;
    // const objLen = this.criteriaObjects.length;

    for (let k = 0; k < this.criteriaDelete.length; k++){
      delete this.searchCriteria[this.criteriaDelete[k].column];
    }

    for (let i = deleteLen - 1; i >= 0; i--) {
      for (let j = 0; j < this.criteriaObjects.length; j++) {
        if (this.criteriaDelete[i].column === this.criteriaObjects[j].column) {
          this.criteriaObjects.splice(j, 1);
          this.criteriasArray.splice(j, 1);
        }
      }
      this.criteriaDelete.splice(i, 1);
    }

    if (this.selection.selected.length > 0) {
      let data = this.dataSource.data;
      for (const s of this.selection.selected) {
        this.criteriaList = this.criteriaList.filter(res => res !== s.criteria);
        data = data.filter(res => res !== s);
      }
      this.dataSource.data = data;
      this.selection.clear();
      // if (this.criteriatable !== null && this.criteriatable !== undefined) {
      //   this.criteriatable.renderRows();
      // }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Criteria): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    const index = this.dataSource.data.indexOf(row);
    const criteria = this.criteriaObjects[index];
    if (this.criteriaDelete === null || this.criteriaDelete === undefined) {
      this.criteriaDelete = [];
    }
    if (this.selection.isSelected(row)) {
      if (index > -1) {
        this.criteriaDelete.push(criteria);
      }
    } else {
      const itemIndex = this.criteriaDelete.indexOf(criteria);
      if (itemIndex > -1) {
        this.criteriaDelete = this.criteriaDelete.splice(itemIndex, 1);
      }
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.criteria + 1}`;
  }

  handleClicked(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // set default values and open the dialog.
  open(data, template) {
    const previousSearch = getEnexisSetting();
    this.columnItems = [];
    this.selectedColumns = [];
    this.criteriaObjects = [];
    this.columnsValues = [];
    this.data1 = data;

    const attributes = template.feature.attributes;
    this.template = template;
    this.templateId = template.id;

    if (this.publicValue === true) {
      // this.$.publicReport.checked = true;
      this.getUserPublicReportTemplates();
    } else {
      // this.$.privateReport.checked = true;
      this.getUserReportTemplates();
    }
    this.pendingStatus = 0;
  }

  // Is called when date selected from datePicker
  getDate(event, date) {
    if (date.type === 'from') {
      // this.$.fromdatepicker.value = date.date;
    } else if (date.type === 'to') {
      // this.$.todatepicker.value = date.date;
    }
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true && JSON.stringify(obj) === JSON.stringify({});
  }

  dismiss() {
    // this.$.searchDataDialog.close();
  }


  dismissDialog() {
    // const TestValue = this.$.DPDialog.$.pickerTo.date;
    // const test = this.$.DPDialog.$.pickerFrom.date;
  }

  getReportTemplateByName(name){
    let reportTemplate = null;

    this.reportsTemplates.forEach(repTemplate => {
      if(repTemplate.name === name){
        reportTemplate = repTemplate;
      }
    });

    return reportTemplate;
  }

  getReportTemplateById(chooseId) {
    this.displayReportTemplatePlaceholders('');
    for (let i = 0; i < this.reportsTemplates.length; i++) {
      if (this.reportsTemplates[i].id === chooseId) {
        // alert(JSON.stringify(this.reportsTemplates[i]));
        this.selectedReportTemplate = this.reportsTemplates[i];
        // this.updatePlaceHolderTable(this.reportsTemplates[i]);
        break;
      }
    }
  }

  chooseReportTemplateById() {
    const chooseId = ''; // this.$.reportsTemplatesSelect.value;
    this.displayReportTemplatePlaceholders('');
    for (let i = 0; i < this.reportsTemplates.length; i++) {
      if (this.reportsTemplates[i].id === chooseId) {
        // alert(JSON.stringify(this.reportsTemplates[i]));
        this.selectedReportTemplate = this.reportsTemplates[i];
        break;
      }
    }
  }

  templateChanged(value) {
    this.template = value;
  }

  chooseOutType() {
    const chooseType = 'pdf'; // this.$.typeColumnId.value;

    if (chooseType === 'pdf' || chooseType === 'html') {
      // this.$.reportsPreview.style.display = 'block';
    } else {
      // this.$.reportsPreview.style.display = 'none';
    }
  }

  handlePublic(selectedElt) {
    if (selectedElt) {
      this.publicValue = true;
      this.privateValue = false;
      this.getUserPublicReportTemplates();
    } else {
      this.publicValue = false;
    }
    // $('.placeholdersValue').html('');
  }

  handlePrivate(selectedElt) {
    if (selectedElt) {
      this.privateValue = true;
      this.publicValue = false;
      this.getUserReportTemplates();
    } else {
      this.privateValue = false;
    }
  //  $('.placeholdersValue').html('');
  }

  getUserReportTemplateById(templateId) {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getReportTemplateById(templateId, token).subscribe(
      res => {
        console.log(res);
        this.reportsTemplates = [res];
        this.selectedReportTemplate = res;
        this.outReportTemplateChanged(templateId);
        this.templateloaded = true;
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.templateloaded = true;
        this.showBoxMessage(err);

      });
  }

  getUserReportTemplates() {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getReportTemplates(token).subscribe(
      res => {
        console.log(res);
        this.reportsTemplates = res;

        // this.$.reportsTemplatesList.render();
        this.chooseReportTemplateById();
        this.templateloaded = true;
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.templateloaded = true;
        this.showBoxMessage(err);
      });
  }

  getUserPublicReportTemplates() {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getReportPublicTemplates(token).subscribe(
      res => {
        // console.log(res);
        this.reportsTemplates = res;
        this.templateloaded = true;
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.templateloaded = true;
        this.showBoxMessage(err);
      });
  }

  updatePlaceHolderTable(reportTemplate: any) {
    const savedPlaceHolders = getEnexisSetting();
    let placeholders = reportTemplate.place_holders;
    if (savedPlaceHolders) {
      placeholders = savedPlaceHolders;
    }
    const data = [];
    if (placeholders !== null && placeholders !== undefined) {
      if (placeholders.length > 0) {
        for (const placeholder of placeholders) {
          const keys = Object.keys(placeholder);
          const name = keys[0];
          const value = placeholder[name];
          const obj = {
            name,
            value
          };
          data.push(obj);
        }
        if (this.placeholderstable !== null  && this.placeholderstable !== undefined) {
          this.rptdataSource.data = [];
        }
      }
    }
    this.rptdataSource.data = data;
    this.placeholderstable.renderRows();
  }

  updatePlaceHolderValue(event, element, value) {
    event.preventDefault();
    const val = event.target.data;
    console.log(element);
    // alert('value: ' + value);
    element['value'] = value;
    event.stopPropagation();
  }

  displayReportTemplatePlaceholders(reportTemplate) {
    let elements = '';
    if (reportTemplate === '') {
      // $('.placeholdersValue').html(elements);
      return;
    }

    const placeHolders = reportTemplate.place_holders;

    for (let i = 0; i < placeHolders.length; i++) {
      const placeHolder = placeHolders[i];
      const placeHolderName = Object.keys(placeHolder)[0];
      const placeHolderValue = (placeHolder[placeHolderName] != null) ? placeHolder[placeHolderName] : '';

      elements += '<tr>' +
                      '<td style="width: 27%; padding-right: 0px; text-align: right">' +
                        '<label style="margin-top: 42px; text-align: right; padding-right: 15px">' + placeHolderName + '</label>' +
                      '</td>' +

                      '<td>' +
                      '<paper-input ' +
                      'id="" ' +
                      'class="placehoderData" ' +
                      'key= "' +  placeHolderName + '" ' +
                      'value= "' +  placeHolderValue + '" ' +
                      'error-message$="" ' +
                      'bind-value="" ' +
                      'label="" always-float-label class="text-left" autofocus ' +
                      'style="text-align: left; margin-left: 9px; margin-right: 26px;" >' +
                      '</paper-input>' +
                      '</td>' +
                    '</tr>';

    }

    // $('.placeholdersValue').html(elements);
  }

  getColumnType() {
    let fieldName = 'v'; // this.$.columnItemsID.value;

    if (fieldName === 'Select Column') {
      return;
    }

    const type = this.getAttributeType(fieldName);

    if (type === 'date') {
      this.showBoxMessage('Example date: 2019-10-23');
    } else {
      // this.$.valueDecorator.invalid = false;
    }
  }

  getCompareType(value) {
    if (value !== null) {
      switch (value) {
        case '=':
          return '$eq';
        case '≠' :
          return '$ne';
        case '<' :
          return '$lt';
        case '<=' :
          return '$lte';
        case '>' :
          return '$gt';
        case '>=' :
          return '$gte';
      }
    }

    return null;
  }

  findContainInCriterias() {
    const len = this.criteriaObjects.length;
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        if (this.criteriaObjects[i].operator === 'contains') {
          return this.criteriaObjects[i].value;
        }
      }
    }
    return null;
  }

  // getting the attribute type by the attribute name
  getAttributeType(name) {
    const fieldnameAndTypes = getTemplateFieldsNameAndTypes();
    return fieldnameAndTypes[name];
  }

  // constructing the criteria with the field name and the comparator operator.
  checkCriteria(fieldName, operator) {
    if (this.searchCriteria === null || this.searchCriteria === undefined) {
      return false;
    }
    const tempValue = this.searchCriteria[fieldName];
    if (tempValue !== null && tempValue !== undefined && tempValue !== '') {
      if (tempValue instanceof Object) {
        let temp = {};
        switch (operator) {
          case '≠':
            temp = tempValue.ne;
            break;

          case '<':
            temp = tempValue.lt;
            break;

          case '<=':
            temp = tempValue.lte;
            break;

          case '>':
            temp = tempValue.gt;
            break;

          case '>=':
            temp = tempValue.gte;
            break;
        }
        if (temp !== null && temp !== undefined && temp !== '') {
          return true;
        }
      } else {
        if (tempValue === this.value) {
          return true;
        }
      }
    }

    return false;
  }

  // manage the interface when change the criteria selection
  handleCriteriaSelected(event) {
    event.preventDefault();
    const criteriaObject = null; // this.$.criteriaObjectsList.itemForElement(event.target);
    if (criteriaObject === null || criteriaObject === undefined) {
      return;
    }

    const criteriaSelected = event.target.parentElement.checked;
    const len = this.criteriaDelete.length;
    for (let i = 0; i < len; i++) {
      if (this.criteriaDelete[i].column === criteriaObject.column) {
        if (!criteriaSelected) {
          this.criteriaDelete.splice(i, 1);
        }
        return;
      }
    }
    this.criteriaDelete.push(criteriaObject);
    event.stopPropagation();
  }

    // gets the index of value in array.
  getIndexOfValue(value) {
    if (value === null || value === undefined) {
      return;
    }
    return this.columnItems.indexOf(value);
  }

  ascendingChecked(selectedElt) {
    if (selectedElt) {
      this.descendingValue = false;
      this.ascendingValue = true;
    } else {
      this.ascendingValue = false;
      this.descendingValue = true;
    }
  // alert(`The column 1 ${this.selectedSortColumn}`);
  }

  descendingChecked(selectedElt) {
    if (selectedElt) {
      this.descendingValue = true;
      this.ascendingValue = false;
    } else {
      this.descendingValue = false;
      this.ascendingValue = true;
    }
    // alert(`The column 1 ${this.selectedSortColumn}`);
  }

  ascendingChecked2(selectedElt) {
    if (selectedElt) {
      this.descendingValue2 = false;
      this.ascendingValue2 = true;
    } else {
      this.ascendingValue2 = false;
      this.descendingValue2 = true;
    }
    // alert(`The column 1 ${this.selectedSortColumn2}`);
  }

  descendingChecked2(selectedElt) {
    if (selectedElt) {
      this.descendingValue2 = true;
      this.ascendingValue2 = false;
    } else {
      this.descendingValue2 = false;
      this.ascendingValue2 = true;
    }
    // alert(`The column 1 ${this.selectedSortColumn2}`);
  }

  handleDescendingSort(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.descendingValue = true;
      this.ascendingValue = false;
      // this.$.sortAscending.checked = false;
    } else {
      this.descendingValue = false;
      // this.$.sortDescending.checked = false;
    }
    event.stopPropagation();
  }

  handleAscendingSort(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.ascendingValue = true;
      this.descendingValue = false;
    } else {
      this.ascendingValue = false;
    }
    event.stopPropagation();
  }

  handleDescendingSort2(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.descendingValue2 = true;
      this.ascendingValue2 = false;
    } else {
      this.descendingValue2 = false;
    }
    event.stopPropagation();
  }

  handleAscendingSort2(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.ascendingValue2 = true;
      this.descendingValue2 = false;
    } else {
      this.ascendingValue2 = false;
    }
    event.stopPropagation();
  }

  // allow to select "To" date for the search criteria by date
  handleToDate(event) {
    event.preventDefault();
    const eltSelected = event.target.parentElement.checked;
    if (eltSelected) {
      this.toDateValue = true;
    } else {
      this.toDateValue = false;
    }
    event.stopPropagation();
  }

  outReportTemplateChanged(selectedRepTem) {
    this.getReportTemplateById(selectedRepTem);
    // this.updatePlaceHolderTable(this.selectedReportTemplate);
  }

  // manage the interface when the user checked From Date
  handleFromDate(event) {
      event.preventDefault();
      const itemSelected = event.target.parentElement.checked;
      if (itemSelected) {
        this.fromDateValue = true;
      } else {
        this.fromDateValue = false;
      }
      event.stopPropagation();
  }



  // build the list of columns' names from attributes.
  buildColumnsValues(attributes) {
    if (attributes === undefined || attributes.length === 0) {
      return;
    }

    for (let i = 0; i < attributes.length; i++) {
      const l = attributes[i].split('[').length;
      if (attributes[i] !== 'Coordinates' && l <= 1) {
        this.columnsValues.push(attributes[i]);
      }
    }
  }

  // displaying the list of columns' names.
  createColumnItemsInSelect() {
    const newItemNode0 = document.createElement('option');
    newItemNode0.selected = true;
    newItemNode0.text = 'Select Column';
    // this.$.columnItemsID.appendChild(newItemNode0);
    const newItemNode1 = document.createElement('option');
    const newItemNode3 = document.createElement('option');
    newItemNode1.selected = true;
    newItemNode3.selected = true;
    newItemNode1.text = 'Select Column';
    newItemNode3.text = 'Select Column';
    // this.$.sortColumnId.appendChild(newItemNode1);
    // this.$.sortColumnId2.appendChild(newItemNode3);
    for (let i = 0; i < this.columnsValues.length; i++) {
      const newItemNode = document.createElement('option');
      newItemNode.className = this.columnsValues[i];
      newItemNode.value = this.columnsValues[i];
      // newItemNode.name = this.columnsValues[i];
      newItemNode.text = this.columnsValues[i];
      // this.$.columnItemsID.appendChild(newItemNode);
      const newItemNode2 = document.createElement('option');
      newItemNode2.className = this.columnsValues[i];
      newItemNode2.value = this.columnsValues[i];
      // newItemNode2.name = this.columnsValues[i];
      newItemNode2.text = this.columnsValues[i];

      const newItemNode4 = document.createElement('option');
      newItemNode4.className = this.columnsValues[i];
      newItemNode4.value = this.columnsValues[i];
      // newItemNode4.name = this.columnsValues[i];
      newItemNode4.text = this.columnsValues[i];
    }
  }


  /**
   * building the object of the colums' name to show and the search criteria to apply to the data
   */
  complete_request(aggrBody) {
    let request_pipeline = {};

    request_pipeline = {
      "$lookup": {
          "from": "features",
          "let": {
              "templateIdStr": {
                "$toString": "$_id"
              }
          },
          "pipeline": [
              {
                "$match": {
                  "$expr": {
                    "$eq": [
                        "$template_id",
                        "$$templateIdStr"
                    ]
                  }
                }
              }
          ],
          "as": "relatedFeatures"
        }
    };

    const unwind = {
      "$unwind": "$relatedFeatures"
    };
    const resplaceroot = {
        "$replaceRoot": {
          "newRoot": "$relatedFeatures"
        }
    }
    aggrBody.push(request_pipeline);
    aggrBody.push(unwind);
    aggrBody.push(resplaceroot);
  }
  submitResearchData() {
    let outputType;
    let splitType;

    outputType = this.outPut;
    splitType = this.splitType;
    const project = getProject();
    console.log(project);

    const aggregateBody =[];
    let tempObj = {
      $match: {
        template_id: this.store.template.value.id,
      }
    };

    aggregateBody.push(tempObj);
    // this.complete_request(aggregateBody)
    let reportTemplate = {};
    const placeHolder = {};
    const holders = [];

    let reportTemplatePlaceholders;

    if (this.selectedReportTemplate !== undefined && this.selectedReportTemplate !== null) {
      reportTemplatePlaceholders = this.selectedReportTemplate.place_holders;
      const data = this.rptdataSource.data;
      if (data !== null && data !== undefined) {
        for (const el of data) {
          const  key = el.name;
          let val = el.value;
          const obj = {};
          if (val === null || val === undefined || val === '') {
            val = '';
          }
          obj[key] = val;
          holders.push(obj);
        }
      }
      reportTemplate = {
        report_template_id: this.selectedReportTemplate.id
      };

    }

    let templateId = '';

    if(!this.selectedReportTemplate) {
      this.showBoxMessage('Please the report template is required');
      return;
    }

    const objectSend = {
      template: templateId,
      aggregateBody,
      outputType,
      splitType,
      report_template_id: this.selectedReportTemplate.id,
      report_template_rtid: "csv.public.stedin",
      place_holders: holders
    };

    persistEnexisSetting(holders)

    persistSearchObject(objectSend);
    const result = {
      event: 'search',
      advanced_request: objectSend
    };
    this.resetDialog();
    this.dialogRef.close(result);
  }

  buildAggregation(dataArray) {
    const aggregate = [];
    const andValue = [];
    const matchValue = {};

    const tempObj = {
      template_id: this.template.id
    };

    andValue.push(tempObj);

    if (dataArray.length > 0) {
      for (let i = 0; i < dataArray.length; i++) {
        const obj = dataArray[i];
        const key = Object.keys(obj)[0];

        const keyValues = key.split('.');

        if (key !== '$text' && key !== '$expr' && keyValues[0] !== 'attributes') {
          const newKey = 'attributes.' + key + '.value';

          obj[newKey] = obj[key];
          delete obj[key];
        }
        andValue.push(obj);
      }
    }

    if (this.fromDateValue) {
      if (this.datesValues.fromDate !== null) {
        const d = new Date(this.datesValues.fromDate);

        const val = {
          $dateFromString: {
            dateString: this.datesValues.fromDate,
          }
        };
        const value = {
          $expr: {
            $gte : ['$created_at', val]
          }
        };
        andValue.push(value);
      }
    }

    if (this.toDateValue) {
      if (this.datesValues.toDate !== null) {
        const d = new Date(this.datesValues.toDate);
        d.setDate(d.getDate() + 1);

        const val = {
          $dateFromString: {
            dateString: this.datesValues.toDate,
          }
        };

        const value = {
          $expr: {
            $lte : ['$created_at', val]
          }
        };
        andValue.push(value);
      }
    }
    if (andValue !== undefined && andValue !== null) {
      aggregate.push({
        $match: {
          $and: andValue
        }
      });
    }
    return aggregate;
  }

  // getting the index of the attributes names to hide in the table.
  getColumnsToHide(arrayToShow) {
    const hideColumns = [];
    if (arrayToShow.length === 0) {
      for (let i = 0; i < this.columnItems.length; i++) {
        hideColumns.push(this.columnItems.indexOf(this.columnItems[i]));
      }
      return hideColumns;
    }

    for (let i = 0; i < this.columnItems.length; i++) {
      if (!this.elementInArray(this.columnItems[i].value, arrayToShow)) {
        hideColumns.push(this.columnItems.indexOf(this.columnItems[i]));
      }
    }

    return hideColumns;
  }

  // check if the array contains
  elementInArray(element, array) {
    if (array.length === 0 || element === null) {
      return false;
    }

    for (let i = 0; i < array.length; i++) {
      if (array[i] === element) {
        return true;
      }
    }
    return false;
  }

  // check if an array of objects contains a specific element.
  elementInObjectArray(element, array) {
    if (array.length === 0 || element === null) {
      return;
    }
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === element) {
        return true;
      }
    }
    return false;
  }

  changeCRSType() {
    const type = ''; // this.$.crsType.value;
    persistChoosenCRSType(type);
  }


}
