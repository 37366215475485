import { Template } from './template';

const READ = 'read';
const CREATE = 'create';
const UPDATE = 'update';
const DELETE = 'delete';

class Perm {
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;

  constructor(permission_type: string[]) {
    if (permission_type !== null && permission_type !== undefined && permission_type.length > 0) {
      const notGranted = this.getNotGrantedPerm(permission_type);
      for (const perm of permission_type) {
        if (perm === READ) {
          this.read = true;
        } else if (perm === CREATE) {
          this.create = true;
        } else if (perm === UPDATE) {
          this.update = true;
        } else if (perm === DELETE) {
          this.delete = true;
        }
      }

      for (const perm of notGranted) {
        if (perm === READ) {
          this.read = false;
        } else if (perm === CREATE) {
          this.create = false;
        } else if (perm === UPDATE) {
          this.update = false;
        } else if (perm === DELETE) {
          this.delete = false;
        }
      }
    } else {
      this.create = false;
      this.read = false;
      this.update = false;
      this.delete = false;
    }
  }

  getNotGrantedPerm(granted: string[]): string[]{
    const notGranted = [];
    const perms = [READ, CREATE, UPDATE, DELETE];

    for(let i = 0; i < perms.length; i++){
      if(granted.indexOf(perms[i]) === -1)
        notGranted.push(perms[i]);
    }

    return notGranted;
  }

  selectRead(grant) {
    this.read = (grant) ? true : false;
  }

  selectCreate(grant) {
    this.create = (grant) ? true : false;
  }

  selectUpdate(grant) {
    this.update = (grant) ? true : false;
  }

  selectDelete(grant) {
    this.delete = (grant) ? true : false;
  }

  selectAll(grant) {
    if (grant) {
      this.read = true;
      this.create = true;
      this.update = true;
      this.delete = true;
    } else {
      this.read = false;
      this.create = false;
      this.update = false;
      this.delete = false;
    }
  }
}

export class TemplatePermission {
  template: Template;
  permissions: Perm;

  constructor(template: Template, permissions: string[]) {
    this.initializePerm(template, permissions);
  }

  initializePerm(template, permission_types: string[]) {
    this.template = template;
    this.permissions = new Perm(permission_types);
  }
}
