import { Component, OnInit, ElementRef, ViewChild, AfterContentInit } from '@angular/core';
import { Token } from 'src/app/share/token';
import { UserService } from 'src/app/services/user.service';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';
import { getToken, removeToken, removeProject, logoutUser,
  getCRSSetting, getUser, persistCRSSetting, getAdminInfo,
  getAdminToken, removeAdminToken, getTemplate, setFormUpdating,
  persistLastMenu, getProject, persistFieldToShowOnMap, getFieldToShowOnMap,
  removeTemplate, isCollaborator, removeFieldToShowOnMap, removeSearchObject,
  removeSearchValue, removeCurrentFeaturesUri, persistProject, persistTemplate, persistGeomFormToShow, removeGeomFormToShow } from 'src/app/share/utils';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SettingCoordinatesReferenceDialogComponent }
from '../dialogs/setting-coordinates-reference-dialog/setting-coordinates-reference-dialog.component';
import { McadminLoginDialogComponent } from '../dialogs/mcadmin-login-dialog/mcadmin-login-dialog.component';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { StoreService, SCHEMA_MODE, MAP_MODE, DATA_MODE, IMPORT_DATA_MODE } from 'src/app/services/store.service';
import { CreateProjectComponent } from '../dialogs/create-project/create-project.component';
import { ProjectService } from 'src/app/services/project.service';
import { TemplateService } from 'src/app/services/template.service';
import { CreateTemplateComponent } from '../dialogs/create-template/create-template.component';
import { AttributeSet } from 'src/app/share/feature/attributes';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { ImportProjectComponent } from '../dialogs/import-project/import-project.component';
import { ImportProjectFromDomainComponent } from '../dialogs/import-project-from-domain/import-project-from-domain.component';
import { TitleCasePipe } from '@angular/common';
import { ImportTemplateFromDomainComponent } from '../dialogs/import-template-from-domain/import-template-from-domain.component';
import { ProjectToProjectTemplateComponent } from '../dialogs/project-to-project-template/project-to-project-template.component';
import { ProjectToDomaineComponent } from '../dialogs/project-to-domaine/project-to-domaine.component';
import { GeomFormsListComponent } from '../dialogs/geom-forms-list/geom-forms-list.component';
import { FoundProjectsComponent } from '../found-projects/found-projects.component';
import { FeatureService } from 'src/app/services/feature.service';
import { EnexisExportCritDialogComponent } from '../dialogs/enexis-export-crit-dialog/enexis-export-crit-dialog.component';
import { ReportsService } from 'src/app/services/reports.service';
import { ExportStedinCritDialogComponent } from '../dialogs/export-stedin-crit-dialog/export-stedin-crit-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentInit {
  userToken: Token;
  username: string;
  myCumulusAdmin: boolean;
  collaboratorUser: boolean;
  updatingForm: boolean;
  fields: any;
  templateNameWidth: number;
  messageBoxWidth: number;
  permission: boolean;
  mcAdminToken: any;
  proClass: string = 'Projects';
  isProjectClass: boolean = false;
  isDomainClass: boolean = false;
  isTemplateClass: boolean = false;
  inArchivedMode: boolean = false;
  listItems = [];
  scrWidth;

  @ViewChild('toolbar',  {static: false}) toolbar: ElementRef;

  matInput: string;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  searchValue: string;

  constructor(
    public store: StoreService,
    private userService: UserService,
    private projectService: ProjectService,
    private titlecasePipe:TitleCasePipe,
    private templateService: TemplateService,
    private featureService: FeatureService,
    private reportService: ReportsService,
    private mcUserService: SysAdminUserServiceService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.store.user = getUser();
    const mcAdminToken = getAdminToken();
    if (mcAdminToken) {
      this.store.isMCAdmin = true;
      this.mcAdminToken = mcAdminToken;
      this.isProjectClass = false;
      this.isDomainClass = false;
      this.isTemplateClass = true;
    } else {
      this.store.isMCAdmin = false;
      this.mcAdminToken = null;
      if((this.store.user !== null &&
        this.store.user !== undefined)
        && isCollaborator(this.store.user)) {
          this.collaboratorUser = true;
      } else {
        this.collaboratorUser = false;
      }
      this.isProjectClass = true;
      this.isDomainClass = false;
      this.isTemplateClass = false;
    }
  }

  /**
   * Init element
   */
  ngOnInit() {
    const pc = this.store.proClass;
    if(pc === "project") {
      this.proClass = 'Projects';
    } else if(pc === "template") {
      this.proClass = 'Project Templates';
    } else if (pc === "domain") {
      this.proClass = 'Domains';
    } else {
      this.proClass = 'Projects';
    }

    let u = getUser();
    this.store.user = getUser();
    const mcAdminToken = getAdminToken();
    if (mcAdminToken) {
      this.store.isMCAdmin = true;
      this.mcAdminToken = mcAdminToken;
    } else {
      this.store.isMCAdmin = false;
      this.mcAdminToken = null;
    }
    if (u !== null && u !== undefined) {
      this.username = u.name;
    } else {
      u = getAdminInfo();
      if (u !== null && u !== undefined) {
        this.username = u.name;
      } else {
        this.username = null;
      }
    }
    this.permission = true;
    this.templateNameWidth = 80;
    this.messageBoxWidth = 20;
    this.showMessage = false;
    this.store.hideLoading();
  }

  ngAfterContentInit() {
    if (this.toolbar !== null && this.toolbar !== undefined) {
      this.toolbar.nativeElement.classList.remove('mat-toolbar-multiple-rows');
    }
  }

  goToProjects() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Projects';
      this.isProjectClass = true;
      this.isDomainClass = false;
      this.isTemplateClass = false;
      this.store.changeProClassVisArchi('project', this.store.visibility, false);
      this.store.visibleProject.next('active project');
      this.inArchivedMode = false;
      this.store.searchMode = false;
      this.searchValue = '';
      this.redirectTo('/projects');
      // this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
      //   this.router.navigate(['/projects']);
      // });
    });
  }

  goToArchivedProjects() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Archived Projects';
      this.inArchivedMode = true;
      this.isProjectClass = true;
      this.isDomainClass = false;
      this.isTemplateClass = false;
      this.store.changeProClassVisArchi('project', this.store.visibility, true);
      this.store.visibleProject.next('Archived project');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserDomains() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Domains';
      this.isProjectClass = false;
      this.isDomainClass = true;
      this.isTemplateClass = false;
      this.store.changeProClassVisArchi('domain', this.store.visibility, false);
      this.store.visibleProject.next('current domain');
      this.inArchivedMode = false;
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserArchivedDomains() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Archived Domains';
      this.isProjectClass = false;
      this.isDomainClass = true;
      this.isTemplateClass = false;
      this.store.changeProClassVisArchi('domain', this.store.visibility, true);
      this.store.visibleProject.next('Archived domain');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserProjectsTemplate() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Project Templates';
      this.isProjectClass = false;
      this.isDomainClass = false;
      this.isTemplateClass = true;
      this.store.changeProClassVisArchi('template', this.store.visibility, false);
      this.store.visibleProject.next('current Project template');
      this.inArchivedMode = false;
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserArchivedProjectsTemplate() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Arch Project Templates';
      this.isProjectClass = false;
      this.isDomainClass = false;
      this.isTemplateClass = true;
      this.store.changeProClassVisArchi('template', this.store.visibility, true);
      this.store.visibleProject.next('Archived Project Templates');
      this.inArchivedMode = true;
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToProjectsTemplates() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Project Templates';
      this.isProjectClass = false;
      this.isDomainClass = false;
      this.isTemplateClass = true;
      this.inArchivedMode = false;

      this.store.changeProClassVisArchi('template', 'public', false);
      this.store.visibleProject.next('Public active project template');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToArchivedProjectsTemplates() {
    this.testIfUpdatingForm(() => {
      this.store.projects = [];
      this.proClass = 'Arch Project Templates';
      this.isProjectClass = false;
      this.isDomainClass = false;
      this.isTemplateClass = true;
      this.inArchivedMode = true;

      this.store.changeProClassVisArchi('template', 'public', true);
      this.store.visibleProject.next('Public Archived Project Templates');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  convertProjectToDomain() {
    this.testIfUpdatingForm(() => {
      this.convertProjectToDomainAfterCheck();
    });
  }

  convertProjectToDomainAfterCheck() {
    let currentProject = this.store.project;
    let proClass = this.titlecasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog(`No ${proClass} is selected, please select the ${proClass} to be copied?`);
      return;
    }

    if (this.inArchivedMode) {
      this.showMessageDialog(`Archived ${proClass} cannot be copied?`);
      return;
    }

    this.openProjectToDomainDialog();
    return;
  }

  convertProjectToTemplate() {
    this.testIfUpdatingForm(() => {
      this.convertProjectToTemplateAfterCheck();
    });
  }

  convertProjectToTemplateAfterCheck() {
    let currentProject = this.store.project;
    let proClass = this.titlecasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog(`No ${proClass} is selected, please select the ${proClass} to be copied?`);
      return;
    }

    if (this.inArchivedMode) {
      this.showMessageDialog(`Archived ${proClass} cannot be copied?`);
      return;
    }

    this.openProjectToProjectTemplateDialog();
    return;
  }

  applySearch() {
    if(this.searchValue && this.searchValue.length > 0) {
        this.store.showLoading();
        this.store.foundProjects = null;
        this.projectService.searchProject(this.searchValue, this.store.token).subscribe(
          (result) => {
            this.store.hideLoading();
            this.store.foundProjects = result;
            this.store.searchMode = true;
            this.store.template.next(null);
            this.redirectTo('/search');
          },
          (error) => {
            this.showErrorMessage('Error while searching data');
            this.store.hideLoading();
          }
        );
    } else {
      this.goToProjects();
    }

  }

  copySelectedFeatures() {
    this.store.destinationProject = null;
    this.store.destinationTemplate = null;
    if(this.store.features && this.store.features.length > 0) {
       this.store.copiedFeatures = this.store.features;
    } else {
      this.showErrorMessage('No records selected. Please choose records to copy or duplicate.');
    }
  }

  deleteSelectedFeatures() {
    if((this.store.copiedFeatures && this.store.copiedFeatures.length > 0) ||
      (this.store.features && this.store.features.length > 0)) {
        const size = this.store.features.length;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message: 'These records will be deleted on the server.',
            title: 'Delete ' + size + ' records ?'
          }
        });
        dialogRef.afterClosed().subscribe(result  => {

          if (result) {
            const token = getToken();
            this.store.showLoading();
            const ids = [];
            const features = this.store.copiedFeatures && this.store.copiedFeatures.length > 0 ? this.store.copiedFeatures : this.store.features;
            for (const feature of features) {
              ids.push(feature.id);
            }
            const project = this.store.destinationProject ? this.store.destinationProject : getProject();
            const template = this.store.destinationTemplate ? this.store.destinationTemplate : getTemplate();
            this.featureService.deleteFeatures(ids, template, project, token).subscribe(
              res => {
                this.store.features = null;
                this.showSuccessMessage(size + ' record(s) deleted');
                if(this.store.searchMode) {
                  this.applySearch();
                } else {
                  this.store.template.next(template);
                }
                this.store.hideLoading();
              },
              errmes => {
                this.store.hideLoading();
                this.showErrorMessage('Error while deleting the features' + errmes);
              }
            );
          }
        });
    } else {
      this.showErrorMessage('No records selected. Please choose records to delete');
    }
  }

  cutSelectedFeatures() {
    if(this.store.features && this.store.features.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          message: 'These records will be deleted from the source forms on the server,after pasting.',
          title: 'Cutting ' + this.store.features.length + ' records ?'
        }
      });
      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          this.store.deleteFeaturesAfterPasting = true;
          this.store.copiedFeatures = this.store.features;
        }
      });
    } else {
      this.showErrorMessage('No records selected. Please choose records to delete');
    }

  }

  pasteCopiedFeaturesAction(project, template) {
    const features = this.store.copiedFeatures;
    const featuresId = [];
    for(const feature of this.store.copiedFeatures) {
        featuresId.push(feature.id);
    }

    const token = getToken();
    this.store.showLoading();
    //console.log(featuresId, template, project);
    this.featureService.duplicateFeatures(featuresId, template, project, token, true).subscribe(
      (result) => {
        if(this.store.deleteFeaturesAfterPasting) {
          const fIds = [];
          for(let f of features) {
            fIds.push(f.id);
          }
          this.featureService.deleteFeatures(fIds, template, project, token).subscribe(
            (res) => {
              this.store.hideLoading();
              this.store.copiedFeatures = [];
              this.showSuccessMessage("Records pasted successfully!");
              this.redirectTo('/projects');
            },
            (err) => {
              this.store.hideLoading();
            }
          );
        } else {
          const feat = result['features'];
          if(feat && feat.length > 0) {
            if(feat.length === this.store.copiedFeatures.length) {
              this.store.copiedFeatures = [];
              if(!this.store.searchMode) {
                this.store.template.next(template);
              } else {
                this.applySearch();
              }
              this.showSuccessMessage(`${feat.length} Records pasted successfully!`);
            } else {
              this.showErrorMessage('Pasting records failed, verify that source and destination forms have the same structure');
            }
          } else {
            this.showErrorMessage('Pasting records failed, verify that source and destination forms have the same structure');
          }
          this.store.hideLoading();
        }
      },
      (error) => {
        this.store.hideLoading();
        this.showErrorMessage('Pasting records failed, verify that source and destination forms have the same structure');
      }
    )
  }

  pasteCopiedFeatures() {
    //if(this.store.searchMode) {
      //this.showMessageDialog("You are pasting the selected features in the same Form of the same project.");
      // return;
    //}
    if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
      let project = this.store.destinationProject;
      let template = this.store.destinationTemplate;
      const templateId = this.store.copiedFeatures[0]['template_id'];

      if(this.store.destinationProject || this.store.destinationTemplate) {
        let project = this.store.destinationProject ? this.store.destinationProject : getProject();
        let template = this.store.destinationTemplate ? this.store.destinationTemplate : getTemplate();
        if(templateId === template.id) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: {
              message: `Pasting into the same form will duplicate the records. Do you wish to continue?`,
              title: 'Duplicate Record(s) Alert'
            }
          });
          dialogRef.afterClosed().subscribe(result  => {
            if (result) {
              if(!project) {
                project = getProject();
              }
              if(!template) {
                template = getTemplate();
              }
              this.pasteCopiedFeaturesAction(project, template);
              if(this.store.searchMode) {
                this.applySearch();
              }
            }
          });
        } else {
          this.pasteCopiedFeaturesAction(project, template);
        }

      } else {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message: `Pasting into the same form will duplicate the records. Do you wish to continue?`,
            title: 'Duplicate Record(s) Alert'
          }
        });
        dialogRef.afterClosed().subscribe(result  => {
          if (result) {
            if(!project) {
              project = getProject();
            }
            if(!template) {
              template = getTemplate();
            }
            this.pasteCopiedFeaturesAction(project, template);
            if(this.store.searchMode) {
              this.applySearch();
            }
          }
        });
      }
    } else {
      this.showErrorMessage('Please copy records before attempting to paste.');
    }
  }

  duplicateSelectedFeatures() {
    if(this.store.features && this.store.features.length > 0){
      this.copySelectedFeatures();
      this.pasteCopiedFeatures();
    } else {
      this.showErrorMessage('No records selected. Please choose records to copy or duplicate.');
    }

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate([uri]));
 }

  convertTemplateToProject() {
    this.testIfUpdatingForm(() => {
      this.convertTemplateToProjectAfterCheck();
    });
  }

  convertTemplateToProjectAfterCheck() {
    let currentProject = this.store.project;
    let proClass = this.titlecasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog(`No ${proClass} is selected. Please select the ${proClass} that needs to be copied.`);

      return;
    }

    if (this.inArchivedMode) {
      this.showMessageDialog(`Archived ${proClass} cannot be copied?`);
      return;
    }
    let token = getToken();
    let proToken = {
      key: '',
      value: ''
    };
    if(this.store.isMCAdmin) {
      token = getAdminToken();
      proToken = {
        key: 'X-Admin-Auth-Token',
        value: token.key
      };
    } else {
      proToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    }
    this.store.showLoading();
    currentProject.project_class = 'project';
    this.projectService.updateProject(currentProject, proToken).subscribe(
      res => {
        this.store.deleteProject(res);
        this.store.hideLoading();
      },
      errmes => {
        this.store.hideLoading();
        this.showMessageDialog(errmes);
      }
    );
  }

  convertDomainToProject() {
    this.testIfUpdatingForm(() => {
      this.convertDomainToProjectAfterCheck();

    });
  }

  convertDomainToProjectAfterCheck() {
    let currentProject = this.store.project;
    let proClass = this.titlecasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog(`No ${proClass} is selected. Please select the ${proClass} that needs to be copied.?`);
      return;
    }
    if (this.inArchivedMode) {
      this.showMessageDialog(`Archived ${proClass} cannot be copied?`);
      return;
    }

    let token = getToken();
    let proToken = {
      key: '',
      value: ''
    };
    if(this.store.isMCAdmin) {
      token = getAdminToken();
      proToken = {
        key: 'X-Admin-Auth-Token',
        value: token.key
      };
    } else {
      proToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    }
    this.store.showLoading();
    currentProject.project_class = 'project';
    this.projectService.updateProject(currentProject, proToken).subscribe(
      res => {
        this.store.deleteProject(res);
        const projectName = currentProject.name;
        this.store.hideLoading();
      },
      errmes => {
        this.store.hideLoading();
        this.showMessageDialog(errmes);
      }
    );

  }

  goToProjectsDomains() {
    this.testIfUpdatingForm(() => {
      this.proClass = 'Domains';
      this.isProjectClass = false;
      this.isDomainClass = true;
      this.isTemplateClass = false;
      this.inArchivedMode = false;

      this.store.changeProClassVisArchi('domain', this.store.visibility, false);
      this.store.visibleProject.next('current domain');
      this.router.navigateByUrl('/projects', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToArchivedProjectsDomains() {
    this.testIfUpdatingForm(() => {
      this.proClass = 'Archived Domains';

      this.isProjectClass = false;
      this.isTemplateClass = false;
      this.inArchivedMode = true;
      this.isDomainClass = true;

      this.store.changeProClassVisArchi('domain', this.store.visibility, true);
      this.store.visibleProject.next('Archived private domain');
      this.router.navigateByUrl('/projects', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToProjectMembers() {
    this.testIfUpdatingForm(() => {
      this.router.navigate(['/users'], { queryParams: { type: 'projects-members' } });
      this.store.hideProjects();
    });
  }


  goToReportTemplates() {
    this.testIfUpdatingForm(() => {
      let token = getToken();
      if (token !== null && token !== undefined) {
          this.router.navigate(['/report-templates']);
      } else {
        token = getAdminToken();
        if (token !== null && token !== undefined) {
          this.router.navigate(['/mcadmins_report_templates']);
        }
      }
      this.store.hideProjects();
    });
  }

  goToTemplatestyle() {
    this.testIfUpdatingForm(() => {
      let token = getToken();
      if(token === null || token === undefined) {
        token = getAdminToken();
      }
      if (token !== null && token !== undefined) {
          this.router.navigate(['/templates_style']);
      }
      this.store.hideProjects();
    });
  }

  showArchivedElement(element) {
    if(element.includes('Archived')) {
      this.inArchivedMode = true;
      this.store.archived = true;
      this.store.changeProClassVisArchi(this.store.proClass, this.store.visibility, true);
      this.store.visibleProject.next('Archived ' + this.store.proClass);
    } else {
      this.inArchivedMode = false;
      this.store.archived = false;
      this.store.changeProClassVisArchi(this.store.proClass, this.store.visibility, false)
      this.store.visibleProject.next('current ' + this.store.proClass);
    }

  }

  goToReports() {
    this.testIfUpdatingForm(() => {
      this.router.navigate(['/reports']);
      this.store.hideProjects();
    });
  }

  goToAccountInfo() {
    this.testIfUpdatingForm(() => {
      if (this.store.isMCAdmin) {
        this.router.navigate(['/mcadmins']);
      } else {
        this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
        this.store.hideProjects();
      }
    });
  }

  /**
   * This method open the login page to the user
   */
  openLoginDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    // dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;
    const dialogRef = this.dialog.open(LoginComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result  => {
        const u = getUser();
        if (u !== null && u !== undefined) {
          this.username = u.name;
          if (this.store.user !== null) {
            this.myCumulusAdmin =
            (this.store.user['admin_type'] !== null) && (this.store.user['admin_type'] !== undefined);
          }
        }
    });
  }

  openMCAdminLoginDialog() {
    const dialogRef = this.dialog.open(McadminLoginDialogComponent, {width: '500px', height: 'auto'});
    dialogRef.afterClosed().subscribe(res => {
      // this.username = this.store.user.name;
      if (this.store.user !== null) {
        this.myCumulusAdmin =
            (this.store.user['admin_type'] !== null) && (this.store.user['admin_type'] !== undefined);
      }
    });
  }

  /**
   * This method open the signup dialog to the user
   */
  openSignupDialog() {
    this.dialog.open(SignupComponent, {width: '500px', height: 'auto'});
  }

  /**
   * This method open the account setting page
   */
  openCRSSettingPage() {
   // this.showMessageDialog('Opening setting dialog');
    let crs = getCRSSetting();
    let data = {};
    if (crs !== null && crs !== undefined && crs !== '') {
      data = {
        crs
      };
    }
    const dialogRef = this.dialog.open(SettingCoordinatesReferenceDialogComponent, {
      width: '500px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
          crs = result.crs;
          persistCRSSetting(crs);
        }
      }
    );
  }

  showMessageDialog(msg) {
    this.showErrorMessage(msg);
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    this.templateNameWidth = 20;
    this.messageBoxWidth = 80;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
    setTimeout(() => {
      this.templateNameWidth = 80;
      this.messageBoxWidth = 20;
    }, 11000);
    setTimeout(() => {
      this.message = '';
      this.showMessage = false;
    }, 13000);
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    this.templateNameWidth = 20 ;
    this.messageBoxWidth = 80;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
    setTimeout(() => {
      this.templateNameWidth = 80;
      this.messageBoxWidth = 20;
    }, 11000);
    setTimeout(() => {
      this.message = '';
      this.showMessage = false;
    }, 13000);
  }

  /**
   * This method send logout request to MyCumulus server and when succeed
   * the user information is cleared from the local storage and also
   * in the IAppState.
   */
  logout() {
    // Send logout request to the server and clear the token from the localstorage
    // Also Reset AppState in Reset
    // // console.log('Call deleting of template');
    this.searchValue = null;
    this.testIfUpdatingForm(() => {
      let token = getToken();
      this.store.showLoading();
      if (token !== null && token !== undefined) {
        this.userService.logout(token).subscribe(
          res => {
            logoutUser();
            removeToken();
            this.store.logout();
            this.proClass = "Projects";
            this.setProjectClass();
            this.router.navigate(['/login']);
            this.store.hideLoading();

            // this.store.token = null;
          },
          err => {
            logoutUser();
            this.store.logout();
            this.proClass = "Projects";
            this.setProjectClass();
            this.router.navigate(['/login']);
            this.store.hideLoading();
          }
        );
      } else {
          token = getAdminToken();
          this.mcUserService.logout(token).subscribe(
            res => {
              logoutUser();
              removeAdminToken();
              this.store.logout();
              this.setProjectClass();
              this.proClass = "Projects";
              this.router.navigate(['/login']);
              this.store.hideLoading();
            },
            err => {
              logoutUser();
              this.store.logout();
              this.proClass = "Projects";
              this.setProjectClass();
              this.router.navigate(['/login']);
              this.store.hideLoading();
            }
          );
      }
      this.myCumulusAdmin = false;
    });
  }

  setProjectClass() {
    this.isProjectClass = true;
    this.isDomainClass = false;
    this.isTemplateClass = false;
    this.inArchivedMode = false;
  }
  /**
   * Code concerning projects management
   */
  showProjectTemplates() {
    this.testIfUpdatingForm(() => {
      this.showProjectTemplatesAfterCheck();
    });
  }

  showProjectTemplatesAfterCheck() {
    const currentProject = this.store.project;
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog('No Project | Template | Domain is selected, please select the project.');
      return;
    }
    this.getTemplates(currentProject);
  }

  getTemplates(p) {
    const token: Token = JSON.parse(localStorage.getItem('token'));

    this.store.showLoading();
    this.templateService.getTemplates(token, p.id).subscribe(
      res => {
        // this.templates = res;
        this.store.readTemplates(res);
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.showMessageDialog(errmess);
    });
  }

  openUpdateProjectDialog() {
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (project !== null && project !== undefined && user.id === project.created_by){
        this.openUpdateProjectDialogAfterCheck();
      } else {
        this.showMessageDialog('No Project | Template | Domain is selected, please select the project to be updated');
        return;
      }
    });
  }

  openUpdateProjectDialogAfterCheck() {
    const currentProject = this.store.project;

    if (currentProject === undefined || currentProject === null) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`No ${projectClass} is selected, please select the project to be updated`);
      return;
    }

    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Archived projects cannot be changed`);
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project: currentProject,
      mode: 'Update'
    };
    this.dialog.open(CreateProjectComponent, dialogConfig);
  }

  removeProject() {
    if(this.store.features && this.store.features.length > 0) {
      this.showMessageDialog(`Deleting the project or form is not possible if any records are selected. Please deselect them`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (user.id === project.created_by) {
        this.removeProjectAfterCheck();
      }
    });
  }

  removeProjectAfterCheck() {
    let currentProject = this.store.project;
    let proClass = this.titlecasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog(`No ${proClass} is selected, please select the project to be deleted?`);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: `Remove project "${currentProject.name}" ?`,
        message: `When you remove the ${proClass}, all forms and data related to it are lost, Continue?`
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const secondDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '600px',
          data: {
            title: `Confirm remove project "${currentProject.name}" ?`,
            message: `Please think twice, this action cannot be undone!\n Are you really sure you want to delete the project, all forms and the data?`,

          }
        });

        secondDialogRef.afterClosed().subscribe(res  => {
          if (res) {
            this.deleteProjectOnServer();
          }
        });
      }
    });
  }

  deleteProjectOnServer() {
    let currentProject = this.store.project;
    let token = getToken();
    let proToken = {
      key: '',
      value: ''
    };
    let isMyCumulusAdministrator = false;
    if(this.store.isMCAdmin) {
      token = getAdminToken();
      if(token) {
        proToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
        isMyCumulusAdministrator = true;
      }
    } else {
      proToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    }

    this.store.showLoading();
    this.projectService.deleteProject(currentProject, proToken, isMyCumulusAdministrator).subscribe(
      res => {
        this.store.deleteProject(res);
        const projectName = currentProject.name;
        removeProject();
        removeTemplate();
        currentProject = null;
        this.store.hideLoading();
      },
      errmes => {
        this.store.hideLoading();
        this.showMessageDialog(errmes);
      }
    );
  }

  archivedDomain() {
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      let isMyCumulusAdministrator = false;
      if (project !== null && project !== undefined) {
        let token = getToken();
        let proToken = {
          key: '',
          value: ''
        };
        if(this.store.isMCAdmin) {
          token = getAdminToken();
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
          isMyCumulusAdministrator = true;
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }
        this.store.showLoading();
        let currentProject = this.store.project;
        currentProject.archived = true;
        this.projectService.updateProject(currentProject, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.store.deleteProject(res);
            removeProject();
            removeTemplate();
            currentProject = null;
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.showMessageDialog(errmes);
          }
        );
      } else {
        let proClass = this.titlecasePipe.transform(this.store.proClass);
        this.showMessageDialog(`No ${proClass} is selected. Please select the ${proClass} that needs to be archived.`);
        return;
      }
    });
  }

  archivedProjectTemplate() {
    this.testIfUpdatingForm(() => {
      const user = getUser();
      let isMyCumulusAdministrator = false;
      const project = this.store.project;
      if (project !== null && project !== undefined) {
        let token = getToken();
        let proToken = {
          key: '',
          value: ''
        };
        if(this.store.isMCAdmin) {
          token = getAdminToken();
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
          isMyCumulusAdministrator = true;
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }
        this.store.showLoading();
        let currentProject = this.store.project;
        currentProject.archived = true;
        this.projectService.updateProject(currentProject, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.store.deleteProject(res);
            removeProject();
            removeTemplate();
            currentProject = null;
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.showMessageDialog(errmes);
          }
        );
      } else {
        let proClass = this.titlecasePipe.transform(this.store.proClass);
        this.showMessageDialog(`No Project Template is selected. Please select the Project Template that needs to be archived.`);
        return;
      }
    });
  }

  archivedProject() {
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (project !== null && project !== undefined) {
        this.archivedProjectAfterCheck();
      } else {
        let proClass = this.titlecasePipe.transform(this.store.proClass);
        this.showMessageDialog(`No ${proClass} is selected. Please select the ${proClass} that needs to be archived.`);
        return;
      }
    });
  }

  archivedProjectAfterCheck() {
    let currentProject = this.store.project;
    let proClass = this.titlecasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog(`No ${proClass} is selected. Please select the ${proClass} that needs to be archived.`);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: `Archive ${proClass} "${currentProject.name}" ?`,
        message: `The archived Project will not be visible anymore to users of the mobile app.`
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        let token = getToken();
        let proToken = {
          key: '',
          value: ''
        };
        if(this.store.isMCAdmin) {
          token = getAdminToken();
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }
        this.store.showLoading();
        currentProject.archived = true;
        this.projectService.updateProject(currentProject, proToken).subscribe(
          res => {
            this.store.deleteProject(res);
            removeProject();
            removeTemplate();
            currentProject = null;
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.showMessageDialog(errmes);
          }
        );
      }
    });
  }

  unArchivedProject() {
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (project !== null && project !== undefined && user.id === project.created_by) {
        this.unArchivedProjectAfterCheck();
      } else {
        let proClass = this.titlecasePipe.transform(this.store.proClass);
        this.showMessageDialog(`No ${proClass} is selected. please select the ${proClass} to be restored?`);
        return;
      }
    });
  }

  unArchivedProjectAfterCheck() {
    let currentProject = this.store.project;
    let proClass = this.titlecasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog(`No ${proClass} is selected, please select the ${proClass} to be restored?`);
      return;
    }
    let token = getToken();
    let proToken = {
      key: '',
      value: ''
    };
    let isMyCumulusAdministrator = false;
    if(this.store.isMCAdmin) {
      token = getAdminToken();
      proToken = {
        key: 'X-Admin-Auth-Token',
        value: token.key
      };
      isMyCumulusAdministrator = true;
    } else {
      proToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    }
    this.store.showLoading();
    currentProject.archived = false;
    this.projectService.updateProject(currentProject, proToken, isMyCumulusAdministrator).subscribe(
      res => {
        this.store.deleteProject(res);
        this.store.hideLoading();
      },
      errmes => {
        this.store.hideLoading();
        this.showMessageDialog(errmes);
      }
    );

  }

  duplicateProject() {
    this.testIfUpdatingForm(() => {
      this.duplicateProjectAfterCheck();
    });
  }

  /**
   *
   */
  duplicateProjectAfterCheck() {
    // Do you really want to duplicate the project?
    // If yes, a new project will be made containing the same forms as the original project.
    // The records in the forms will not be copied!
    const currentProject = this.store.project;

    if (currentProject === undefined || currentProject === null) {
      this.showMessageDialog('No Project | Template | Domain is selected, please select the project to be deleted');
      return;
    }
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Please you cannot duplicate archived  ${projectClass}`);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: `Do you really want to duplicate the project "${currentProject.name}"?\n ` +
        `If yes, a new project will be made containing the same forms as the original project. ` +
        `The records in the forms will not be copied!`,
        title: 'Duplicate Project?',
        formDuplication: true
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.createProjectAndTemplates();
      }
    });
  }

  createProjectAndTemplates() {
    this.testIfUpdatingForm(() => {
      this.createProjectAndTemplatesAfterCheck();
    });
  }

  createProjectAndTemplatesAfterCheck() {
    const newProjName = this.store.project.name + ' Copy 1';
    const currentProject = this.store.project;
    const project = {
      project_id: currentProject.id,
      name: currentProject.name,
      description: currentProject.description
    };

    const token = getToken();
    const proToken = {
      'key': 'key',
      value: token.key
    };
    const oldProject = currentProject;
    this.store.showLoading();

    this.projectService.duplicateProject(project, proToken).subscribe(
      newProject => {
        this.store.createProject(newProject);
        this.store.hideLoading();
      },
      error => {
        if (error.status === '409') {
          const names = newProjName.split('Copy');
          const num = +names[names.length - 1] + 1;
          const project = {
            project_id: currentProject.id,
            name: currentProject.name + ' Copy ' + num,
            description: currentProject.description
          };
          const token = getToken();
          const proToken = {
            'key': 'key',
            value: token.key
          };
          const oldProject = currentProject;
          this.store.showLoading();
          this.projectService.duplicateProject(project, proToken).subscribe(
            res3 => {
              const newProject = res3;
              this.store.createProject(res3);
              this.store.hideLoading();
            },
            err4 => {
              if (err4.status === '409') {
                this.showMessageDialog(`You or one of your collaborator has a project with the same name. `);
                this.store.hideLoading();
              }
              this.showMessageDialog(err4);
              this.store.hideLoading();
            });
        }
        this.showMessageDialog(error.error.error);
        this.store.hideLoading();
        return;
      });
  }

  openProjectToDomainDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.openCopyProjectToDomainDialogAfterCheck();
        }
      } else {
        this.openCopyProjectToDomainDialogAfterCheck();
      }
    });
  }

  openCopyProjectToDomainDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    let currentProject = this.store.project;
    dialogConfig.data = {
      project: currentProject,
      mode: 'Copy'
    };

    if(this.store.isMCAdmin) {
      this.dialog.open(ProjectToDomaineComponent, dialogConfig);
    } else {
      this.dialog.open(ProjectToDomaineComponent, dialogConfig);
    }
  }

  openProjectToProjectTemplateDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived  ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.openCopyProjectToProjectTemplateDialogAfterCheck();
        }
      } else {
        this.openCopyProjectToProjectTemplateDialogAfterCheck();
      }
    });
  }

  openCopyProjectToProjectTemplateDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    let currentProject = this.store.project;
    dialogConfig.data = {
      project: currentProject,
      mode: 'Copy'
    };

    if(this.store.isMCAdmin) {
      this.dialog.open(ProjectToProjectTemplateComponent, dialogConfig);
    } else {
      this.dialog.open(ProjectToProjectTemplateComponent, dialogConfig);
    }
  }

  openCreateProjectDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.openCreateProjectDialogAfterCheck();
        }
      } else {
        this.openCreateProjectDialogAfterCheck();
      }
    });
  }

  openImportProjectDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.openImportProjectDialogAfterCheck();
        }
      } else {
        this.openImportProjectDialogAfterCheck();
      }
    });
  }

  openCreateProjectDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;

    this.dialog.open(CreateProjectComponent, dialogConfig);

  }

  openImportProjectDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;
    this.dialog.open(ImportProjectComponent, dialogConfig);

  }

  openImportDomainProjectDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.openImportDomainProjectDialogAfterCheck();
        }
      } else {
        this.openImportDomainProjectDialogAfterCheck();
      }
    });
  }

  openImportDomainProjectDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;

    let dialogRef = null;
    if(this.store.isMCAdmin) {
      dialogRef = this.dialog.open(ImportProjectFromDomainComponent, dialogConfig);
    } else {
      dialogRef = this.dialog.open(ImportProjectFromDomainComponent, dialogConfig);
    }
    dialogRef.afterClosed().subscribe(res  => {
      if(res !== null && res !== undefined && res !== "") {
        this.openTemplatePage(res);
      }
    });
  }

  testIfUpdatingForm(action) {
    // this.updatingForm = getFormUpdating();
    this.updatingForm = this.store.updatingForm;
    if (this.updatingForm !== undefined &&
        this.updatingForm !== null &&
        this.updatingForm === true) {
        const templ = getTemplate();
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message:
            `Some changes to the form "${templ.name}" have been made. \nDo you want to continue without saving the changes?`,
            title: 'Save changes?'
          }
        });

        dialogRef.afterClosed().subscribe(result  => {
          if (result) {
            setFormUpdating(false);
            this.store.updatingForm = false;
            this.store.changeFormUpdatingState(false);
            action();
          }
        });
    } else {
      action();
    }
  }

  openTemplatePage(p) {
    removeProject();
    removeFieldToShowOnMap();
    removeSearchObject();
    removeSearchValue();
    removeCurrentFeaturesUri();
    this.store.features = null;
    this.store.readProject(p);
    persistProject(p);
    this.store.template.next(null);
    persistTemplate(null);
    this.store.selectedTemplate.next('');
    this.store.selectedFieldSet.next('');
    if(p !== null && p !== undefined) {
      this.getTemplates(p);
      this.store.showTemplates();
    } else {
      this.store.hideTemplate();
    }
  }



  /**
   * Code concerning templates management
   */

  openAddTemplateDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (user.id === project.created_by) {
        this.openAddTemplateDialogAfterCheck();
      }
    });
  }

  openAddTemplateDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Create'
    };
    this.dialog.open(CreateTemplateComponent, dialogConfig);
  }

  openAddTemplateFromDomainDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (project != null && user.id === project.created_by) {
        this.openAddTemplateFromDomainDialogAfterCheck();
      } else {
        const projectClass = this.titlecasePipe.transform(this.store.proClass);
        this.showMessageDialog(`Please, choose a  ${projectClass}`);
        return;
      }
    });
  }

  openAddTemplateFromDomainDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Create',
      proClass: 'domain'
    };
    this.dialog.open(ImportTemplateFromDomainComponent, dialogConfig);
  }

  openAddTemplateFromFileDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived  ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (user.id === project.created_by) {
        this.openAddTemplateFromFileDialogAfterCheck();
      }
    });
  }

  openImportIKMLDialog(type) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived  ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (user.id === project.created_by) {
        this.openAddTemplateFromIMKLFileAfterCheck(type);
      }
    });
  }

  openImportShapeDialog(type) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived  ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (user.id === project.created_by) {
        this.openAddTemplateFromShapeFileAfterCheck(type);
      }
    });
  }

  showCustomExport(event) {
    event.stopPropagation();
    if(this.store.templateData) {
      this.store.customExport.next(true);
    } else {
      this.showErrorMessage("That action is only available in data view mode.");
    }

  }

  openAddTemplateFromIMKLFileAfterCheck(type) {
    persistLastMenu('import');
    this.store.hideTemplate();
    this.store.typeOfIMKLFile.next(type);
    this.store.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.store.showImportIMKLData(type);
  }

  openAddTemplateFromShapeFileAfterCheck(type) {
    persistLastMenu('import');
    this.store.hideTemplate();
    this.store.typeOfIMKLFile.next(type);
    this.store.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.store.showImportShapeFile(type);
  }

  openImportExcelDialog(type) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived  ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (user.id === project.created_by) {
        this.openAddTemplateFromExcelFileAfterCheck(type);
      }
    });
  }

  openAddTemplateFromExcelFileAfterCheck(type) {
    persistLastMenu('import');
    this.store.hideTemplate();
    this.store.typeOfExcelFile.next(type);
    this.store.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.store.showImportExcelData(type);
  }


  openAddTemplateFromFileDialogAfterCheck() {
    persistLastMenu('import');
    //this.store.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.store.showImportDataInTemplate();

    // this.dialog.open(ImportFileDialogComponent, dialogConfig);
  }

  openMapData() {
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      return;
    }
    this.testIfUpdatingForm(() => {
      this.openMapDataAfterCheck();
    });
  }

  openMultipleFormMapData() {
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      return;
    }
    this.testIfUpdatingForm(() => {
      persistLastMenu('map');
      this.store.formMode = MAP_MODE;
      this.store.showMultipleFormsOnMap();
    });
  }

  showEnexisExportDialog() {
    const project = getProject();
    if(!project) {
      this.showMessageDialog(`Please choose a project`);
      return;
    }
    const dialogRef = this.dialog.open(EnexisExportCritDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '80vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: null
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        this.submitReport(result.advanced_request, ExportTypes.Enexis);
      }
    });
  }

  showStedinExportDialog() {
    const project = getProject();
    if(!project) {
      this.showMessageDialog(`Please choose a project`);
      return;
    }
    const dialogRef = this.dialog.open(ExportStedinCritDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '80vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: null
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        this.submitReport(result.advanced_request, ExportTypes.Stedin);
      }
    });
  }


  submitReport(data, exportType: ExportTypes) {
    const aggregate = data.aggregateBody;
    const output = data.outputType;
    const splitType = data.splitType;
    const report_template_rtid = data.report_template_rtid;
    const placesHolders = data.place_holders;

    if (aggregate !== null && aggregate.length > 0) {

      if(exportType === ExportTypes.Enexis) {
        this.generateEnexisReport(report_template_rtid, aggregate, output, splitType, placesHolders);
      } else if(exportType === ExportTypes.Stedin) {
        this.generateStedinReport(report_template_rtid, aggregate, output, splitType, placesHolders);
      }
    }
  }

  generateEnexisReport(report_template_rtid, operators, reportType, splitType, placesHolders) {
    const token = getToken();
    const body = JSON.stringify({
      configuration: {
        format: reportType,
        split_type: splitType
      },
      report_template_rtid: report_template_rtid,
      place_holders: placesHolders,
      queries: [{
        collection: 'templates',
        stages: operators,
      }]
    });


    this.store.showLoading();
    this.reportService.createEnexisReport(token, body).subscribe(
      res => {
        this.store.hideLoading();
        this.showSuccessMessage('The request to create the report has been sent.');
      },
      err => {
        this.message = err;
        this.showErrorMessage(err);
        this.store.hideLoading();
      }
    );
  }

  generateStedinReport(report_template_rtid, operators, reportType, splitType, placesHolders) {
    const token = getToken();
    const body = JSON.stringify({
      configuration: {
        format: reportType,
        split_type: splitType
      },
      report_template_rtid: report_template_rtid,
      place_holders: placesHolders,
      queries: [{
        collection: 'features',
        stages: operators,
      }]
    });

    this.store.showLoading();
    this.reportService.createEnexisReport(token, body).subscribe(
      res => {
        this.store.hideLoading();
        this.showSuccessMessage('The request to create the report has been sent.');
      },
      err => {
        this.message = err;
        this.showErrorMessage(err);
        this.store.hideLoading();
      }
    );
  }

  openMapDataAfterCheck() {
    const selectedTemplate = this.store.template.getValue();
    if (selectedTemplate === null || selectedTemplate.feature === null || selectedTemplate.feature.geometry_type === null) {
      // this.toastr.warning('Open map is reseved for Geometry Forms', 'MyCumulus');
      // return;
    }
    const fields = getFieldToShowOnMap();
    if (fields !== null && fields !== undefined &&
      fields[0].template_to_show === this.store.template.getValue().id) {
      this.store.showTemplateFeatureDataMap();
      return;
    } else {
      if(selectedTemplate) {
        const obj = {};
        const templId = selectedTemplate['id'];
        obj[templId] = true;
        removeGeomFormToShow();
        if(obj) {
          persistGeomFormToShow(obj);
        }
        this.saveFieldsAndLabels(this.store.template.getValue().feature.attributes);
        const labels = [];
        //const keys = Object.keys(this.fields);
        for (const value of this.fields) {
          // const value = this.fields[key];
          if (value['template_to_show'] !== null) {
            const fieldname = value['label'];
            labels.push(fieldname);
          }
        }
        this.store.setFieldToShowOnMap(labels);
        persistFieldToShowOnMap(this.fields);
      }
    }
    persistLastMenu('map');
    this.store.formMode = MAP_MODE;
    this.store.showTemplateFeatureDataMap();
  }

  saveFieldsAndLabels(attributes: any[]) {
    this.fields = [];
    this.fields.push({
      template_to_show: this.store.template.getValue().id
    });
    this.saveFields(attributes);
  }

  saveFields(attributes: any, parent= '') {
    const name = 'name';
    const _class = '_class';
    for (const attribute of attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const value = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        const label = {
          label: value
        };
        this.fields.push(label);
      } else if (typeAttr === 'attributeset') {
        const nested_parent = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        this.saveFields((attribute as AttributeSet).attributes, nested_parent);
      }
    }
  }

  openEditTemplateDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if(user.id === project.created_by){
        this.openEditTemplateDialogAfterCheck();
      }

    });
  }

  openEditTemplateDialogAfterCheck() {
    const selectedTemplate = this.store.template.getValue();
    if (selectedTemplate === null) {
      this.showMessageDialog('Please choose a Form');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project: this.store.project,
      template: selectedTemplate,
      mode: 'Update'
    };
    this.dialog.open(CreateTemplateComponent, dialogConfig);
  }

  openFeatureData() {
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      return;
    }
    this.testIfUpdatingForm(() => {
      this.openFeatureDataAfterCheck();
    });
  }

  openFeatureDataAfterCheck() {
    // TODO show template feature data
    persistLastMenu('data');
    this.store.showTemplateFeatureData();
    this.store.formMode = DATA_MODE;
  }

  openTemplateSchema() {
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if (user.id === project.created_by) {
        this.openTemplateSchemaAfterCheck();
      }
    });
  }

  openTemplateSchemaAfterCheck() {
    if (this.store.template.getValue()) {
      persistLastMenu('info');
      this.store.showTemplateInfo();
      this.store.formMode = SCHEMA_MODE;
    }
  }

  duplicateTemplate() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.testIfUpdatingForm(() => {
      this.duplicateTemplateAfterCheck();
    });
  }

  duplicateTemplateAfterCheck() {
    const selectedTemplate = this.store.template.getValue();
    if (selectedTemplate === undefined || selectedTemplate === null) {
      this.showMessageDialog('No template is selected, please select the template to be duplicated');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: `Do you really want to duplicate the form "${selectedTemplate.name}" ?` +
        `\nIf yes, a new form will be made containing the same structure as the original form. \n` +
        `The records in the form will not be copied!`,
        title: 'Duplicate Form?',
        formDuplication: true
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        this.sendDuplicateTemplateRequest();
      }
    });
  }

  sendDuplicateTemplateRequest() {
    this.testIfUpdatingForm(() => {
      this.sendDuplicateTemplateRequestAfterCheck();
    });
  }

  /**
   *
   */
   sendDuplicateTemplateRequestAfterCheck() {
    const selectedTemplate = this.store.template.getValue();
    const template = {
      name: selectedTemplate.name + ' Copy',
      description: selectedTemplate.description,
      project_id: selectedTemplate.project_id,
      template_id: selectedTemplate.id
    };
    let token = getToken();
    let isMyCumulusAdministrator = false;
    if(token === null || token === undefined){
      token = getAdminToken();
      if(token) {
        isMyCumulusAdministrator = true;
      }
    }
    const tempToken = {
      key: "key",
      value: token.key
    }
    const project = getProject();
    this.store.showLoading();
    this.templateService.duplicateTemplate(template, tempToken, isMyCumulusAdministrator).subscribe(
      res => {
        this.store.createTemplate(res);
        this.store.hideLoading();
      },
      err => {
        this.showMessageDialog(err);
        this.store.hideLoading();
      });
  }

  deleteTemplate() {
    if(this.store.features && this.store.features.length > 0) {
      this.showMessageDialog(`Deleting the project or form is not possible if any records are selected. Please deselect them`);
      return;
    }
    this.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.store.project;
      if(user.id === project.created_by){
        this.deleteTemplateAfterCheck();
      }
    });
  }

  deleteTemplateAfterCheck() {
    const selectedTemplate = this.store.template.getValue();
    if (selectedTemplate === undefined || selectedTemplate === null) {
      this.showMessageDialog('No template is selected, please select the template to be deleted');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        title: `Remove Form "${selectedTemplate.name}" ?`,
        message: `When you remove the form, all data related to it are lost.`
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '600px',
          data: {
            title: `Remove Form "${selectedTemplate.name}" ?`,
            message:`Please think twice, this action cannot be undone!\n Are you really sure you want to delete the form and the data?`
          }
        });

        confirmDialogRef.afterClosed().subscribe(res  => {
          if (res) {
            this.deleteTemplateOnServer();
          }
        });
      }
    });
  }

  deleteTemplateOnServer() {
    const selectedTemplate = this.store.template.getValue();
    let token = getToken();
    let isMyCumulusAdministrator =  false;

    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator =  true;
      }
    }
    const project = getProject();

    this.store.showLoading();
    this.templateService.deleteTemplate(selectedTemplate, project.id, token, isMyCumulusAdministrator).subscribe(
      res => {
        this.store.deleteTemplate(res);
        this.store.hideLoading();
        // this.toastr.success('Template deleted with success', 'MyCumulus');
      },
      errmes => {
        this.store.deleteTemplate(selectedTemplate);
        this.store.hideLoading();
        this.showMessageDialog(errmes);
      }
    );
  }
}

export enum ExportTypes {
  Enexis,
  Stedin
}
