import { Component, OnInit, Input } from '@angular/core';
import { Template } from '../../share/template';
import { TemplateService, TEMPLATES} from '../../services/template.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CreateTemplateComponent } from '../dialogs/create-template/create-template.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { getToken, getProject, persistTemplate, persistLastMenu, getlastMenu, getFormUpdating, getTemplate, setFormUpdating, getFieldToShowOnMap, persistFieldToShowOnMap, removeSearchObject, removeSearchValue, removeCurrentFeaturesUri, removeGeomFormToShow, persistGeomFormToShow } from 'src/app/share/utils';
import { StoreService, SCHEMA_MODE, MAP_MODE, DATA_MODE } from 'src/app/services/store.service';
import { AttributeSet } from 'src/app/share/feature/attributes';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  projectId: string;
  selectedTemplate: Template;
  fields: any[];

  constructor(
    public store: StoreService,
    private templateService: TemplateService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const currentTemplate = getTemplate();
    if (currentTemplate) {
      this.onSelectedTemplateAfterCheck(currentTemplate);
    }
  }

  getTemplates() {}

  onSelectedTemplate(template, event) {
    event.preventDefault();
    // this.store.template.next(null);
    this.testIfUpdatingForm(() => {
      this.onSelectedTemplateAfterCheck(template);
    });
    event.stopPropagation();
  }

  onSelectedTemplateAfterCheck(template) {
    this.store.features = [];
    this.selectedTemplate = template;
    persistTemplate(this.selectedTemplate);

    this.store.readTemplate(template);
    const obj = {};
    const templId = template['id'];
    obj[templId] = true;

    if(obj) {
      removeGeomFormToShow();
      persistGeomFormToShow(obj);
    }
    this.store.features = null;
    // this.openFeatureData();
    let menu = this.store.formMode;
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      menu = SCHEMA_MODE;
    } else if(template.feature.geometry_type === null || template.feature.geometry_type === undefined) {
      menu = DATA_MODE;
    }
    removeSearchObject();
    removeSearchValue();
    removeCurrentFeaturesUri();

    if (menu === SCHEMA_MODE) {
      this.openTemplateSchema();
    } else if (menu === MAP_MODE) {
      this.store.hideTemplate();
      this.openMapData();
    } else {
      this.openFeatureData();
      if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
        this.store.destinationTemplate = template;
      }
    }
  }

  openAddTemplateDialog() {
    this.testIfUpdatingForm(() => {
      this.openAddTemplateDialogAfterCheck();
    });
  }

  openAddTemplateDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Create'
    };
    this.dialog.open(CreateTemplateComponent, dialogConfig);
  }

  openMapData() {
    // this.testIfUpdatingForm(() => {
      this.openMapDataAfterCheck();
    // });
  }

  openMapDataAfterCheck() {
    if (this.selectedTemplate.feature.geometry_type === null) {
      // this.toastr.warning('Open map is reseved for Geometry Forms', 'MyCumulus');
      return;
    }
    const fields = getFieldToShowOnMap();
    if (fields !== null && fields !== undefined &&
      fields[0].template_to_show === this.store.template.getValue().id) {
      this.store.showTemplateFeatureDataMap();
      return;
    } else {
      this.saveFieldsAndLabels(this.store.template.getValue().feature.attributes);
      const labels = [];
      //const keys = Object.keys(this.fields);
      for (const value of this.fields) {
        //const value = this.fields[key];
        if (value['template_to_show'] !== null) {
          const fieldname = value['label'];
          labels.push(fieldname);
        }
      }
      this.store.setFieldToShowOnMap(labels);
      persistFieldToShowOnMap(this.fields);
    }
    this.store.showTemplateFeatureDataMap();
    persistLastMenu('map');
    this.store.formMode = MAP_MODE;
  }

  saveFieldsAndLabels(attributes: any[]) {
    this.fields = [];
    this.fields.push({
      template_to_show: this.store.template.getValue().id
    });
    this.saveFields(attributes);
  }

  saveFields(attributes: any, parent= '') {
    const name = 'name';
    const _class = '_class';
    for (const attribute of attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const value = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        const label = {
          label: value
        };
        this.fields.push(label);
      } else if (typeAttr === 'attributeset') {
        const nested_parent = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        this.saveFields((attribute as AttributeSet).attributes, nested_parent);
      }
    }
  }

  openEditTemplateDialog() {
    this.testIfUpdatingForm(() => {
      this.openEditTemplateDialogAfterCheck();
    });
  }

  openEditTemplateDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project: this.store.project,
      template: this.selectedTemplate,
      mode: 'Update'
    };
    this.dialog.open(CreateTemplateComponent, dialogConfig);
  }

  testIfUpdatingForm(action) {
    const templ = this.store.template.getValue();
    const updatingForm = this.store.updatingForm;
    if ((templ !== null && templ !== undefined) && (updatingForm === true)) {
      let message = `The form "${templ.name}" should contain at least one field. \nContinue?`;
      if(templ.form === null || templ.feature === null) {
        message = `The form "${templ.name}" should contain at least one field. \nContinue?`;
      } else {
        message = `Some changes to the form ${templ.name} have been made. \nDo you want to continue without saving the changes?`;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: 'MyCumulus',
          message
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          setFormUpdating(false);
          this.store.updatingForm = false;
          this.store.changeFormUpdatingState(false);
          action();
        } else {
          this.store.selectedTemplate.next(templ.name);
        }
      });
    } else {
      if (updatingForm) {
          if (templ === null || templ === undefined) {

            const mes = ``;
            const dlgRef = this.dialog.open(ConfirmationDialogComponent, {
              width: '400px',
              data: {
                title: 'Cancel Import?',
                message: mes
              }
            });
            dlgRef.afterClosed().subscribe(result  => {
              if (result) {
                if (updatingForm) {
                  setFormUpdating(false);
                  this.store.updatingForm = false;
                  this.store.changeFormUpdatingState(false);
                  action();
                }
              } else {
                return;
              }
            });
            return;
          } else {
            const message = `Some changes to the form ${templ.name} have been made. \nDo you want to continue without saving the changes?`;
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              width: '500px',
              data: {
                title: 'MyCumulus',
                message
              }
            });
            dialogRef.afterClosed().subscribe(result  => {
              if (result) {
                if (updatingForm) {
                  setFormUpdating(false);
                  this.store.updatingForm = false;
                  this.store.changeFormUpdatingState(false);
                  action();
                } else {
                  this.store.selectedTemplate.next(templ.name);
                }
              } else {
                this.store.selectedTemplate.next(templ.name);
              }
            });
          }
      } else {
        action();
      }
    }
  }

  openFeatureData() {
    // this.testIfUpdatingForm(() => {
      this.openFeatureDataAfterCheck();
    // });
  }

  openFeatureDataAfterCheck() {
    // TODO show template feature data
    this.store.showTemplateFeatureData();
    persistLastMenu('data');
  }

  openTemplateSchema() {
   // this.testIfUpdatingForm(() => {
      this.openTemplateSchemaAfterCheck();
   // });
  }

  openTemplateSchemaAfterCheck() {
    this.store.showTemplateInfo();
    persistLastMenu('info');
  }

  duplicateTemplate() {
    this.testIfUpdatingForm(() => {
      //this.duplicateTemplateAfterCheck();
    });
  }

  duplicateTemplateAfterCheck() {
    if (this.selectedTemplate === undefined || this.selectedTemplate === null) {
      // this.toastr.warning('No template is selected, please select the template to be duplicated', 'MyCumulus');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: `Do you really want to duplicate the form " ${this.selectedTemplate.name}` +
        `" ? If yes, a new form will be made containing the same structure as the original form. ` +
        `The records in the form will not be copied!`,
        title: 'Duplicate Form?'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        this.createProjectAndTemplates();
      }
    });
  }

  createProjectAndTemplates() {
    this.testIfUpdatingForm(() => {
      this.createProjectAndTemplatesAfterCheck();
    });
  }

  createProjectAndTemplatesAfterCheck() {
    const template = {
      name: this.selectedTemplate.name + ' Copy',
      description: this.selectedTemplate.description,
      image_file_url: this.selectedTemplate.image_file_url,
      form: this.selectedTemplate.form,
      feature: this.selectedTemplate.feature,
      project_id: this.selectedTemplate.project_id,
      map_style: this.selectedTemplate.map_style
    };
    const token = getToken();
    const project = getProject();
    this.store.showLoading();
    this.templateService.createTemplate(template, project.id, token).subscribe(
      res => {
        const newtemplate = res;
        this.store.createTemplate(res);
        this.store.hideLoading();
      },
      err => {
        // this.toastr.error(`Error while creaing the new template, please retry later `, 'MyCumulus');
        this.store.hideLoading();
      });
  }

  deleteTemplate() {
    this.testIfUpdatingForm(() => {
      this.deleteTemplateAfterCheck();
    });
  }

  deleteTemplateAfterCheck() {
    if (this.selectedTemplate === undefined || this.selectedTemplate === null) {
      // this.toastr.warning('No template is selected, please select the template to be deleted', 'MyCumulus');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        message: `Do you confirm the deletion of the template "${this.selectedTemplate.name}" ?`,
        title: 'MyCumulus'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        const project = getProject();
        this.store.showLoading();
        this.templateService.deleteTemplate(this.selectedTemplate, project.id, token).subscribe(
          res => {
            this.store.deleteTemplate(res);
            this.store.hideLoading();
            // this.toastr.success('Template deleted with success', 'MyCumulus');
          },
          errmes => {
            this.store.hideLoading();
            // this.toastr.error('Error while deleting the template' + errmes, 'MyCumulus');
          }
        );
      }
    });
  }
}
