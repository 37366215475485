import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { getPrivateApiAURL } from '../share/utils';
import { Coordinates, CoordinatesConversionResult, InvertCoordinatesConversionResult } from '../share/result';

@Injectable({
  providedIn: 'root'
})
export class CrsService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService
  ) { }

  convertCRS(token, crs, coordinates): Observable<Coordinates[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const body = {
      crs,
      coordinates
    };

    return this.http.post<CoordinatesConversionResult>(getPrivateApiAURL() + 'crs/listconversion', body, httpOptions)
    .pipe(map(res => {
      return res.coordinates;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  convertCRSToGeodetic(token, crs, coordinates): Observable<Coordinates[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const body = {
      crs,
      coordinates
    };

    return this.http.post<InvertCoordinatesConversionResult>(getPrivateApiAURL() + 'crs/list_inverse_conversion', body, httpOptions)
    .pipe(map(res => {
      return res.locations;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
