import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateIntegerFieldComponent } from '../../dialogs/create-integer-field/create-integer-field.component';
import { CreateStringFieldComponent } from '../../dialogs/create-string-field/create-string-field.component';
import { CreateDecimalFieldComponent } from '../../dialogs/create-decimal-field/create-decimal-field.component';
import { CreateAutoincrementFieldComponent } from '../../dialogs/create-autoincrement-field/create-autoincrement-field.component';
import { CreateNoteFieldComponent } from '../../dialogs/create-note-field/create-note-field.component';
import { CreateBarcodeFieldComponent } from '../../dialogs/create-barcode-field/create-barcode-field.component';
import { CreateGpsCoordinatesFieldComponent } from '../../dialogs/create-gps-coordinates-field/create-gps-coordinates-field.component';
import { CreatePhotoFieldComponent } from '../../dialogs/create-photo-field/create-photo-field.component';
import { CreateVideoFieldComponent } from '../../dialogs/create-video-field/create-video-field.component';
import { CreateFieldsetComponent } from '../../dialogs/create-fieldset/create-fieldset.component';
import { CreateFieldsetArrayComponent } from '../../dialogs/create-fieldset-array/create-fieldset-array.component';
import { CreateAudioFieldComponent } from '../../dialogs/create-audio-field/create-audio-field.component';
import { CreateTimeFieldComponent } from '../../dialogs/create-time-field/create-time-field.component';
import { CreateBooleanFieldComponent } from '../../dialogs/create-boolean-field/create-boolean-field.component';
import { CreateDateFieldComponent } from '../../dialogs/create-date-field/create-date-field.component';
import { Template } from 'src/app/share/template';
import { getTemplate, getToken, getSelectedFieldIndex, persistCurrentFieldset,
  setFormUpdating, getActiveFieldset, getCurrentFieldset, persistActiveFieldset, getAdminToken } from 'src/app/share/utils';
import { TemplateService } from 'src/app/services/template.service';
import { Field } from 'src/app/share/form/field';
import { ConstraintListDialogComponent } from '../../dialogs/constraint-list-dialog/constraint-list-dialog.component';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';
import { FieldsetComponent } from '../fieldset/fieldset.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-template-schema',
  templateUrl: './template-schema.component.html',
  styleUrls: ['./template-schema.component.scss']
})
export class TemplateSchemaComponent implements OnInit, OnChanges {
  // @select() template: Observable<Template>;
  @Output() templateChanged = new EventEmitter<Template>();
  fields: Field[];
  // @select() currentFieldset: Observable<any>;
  rootFieldset: FieldSet;
  @Input() activeTemplate: Template;
  activeFields: Field[];
  activeFieldset: FieldSet;
  activeFieldIndex: number;
  activeFieldsetIndex: number;
  selectedParent: string;

  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    public store: StoreService,
    private titlecasePipe:TitleCasePipe,
    private templateService: TemplateService,
    private dialog: MatDialog
  ) {
    console.log('Enter Feature Schema: construction');
  }

  ngOnInit() {
    console.log('Enter Feature Schema: Init');
    this.activeTemplate = this.store.template.getValue();
    this.initializeTemplateAndField(this.activeTemplate);
    this.store.template.subscribe(templ => {
      // if (templ instanceof Template) {
        this.initializeTemplateAndField(templ);
      // }
    });
  }

  initializeTemplateAndField(templ) {
    this.activeTemplate = templ;
    this.rootFieldset = new FieldSet();
    this.selectedParent = 'rootFieldset';
    if (this.activeTemplate === null) {
      return;
    }
    if (this.activeTemplate.form !== undefined && this.activeTemplate.form !== null) {
      this.fields = this.activeTemplate.form.fields;
      this.activeFieldset = new FieldSet();
      this.activeFieldset.fields = this.activeTemplate.form.fields;
    } else {
      this.fields = [];
      this.activeFieldset = new FieldSet();
      this.activeFieldset.fields = this.fields;
    }
    this.rootFieldset.fields = this.fields;
    this.activeFieldset = this.rootFieldset;
    this.store.currentFieldSet = this.activeFieldset;
    persistCurrentFieldset(this.activeFieldset);
  }

  setActiveFieldSet(f) {
    // this.removeSelectedFieldset();
    // this.selectedParent = f.name;
    this.activeFieldset = f;
    this.store.currentFieldSet = f;
  }

  updateTemplate() {
    debugger;
    this.buildFeatureSchema();
  }

  buildFeatureSchema() {
    debugger;
    if (this.activeTemplate.form !== undefined && this.activeTemplate.form !== null) {
      this.activeTemplate.form.fields = this.rootFieldset.fields;
    } else {
      const form = {
        fields: this.rootFieldset.fields
      };
      this.activeTemplate.form = form;
      /*
      this.activeTemplate = Object.assign(
        {}, this.activeTemplate, {
          ...this.activeTemplate,
          form: {
            fields: this.rootFieldset.fields
          }
        }
      );
      */
    }
    const set = this.buildAttributeSet(this.activeTemplate.form.fields);

    this.activeTemplate.feature.attributes = set.attributes;
    this.store.showLoading();
    let token = getToken();
    let tempToken = {
      key: 'X-Auth-Token',
      value: ''
    };
    let isMyCumulusAdministrator = false;
    if (token !== null) {
      tempToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    } else {
      token = getAdminToken();
      if(token !== null) {
        tempToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
        isMyCumulusAdministrator = true;
      }
    }
    
    this.templateService.updateTemplate(this.activeTemplate, this.activeTemplate.project_id, tempToken, isMyCumulusAdministrator).subscribe(
      res => {
        setFormUpdating(false);
        this.store.updateTemplate(res);
        this.initializeTemplateAndField(res);
        this.store.changeFormUpdatingState(false);
        this.store.hideLoading();
      },
      err => {
        if (err === 'feature structure are empty.') {
          this.showErroMessage('The form must contain at least one field.');
        } else {
          this.showErroMessage(err);
        }
        this.store.hideLoading();
      });
  }

  buildAttributeSet(fields, attributesettype = '') {
    const set_type = attributesettype !== '' ? attributesettype : 'attributeset';
    const set = {
      _class: set_type,
      attributes: []
    };

    fields.forEach((field) => {
      if (field._class === 'fieldset') {
        let attributeset = this.buildAttributeSet(field.fields);
        attributeset = Object.assign({}, attributeset, {
          ...attributeset,
          name: field.name
        });

        set.attributes.push(attributeset);

      } else if (field._class === 'arrayfieldset') {
        let arrayattributeset = this.buildAttributeSet(field.fields, 'arrayattributeset');
        arrayattributeset = Object.assign({}, arrayattributeset, {
          ...arrayattributeset,
          name: field.name
        });

        set.attributes.push(arrayattributeset);
      } else {
        let attribute = {
          _class: 'attribute'
        };

        attribute = Object.assign({}, attribute, {
          ...attribute,
          name: field.name
        });
        // attribute.name = field.name;

        switch (field._class) {
          case 'autoincrementintegerfield':
          case 'integerfield':
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'integer'
            });
            break;

          case 'decimalfield':
          // attribute.type = 'double';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'double'
            });
            break;

          case 'booleanfield':
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'boolean'
            });
            // attribute.type = 'boolean';
            break;

          case 'photofield':
          case 'videofield':
          case 'audiofield':
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'file'
            });
            // attribute.type = 'file';
            break;

          case 'datefield':
          // attribute.type = 'date';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'date'
            });
            break;

          case 'timefield':
          // attribute.type = 'time';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'time'
            });
            break;

          case 'gpscoordinatesfield':
            // attribute.type = 'coordinates';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'coordinates'
            });
            break;

          case 'arrayfield':
            // attribute.type = 'array';
            // attribute._class = 'arrayattribute';
            // attribute.element_type = field.element_type;
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'array',
              _class: 'arrayattribute',
              element_type: field.element_type
            });
            break;

          default:
            // attribute.type = 'string';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'string'
            });
            break;
        }

        attribute['restrictions'] = [];
        /*
        if (field.constraints !== null && field.constraints !== undefined) {
          field.constraints.forEach((constraint) => {
            const restriction = {};
            restriction['_class'] = constraint._class;
            if (constraint.value) {
              restriction['value'] = constraint.value;
            }
            if (constraint.values) {
              restriction['values'] = constraint.values;
            }
            attribute['restrictions'].push(restriction);
          });
        }
        */
        set.attributes.push(attribute);
      }
    });

    return set;
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  isEltField(f) {
    if (f._class !== 'fieldset' && f._class !== 'fieldsetarray' && f._class !== 'arrayfieldset') {
      return true;
    }
    return false;
  }

  isEltFieldset(f) {
    if (f._class === 'fieldset' || f._class === 'fieldsetarray' || f._class === 'arrayfieldset') {
      return true;
    }
    return false;
  }

  public handleFieldsetClicked(f: FieldSet): void {
    this.removeSelectedFieldset();
    this.selectedParent = f.name;
    this.activeFieldset = f;
    persistCurrentFieldset(f);
    persistActiveFieldset(f);
  }

  showIndex(i) {
    // alert('The value of index is ' + i);
  }

  handleRootFieldset(event) {
    event.preventDefault();
    this.removeSelectedFieldset();
    // this.handleClick(event);
    this.selectedParent = 'rootFieldset';
    this.activeFieldset = this.rootFieldset;
    this.store.selectedFieldSet.next('rootFieldset');
    this.store.currentFieldSet = this.activeFieldset;
    event.stopPropagation();
  }

  handleClick(event) {
    event.preventDefault();
    let $items = $('.fs-item');
    const classHighlight = 'selected-fs';
    $items.removeClass(classHighlight);
    this.selectedParent = 'rootFieldset';
    event.stopPropagation();
  }

  removeSelectedFieldset() {
    const items = $('highlightParent');
    const classHighlight = 'highlightParent';
    items.removeClass(classHighlight);
  }

  changedTemplate(template) {
    this.templateChanged.emit(template);
    this.fields = null;
    this.store.template.subscribe(temp => {
      this.fields = temp.form.fields;
    });
  }

  setCreateDialogConfig(): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let parent = this.store.currentFieldSet;

    console.log(this.store.currentFieldSet);
    /*
    if (this.activeFieldset !== null || this.activeFieldset !== undefined) {
      parent = this.activeFieldset;
    } else {
      parent = getCurrentFieldset();
    }
    */

    dialogConfig.data = {
      mode: 'Create',
      parent
    };
    return dialogConfig;
  }

  addResultOnDialogClose(dialogRef) {
    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.store.currentFieldSet.fields.push(result);
        // this.activeFieldset.fields.push(result);
        // this.fields.push(result);
        // console.log(result);
      }
    });
  }

  openIntegerDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateIntegerFieldComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( result => {
      if (result !== null) {
        this.addOrUpdateField(result);
      }
    });
    // this.addResultOnDialogClose(dialogRef);
  }

  openStringDialog() {
    // this.formFieldset.openStringDialog();
    // Get the actual fieldset
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateStringFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  addOrUpdateField(result, isFieldSet = false) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    if (result.action === 'Create') {
      // this.activeFieldset.fields.push(result.field);
      this.store.currentFieldSet.fields.push(result.field);
      if (isFieldSet) {
        this.activeFieldset = result.field;
        this.store.currentFieldSet = this.activeFieldset;
      }
    } else  if (result.action === 'Update') {
      // this.activeFieldset.fields.splice(this.activeFieldIndex, 1, result.field);
      this.store.currentFieldSet.fields.splice(this.activeFieldIndex, 1, result.field);
    }
    setFormUpdating(true);
    this.store.changeFormUpdatingState(true);
  }

  openDecimalDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateDecimalFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  fieldUpdated(f) {
    const fieldIndex = getSelectedFieldIndex();
    const field = this.activeFieldset.fields[fieldIndex];
    this.activeFieldset.fields.splice(fieldIndex, 1, f);
    this.store.changeFormUpdatingState(true);
    // console.log(`Selected field  ${field}`);
    // console.log(`Selected field index ${fieldIndex}`);
    // console.log(`Selected field updated  ${f}`);
    /*
    const fields = [
      this.activeFieldset.fields.slice(0, fieldIndex),
      Object.assign({}, field),
      this.activeFieldset.fields.slice(fieldIndex + 1)
    ];
    */
  }

  openAutoIntegerDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    if (this.store.currentFieldSet['name'] !== null &&
        this.store.currentFieldSet['name'] !== undefined) {
      this.showErroMessage('An auto increment field is not allowed in a fieldset or fieldset array.');
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateAutoincrementFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      // console.log(result);
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openNoteDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateNoteFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openBarCodeDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateBarcodeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openGPSCoordinatesDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateGpsCoordinatesFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openPhotoDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreatePhotoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openVideoDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateVideoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openFieldsetDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateFieldsetComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result, true);
      }
    });
  }

  openFieldSetArrayDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateFieldsetArrayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.addOrUpdateField(result, true);
      }
    });
  }

  openAudioFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateAudioFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openTimeFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateTimeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openDateFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateDateFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openBooleanFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showErroMessage(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateBooleanFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  fieldDeleted(f) {
  }

  fieldMovedUp(f) {

  }

  fieldMovedDown(f) {

  }

  fieldMovedToBottom(f) {

  }

  fieldMovedToTop(f) {

  }

  // Implement the ngOnChanges for handling the template changes
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    const mat = {};
    for (const prop in changes) {
      const t = changes[prop];
      if (!t.isFirstChange()) {
        this.initializeTemplateAndField(t);
      }
    }
  }

}
