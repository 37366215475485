import { Field } from './field';

export class FieldSet extends Field {
  on_same_screen_as_parent: boolean;

  fields: Field[];

  constructor() {
    super();
    this._class = 'fieldset';
    this.fields = [];
  }
}

export class FieldsetData {
  fieldset: FieldSet;
  values: any[];
}

export class FieldData {
  field: Field;
  value: any;
}
