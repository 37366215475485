import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { FieldSet } from 'src/app/share/form/fieldset';

@Component({
  selector: 'app-form-fieldset',
  templateUrl: './form-fieldset.component.html',
  styleUrls: ['./form-fieldset.component.scss']
})
export class FormFieldsetComponent implements OnInit {

  @Input() public rootFieldSet: FieldSet | null;
  @Input() public selectedFieldSet: FieldSet | null;
  @Output() public selectEvents: EventEmitter<FieldSet>;

  constructor() { }

  ngOnInit() {
    // this.rootFieldSet = null;
    // this.selectedFieldSet = null;
    this.selectEvents = new EventEmitter();
  }

}
