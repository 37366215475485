import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MarxactService } from 'src/app/services/marxact.service';
import { getToken, persistMarxactAuthToken,
  persistMarxactUsername, persistMarxactPassword, persistMarxactUserConnected } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-marxact-login-page',
  templateUrl: './marxact-login-page.component.html',
  styleUrls: ['./marxact-login-page.component.scss']
})
export class MarxactLoginPageComponent implements OnInit {

  marxactLoginForm: FormGroup;
  message = '';
  showMessage: boolean;

  @ViewChild('fform', { static: false}) marxactLoginFormDirective;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private marxactService: MarxactService,
    private dialogRef: MatDialogRef<MarxactLoginPageComponent>
  ) {
    this.createForm();
   }

  ngOnInit() {
  }

  createForm() {
    this.marxactLoginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  closeDialog(event) {
    event.preventDefault();
    const result = null;
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  onSubmit() {
    const token = getToken();
    const data = this.marxactLoginForm.value;
    const marxactusername = data['username'];
    const marxactpassword = data['password'];
    this.store.showLoading();
    this.marxactService.loginToMarxactBackend(token, data.username, data.password).subscribe(
      res => {
        const marxactToken = res.token;
        persistMarxactUsername(marxactusername);
        persistMarxactPassword(marxactpassword);
        persistMarxactUserConnected();
        persistMarxactAuthToken(marxactToken);
        this.store.hideLoading();
        const result = {
          marxact_connected: true
        };
        this.dialogRef.close(result);
      },
      err => {
        this.showErroMessage(err);
        this.store.hideLoading();
      }
    );
  }
}
