import { Component, OnInit, Inject, ViewChild, Input, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-edit-feature-value',
  templateUrl: './edit-feature-value.component.html',
  styleUrls: ['./edit-feature-value.component.scss']
})
export class EditFeatureValueComponent implements OnInit, AfterViewInit {
  editDataForm: FormGroup;
  column: any;
  feature: any;
  element: any;
  value: any;
  valueUpdated: boolean;
  type: string;
  errMsg: string;
  badFormat = false;

  @ViewChild('fform', { static: false}) editDataFormDirective;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<EditFeatureValueComponent>) {
    if (data !== undefined && data !== null) {
      this.column = data.column;
      this.feature  = data.feature;
      this.element = data.element;
      this.value = data.value;
      this.type = data.type;
    }
    this.valueUpdated = false;
  }

  ngOnInit() {
    this.createForm();
    if(this.type === 'date'){
      this.errMsg = 'format is invalid. Must be a valid "YYYY-mm-dd"';
    } else if(this.type === 'time'){
      this.errMsg = 'format is invalid. Must be a valid "HH:mm:ss"';
    } else {
      this.errMsg = 'Value is requied.';
    }
  }

  ngAfterViewInit() {

  }

  private myValidator = [Validators.required];

  createForm() {
    const dateRegEx = /^\d{4}-\d{2}-\d{2}$/;
    const timeRegEx = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])?$/;

    if(this.type === 'boolean'){
      if(this.value === true){
        this.value = '1';
      } else if(this.value === false){
        this.value = '2';
      }
    } else if(this.type === 'date'){
      this.value = this.value.split('T')[0];
    }
    this.editDataForm = this.fb.group({
        name: [this.value]
    });

    this.editDataForm.get('name').valueChanges.subscribe(
      value => {
        if (value && this.type === 'date') {
          this.errMsg = 'format is invalid. Must be a valid "YYYY-mm-dd"';
          this.editDataForm.get('name').setValidators(this.myValidator.concat(Validators.pattern(dateRegEx)));
        } else if (value && this.type === 'time') {
          this.errMsg = 'format is invalid. Must be a valid "HH:mm:ss"';
          this.editDataForm.get('name').setValidators(this.myValidator.concat(Validators.pattern(timeRegEx)));
        }
      }
    );
  }

  onmychange(e) {
    //if (e.changeType !== 'EDIT') {
      this.valueUpdated = true;
    //}
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.valueUpdated) {

      const formValue = this.editDataForm.value;
      let value = formValue['name'];

      if(this.type === 'boolean') {
        if(value === '1'){
          value = true;
        } else if(value === '2'){
          value = false;
        }
      }

      this.updateObjectValue(this.feature.attributes, value , this.column);
      // console.log('feature', this.feature);
      const result = {
        event: 'updated',
        feature: this.feature
      };
      this.dialogRef.close(result);
    } else {
      this.dialogRef.close();
    }
  }

  onKeySubmit(event) {
    if(event.keyCode === 13){
      this.onmychange(event);
      this.onSubmit();
    }
  }

  /*isValidDate(dateString): boolean{
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) return false;
    var date = new Date(dateString);
    if(Number.isNaN(date.getTime())) return false;
    return date.toISOString().slice(0,10) === dateString
  }

  isValidTime(timeString): boolean{
    var isValid = false;
    var regEx = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])?$/;
    if(timeString.match(regEx)) isValid = true;
    return isValid;
  }
*/
  updateObjectValue(attributes, value, path: string) {
    switch (this.type) {
      case 'integer' :
        value = parseInt(value);
        break;

      case 'double' :
        value = parseFloat(value);
        break;

      case 'decimal' :
        value = parseFloat(value);
        break;

      case 'date' :

        break;
      default:
    }
    if (path.indexOf('.') < 0) {
      attributes[path] = value;
    } else {
      const columns = path.split('.');
      let index = 0;
      let absAtt = null;
      while (index < columns.length) {
        if (index === columns.length - 1) {
          absAtt[columns[index]] = value;
        }
        if(index == 0){
          absAtt = attributes[columns[index]];
        } else {
          absAtt = absAtt[columns[index]];
        }
        index++;
      }
    }
  }

}
