import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  templatesOpen: boolean;
  projectsOpen: boolean;

  constructor(
    public store: StoreService
  ) { }

  ngOnInit() {
    this.templatesOpen = false;
    this.projectsOpen = false;
    // console.log(this.projectsOpen);
  }

  openProjectsPageContainer() {
    this.projectsOpen = false;
    this.store.hideProjectsContainer = this.projectsOpen;
    // console.log(this.projectsOpen);
  }

  closeProjectsPageContainer() {
    this.projectsOpen = true;
    this.store.hideProjectsContainer = this.projectsOpen;
    // console.log(this.projectsOpen);
  }

  closeTemplatesPageContainer() {
    this.templatesOpen = true;
    this.store.hideTemplatesContainer = this.templatesOpen;
  }

  openTemplatesPageContainer() {
    this.templatesOpen = false;
    this.store.hideTemplatesContainer = this.templatesOpen;
  }
}
