import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { AutoIncrementField } from 'src/app/share/form/auto-increment-field';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-create-autoincrement-field',
  templateUrl: './create-autoincrement-field.component.html',
  styleUrls: ['./create-autoincrement-field.component.scss']
})
export class CreateAutoincrementFieldComponent implements OnInit {
  autoIncrementField: AutoIncrementField;
  autoIncrementFieldForm: FormGroup;
  @Input() parent: FieldSet;
  @Input() mode = 'Create';
  @ViewChild('fform', {static: false}) autoIncrementFieldFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateAutoincrementFieldComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.autoIncrementField = data.field;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    if (this.mode === 'Create') {
      this.autoIncrementFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: [''],
        initial_value: [1, Validators.required],
        step: [1, Validators.required],
        enabled: true
      });
    } else {
      this.autoIncrementFieldForm = this.fb.group({
        name: [this.autoIncrementField.name, Validators.required],
        label: [this.autoIncrementField.label, Validators.required],
        description: [this.autoIncrementField.description],
        initial_value: [this.autoIncrementField.initial_value, Validators.required],
        step: [this.autoIncrementField.step, Validators.required],
        enabled: this.autoIncrementField.enabled
      });
    }

  }

  onNameChange() {
    if (this.autoIncrementFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.autoIncrementFieldForm.controls.name.setValue(removeSpecialCharacters(this.autoIncrementFieldForm.value.label));
    if (this.autoIncrementFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  closeDialog(event) {
    event.preventDefault();
    const result = {
      action: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  onSubmit() {
    if (this.autoIncrementField) {
      this.autoIncrementField = {
        ...this.autoIncrementField,
        ...this.autoIncrementFieldForm.value
      };
    } else {
      this.autoIncrementField = this.autoIncrementFieldForm.value;
    }
    // this.autoIncrementField = this.autoIncrementFieldForm.value;
    this.autoIncrementField._class = 'autoincrementintegerfield';

    if (this.autoIncrementField.name === this.parent.name) {
      this.showErrorMessage('Field cannot take the same name as the active fieldset');
      return;
    }
    const result = {
      action: 'Create',
      field: this.autoIncrementField
    };
    if (this.mode === 'Create') {
      if (isFieldAlreadyExist(this.autoIncrementField, this.parent)) {
        this.showErrorMessage('Another field with the same name/label already exists');
        return;
      }
      this.store.addField(this.autoIncrementField);
    } else if (this.mode === 'Update') {
      result.action = 'Update';
    }
    this.dialogRef.close(result);
  }
}
