import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/share/user';
import { MatTableDataSource, MatTable, MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { getUser, getToken, getMarxactAuthToken, isTokenValid, getAdminToken, getMarxactUsername, getMarxactPassword } from 'src/app/share/utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MarxactLoginPageComponent } from '../dialogs/marxact-login-page/marxact-login-page.component';
import { AssignmarxactdevicesComponent } from '../dialogs/assignmarxactdevices/assignmarxactdevices.component';
import { CollaboratorsDialogComponent } from '../dialogs/collaborators-dialog/collaborators-dialog.component';
import { SysAdminUserServiceService, SIGNUP_ADMIN_LOGIN, SIGNUP_ADMIN_PASS } from 'src/app/services/sys-admin-user-service.service';
import { CollaboratorListDialogComponent } from '../dialogs/collaborator-list-dialog/collaborator-list-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from 'src/app/services/store.service';
import { from } from 'rxjs';

export interface AdminUser {
  id: string;
  name: string;
  email: string;
  created_date: string;
  managed_users: string;
  gnss_source: string;
  not_collaborator: boolean;
  administrator: string;
}

const ELEMENT_DATA: AdminUser[] = [];

@Component({
  selector: 'app-mc-users-page',
  templateUrl: './mc-users-page.component.html',
  styleUrls: ['./mc-users-page.component.scss']
})

export class McUsersPageComponent implements OnInit {
  user: any;
  collaborator: AdminUser;
  displayedColumns: string[] = ['select', 'name', 'email', 'created_date', 'administrator', 'managed_users', 'gnss_source'];

  dataSource = new MatTableDataSource<AdminUser>(ELEMENT_DATA);
  selection = new SelectionModel<AdminUser>(true, []);
  searchValue: string;
  message = '';
  showMessage: boolean;
  metadata: any;
  moreUsers: boolean;
  onlyAdmins: boolean;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private mcUserService: SysAdminUserServiceService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.dataSource.data = [];
    this.moreUsers = true;
    this.onlyAdmins = true;
    this.loadUsers('users', []);
  }

  displayUsers(res, firstPage){
    let data = this.dataSource.data;
    data = [];
    if(firstPage.length > 0)
      data = firstPage;

    this.metadata = res.metadata;

    if(this.metadata.uris.next !== null && this.metadata.uris.next !== undefined){
      this.moreUsers = false;
    } else {
      this.moreUsers = true;
    }
    for (const collaborator of  res.instances) {
      let gnssSource = '';
      if (collaborator.mc_marxact !== null && collaborator.mc_marxact !== undefined) {
        const devices = collaborator.mc_marxact.devices;
        if (devices !== null) {
          if (devices.length > 0) {
              for (const device of devices) {
                gnssSource = (gnssSource === null || gnssSource === '') ? device.device_name : gnssSource + ',' + device.device_name;
              }
          }
        }
      }

      let el;
      let managedUserCnt = 0;
      if (collaborator.managed_users_ids !== null && collaborator.managed_users_ids !== undefined) {
        managedUserCnt = collaborator.managed_users_ids.length;
      }
      if (managedUserCnt > 0) {
        // console.log(`Managed users of ${collaborator.name} is ${managedUserCnt}`);
      }
      el = {
        id: collaborator.id,
        name: collaborator.name,
        email: collaborator.email,
        created_date: new Date(collaborator.created_at),
        administrator: this.getUser(collaborator, res.instances),
        managed_users: managedUserCnt,
        users: collaborator.managed_users_ids,
        gnss_source: gnssSource,
        not_collaborator: this.getUsertype(collaborator)
      };

      if(this.onlyAdmins){
        if(this.getUsertype(collaborator) !== 'collaborator')
          data.push(el);
      } else {
        data.push(el);
      }
    }
    this.dataSource.data = data;
    this.table.renderRows();
    this.store.hideLoading();
  }

  showOnlyAdmins(event){
    this.onlyAdmins = event.checked;
    this.loadUsers('users', []);
  }

  openCollaboratorList(collaborator){
    if(collaborator.managed_users > 0){
      const dialogRef = this.dialog.open(CollaboratorListDialogComponent, {
        width :'400px',
        height : 'auto',
        data : {
          admin: collaborator
        }
      });
    }
  }

  getUsertype(collaborator): string {
    let userType = collaborator['user_type'];
    if(userType === null || userType === 'collaborator'){
      const manager = collaborator['manager_id'];
      if(manager !== undefined && manager !== null && manager !== '')
        userType = 'collaborator';
    }

    return userType;
  }

  getUser(collaborator, users): string {
    let id = collaborator.id;
    let managerName = "";
    let userType = collaborator['user_type'];
    if(userType === null || userType === 'collaborator'){
      const managerId = collaborator['manager_id'];
      if(managerId !== undefined && managerId !== null && managerId !== '') {
        const manager = users.find(user => {
          return user.id === managerId;
        } );
        if(manager && manager.id === managerId) {
          managerName = manager['name'];
        }
        id = managerId;
      }
    } else if (userType === 'admin_user') {
      managerName = collaborator.name;
    }
    return managerName;
  }

  loadUsers(uri, firstPage){
    this.user = getUser();
    const token = getAdminToken();
    this.store.showLoading();
    this.mcUserService.getAdminUsers(token, uri).subscribe(
      res => {
        this.displayUsers(res, firstPage);

        if(this.moreUsers === false)
          this.loadMoreUsers();
      },
      err => {
        this.showErroMessage(err);
        this.store.hideLoading();
      });
  }

  loadMoreUsers(){
    if(this.metadata !== null && this.metadata !== undefined){
      const uris = this.metadata.uris;

      if(uris.next !== null && uris.next !== undefined){
        let linkValue = uris.next;
        for (let i = 0; i < 4; i++) {
          linkValue = linkValue.substring(1);
        }

        this.loadUsers(linkValue, this.dataSource.data);
      }
    }
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  isMarxactDevices(element): boolean {
    if (element.gnss_source !== null && element.gnss_source !== undefined && element.gnss_source !== '') {
        return true;
    }
    return false;
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    event.stopPropagation();
  }

  /**
   * This method allow to create new collaborator user
   */
  openAddUserDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CollaboratorsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        // User created add it to the table and refresh the table
        this.store.showLoading();
        let userData = result.data;
        userData.user_type = 'admin_user';
        delete userData.may_create_projects;
        const token = getAdminToken();

        this.mcUserService.login(SIGNUP_ADMIN_LOGIN, SIGNUP_ADMIN_PASS).subscribe(
          result => {
            this.mcUserService.signupUser(userData, result).subscribe(
              res => {
                this.userService.oldActivateAccount({activation_code: res.activation_code}).subscribe(
                  resu => {
                    this.loadUsers('users', []);
                    this.store.hideLoading();
                  },
                  er => {
                    this.store.hideLoading();
                    this.showErroMessage(er);
                  }
                );
              },
              err => {
                this.store.hideLoading();
                this.showErroMessage(err);
              }
            );
          },

          error => {
            this.store.hideLoading();
            this.showErroMessage(error);
          }
        );

      } else if (result.event === 'Cancel') {
        // console.log('Collaborator creation cancelled');
      }
    });
  }

  /*openGnssSourceLogin(element) {
    const marxactuserconnected = this.checkIfUserConnected();
    if (!marxactuserconnected) {
      const dialogRef = this.dialog.open(MarxactLoginPageComponent, {
        width: '400px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== null) {
          if (result.marxact_connected ) {
            // this.openMarxactDeviceSettingDialog(element);
          }
        }
      });
    } else {
      // this.openMarxactDeviceSettingDialog(element);
    }
  } */

  checkIfUserConnected() {
    const username = getMarxactUsername();
    const password = getMarxactPassword();
    if (username !== null && username !== undefined && username !== '') {
      return true;
    }
    return false;
  }

  createNewUser(name, email, password, invitationToken) {
    this.store.showLoading();
    /*
    this.mcUserService.createUser(name, email, password, 'collaborator', invitationToken).subscribe(
      res => {
        const gnsssource = res.mc_marxact !== null ? res.mc_marxact.join(',') : null;
        const managedUsers = res.managed_user_ids !== null ? res.managed_user_ids.length : 0;
        this.collaborator = {
            id: res.id,
            name: res.name,
            email: res.email,
            created_date: res.created_at,
            managed_users: managedUsers,
            gnss_source: gnsssource
        };
        const data = this.dataSource.data;
        data.push(this.collaborator);
        this.dataSource.data = data;
        this.table.renderRows();
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.showErroMessage(err);
      }
    );
    */
  }

  openConfirmDeleteUserDialog() {
    if (this.selection.selected.length <= 0) {
      this.showErroMessage('No user is selected');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: 'do you really want to delete the selected users?',
        title: 'Delete Users'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const user = this.selection.selected[0];
        const token = getAdminToken();
        while (this.selection.selected.length > 0) {
          const tempU = this.selection.selected.splice(0, 1)[0];
          this.deleteUser(tempU.id, token);
          this.selection.toggle(tempU);
        }
        /*
        for (const selectedUser of this.selection.selected) {
          this.deleteUser(selectedUser.id, token);
        }
        */
      }
    });
  }

  deleteUser(userId, token) {
    this.store.showLoading();
    this.mcUserService.deleteAdminUser(userId, token).subscribe(
      res => {
       // console.log(res);
        const data = this.dataSource.data;
        const newdata = data.filter(f => f.id !== userId);
        this.dataSource.data = newdata;
        this.table.renderRows();
        this.store.hideLoading();
      },
      errmes => {
        this.store.hideLoading();
        this.showErroMessage(errmes);
      }
    );
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: AdminUser): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }
}
