import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Collaborator } from 'src/app/share/user';
import { Project } from 'src/app/share/projects';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from 'src/app/services/user.service';
import { ProjectMemberService } from 'src/app/services/project-member.service';
import { getToken, getUser } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';

export interface PeriodicElement {
  project_member: string;

}

const ELEMENT_DATA: Collaborator[] = [];

@Component({
  selector: 'app-add-member-dialog',
  templateUrl: './add-member-dialog.component.html',
  styleUrls: ['./add-member-dialog.component.scss']
})
export class AddMemberDialogComponent implements OnInit {
  project: Project;
  actions = ['Permissions', 'Remove Members'];
  displayedColumns: string[] = ['select', 'name'];
  addedMembers: Collaborator[] = [];

  dataSource = new MatTableDataSource<Collaborator>(ELEMENT_DATA);
  selection = new SelectionModel<Collaborator>(true, []);
  searchValue: string;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private dialog: MatDialogRef<AddMemberDialogComponent>,
    public store: StoreService,
    private userService: UserService,
    private projectMemberService: ProjectMemberService,
    @Inject(MAT_DIALOG_DATA) data) {
      if (data !== undefined && data !== null) {
        this.project = data.project;
        this.addedMembers = data.addedMembers;
      }
      const token = getToken();
      const user = getUser();
      this.store.showLoading();
      this.userService.getCollaboratorUsers(user, token).subscribe(
        res => {
          res = this.removedAddedMembers(res);
          this.dataSource.data = res.filter(u => u.id !== this.project.created_by);
          this.store.hideLoading();
        },
        err => {
          this.showErroMessage(err);
          this.store.hideLoading();
        }
      );
  }

  removedAddedMembers(allUsers): Collaborator[] {
    const membersToShow = [];
    if (this.addedMembers.length > 0) {
      for (var i = 0; i < allUsers.length; i++) {
        const ind = this.addedMembers.indexOf(allUsers[i]);
        if (!this.contains(allUsers[i], this.addedMembers)) {
          membersToShow.push(allUsers[i]);
        }
      }
      return membersToShow;
    } else {
      return allUsers;
    }
  }

  contains(value, array): boolean {
    var size = array.length;
    for(var i = 0; i < size; i++){
      if(value.id === array[i].id){
        return true;
      }
    }
    return false;
  }

  ngOnInit() {
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  addMemberToProject() {
    if (this.selection.selected.length <= 0) {
      this.showErroMessage('No user is selected');
      return;
    }
    const token = getToken();
    for (const collab of this.selection.selected) {
      this.store.showLoading();
      this.projectMemberService.addUserToProject(token, this.project.id, collab.id).subscribe(
        res => {
          this.store.hideLoading();
          const result = {
            event: 'memberAdded'
          };
          this.dialog.close(result);
        },
        err => {
          this.showErroMessage(err);
          this.store.hideLoading();
          return;
        }
      );
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Collaborator): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }
}
