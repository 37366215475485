import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';
import { Project } from '../share/projects';
import { ProjectsResult } from '../share/result';
import { getPrivateApiAURL } from '../share/utils';
import { catchError } from 'rxjs/operators';
import { map  } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService
  ) { }

  getDomains(): Observable<Project[]> {
    return this.http.get<ProjectsResult>(getPrivateApiAURL() + 'project/domains')
    .pipe(map(result => {
      return result.projects.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
    }
}
