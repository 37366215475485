import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import { Template } from 'src/app/share/template';
import { getToken, getUser, persistSelectedFieldIndex, getSelectedFieldIndex, persistFieldToShowOnMap, getFieldToShowOnMap } from 'src/app/share/utils';
import { AttributeSet } from 'src/app/share/feature/attributes';

export class Field {
  label: string;
}

const ELEMENT_DATA = [];
@Component({
  selector: 'app-choose-label-dialog',
  templateUrl: './choose-label-dialog.component.html',
  styleUrls: ['./choose-label-dialog.component.scss']
})
export class ChooseLabelDialogComponent implements OnInit {
  template: Template;
  displayedColumns: string[] = ['select', 'label'];
  fields: Field[];
  dataSource = new MatTableDataSource<Field>(ELEMENT_DATA);
  selection = new SelectionModel<Field>(true, []);
  searchValue: string;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private userService: UserService,
    private dialogRef: MatDialogRef<ChooseLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      if (data !== undefined && data !== null) {
        this.template = this.store.template.getValue();
      }
  }

  ngOnInit() {
    const token = getToken();
    this.fields = [];
    this.store.showLoading();
    this.constructHeaderList();
    this.dataSource.data = this.fields;
    this.store.hideLoading();
  }

  constructHeaderList() {
    const templ = this.store.template.getValue();
    const savedfields = getFieldToShowOnMap();
    if (templ !== null && templ !== undefined) {
      const name = 'name';
      const _class = '_class';
      const schema = templ.feature;
      const attributes = schema.attributes;
      for (const attribute of attributes) {
        const typeAttr = attribute[_class];
        if (typeAttr === 'attribute') {
          const label = {
            label: attribute[name]
          };
          for (const field of savedfields) {
              const item = field['label'];
              if (item !== null && item !== undefined) {
                if (item === attribute[name]) {
                  this.selection.selected.push(label);
                  break;
                }
              }
          }
          this.fields.push(label);
        } else if (typeAttr === 'attributeset') {
          this.constructAttributesetHeaderList(attribute as AttributeSet, attribute[name]);
        }
      }
    } else {
      this.fields = [];
    }
  }

  constructAttributesetHeaderList(atributeset: AttributeSet, parent= '') {
    const name = 'name';
    const _class = '_class';
    const savedfields = getFieldToShowOnMap();
    for (const attribute of atributeset.attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const label = {
          label: `${parent}.${attribute[name]}`
        };
        for (const field of savedfields) {
          const item = field['label'];
          if (item !== null && item !== undefined) {
            if (item === `${parent}.${attribute[name]}`) {
              this.selection.selected.push(label);
              break;
            }
          }
        }
        this.fields.push(label);
      } else if (typeAttr === 'attributeset') {
        const nested_parent = `${parent}.${attribute[name]}`;
        this.constructAttributesetHeaderList(attribute as AttributeSet, nested_parent);
      }
    }
  }

  submit() {
    const fields = this.selection.selected;

    const labels = [];
    const keys = Object.keys(fields);
    for (const key of keys) {
        const value = fields[key];
        const fieldname = value['label'];
        labels.push(fieldname);
    }
    this.store.setFieldToShowOnMap(labels);
    persistFieldToShowOnMap(fields);
    const result = {
      fields
    };
    const element = getFieldToShowOnMap();
    this.dialogRef.close(result);
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Field): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.label + 1}`;
  }
}
