import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  message: string;
  title: string;
  formDuplication: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
    ) {
      if (data) {
        this.message = data.message;
        this.title = data.title;
        const formDupl = data.formDuplication;
        if (formDupl !== null && formDupl !== undefined) {
          this.formDuplication = data.formDuplication;
        } else {
          this.formDuplication = false;
        }
      }
    }

  ngOnInit() {
  }

  confirmChanges(event) {
    event.preventDefault();
    const res = {
      response: 'Yes'
    };
    this.dialogRef.close(res);
  }

  onNoClick(event): void {
    event.preventDefault();
    this.dialogRef.close(null);
    event.stopPropagation();
  }
}
