import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/share/user';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from 'src/app/services/store.service';

export interface Device {
  device_id: string;
  device_name: string;
}

const ELEMENT_DATA: Device[] = [];

@Component({
  selector: 'app-assignmarxactdevices',
  templateUrl: './assignmarxactdevices.component.html',
  styleUrls: ['./assignmarxactdevices.component.scss']
})
export class AssignmarxactdevicesComponent implements OnInit {
  user: User;
  displayedColumns: string[] = ['select', 'device_name', 'device_id'];

  dataSource = new MatTableDataSource<Device>(ELEMENT_DATA);
  selection = new SelectionModel<Device>(true, []);
  searchValue: string;

  constructor(
    private dialog: MatDialog,
    private store: StoreService,
    private userService: UserService) { }

  ngOnInit() {
  }

  assignAndOrDeassignDevices() {

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Device): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.device_name + 1}`;
  }

}
