import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { BooleanField } from 'src/app/share/form/boolean-field';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-create-boolean-field',
  templateUrl: './create-boolean-field.component.html',
  styleUrls: ['./create-boolean-field.component.scss']
})
export class CreateBooleanFieldComponent implements OnInit {
  booleanField: BooleanField;
  booleanFieldForm: FormGroup;
  @Input() parent: FieldSet;
  @Input() mode = 'Create';

  @ViewChild('fform', { static: false}) booleanFieldFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateBooleanFieldComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.booleanField = data.field;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    if (this.mode === 'Create') {
      this.booleanFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        default_value: false,
        use_previous_value: false,
        enabled: true,
        required: false,
        show_value_on_map: false
      });
    } else {
      this.booleanFieldForm = this.fb.group({
        name: [this.booleanField.name, Validators.required],
        label: [this.booleanField.label, Validators.required],
        description: this.booleanField.description,
        default_value: this.booleanField.default_value,
        use_previous_value: this.booleanField.use_previous_value,
        enabled: this.booleanField.enabled,
        required: this.booleanField.required,
        show_value_on_map: this.booleanField.show_value_on_map
      });
    }

  }

  onNameChange() {
    if (this.booleanFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.booleanFieldForm.controls.name.setValue(removeSpecialCharacters(this.booleanFieldForm.value.label));
    if (this.booleanFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  closeDialog(event) {
    event.preventDefault();
    const result = {
      action: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  onSubmit() {
    if (this.booleanField) {
      this.booleanField = {
        ...this.booleanField,
        ...this.booleanFieldForm.value
      };
    } else {
      this.booleanField = this.booleanFieldForm.value;
    }

    // this.booleanField = this.booleanFieldForm.value;
    this.booleanField._class = 'booleanfield';

    if (this.booleanField.name === this.parent.name) {
      this.showErrorMessage('Field cannot take the same name as the active fieldset');
      return;
    }
    const result = {
      action: 'Create',
      field: this.booleanField
    };
    if (this.mode === 'Create') {
      if (isFieldAlreadyExist(this.booleanField, this.parent)) {
        this.showErrorMessage('Another field with the same name/label already exists');
        return;
      }
      this.store.addField(this.booleanField);
    } else if (this.mode === 'Update') {
      result.action = 'Update';
    }
    this.dialogRef.close(result);
  }
}
