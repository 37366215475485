import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FieldSet } from 'src/app/share/form/fieldset';
import { CreateIntegerFieldComponent } from '../../dialogs/create-integer-field/create-integer-field.component';
import { CreateStringFieldComponent } from '../../dialogs/create-string-field/create-string-field.component';
import { CreateDecimalFieldComponent } from '../../dialogs/create-decimal-field/create-decimal-field.component';
import { CreateAutoincrementFieldComponent } from '../../dialogs/create-autoincrement-field/create-autoincrement-field.component';
import { CreateNoteFieldComponent } from '../../dialogs/create-note-field/create-note-field.component';
import { CreateBarcodeFieldComponent } from '../../dialogs/create-barcode-field/create-barcode-field.component';
import { CreateGpsCoordinatesFieldComponent } from '../../dialogs/create-gps-coordinates-field/create-gps-coordinates-field.component';
import { CreatePhotoFieldComponent } from '../../dialogs/create-photo-field/create-photo-field.component';
import { CreateVideoFieldComponent } from '../../dialogs/create-video-field/create-video-field.component';
import { CreateFieldsetComponent } from '../../dialogs/create-fieldset/create-fieldset.component';
import { CreateFieldsetArrayComponent } from '../../dialogs/create-fieldset-array/create-fieldset-array.component';
import { CreateAudioFieldComponent } from '../../dialogs/create-audio-field/create-audio-field.component';
import { CreateTimeFieldComponent } from '../../dialogs/create-time-field/create-time-field.component';
import { CreateDateFieldComponent } from '../../dialogs/create-date-field/create-date-field.component';
import { CreateBooleanFieldComponent } from '../../dialogs/create-boolean-field/create-boolean-field.component';
import { Field } from 'src/app/share/form/field';
import { persistSelectedFieldIndex, setFormUpdating } from 'src/app/share/utils';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ConstraintListDialogComponent } from '../../dialogs/constraint-list-dialog/constraint-list-dialog.component';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {
  @Input() field;
  @Input() parent: FieldSet;
  @Input() index: number;
  @Output() movedUp = new EventEmitter<Field>();
  @Output() movedDown = new EventEmitter<Field>();
  @Output() movedTop = new EventEmitter<Field>();
  @Output() movedBottom = new EventEmitter<Field>();
  @Output() deletedField = new EventEmitter<Field>();
  @Output() updatedField = new EventEmitter<Field>();

  constructor(
    private dialog: MatDialog
  ) {}

  ngOnInit() {
  }

  isFieldHaveConstraint() {
    if (this.field.constraints !== null &&
      this.field.constraints !== undefined &&
      this.field.constraints.length > 0) {
      return true;
    }

    return false;
  }

  openFieldConstraintPage(field) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      field
    };

    const dialogRef = this.dialog.open(ConstraintListDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // if (result.event === 'Add') {
      // const constraint = result.constraint;
      // field.constraints.push(constraint);
      // }
    });
  }

  /**
   * This method open the appriopriate dialog for updatating
   * @param f The field we want to update
   */
  openUpdateField(f) {
    switch (f._class) {
      case 'stringfield':
        this.openStringDialog(f);
        break;

      case 'integerfield':
        this.openIntegerDialog(f);
        break;

      case 'decimalfield':
        this.openDecimalDialog(f);
        break;

      case 'notefield':
        this.openNoteDialog(f);
        break;

      case 'barcodefield':
        this.openBarCodeDialog(f);
        break;

      case 'booleanfield':
        this.openBooleanFieldDialog(f);
        break;

      case 'arrayfield':
        //  return 'Array';
        break;

      case 'gpscoordinatesfield':
        this.openGPSCoordinatesDialog(f);
        break;

      case 'No Geometry':
        break;

      case 'computedfield':
        break;

      case 'datefield':
        this.openDateFieldDialog(f);
        break;

      case 'timefield':
        this.openTimeFieldDialog(f);
        break;

      case 'photofield':
        this.openPhotoDialog(f);
        break;

      case 'videofield':
        this.openVideoDialog(f);
        break;

      case 'audiofield':
        this.openAudioFieldDialog(f);
        break;

      case 'autoincrementintegerfield':
        this.openAutoIntegerDialog(f);
        break;
    }
  }

  /**
   * This method call the confirmation button before delete a field
   * @param f The selected field
   */
  openDeleteConfirmationDialog(f) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: 'Do you want to delete the selected field?',
        title: 'Delete selected Field'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.deleteField(f);
      }
    });
  }

  getConstraintText(constraint) {
    switch (constraint) {
      case 'choice':
        return 'Single choice list';

      case 'multiple_choice':
        return 'Multiple choice list';

      case 'minimum':
        return 'Minimum';

      case 'maximum':
        return 'Maximum';

      case 'length':
        return 'Length';
    }
  }

  /**
   * This method delete the selected field from the list of fields in parent
   * If the index is lower than 0 or egal the length of the parent fields, nothing happen
   * @param f The selected field
   */
  deleteField(f) {
    if (this.index < 0 || this.index > this.parent.fields.length - 1) {
      return;
    }
    this.parent.fields.splice(this.index, 1);
    this.deletedField.emit(f);
    setFormUpdating(true);
  }

  /**
   * This method move to the top the selected field
   * If the index is egal to 0, nothing happen
   * @param f The selected field
   */
  moveToTop(f) {
    if (this.index <= 0) {
      return;
    }
    this.parent.fields.splice(this.index, 1);
    this.parent.fields.unshift(f);
    this.movedTop.emit(f);
    setFormUpdating(true);
  }

  /**
   * This method move to the bottom the selected field
   * If the index is egal the length of the parent fields, nothing happen
   * @param f The selected field
   */
  moveToBottom(f) {
    if (this.index >= this.parent.fields.length - 1) {
      return;
    }
    this.parent.fields.splice(this.index, 1);
    this.parent.fields.push(f);
    this.movedBottom.emit(f);
    setFormUpdating(true);
  }

  /**
   * This method move up the selected field
   * If the index is egal to 0, nothing happen
   * @param f The selected field
   */
  moveUp(f) {
    if (this.index <= 0) {
      return;
    }
    const upField = this.parent.fields[this.index - 1];
    this.parent.fields[this.index - 1] = f;
    this.parent.fields[this.index] = upField;

    this.movedUp.emit(f);
    setFormUpdating(true);
  }

  /**
   * This method move down the selected field
   * If the index is egal the length of the parent fields, nothing happen
   * @param f The selected field
   */
  moveDown(f) {
    if (this.index >= this.parent.fields.length - 1) {
      return;
    }
    const upField = this.parent.fields[this.index + 1];
    this.parent.fields[this.index + 1] = f;
    this.parent.fields[this.index] = upField;
    this.movedDown.emit(f);
    setFormUpdating(true);
  }

  openConstraintsDialog(f) {

  }

  /**
   * This method create and return the MatDialogConfig setting for the dialog to open
   * for updating a selected field
   * @param f The current field element
   * @param parent The parent fieldset of the selected field
   */
  setUpdateDialogConfig(f, parent): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: 'Update',
      field: f,
      index: this.index,
      parent
    };
    persistSelectedFieldIndex(this.index);
    return dialogConfig;
  }

  /**
   * This method open integer dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openIntegerDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateIntegerFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
        // this.activeFieldset.fields.push(result);
        // this.fields.push(result);
      }
    });
  }

  /**
   * Apply the change and update the field, and fieldset and also the UI
   * @param result the result given by the dialog
   */
  applyUpdate(result) {
    debugger;
    if (result.action === 'Update') {
      this.field = result.field;
      setFormUpdating(true);
      this.parent.fields.splice(this.index, 1, this.field);
      this.updatedField.emit(this.field);
    }
  }

  /**
   * This method open string field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openStringDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateStringFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open decimal field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openDecimalDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateDecimalFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open Integer field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openAutoIntegerDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateAutoincrementFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open note field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openNoteDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateNoteFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open barcode field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openBarCodeDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateBarcodeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open GPSCoordinate Field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openGPSCoordinatesDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateGpsCoordinatesFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open photo field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openPhotoDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreatePhotoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open video field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openVideoDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateVideoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open fieldset dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openFieldsetDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateFieldsetComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open FieldsetArray field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openFieldSetArrayDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    this.dialog.open(CreateFieldsetArrayComponent, dialogConfig);
  }

  /**
   * This method open audio field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openAudioFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateAudioFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open video field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openTimeFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateTimeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open date field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openDateFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateDateFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open boolean field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openBooleanFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateBooleanFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method take as parameter the _class value of a field and
   * return the text value to be shown on the UI
   * @param type the _class value of a field
   */
  getFieldTypeText(type) {
    switch (type) {
      case 'stringfield':
        return 'Text';

      case 'integerfield':
        return 'Integer';

      case 'decimalfield':
        return 'Decimal';

      case 'notefield':
        return 'Note';

      case 'barcodefield':
          return 'Barcode';

      case 'booleanfield':
          return 'Boolean';

      case 'arrayfield':
          return 'Array';

      case 'gpscoordinatesfield':
          return 'GPS Coordinates';

      case 'No Geometry':
          return '';

      case 'computedfield':
          return 'Computed';

      case 'datefield':
          return 'Date';

      case 'timefield':
          return 'Time';

      case 'photofield':
          return 'Photo';

      case 'videofield':
          return 'Video';

      case 'audiofield':
          return 'Audio';

      case 'autoincrementintegerfield':
          return 'Auto Increment Integer';

      case 'Type':
          return 'Type';
    }
    return 'Text';
  }

}
