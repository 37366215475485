import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectService } from 'src/app/services/project.service';
import { getAdminToken, getToken } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { FileServiceService } from 'src/app/services/file-service.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-project-to-project-template',
  templateUrl: './project-to-project-template.component.html',
  styleUrls: ['./project-to-project-template.component.scss']
})
export class ProjectToProjectTemplateComponent implements OnInit {
  @Input() project: Project;
  private addedProject: Project;
  projectDialogForm: FormGroup;
  image: string;
  name: string;
  description: string;
 
  deleteProjectAfterCopy: boolean = false;
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = 'Create';
  @ViewChild('pjtIcon', {static: false}) img;
  @ViewChild('fform', { static: false}) projectFormDirective;
  private messageBox: MessageBoxComponent;
 

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fileService: FileServiceService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ProjectToProjectTemplateComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
      this.mode = data.mode;
      this.name = data.project.name;
      this.description = data.project.description;
      
    }
    
    this.createForm();
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  ngOnInit() {
  }

  initializeFormData(p: Project) {
    this.name = p.name;
    this.description = p.description;
  }

  createForm() {
    let name = '';
    let des = '';
    


    if (this.project !== undefined && this.project !== null) {
      name = this.project.name;
      des = this.project.description;

    }
    this.projectDialogForm = this.fb.group({
      name: [name, Validators.required],
      description: des
    });
  }



  getUniqueFilename(prefix, extension): string {
    return prefix.replace(/\s/g, '') + '_' + Date.now() + '.' + extension;
  };

  getExtensionFromDataURI(data){
    return data.split(';')[0].split('/')[1];
  };

  onSubmit() {
    const temp = this.projectDialogForm.value;
    let isMyCumulusAdministrator = false;

    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    } 
    const proToken = {
      'key': 'key',
      'value': token.key
    }

    if (this.deleteProjectAfterCopy) {
      const secondDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: `Do you really want to delete the project "${temp.name}" ?`,
          message: `Please think twice, this action cannot be undone! \n Are you really sure you want to delete the project, all forms and the data?`
        }
      });

      secondDialogRef.afterClosed().subscribe(res  => {
        if (res) {
          this.project = {
            ...this.store.project,
            name: temp.name + " Project Template",
            description: temp.description,
            project_class: "template"
          };
          this.store.showLoading();
          this.projectService.updateProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
            res => {
              this.store.deleteProject(res);
              this.showSuccessMessage("The project is moved to 'Project Templates'");
              this.store.templates = [];
              this.dialogRef.close();
              this.store.hideLoading();
            },
            errmess => {
              this.showErroMessage(errmess);
              this.store.hideLoading();
          });
        } else {
          return;
        }
      });
    } else {
      this.store.showLoading();
      let currentProject = this.store.project;
      const project = {
        project_id: currentProject.id,
        name: temp.name,
        description: temp.description,
      }
      
      this.projectService.duplicateProject(project, proToken, isMyCumulusAdministrator).subscribe(
        res => {
          const p = {
            ...res,
            name: res.name + " Project Template",
            project_class: 'template'
          };
          this.projectService.updateProject(p, proToken, isMyCumulusAdministrator).subscribe(updateRes => {
            this.showSuccessMessage("A copy of the project is moved to 'Project templates'");
            this.store.hideLoading();
            setTimeout(() => {
              this.dialogRef.close();
            }, 2000);
            
          }, err => {
            this.store.hideLoading();
            this.showErroMessage(err);
          });
        },
        errmes => {
          this.store.hideLoading();
          this.showErroMessage(errmes);
        }
      );
    }
  }

  deleteProjectAfter(event) {
    this.deleteProjectAfterCopy = event.checked;
  }

  openCreateProject() {
    this.dialog.open(ProjectToProjectTemplateComponent, {width: '500px', height: 'auto'});
  }

  onProjectAdded(project: Project) {
    // this.toastr.success('Your project has been successfully created.', 'MyCumulus');
  }

  onProjectUpdated(project: Project) {

  }

  openEditProject(p) {
    this.project = p;
    this.dialog.open(ProjectToProjectTemplateComponent, {width: '500px', height: 'auto'});
  }

  uploadProjectIcon() {
    // Send image icon and then come continue operation
  }

  removeProjectIcon() {
    this.image = '';
  }

  invalidIconDrop(icon: boolean) {
    this.invalidIconDropped.emit(icon);
    // this.toastr.error(, 'MyCumulus');
    this.showErroMessage('drop only images on the icon space');
  }

  /**
   * This method allow to change the new value provided by the user to
   * be the new data of project
   * TODO: Change the code to use way of extending object, (use of spread)
   */
  changedProperties() {
    let data = {};

    if (this.name !== this.project.name) {
      data['name'] = this.name;
    }

    if (this.description !== this.project.description) {
      data['description'] =  this.description;
    }

    if (this.image !== this.project.image_file_url) {
      if (this.image === '') {
        data['image_file_url'] = null;
      } else {
        data['image_file_url'] = this.image.split(',')[1];
      }
    }

    return data;
  }

}
