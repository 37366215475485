import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { getToken, getUser } from 'src/app/share/utils';
import { ProjectMemberDialogComponent } from '../dialogs/project-member-dialog/project-member-dialog.component';
import { MatDialog, MatTableDataSource, MatDialogConfig } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { User } from 'src/app/share/user';
import { ProjectService, ProjectToken } from 'src/app/services/project.service';
import { Project } from 'src/app/share/projects';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';

const ELEMENT_DATA: Project[] = [];

@Component({
  selector: 'app-user-projects-page',
  templateUrl: './user-projects-page.component.html',
  styleUrls: ['./user-projects-page.component.scss']
})

export class UserProjectsPageComponent implements OnInit {
  user: User;
  displayedColumns: string[] = ['projects', 'members'];
  dataSource = new MatTableDataSource<Project>(ELEMENT_DATA);
  selection = new SelectionModel<Project>(true, []);
  searchValue: string;
  message = '';
  showMessage: boolean;
  proClass: string = null;
  visibility: string = null;
  archived: boolean = false;

  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private projectSevice: ProjectService
  ) {
    this.store.showLoading();
    const token = getToken();
    const user = this.store.user;
    const proToken: ProjectToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    this.proClass = this.store.proClass;
    this.visibility = this.store.visibility;
    this.archived = this.store.archived;

    if (user !== undefined && user !== null) {
      if (user['user_type'] === 'collaborator' || (user['manager_id'] !== null && user['manager_id'] !== undefined)) {
        this.projectSevice.getCollaboratorProjects(token, this.proClass, this.visibility, this.archived).subscribe(
          res => {
            let data = this.dataSource.data;
            data = [];
            for (const p of res) {
              data.push(p);
            }
            this.dataSource.data = data.filter(p => p.created_by === user.id).sort(
              (p1, p2) => {
              if (p1.name < p2.name) { return -1; }
              if (p1.name > p2.name) { return 1; }
              return 0;
            });
            this.store.hideLoading();
          },
          errmess => {
            this.showErroMessage(errmess);
            this.dataSource.data = [];
            this.store.hideLoading();
          }
        );
        return;
      }
    }
    this.projectSevice.getProjects(proToken, this.proClass, this.visibility, this.archived).subscribe(
      projects => {
        let data = this.dataSource.data;
        data = [];
        for (const p of projects) {
          data.push(p);
        }
        this.dataSource.data = data.sort(
          (p1, p2) => {
          if (p1.name < p2.name) { return -1; }
          if (p1.name > p2.name) { return 1; }
          return 0;
        });
        this.store.hideLoading();
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.showErroMessage(err);
        this.dataSource.data = [];
        this.store.hideLoading();
      }
    );
  }

  ngOnInit() {
    // this.user = getUser();
    this.user = this.store.user;
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  openProjectMembersDialog(project: Project) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project
    };
    /*
    if(project.template_ids.length <= 0){
      this.message = 'There is no form in the project, permissions cannot be assigned';
      this.showErroMessage(this.message);
      return;
    }
    */
    this.dialog.open(ProjectMemberDialogComponent, dialogConfig);
  }
}
