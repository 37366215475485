import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Project } from 'src/app/share/projects';
import { ProjectMemberService } from 'src/app/services/project-member.service';
import { TemplateService } from 'src/app/services/template.service';
import { getToken } from 'src/app/share/utils';
import { Collaborator } from 'src/app/share/user';
import { ProjectMember } from 'src/app/share/project-member';
import { TemplatePermission } from 'src/app/share/permission';
import { StoreService } from 'src/app/services/store.service';

/*
export interface TemplatePermissions {
  template_id: string;
  permission_types: string[];
}
*/

const  CREATE  = 'create';
const  READ    = 'read';
const  UPDATE  = 'update';
const  DELETE  = 'delete';

const ELEMENT_DATA: TemplatePermission[] = [];

@Component({
  selector: 'app-templates-permissions-dialog',
  templateUrl: './templates-permissions-dialog.component.html',
  styleUrls: ['./templates-permissions-dialog.component.scss']
})
export class TemplatesPermissionsDialogComponent implements OnInit {
  users: Collaborator[];
  project: Project;
  projectMember: ProjectMember;
  displayedColumns: string[] = ['name', 'read', 'create', 'update', 'delete', 'all'];
  permissionsToGrant: TemplatePermission[];
  permissionsToRevoke: TemplatePermission[];
  existingPermissions: TemplatePermission[];
  userPermissions: TemplatePermission[];
  dataSource = new MatTableDataSource<TemplatePermission>(ELEMENT_DATA);
  selection = new SelectionModel<TemplatePermission>(true, []);
  searchValue: string;
  allExistings: any[];

  constructor(
    private dialog: MatDialogRef<TemplatesPermissionsDialogComponent>,
    private store: StoreService,
    private projectMemberService: ProjectMemberService,
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
      this.users = data.user;
    }
  }

  buildExistingPermissions(){
    const token = getToken();

    this.users.forEach(user => {
      let  userPermObj = {};
      this.existingPermissions = [];
      this.projectMemberService.getProjectMemberInfo(token, this.project.id, user.id).subscribe(
        res => {
          this.projectMember = res;
          if (this.projectMember.permissions && this.projectMember.permissions.features) {
            const features = this.projectMember.permissions.features;
          }
          /**
           * Get the template of this project
           */
          this.templateService.getTemplates(token, this.project.id).subscribe(
            result => {
              if (result.length > 0) {
                //let userslist = []; //this.dataSource.data;
                if (this.projectMember.permissions && this.projectMember.permissions.features) {
                  const features = this.projectMember.permissions.features;
                  for (const template of result) {
                    let found = false;
                    if (features != null && features !== undefined && features.length > 0) {
                      for (const feature of features) {
                        if (feature.template_id === template.id) {
                          //userslist.push(new TemplatePermission(template, feature.permission_types));
                          this.existingPermissions.push(new TemplatePermission(template, feature.permission_types));
                          found = true;
                          break;
                        }
                      }
                      if (!found) {
                        //userslist.push(new TemplatePermission(template, null));
                        this.existingPermissions.push(new TemplatePermission(template, null));
                      }
                    } else {
                      //userslist.push(new TemplatePermission(template, null));
                      this.existingPermissions.push(new TemplatePermission(template, null));
                    }
                  }
                }

              }
              this.store.hideLoading();
              userPermObj = {
                userId : user.id,
                exPermisssions: this.existingPermissions
              };
              this.allExistings.push(userPermObj);
            },
            err => {
              // this.toastr.error(err, 'MyCumulus');
              this.store.hideLoading();
            }
          );
        },
        err => {
          // this.toastr.error(err, 'MyCumulus');
          this.store.hideLoading();
        }
      );

    });
    this.loadEmptyPermissions();
  }

  ngOnInit() {
    this.permissionsToGrant = [];
    this.permissionsToRevoke = [];
    this.userPermissions = [];
    this.existingPermissions = [];
    this.allExistings = [];
    const token = getToken();
    this.store.showLoading();
    if(this.users.length > 1){
      this.buildExistingPermissions();
      return;
    }
    this.projectMemberService.getProjectMemberInfo(token, this.project.id, this.users[0].id).subscribe(
      res => {
        this.projectMember = res;
        if (this.projectMember.permissions && this.projectMember.permissions.features) {
          const features = this.projectMember.permissions.features;
        }
        /**
         * Get the template of this project
         */
        this.templateService.getTemplates(token, this.project.id).subscribe(
          result => {
            if (result.length > 0) {
              let userslist = []; //this.dataSource.data;

              if (this.projectMember.permissions && this.projectMember.permissions.features) {
                const features = this.projectMember.permissions.features;
                for (const template of result) {
                  let found = false;
                  if (features != null && features !== undefined && features.length > 0) {
                    for (const feature of features) {
                      if (feature.template_id === template.id) {
                        userslist.push(new TemplatePermission(template, feature.permission_types));
                        this.existingPermissions.push(new TemplatePermission(template, feature.permission_types));
                        found = true;
                        break;
                      }
                    }
                    if (!found) {
                      userslist.push(new TemplatePermission(template, null));
                      this.existingPermissions.push(new TemplatePermission(template, null));
                    }
                  } else {
                    userslist.push(new TemplatePermission(template, null));
                    this.existingPermissions.push(new TemplatePermission(template, null));
                  }
                }
              }
              this.dataSource.data = userslist;
              this.userPermissions = userslist;
            }
            this.store.hideLoading();
          },
          err => {
            // this.toastr.error(err, 'MyCumulus');
            this.store.hideLoading();
          }
        );
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.store.hideLoading();
      }
    );
  }

  loadEmptyPermissions(){
    const token = getToken();
    this.templateService.getTemplates(token, this.project.id).subscribe(
      result => {
        let userslist = [];

        for (const template of result) {
          userslist.push(new TemplatePermission(template, null));
          //this.existingPermissions.push(new TemplatePermission(template, null));
        }
        this.dataSource.data = userslist;
        this.userPermissions = userslist;
        this.store.hideLoading();
      },

      err => {
        this.store.hideLoading();
      }
    );
  }

  initializeReadPermission(element) {
    return this.initializePermission(element, READ);
  }

  initializeCreatePermission(element) {
    return this.initializePermission(element, CREATE);
  }

  initializeUpdatePermission(element) {
    return this.initializePermission(element, UPDATE);
  }

  initializeDeletePermission(element) {
    return this.initializePermission(element, DELETE);
  }

  initializeAllPermission(element) {
    return element.permissions.read && element.permissions.create &&
            element.permissions.update && element.permissions.delete;
  }

  initializePermission(element, permission) {
    let result = false;
    let features = null;

    if (this.projectMember.permissions && this.projectMember.permissions.features) {
      features = this.projectMember.permissions.features;
      for (const featurePerm of features) {
        if (element.id === featurePerm.template_id && featurePerm.permission_types.indexOf(permission) >= 0) {
          result = true;
          break;
        }
      }
    }
    // console.log(features, permission, result);
    return result;
  }

  setAllPermissions(isChecked) {
    let index = 0;
    for (const element of this.dataSource.data) {
      this.setAllPermission(element, index++, isChecked);
    }
  }

  initializeAllPermissions() {
    let allPermission = true;
    for (const element of this.dataSource.data) {
      if (!this.initializeAllPermission(element)) {
        return false;
      }
    }
    return allPermission;
  }

  updatePermission(element, permissionType, access) {
    // let alreadyExist = false;
    const index = this.permissionsToGrant.indexOf(element);
    this.grantPerm(element, permissionType, access);

    if (index >= 0) {
      this.permissionsToGrant[index] = element;
    } else {
      this.permissionsToGrant.push(element);
    }
  }

  grantPerm(element: TemplatePermission, permission_type, access) {
    if (permission_type === READ) {
      element.permissions.selectRead(access);
    } else if (permission_type === CREATE) {
      element.permissions.selectCreate(access);
    } else if (permission_type === UPDATE) {
      element.permissions.selectUpdate(access);
    } else if (permission_type === DELETE) {
      element.permissions.selectDelete(access);
    }
  }

  checkIfPermissionTypeExist(element, permissionType) {
    if (this.permissionsToGrant.length > 0) {
      for (const perm of this.permissionsToGrant) {
        /*
        if (perm.template_id === element.id) {
          if (perm.permission_types.indexOf(permissionType) >= 0) {
            return true;
          }
        }
        */
      }
    }
    return false;
  }

  revokePermission(element, permissionType) {

  }

  setCreatePermission(element, isChecked: boolean) {
    this.updatePermission(element, CREATE, isChecked);
    // element.permissions.selectCreate(isChecked);
    // this.permissionsToGrant.push(element);
  }

  setUpdatePermission(element, isChecked: boolean) {
    this.updatePermission(element, UPDATE, isChecked);
    // element.permissions.selectUpdate(isChecked);
    // this.permissionsToGrant.push(element);
  }

  setDeletePermission(element, isChecked: boolean) {
    this.updatePermission(element, DELETE, isChecked);
    // element.permissions.selectDelete(isChecked);
    // this.permissionsToGrant.push(element);
  }

  setDeleteAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setDeletePermission(element, isChecked);
    }
  }

  setUpdateAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setUpdatePermission(element, isChecked);
    }
  }

  setReadAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setReadPermission(element, isChecked);
    }
  }

  getAllColReadPermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.read){
        return false;
      }
    }
    return true;
  }

  getAllColCreatePermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.create){
        return false;
      }
    }
    return true;
  }

  getAllColUpdatePermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.update){
        return false;
      }
    }
    return true;
  }

  getAllColDeletePermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.delete){
        return false;
      }
    }
    return true;
  }

  setCreateAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setCreatePermission(element, isChecked);
    }
  }

  setReadPermission(element, isChecked: boolean) {
    this.updatePermission(element, READ, isChecked);
    // element.permissions.selectRead(isChecked);
    // this.permissionsToGrant.push(element);
  }

  grantAllPermission(element) {

  }

  setAllPermission(element, index, isChecked: boolean) {
    element.permissions.selectAll(isChecked);
    this.permissionsToGrant.push(element);
  }

  getTemplatePermission(permis: TemplatePermission[], permi: TemplatePermission): any {
    if(permis.length > 0){
      for (const perm of permis){
        if(perm.template.id === permi.template.id){
          return perm;
        }
      }
    }

    return null;
  }

  onSubmit() {
    const token = getToken();
    this.store.showLoading();

    if(this.users.length <= 0){
      return;
    }

    this.users.forEach(user => {
      const grantFeatures = [];
      const revokeFeature = [];

      this.allExistings.forEach(perm => {
        if(perm.userId === user.id){
          this.existingPermissions = perm.exPermisssions;
          return;
        }
      });

      for (const permission of this.permissionsToGrant) {
        const extPerm = this.getTemplatePermission(this.existingPermissions, permission);
        const perm =  this.createPermissions(permission, extPerm);
        if (perm.grantPermission !== null) {
          grantFeatures.push(perm.grantPermission);
        }

        if (perm.revokePermission !== null) {
          revokeFeature.push(perm.revokePermission);
        }
      }

      if (grantFeatures.length <= 0 && revokeFeature.length <= 0) {
        this.store.hideLoading();
        return;
      }


      if(grantFeatures.length > 0){
        const grandData = {
          user_id: user.id,
          permissions: {
            'features': grantFeatures
          }
        };

        this.projectMemberService.grantTemplatesPermissionToCollaborator(token, user.id, this.project.id, grandData).subscribe(
          res => {
            this.dialog.close();
            this.store.hideLoading();
          },
          err => {
            this.dialog.close();
            this.store.hideLoading();
        });
      }

      if(revokeFeature.length > 0){
        const revokeData = {
          user_id: user.id,
          permissions: {
            'features': revokeFeature
          }
        };

        this.projectMemberService.revokeTemplatesPermissionToCollaborator(token, user.id, this.project.id, revokeData).subscribe(
          res => {
            this.dialog.close();
            this.store.hideLoading();
          },
          err => {
            this.dialog.close();
            this.store.hideLoading();
        });
      }
    });

  }

  createPermissions (templatePermission: TemplatePermission, existPerms: TemplatePermission): any {
    const readPermission = templatePermission.permissions.read;
    const createPermission = templatePermission.permissions.create;
    const updatePermission = templatePermission.permissions.update;
    const deletePermission = templatePermission.permissions.delete;

    const readExist = existPerms.permissions.read;
    const createExist = existPerms.permissions.create;
    const updateExist = existPerms.permissions.update;
    const deleteExist = existPerms.permissions.delete;

    let permission = {};
    let permission_types = [];

    if (readPermission || createPermission || updatePermission || deletePermission) {

      if (readPermission && !readExist) {
        permission_types.push('read');
      }
      if (createPermission && !createExist) {
        permission_types.push('create');
      }
      if (updatePermission && !updateExist) {
        permission_types.push('update');
      }
      if (deletePermission && !deleteExist) {
        permission_types.push('delete');
      }
      if(permission_types.length > 0){
        const grantPerm = {
          template_id: templatePermission.template.id,
          permission_types
        };

        permission['grantPermission'] = grantPerm;
      } else {
        permission['grantPermission'] = null;
      }


    }

    if (!readPermission || !createPermission || !updatePermission || !deletePermission) {
      permission_types = [];
      if (!readPermission && readExist) {
        permission_types.push('read');
      }
      if (!createPermission && createExist) {
        permission_types.push('create');
      }
      if (!updatePermission && updateExist) {
        permission_types.push('update');
      }
      if (!deletePermission && deleteExist) {
        permission_types.push('delete');
      }

      if(permission_types.length > 0){
        const revokPerm = {
          template_id: templatePermission.template.id,
          permission_types
        };
        permission['revokePermission'] = revokPerm;
      } else {
        permission['revokePermission'] = null;
      }

    }

    return permission;
  }

  getPermissionsDiff(){
    if(this.existingPermissions.length > 0){

    }
  }

   /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TemplatePermission): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.template.name + 1}`;
  }
}
