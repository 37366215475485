import { Component, OnInit } from '@angular/core';
import { Token } from './share/token';
import { User } from './share/user';
import { UserService } from './services/user.service';
import { Observable } from 'rxjs';
import { getToken, getAdminToken, isTokenValid, logoutUser, removeToken } from './share/utils';
import { NavigationEnd, Router } from '@angular/router';
import { StoreService } from './services/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // @select() token: Observable<Token>;
  // @select() showprojects: Observable<boolean>;
  showHeaderFooter: boolean = true;

  title = 'mycumulus-web-application-v2';

  constructor(
    private store: StoreService,
    private router: Router,
    private userService: UserService
  ) {
    let token = getToken();
    if (token) {
      if (isTokenValid(token)) {
        this.store.saveToken(token);
        this.router.navigate(['/projects']);
        this.store.hideProjects();
      } else {
        logoutUser();
        this.store.initializeStore();
       // this.router.navigate(['/login']);
      }
    } else {
      token = getAdminToken();
      if (token) {
        if (isTokenValid(token)) {
          this.store.saveToken(token);
          this.router.navigate(['/mcadmins']);
          return;
        } else {
          logoutUser();
          this.store.initializeStore();
          this.store.initializeStore();
          this.router.navigate(['/mcadmin-login']);
          return;
        }
      } else {
        logoutUser();
        this.store.initializeStore();
        //this.router.navigate(['/login']);
        return;
      }
      // this.store.hideProjects();
      // this.store.hideLoading();
    }
  }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeaderFooter = !(event.urlAfterRedirects.includes('/signup'));
      }
    });
  }
}
