import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AudioField } from 'src/app/share/form/audio-field';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FieldSet } from 'src/app/share/form/fieldset';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-create-audio-field',
  templateUrl: './create-audio-field.component.html',
  styleUrls: ['./create-audio-field.component.scss']
})
export class CreateAudioFieldComponent implements OnInit {
  audioFieldForm: FormGroup;
  audioField: AudioField;
  parent: FieldSet;
  @Input() mode = 'Create';

  @ViewChild('fform', {static: false}) audioFieldFormDirectives;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateAudioFieldComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.audioField = data.field;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    if (this.mode === 'Create') {
      this.audioFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        enabled: true,
        visible: true,
        required: false
      });
    } else {
      this.audioFieldForm = this.fb.group({
        name: [this.audioField.name, Validators.required],
        label: [this.audioField.label, Validators.required],
        description: this.audioField.description,
        enabled: this.audioField.enabled,
        visible: this.audioField.visible,
        required: this.audioField.required
      });
    }
  }

  onNameChange() {
    if (this.audioFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.audioFieldForm.controls.name.setValue(removeSpecialCharacters(this.audioFieldForm.value.label));
    if (this.audioFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  onSubmit() {
    if (this.audioField) {
      this.audioField = {
        ...this.audioField,
        ...this.audioFieldForm.value
      };
    } else {
      this.audioField = this.audioFieldForm.value;
    }
    // this.audioField = this.audioFieldForm.value;
    this.audioField._class = 'audiofield';

    if (this.audioField.name === this.parent.name) {
      this.showErrorMessage('Field cannot take the same name as the active fieldset');
      return;
    }
    const result = {
      action: 'Create',
      field: this.audioField
    };
    if (this.mode === 'Create') {
        if (isFieldAlreadyExist(this.audioField, this.parent)) {
          this.showErrorMessage('Another field with the same name/label already exists');
          return;
        }
        this.store.addField(this.audioField);
    } else if (this.mode === 'Update') {
      result.action = 'Update';
    }
    this.dialogRef.close(result);
    // Add the update to the parent fields
  }

  closeDialog(event) {
    event.preventDefault();
    const result = {
      action: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  createFormForUpdate(field) {
    this.audioField = field;
    this.audioFieldForm = this.fb.group({
      name: [this.audioField.name, Validators.required],
      label: [this.audioField.label, Validators.required],
      description: this.audioField.description,
      enabled: this.audioField.enabled,
      visible: this.audioField.visible,
      required: this.audioField.visible
    });
  }
}
