import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { Router } from '@angular/router';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { StoreService } from 'src/app/services/store.service';
import { MatDialog } from '@angular/material';
import { getAdminToken, getAdminInfo, removeAdminInfo, persistAdminInfo } from 'src/app/share/utils';

@Component({
  selector: 'app-mycumulusadministrators-page',
  templateUrl: './mycumulusadministrators-page.component.html',
  styleUrls: ['./mycumulusadministrators-page.component.scss']
})
export class MycumulusadministratorsPageComponent implements OnInit {
  user: any;
  adminName: string;
  adminEmail: string;
  adminType: string;
  assignDeviceToAdmin: boolean;
  editUserEmail: boolean;
  usermarxactDevicesName: string;
  adminMCMarxact: any;
  userHasMarxactAccount = false;
  marxactLoginLoading: boolean;
  marxactDevicesList: any[];
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  constructor(
    private router: Router,
    private userService: SysAdminUserServiceService,
    public store: StoreService,
    private mcUserSerice: SysAdminUserServiceService,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
    const token = getAdminToken();
    if (token === null || token === undefined) {
      this.router.navigate(['/']);
      return;
    }
    this.editUserEmail = false;
    this.usermarxactDevicesName = '';
    this.user = this.store.user;
    this.refreshAdminValues();
  }

  editProfile() {
    this.editUserEmail = true;
  }

  refreshAdminValues() {
    this.adminName = this.user.name;
    this.adminEmail = this.user.email;
    if (this.user.user_type !== null && this.user.user_type !== undefined ) {
      this.adminType = this.user.user_type;
    } else {
      this.adminType = this.user.admin_type;
    }
  }

  updateProfile() {
    if (this.user !== null) {
        const token = getAdminToken();
        this.mcUserSerice.updateMCNameAndEmail(this.user, token).subscribe(
          res => {
            this.user = res;
            removeAdminInfo();
            persistAdminInfo(res);
            this.refreshAdminValues();
          },
          err => {
            this.showErroMessage(err);
          }
        );
    }
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  openGnssSourceLogin() {

  }

  cancelProfile() {
    this.editUserEmail = false;
  }

}
