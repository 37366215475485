// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_API_URL: 'https://api.mycumulus.com',
  URL: 'https://api.mycumulus.com/v1/',
  DOMAIN: 'api.mycumulus.com',
  //BASE_API_URL: 'http://localhost:8000',
  //URL: 'http://localhost:8000/v1/',
  //DOMAIN: 'localhost:8000',
  webAppVersion: '2.4.2 30 August, 2024',
  apiVersion: '6.1.0',
  SignupAdministrator: {
    username: 'paSignupAdmin',
    password: 'AJP2'
  },
  EmailSenderAdministrator: {
    username: 'pythagorasSender',
    password: 'AJP2'
  },
  GOOGLE_API_KEY: 'AIzaSyC5JZscv28xSvFC4VCreuE76I94V1_xYCg'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
