import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { Attribute, AttributeSet } from 'src/app/share/feature/attributes';
import { FeatureSchema } from 'src/app/share/schema/schema';
import { Template } from 'src/app/share/template';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { FeatureService } from 'src/app/services/feature.service';
import { TitleCasePipe } from '@angular/common';
import { RECORD_DATA_MODE, StoreService } from 'src/app/services/store.service';
import { TokenType, getCurrentDomain, getProject, getTemplate, getTemplateFieldsNameAndTypes, getToken, persistLastMenu, saveTemplateFieldsNameAndTypes, saveTemplateFieldsNameAndValues, url } from 'src/app/share/utils';
import { GeomTypeCoordinatesDialogComponent } from '../dialogs/geom-type-coordinates-dialog/geom-type-coordinates-dialog.component';
import { AttributesetarrayDialogComponent } from '../dialogs/attributesetarray-dialog/attributesetarray-dialog.component';
import { MediaShowingDialogComponent } from '../dialogs/media-showing-dialog/media-showing-dialog.component';
import * as $ from 'jquery';
import { FieldSet } from 'src/app/share/form/fieldset';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditFeatureValueComponent } from '../dialogs/edit-feature-value/edit-feature-value.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ExportDataCriteriaDialogComponent } from '../dialogs/export-data-criteria-dialog/export-data-criteria-dialog.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: AttributeSet[] = [];

const COORDINATES = 'coordinates';
const ATTRIBUTESETARRAY = 'arrayattributeset';

const _SELECT = '_select';
@Component({
  selector: 'app-found-features',
  templateUrl: './found-features.component.html',
  styleUrls: ['./found-features.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FoundFeaturesComponent implements OnInit, AfterViewInit, AfterContentInit {

  GEOMETRY_TYPE = 'Geometry_Type';
  searchValue: string;
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  templateName: string;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatTable, {static: false}) foundfeaturetable: MatTable<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  @Input() 
  template: Template;

  instances: any[] = [];

  featureSchema: FeatureSchema;
  initHeaders: any[];
  arrayHeadersAndSize: any[];
  geomHeader: any;
  attHeaders: any[];
  schemaHeader: any[];
  allCoordinates: any[];
  featureAttrib: any[] = [];
  missIndexes: any[] = [];
  
  allArrayAttributeSet: any[] = [];
  allArrayAttributeSetElemment: any[] = [];
  allCoordinatesFields: any[] = [];
  allCoordinatesFieldsElemment: any[] = [];
  ArrayAttSetFeatureSelected: any; // feature cliqué
  ArrayAttSetFeatureSelectedIndex: any;
  arrayAttSetHeader: any[] = [];
  arrayAttSetData: any[] = [];
  linksArray: any[];
  ColumsValues: any[] = [];
  columnsValues: any[];
  columnsName: any[] = [];
  advancedSearchCriteria: any;
  aggregation: any[] = [];
  fromDate: any;
  toDate: any;
  sortedObject: any;
  tableheaderobject: any;
  featureData: any[] = [];
  nbrPage: number;
  fetchMore: boolean;
  searchCriteria: any;
  numindex: number;
  templateId: string;
  links: any[];
  arrayToSend: any[];
  geometryLength: number;
  fileType: string;
  nextLink: string;
  prevLink: string;
  pageFeatures: number;
  totalFeatures: number;
  featuresSelected: any[];
  featuresForDeletion: any[];
  filteredFeatures: any[];
  projectionObject: any;
  reportsTemplates: any[];
  selectedReportsTemplates: any[];
  operators: any[];
  reportType: string;
  doingReport: boolean;
  pendingStatus: number;
  editingFeature: any;
  editingFeatureAttribute: any;
  booleanAttributeValue: boolean;
  metadata: object;
  more: boolean;
  resultsLength = 0;
  initValue = 0;
  uri: string;
  prevDisabled: boolean;
  nextDisabled: boolean;
  pages = 0;
  allPages: string[];
  selectedPage: string;
  activePage: number;
  templateChanged: string;

  message = '';
  showMessage = false;
  constructor(
    private dialog: MatDialog,
    private titlecasePipe: TitleCasePipe,
    private featureService: FeatureService,
    public store: StoreService  ) { }

  ngOnInit() {
    if(this.store.archived) {
      // return;
    }

    // this.store.selectedFoundTemplate.subscribe(t=> {
    //   this.template = t;
    //   this.columnsValues = [];
    
    //   this.initElements(this.template);
    //   this.loadFeatures();
    // });
    
  }

  ngAfterContentInit() {
    this.columnsValues = [];
    if(this.store.archived) {
      // return;
    }

    this.initElements(this.template);
    this.store.features = null;
    this.loadFeatures();
    
    this.store.selectedFoundTemplate.subscribe(t=> {
      this.store.features = null;
      this.template = t;
      this.columnsValues = [];
    
      this.initElements(this.template);
      this.loadFeatures();
    });
  }

  ngAfterViewInit() {

  }

  resetSelectedFeature() {
    this.store.features = null;
    this.selection.clear();
    this.store.copiedFeatures = [];
  }
  
  deleteSelectedFeatures() {
    if((this.store.copiedFeatures && this.store.copiedFeatures.length > 0) ||
      (this.store.features && this.store.features.length > 0)) {
        const size = this.store.features.length;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message: 'These records will be deleted on the server.',
            title: 'Delete ' + size + ' records ?'
          }
        });
        dialogRef.afterClosed().subscribe(result  => {
           
          if (result) {
            const token = getToken();
            this.store.showLoading();
            const ids = [];
            const features = this.store.copiedFeatures && this.store.copiedFeatures.length > 0 ? this.store.copiedFeatures : this.store.features;
            for (const feature of features) {
              ids.push(feature.id);
            }
            const project = this.store.destinationProject ? this.store.destinationProject : getProject();
            const template = this.store.destinationTemplate ? this.store.destinationTemplate : getTemplate();
            this.featureService.deleteFeatures(ids, template, project, token).subscribe(
              res => {
                this.store.features = null;
                this.showSuccessMessage(size + ' record(s) deleted');
                this.selection.clear();
                this.instances = this.instances.filter(f => ids.indexOf(f.id) < 0);
                this.updateDataFormServer(this.instances);
                this.store.hideLoading();
              },
              errmes => {
                this.store.hideLoading();
                this.showErrorMessage('Error while deleting the features' + errmes);
              }
            );
          }
        });
    } else { 
      this.showErrorMessage('No records selected. Please choose records to delete');
    }
  }

  showExportDialog() {
    const dialogRef = this.dialog.open(ExportDataCriteriaDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: {
        template: getTemplate()
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        //this.submitSeletedItems2(result.advanced_request);
      }
    });
  }

  loadFeatures() {
    try{
      this.store.showLoading();
      
      this.store.listFoundFeatures = this.store.foundProjects['features'].filter(f => f.template_id === this.template.id);
      
      this.instances = this.store.foundProjects['features'].filter(f => f.template_id === this.template.id);
      this.updateDataFormServer(this.instances);
    } catch (e){
      this.store.hideLoading();
    } 
    
  }


  initElements(template) {
    if (template) {
      this.templateName = template.name;
    }

    this.nbrPage = 100;
    this.initValue = 0;
    this.fetchMore = false;
    this.attHeaders = [];
    this.featureData = [];
    this.instances = [];
    this.allCoordinates = [];
    this.allCoordinatesFields = [];
    this.allCoordinatesFieldsElemment = [];
    this.allArrayAttributeSet = [];
    this.allArrayAttributeSetElemment = [];
    this.searchCriteria = {};
    this.numindex = 0;
    this.resultsLength = 100;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.doingReport = false;
    this.allPages = [];
    this.pages = 0;
    this.activePage = 0;
    this.selectedPage = '';
  }


  updateDataFormServer(features) {
    this.featuresSelected = [];
    this.allPages = [];
    const template = this.template;
    
    if (template !== null && template !== undefined) {
      this.template = template;
      this.featureSchema = template.feature;
      this.saveTemplateFieldsNameAndType(template);
    }

    this.featureData = [];
    this.resultsLength = features.length;
   
    const headers = this.createHeaderArrayWithinTemplate(template);
   // console.log('Headers: ', headers);
    this.attHeaders = headers;
    if (this.instances.length > 0) {
      const data = this.createFeatureData();
     
      let listGeometryHeader = null;
      // const highElts = this.getHighestAttributeSize() > 0 ? this.getHighestAttributeSize() : 0;
      if (this.instances[0]['geometry'] !== null && this.instances[0]['geometry'] !== undefined) {
        listGeometryHeader = Object.keys(this.instances[0]['geometry']);
      }
      // const listDataHeader = Object.keys(this.instances[highElts]['attributes']);
      // const headers = this.createHeaderArray(this.instances[highElts]['attributes'], '');
      
      // console.log(headers);
      if (this.columnsValues.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push(_SELECT);
        const index = this.columnsValues.indexOf('Geometry Type');
        if (listGeometryHeader !== null && listGeometryHeader !== undefined && index !== -1) {
          this.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const val of this.columnsValues) {
          if (val !== 'Geometry Type') {
            this.displayedColumns.push(val);
          }
        }
      } else if (headers !== null && headers !== undefined && headers.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push(_SELECT);
        if (listGeometryHeader !== null && listGeometryHeader !== undefined) {
          this.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const h of headers) {
          this.displayedColumns.push(h);
        }
      }
      this.initValue = 1;
      this.pageFeatures =  this.instances.length;
      this.totalFeatures =  this.instances.length;
      let $items = $('.bleuPage');
      const classHighlight = 'selectedPage';
      $items.removeClass(classHighlight);
      this.selectedPage = this.activePage + ' ';
      // const oldData = this.dataSource.data;
      // console.log('Before apply data: ', data);
      this.dataSource.data = data;
      this.foundfeaturetable.renderRows();
    } else {
      this.initValue = 0;
      this.pageFeatures = 0;
      this.totalFeatures = 0;
      
      this.displayedColumns = [];
      this.dataSource.data = [];
      this.foundfeaturetable.renderRows();
    }
    this.store.hideLoading();
  }

  loadPrevFeatures() {
    if (!this.prevLink || this.prevLink === '') {
      return;
    }

    for (let i = 0; i < 4; i++) {
      this.prevLink = this.prevLink.substring(1);
    }

    this.loadFeatures();
  }

  loadNextFeatures() {
    if (this.nextLink === undefined || this.nextLink === null || this.nextLink === '') {
      return;
    }

    for (let i = 0; i < 4; i++) {
      this.nextLink = this.nextLink.substring(1);
    }

    this.loadFeatures();
  }

  addFeatureRecord(): void {
    persistLastMenu('add_record');
    this.store.formMode = RECORD_DATA_MODE;
    this.store.showRecordTemplateData();
  }


  initializeFeature(template: Template): any {
    const fields = template.form.fields;
    const attributeSet = {};
    for (const field of fields) {
      if (field._class === 'fieldset' || field._class === 'arrayfieldset' || field._class === 'fieldsetarray' ) {
        if (field._class === 'fieldset') {
          attributeSet[field.name] = this.initializeAttributeSet(field as FieldSet);
        } else {
          attributeSet[field.name] = [];
        }
      } else {
        const val = field.default_value ? field.default_value : null;
        attributeSet[field.name] = val;
      }
    }
    return attributeSet;
  }

  initializeAttributeSet(fieldset: FieldSet): any {
    const attSet = {};
    for (const field of fieldset.fields) {
      if (field._class === 'fieldset' || field._class === 'arrayfieldset' || field._class === 'fieldsetarray' ) {
        if (field._class === 'fieldset') {
          attSet[field.name] = this.initializeAttributeSet(field as FieldSet);
        } else {
          attSet[field.name] = [];
        }
      } else {
        const val = field.default_value ? field.default_value : null;
        attSet[field.name] = val;
      }
    }
    return attSet;
  }



  showMessageDialog(msg) {
    this.message = msg;
    this.store.showLoading();
    setTimeout(() => {
      this.store.hideLoading();
    }, (10000));
  }


  copySelectedFeatures() {
    if(this.selection && this.selection.selected && this.selection.selected.length > 0) {
      this.store.copiedFeatures = this.selection.selected;
      this.showSuccessMessage('The selected features are copied to the clipboard');
    } else {
      this.showErrorMessage('No feature is selected, please select features to copy');
    }
  }

  createFeatureData(): any[] {
    const data = [];
    this.instances.forEach(inst => {
      // console.log('Feature instance', inst);
      let dataItem = this.createDataArray(inst['attributes'], '');
      dataItem['id'] = inst['id'];
      if (inst['geometry'] !== null && inst['geometry'] !== undefined) {
        const geomDataItem = this.createGeometryDataArray(inst['geometry']);
        if (geomDataItem !== null && geomDataItem !== undefined) {
          dataItem = Object.assign(geomDataItem, dataItem);
        }
      }
      // console.log(dataItem);
      data.push(dataItem);
    });
    // console.log('data: ', data);
    return data;
  }

  createHeaderArray(attributeset: AttributeSet, parent = ''): string[] {
    let result = [];
    const keys = Object.keys(attributeset);
    for (const key of keys) {
      const absAttribute = attributeset[key];
      const path = parent === '' ? key : `${parent}.${key}`;
      if (absAttribute instanceof Object) {
        if (absAttribute instanceof Array) {
          // TODO: Implement Attributeset array here
          result.push(path);
        } else {
          result = result.concat(this.createHeaderArray(attributeset[key], path));
        }
      } else {
        result.push(path);
      }
    }

    return result;
  }

  createHeaderArrayWithinTemplate(template: Template): string[] {
    let result = [];
    const schema = template.feature;
    // console.log('schema', schema);
    const attributes = schema.attributes;
    //console.log(attributes);
    for (const attribute of attributes) {
      const attname = attribute['name'];
      if (attribute['_class'] === 'attribute') {
        result.push(attname);
      } else if (attribute['_class'] === 'arrayattributeset') {
        result.push(attname);
      } else {
        result = result.concat(this.createHeaderWithinAttributeSet(attribute, attname));
      }
    }
    return result;
  }

  createHeaderWithinAttributeSet(attributeset, parent): string[] {
    let obj = [];
    let attsetName = attributeset['name'];
    if (attsetName === null || attsetName === undefined || attsetName === '') {
      attsetName = '';
    }
    // const parentname = (parent !== null && parent !== undefined && parent !== '')
    //         ? `${parent}.${attsetName}`
    //         : attsetName;

    for (const attribute of attributeset.attributes) {
      if (attribute._class === 'attribute') {
        const attname = `${parent}.${attribute.name}`;
        obj.push(attname);
      } else if (attribute._class === 'arrayattributeset') {
        const name = `${parent}.${attribute.name}`;
        obj.push(name);
      } else {
        const name = `${parent}.${attribute.name}`;
        obj = obj.concat(this.createHeaderWithinAttributeSet(attribute, name));
      }
    }
    return obj;
  }


  createGeometryHeaderArray(): any {
    const result = [this.GEOMETRY_TYPE];

    return result;
  }

  createGeometryDataArray(gemometry: any): any {
    const result = {
      Geometry_Type: gemometry['type'],
      coordinates: gemometry[COORDINATES]
    };

    return result;
  }

  createDataArray(attributeset: any, parent = ''): any {
    let result = {};
    const keys = Object.keys(attributeset);
    for (const key of keys) {
      const absAttribute = attributeset[key];
      const path = parent === '' ? key : `${parent}.${key}`;
      if (absAttribute instanceof Object) {
        if (absAttribute instanceof Array) {
          const itemIndex = this.attHeaders.findIndex(header => header === path, 0);
          if(itemIndex >= 0) {
            result[path] = absAttribute;
          }
        } else {
          const tempResult = this.createDataArray(attributeset[key], path);
          result = Object.assign(result, tempResult);
        }
      } else {
        const itemIndex = this.attHeaders.findIndex(header => header === path, 0);
        if(itemIndex >= 0) {
          result[path] = attributeset[key];
        }
        
      }
    }
    

    return result;
  }

  // filling empty spaces in order to fit the highest feature.
  fillMissingElementValue(featureInstance, dataArray) {
    const attributes = featureInstance.attributes;
    for (let i = 0; i < this.schemaHeader.length; i++) {
      const name = this.schemaHeader[i];
      if (this.checkGeometryObject(name)) {
        continue;
      }
      const tempTab = this.readToken(name, TokenType.PointToken);
      const arrayObj = this.readToken(name, TokenType.StartArrayToken);
      if (tempTab === null) {
        if (arrayObj === null) {
          const value = attributes[name];
          if (value === null || value === undefined) {
            dataArray.splice(i, 0, '');
          }
        } else {
          dataArray.splice(i, 0, '');
        }
      } else {
        const size = tempTab.length;
        if (arrayObj === null) {
          if (size === 1) {
            const element = attributes[name];
            if (element === null || element === undefined) {
              dataArray.splice(i, 0, '');
            }
          } else {
            let parentObject = attributes[tempTab[0]];
            for (let j = 1; j < size; j++) {
              const curObject = parentObject[tempTab[j]];
              if (j === size - 1) {
                if (curObject === null || curObject === undefined) {
                  dataArray.splice(i, 0, '');
                }
              } else {
                parentObject = curObject;
              }
            }
          }
        }
      }
    }
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  isMorePages(): boolean {
    return this.pages > 1;
  }

  openPage(item) {
    let linkValue = '';
    if (this.nextLink !== null && this.nextLink !== undefined) {
      linkValue = this.nextLink;
    } else if (this.prevLink !== null && this.prevLink !== undefined) {
      linkValue = this.prevLink;
    }

    if (linkValue !== '') {
      for (let i = 0; i < 4; i++) {
        linkValue = linkValue.substring(1);
      }

      const links = linkValue.split('?');
      const values = links[1].split('&');
      const vals = values[0].split('=');
      const uri = links[0] + '?' + vals[0] + '=' + item.trim() + '&' + values[1];

      this.loadFeatures();
    }

  }

  getValue(value: string) {
    $('.has-clear input[type="text"]').on('input propertychange', function() {
      const $this = $(this);
      const visible = Boolean($this.val());
      $this.siblings('.form-control-clear').toggleClass('hidden', !visible);
    }).trigger('propertychange');

    $('.form-control-clear').click(function() {
      $(this).siblings('input[type="text"]').val('')
        .trigger('propertychange').focus();
    });
  }

  applyFilter() {
    const value = this.searchValue.trim().toLowerCase();

    if (value && value !== '') {
      //this.searchText(value);
    } else {
      //this.updateTemplateFeature(this.store.template.getValue());
    }
  }

  refreshData() {
    this.searchValue = '';
    this.columnsValues = [];
   // this.updateTemplateFeature(this.store.template.getValue());
  }

  displayColumnItem(column) {
    const parts = column.split('.');
    if (parts && parts.length > 1) {
       let columns = '';
       for (const part of parts) {
         columns = columns.length > 0 ? columns + '\n' + part : part;
       }
    }
    return column;
  }

  displayItemValue(column, value) {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    const type = fieldsAndType[column];
    if (type === COORDINATES) {
      return COORDINATES;
    } else if (type === ATTRIBUTESETARRAY) {
      return this.displayAttSetArrayValue(column);
    } else {
      const data = this.checkFiles(value);
      if (this.checkIfIsUrl(data)) {
        return '';
      } else if (type === 'date' && this.checkIfDate(data, name)) {
        return data.split('T')[0];
      }
      return data;
    }
    return '';
  }

  displayAttSetArrayValue(column) {
    const index = column.indexOf('.');
    if (index > 0) {
      const values = column.split('.');
      return values[values.length - 1];
    }
    return column;
  }

  checkIfColumnIsGeometry(column) {
    return column === this.GEOMETRY_TYPE;
  }

  checkIfCoordinatesField(column) {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    // console.log(fieldsAndType);
    // console.log(column);
    const type = fieldsAndType[column];
    // console.log(type);
    return type === COORDINATES;
  }

  checkIfAttributesetArray(column) {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    const type = fieldsAndType[column];
    return type === ATTRIBUTESETARRAY;
  }

  openMediaDialogDialog(column, element) {
    const files = this.checkFiles(element[column]);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '800px';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      files
    };

    const dialogRef = this.dialog.open(MediaShowingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
      }
    });
  }

  selectFeature(event, element): void {
     
    this.selection.toggle(element);
    const feature = this.instances.find(f => f.id === element.id);
    if (event.checked) {
      if (this.store.features) {
        this.store.features.push(feature);
      } else {
        this.store.features = [feature];
      }
    } else {
      if (this.store.features) {
        this.store.features = this.store.features.filter(f => f.id !== element.id);
      }
    }
  }

  openMediaShowingDialog(event, link) {
    event.preventDefault();
    const medialink = this.checkFile(link);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.data = {
      medialink
    };

    const dialogRef = this.dialog.open(MediaShowingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // if (result.event === 'Add') {
      // }
    });
    event.stopPropagation();
  }

  openEditFeatureValue(column, element) {
    // console.log('Open edit dialog');
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Please, this operation is only available for active  ${projectClass}`);
      return;
    } 
    const dialogConfig = new MatDialogConfig();
    const columnValues = column.split('.');
    let newColumn = '';
    if(columnValues.length > 0)
      newColumn = columnValues[columnValues.length - 1];
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    let type = '';
    if (id !== null && id !== undefined) {
      feature = this.instances.filter(f => f.id === id)[0];
      featureIndex = this.instances.indexOf(feature);
    }

    const attributes = this.template.feature.attributes;

    type = this.getAttributeType(attributes, newColumn);
   // console.log('Feature: ' + feature);
    //console.log('Type: ' + type);
    // dialogConfig.disableClose = true;
    const value = element[column];
    //console.log('Column name: ' + column);
    //console.log('Value: ' + value);
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      column,
      feature,
      element,
      value,
      type
    };
    if(type === 'file' || type === undefined || type === '')
      return;

    const dialogRef = this.dialog.open(EditFeatureValueComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        if (result.event === 'updated') {
          const updatedFeature = result.feature;
          const project = getProject();
          const token = getToken();
          this.store.showLoading();

          this.template.feature.geometry_type = updatedFeature.geometry ?
          updatedFeature.geometry.type : null;
          const attributes = this.template.feature.attributes as Attribute[];
          const attrKeys = Object.keys(updatedFeature.attributes);
          let attrNames = [];

          attributes.forEach(attribute => {
            attrNames.push(attribute.name);
            if (!this.valueExistInArarray(attribute.name, attrKeys)) {
              const tpe = attribute.type;
              let val = null;
              if (tpe === 'file') { val = []; }
              updatedFeature.attributes[attribute.name] = val;
            }

          });

          attrKeys.forEach( val => {
            if (!this.valueExistInArarray(val, attrNames)) {
              delete updatedFeature.attributes[val];
            }
          });
          this.featureService.updateFeature(updatedFeature, this.template, project, token)
          .subscribe(
            res => {
              const tempFeat = (feat) => feat.id === res.id;
              if  (featureIndex >= 0) {
                this.instances[featureIndex] = res;
                const data = this.createFeatureData();
                this.dataSource.data = data;
                this.foundfeaturetable.renderRows();
              }
              this.store.hideLoading();
            },
            err => {
              this.showErrorMessage(err);
              this.store.hideLoading();
            });
        }
      }
    });
  }

  isSelectFeatureHeader(column) {
    return column === '_select';
  }

  isSelectFeatureData(column, element) {
    return column === '_select';
  }

  openAttributesetArrayDialog(column, element) {
    const attributesetarray = element[column];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '700px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      attributesetarray: element[column],
      path: column
    };

    const dialogRef = this.dialog.open(AttributesetarrayDialogComponent, dialogConfig);
  }

  displayCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
    }
    this.openCoordinateDialog(geomType, coordinates, gpscoord);
  }

  displayGPSCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
    }
    this.openCoordinateDialog(null, geomType, gpscoord);
  }

  openCoordinateDialog(geometryType, coordinates, gpscoord = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      geometry_type: geometryType,
      gpscoord,
      coordinates
    };

    const dialogRef = this.dialog.open(GeomTypeCoordinatesDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  getAttributeType(attributes, column): string {
    let type = '';

    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];

        if(attribute._class === 'attributeset')
          type = this.getAttributeType(attribute.attributes, column);

        if(attribute.name === column){
          type = attribute.type;
          return type;
        }
      }
    }
    return type;
  }

  valueExistInArarray(value, array): boolean{
    if(array.length <= 0){
      return false;
    }

    for(let i = 0; i < array.length; i++){
      if(array[i] === value)
        return true;
    }

    return false;
  }

  isDataIsFile(column, data): boolean {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    if (fieldsAndType[column] === ATTRIBUTESETARRAY) {
      return false;
    }
    if (fieldsAndType[column] === COORDINATES) {
      return false;
    }
    const temp = this.checkFiles(data);
    return this.checkIfIsUrl(temp);
  }

  checkIfIsUrl(data) {
    const pat = /^https?:\/\//i;
    let values = [];

    if (data === undefined || data === null) {
      return false;
    }
    let str;
    if ((data instanceof Object) && (data instanceof Array) && data.length > 0) {
      str = data[0];
    } else {
      str = data;
    }
    if (isNaN(str)) {
      if (typeof(str) === 'string') {
        values = str.split('/');
      }
    }
    const currentDomain = getCurrentDomain();
    if (pat.test(str) && values.includes(currentDomain) && values.includes('v1') && values.includes('files')) {
      return true;
    }
    return false;
  }

  addColumn() {
    const randomColumn = Math.floor(Math.random() * this.displayedColumns.length);
    this.columnsToDisplay.push(this.displayedColumns[randomColumn]);
  }

  createFeatureHeader() {
    const feature = this.instances[0];
    const listHeader = [];
  }

  createGeometryHeader(geometry, header) {
    if (geometry === null) {

    } else {
      switch (geometry.type) {
        case 'Point':
          break;

        case 'Linestring':
          break;

        case 'Polygon':
          break;
      }
      header.push('Coordinates');
    }
  }

   // constructing the header of the table using the attributes of the template.
   buildHeader(geometrySize) {
    this.featureSchema = this.template.feature;
    if (this.featureSchema !== null || this.featureSchema !== undefined) {
      const geoType = this.featureSchema.geometry_type;

      const geoHeader = this.buildGeometryHeader(geoType, geometrySize);

      const attributesHeader = this.buildAttributesHeader(this.featureSchema.attributes);

      if (geoHeader.length > 0 && attributesHeader.length > 0 ) {
        const header = geoHeader.concat(attributesHeader);
        return header;
      } else {
        return attributesHeader;
      }
      return null;
    }
    return null;
  }

  updateAttHeaders(index) {
    this.parseFeatureInstance(index);
  }

  /**
   * getting the coordinates length of geometry features.
   */
  getGeometryTypeElementSize(features) {
    const size = features.length;
    let geometrySize = 0;
    for (let i = 0; i < size; i++) {
        let coordinates = null;
        const geometry = features[i].geometry;
        if (geometry !== null && geometry !== undefined) {
          switch (geometry.type) {
            case 'Point':
              coordinates = geometry.coordinates;
              break;

            case 'LineString':
              coordinates = geometry.coordinates;
              break;

            case 'Polygon':
              coordinates = geometry.coordinates[0];
              break;

            case 'MultiPoint':

              break;

            case 'MultiLineString':

              break;

            case 'MultiPolygon':

              break;
          }
        }

        if (coordinates !== null && coordinates !== undefined) {
          if (coordinates instanceof Array) {
            if (coordinates.length > geometrySize) {
              geometrySize = coordinates.length;
              const element = {
                name : 'coordinates',
                size : coordinates.length
              };
              if (this.arrayHeadersAndSize.length <= 0) {
                this.arrayHeadersAndSize.push(element);
              } else {
                let found = false;
                for (const item of this.arrayHeadersAndSize) {
                  if (item.name === 'coordinates') {
                    if (item.size < element.size) {
                      item.size = element.size;
                      found = true;
                      break;
                    }
                  }
                }
                if (!found) {
                  this.arrayHeadersAndSize.push(element);
                }
              }
            }
          }
        }
    }
    return geometrySize;
  }

  /**
   *  building the header of the geometry part of templates based on the highest length of features.
   */
  buildGeometryHeader(geometryType, size) {
    let geomTypeheaderStr = this.buildGeometryType(geometryType);
    const geometryHeader = [];
    switch (geometryType) {
      case 'Point':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildPointHeader();
        // geometryHeader.push(geometryType);
        break;

      case 'LineString':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildGeometryObjHeader(size);
        // geometryHeader.push(geometryType);
        break;

      case 'Polygon':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildGeometryObjHeader(size);
        // geometryHeader.push(geometryType);
        break;

      case 'MultiPoint':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildMultiPointHeader();
        // geometryHeader.push(geometryType);
        break;

      case 'MultiLineString':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildMultiLineStringHeader();
        // geometryHeader.push(geometryType);
        break;

      case 'MultiPolygon':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildMultiPolygonHeader();
        // geometryHeader.push(geometryType);
        break;
    }
    this.geomHeader = geomTypeheaderStr.split('');

    if (geometryType !== '' && geometryType !== null && geometryType !== undefined) {
      geometryHeader.push('Geometry Type');
    }

    return geometryHeader;
  }

  buildGeometryType(type) {
    return 'geometry.type';
  }

  buildPointHeader() {
    return 'x y z Accuracy';
  }

  buildLineStringHeader() {
    return 'p1.x p1.y p2.x p2.y';
  }

  buildGeometryObjHeader(size) {
    let polygonHeader = '';
    if (size > 0) {
      for (let i = 1; i <= size; i++) {
        if (polygonHeader === '') {
          polygonHeader = polygonHeader + 'p' + i + '.x p' + i + '.y p' + i + '.z p' + i + '.Accuracy';
        } else {
          polygonHeader = polygonHeader + ' p' + i + '.x p' + i + '.y p' + i + '.z p' + i + '.Accuracy';
        }

      }
      return polygonHeader;
    }
    return null;
  }

  buildMultiPointHeader() {
    return null;
  }

  buildMultiLineStringHeader() {
    return null;
  }

  buildMultiPolygonHeader() {
    return null;
  }

  // building the attributes header
  buildAttributesHeader(attribs, prefix = '') {
    const tempAttributesHeaderArray = [];
    this.initHeaders = [];
    this.buildAttributesetHeader(attribs, tempAttributesHeaderArray);

    for (const item of tempAttributesHeaderArray) {
        this.initHeaders.push(item);
    }
    return tempAttributesHeaderArray;
  }

  // building the attributeset with nested attributes header
  buildAttributesetHeader(attributes, headerArray, prefix = '') {
    const prefixEmpty = prefix === '' || prefix === null || prefix === undefined;

    const size = attributes.length;

    for (let i = 0; i < size; i++) {
      const attribute = attributes[i];
      if (attribute._class === 'attributeset') {
        prefix = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        this.buildAttributesetHeader(attribute.attributes, headerArray, prefix);
      } else if (attribute._class === 'arrayattributeset') {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      } else {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      }
    }
  }

  getHeaderInSplitMode(h): string {
    let parts = h.split('.');
    if (parts && parts.length > 0) {
      let tempHeader = '';
      for(const part of parts) {
        tempHeader = tempHeader === '' ? part : `${tempHeader} <br /> ${part}`;
      }
      return tempHeader;
    }
    return h;
  }

  buildStaticHeader() {
    const staticHeader = 'createdAt, updatedAt, deletedAt, createdBy, updatedBy, deletedBy';
    return staticHeader;
  }

  // building the values of features to display in the table.
  buildFeatureData(featureInstance) {
    const geometry = featureInstance.geometry;
    const attributes = featureInstance.attributes;
    // this.saveTemplateFieldsNameAndValue(featureInstance);
    let instanceDataArray ;
    if (this.buildGeometryData(geometry).length > 0) {
        instanceDataArray = this.buildGeometryData(geometry).concat(this.buildAttributesData(attributes));
    } else {
      instanceDataArray = this.buildAttributesData(attributes);
    }

    if (instanceDataArray.length < this.attHeaders.length) {
      // console.log('Not all value rendered');
      // this.fillMissingElementValue(featureInstance instanceDataArray);
    }

    return instanceDataArray;
  }

  // send the request for fetching features from the server.
  fetchFeatures() {
    this.linksArray = [];
    if (this.nbrPage === 0) {
      this.nbrPage = 100;
    }

    if (this.template.id === undefined || this.template.id === null || this.template.id === '') {
      return;
    }

  }

  saveTemplateFieldsNameAndValue(featureInstance) {
    const geometry = featureInstance.geometry;
    const attributes = featureInstance.attributes;
    const parent = '';
    const fieldsAndValues = [];

    for (const attribute of attributes) {
     // const attribute = attributes[i];
      this.saveAttributesetNamesAndValue(attribute, parent, fieldsAndValues);
    }

    saveTemplateFieldsNameAndValues(fieldsAndValues);
  }

  saveAttributesetNamesAndValue(attributeset, parent, fieldsAndValues) {
    const keys = Object.keys(attributeset);
    for (const attName of keys) {
      // let attName = keys[i];
      const obj = attributeset[attName];
      if (typeof(obj) === 'object') {
        const parentname = (parent !== null && parent !== undefined && parent !== '')
                              ? parent + '.' + attName
                              : attName;
        // this.saveAttributesetNamesAndType(obj, parentname, fieldsAndValues);
      } else {
        // this.saveAttributesetNamesAndType(obj, parent, fieldsAndValues);
      }
    }
  }

  saveAttributeNameAndValue(attributeName, attributeValue, parent, fieldsAndValues) {
    const name = (parent !== null && parent !== undefined && parent !== '')
                              ? parent + '.' + attributeName
                              : attributeName;
    const elt = {};
    elt[attributeName] = attributeValue;
    fieldsAndValues.push(elt);
  }

  saveTemplateFieldsNameAndType(template) {
    const feature = template.feature;
    const attributes = feature.attributes;
    let parent = '';
    let fieldsAndType = {};
    let set = {};

    for (const attribute of attributes) {
      // let attribute = attributes[i];
      if (attribute._class === 'attributeset' ) {
        const temparent = (parent !== null && parent !== undefined && parent !== '')
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        fieldsAndType = Object.assign(fieldsAndType, this.saveAttributesetNamesAndType(attribute, temparent));
      } else if (attribute._class === 'arrayattributeset') {
        const name = (parent !== null && parent !== undefined && parent !== '')
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        fieldsAndType[name] = 'arrayattributeset';
      } else {
        const attname = (parent !== null && parent !== undefined && parent !== '')
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        const type = attribute['type'];
        fieldsAndType[attname] = type;
      }
    }
    this.schemaHeader = Object.keys(fieldsAndType);
    saveTemplateFieldsNameAndTypes(fieldsAndType);
  }

  saveAttributesetNamesAndType(attributeset, parent) {
    let obj = {};
    let attsetName = attributeset['name'];
    if (attsetName === null || attsetName === undefined || attsetName === '') {
      attsetName = '';
    }
    const parentname = (parent !== null && parent !== undefined && parent !== '')
            ? `${parent}.${attsetName}`
            : attsetName;

    for (const attribute of attributeset.attributes) {

      if (attribute._class === 'attribute') {
        const attname = `${parent}.${attribute.name}`;
        obj[attname] = attribute['type'];
      } else if (attribute._class === 'arrayattributeset') {
        const name = `${parent}.${attribute.name}`;
        obj[name] = 'arrayattributeset';
      } else {
        obj = Object.assign(obj, this.saveAttributesetNamesAndType(attribute, parentname));
      }
    }
    return obj;
  }

  saveAttributeNameAndType(attribute, parent = '') {
    const name = (parent !== null && parent !== undefined && parent !== '')
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
    const obj = { };
    obj[name] = attribute.type;
    return obj;
  }

  readToken(path, token) {
    const tempValue = path.split(token);
    if (tempValue.length > 1) {
      return tempValue;
    }
    return null;
  }

  // check if the template is geometry or no geometry.
  checkGeometryObject(value) {
    const size = this.geomHeader.length;
    for (let i = 0; i < size; i++) {
      if (this.geomHeader[i] === value) {
        return true;
      }
    }
    return false;
  }

  // building coordinates elements from features.
  buildGeometryData(geometry) {
    const tempGeomArray = [];
    const newCoordinates = [];
    const coordValue = [];

    if (geometry !== null && geometry !== undefined) {
      this.buildGeometryObjectData(geometry, tempGeomArray, '');
      for (let i = 1; i < tempGeomArray.length; i += 4) {
        const coordinate = {
          x: tempGeomArray[i],
          y: tempGeomArray[i + 1],
          z: tempGeomArray[i + 2],
          Accuracy: tempGeomArray[i + 3]
        };
        newCoordinates.push(coordinate);
      }
      this.allCoordinates.push(newCoordinates);
    } else {
      // tempGeomArray.push('No geometry');
      // coordValue.push('No geometry');
    }
    return coordValue;
  }

  // building the features' values of geometry types to display in the table.
  buildGeometryObjectData(geometry, dataArray, path) {
    const type = geometry.type;

    const propertyNames = Object.getOwnPropertyNames(geometry);
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      let attribute = geometry[name];
      let currentPath = path === '' ? '' : path + '.';
      if (name === 'coordinates' && type === 'LineString') {
        this.buildPolygonData(attribute, dataArray);
      } else if (name === 'coordinates' && type === 'Polygon') {
        attribute = attribute[0];
        this.buildPolygonData(attribute, dataArray);
      } else {
        currentPath = currentPath + name;
        if (attribute === null || attribute === undefined) {
          if (this.findObjectInStoredArraySizes(currentPath) > 0) {
            this.buildArrayData(attribute, dataArray, currentPath);
          }
        } else {
          if (attribute instanceof Object) {
            if (attribute instanceof Array) {
              this.buildArrayData(attribute, dataArray, currentPath);
            } else {
              this.buildObjectData(attribute, dataArray, currentPath);
            }
          } else {
            dataArray.push(geometry[name]);
          }
        }
      }
    }
  }

  buildPolygonData(coordinates, dataArray) {
    let highestLength = 0;
    for (const item of this.arrayHeadersAndSize) {
      if (item.name === 'coordinates') {
        highestLength = item.size;
        break;
      }
    }
    let size = 0;
    if (coordinates !== null && coordinates !== undefined) {
      size = coordinates.length;
    }

    for (let i = 0; i < size; i++) {
      const data = coordinates[i];

      if (data instanceof Object) {
        if (data instanceof Array) {
          for (const item of data) {
            dataArray.push(item);
          }
        }
      } else {
        dataArray.push(data);
      }
    }
    if (highestLength > size) {
      const remainSize = highestLength - size;
      for (let l = 0; l < remainSize; l++) {
        dataArray.push('');
        dataArray.push('');
      }
    }
  }

  // building attributes values putting values in array.
  buildAttributesData(attributes) {
    const tempAttributesArray = [];
    this.featureAttrib = [];
    this.missIndexes = [];
    this.buildObjectData(attributes, tempAttributesArray, '', null);
    return tempAttributesArray;
  }

  // parsing features to get the highest feature in the features.
  parseFeatureInstance(highestAttributeIndex) {
    if (highestAttributeIndex >= 0) {
      const featureInstance = this.instances[highestAttributeIndex];
      this.parseAttributesObject(featureInstance.attributes, '');
    }
  }

  /**
   * getting the values of nested attributes.
   */
  parseAttributesObject(attributes, path) {
    const propertyNames = Object.getOwnPropertyNames(attributes);
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (!this.checkArrayAttributeSet(currentPath)) {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (!this.checkCoordinateField(currentPath)) {
              this.parseArrayObject(attribute, currentPath);
            }
          } else {
            this.parseAttributesObject(attribute, currentPath);
          }
        }
      }
    }
  }

  parseArrayObject(attribute, path) {
    const size = attribute.length;
    const tempSize = this.findArrayMaxSize(path, size);
    if (this.findObjectInStoredArraySizes(path) <= 0) {
      const element =  {
        name: path,
        size: tempSize
      };
      this.arrayHeadersAndSize.push(element);
    }

    this.replaceHeaderInHeaders(path, tempSize);
    for (let i = 0; i < size; i++) {
      const data = attribute[i];
      const arrPath = path + '[' + i + ']';
      if (data instanceof Object) {
        if (data instanceof Array) {
          this.parseArrayObject(data, arrPath);
        } else {
          this.parseAttributesObject(data, arrPath);
        }
      } else {

      }
    }
  }

  // If the template contains file types find the longest array in order to fill others with empty space.
  findArrayMaxSize(path, size) {
    let tempSize = size;
    for (const instance of this.instances) {
      const attributes = instance.attributes;
      const findSize = this.findArrayMaxSizeInObject(attributes, path, '', tempSize);
      if (findSize > 0) {
        tempSize = findSize;
      }
    }
    return tempSize;
  }

  checkCoordinateField(path) {
    const fieldAndTypes = getTemplateFieldsNameAndTypes();
    const type = fieldAndTypes[path];
    if (type === 'coordinates') {
      return true;
    }

    return false;
  }

  checkArrayAttributeSet(path) {
    const fieldAndTypes = getTemplateFieldsNameAndTypes();
    const type = fieldAndTypes[path];
    if (type === 'arrayattributeset') {
      return true;
    }

    return false;
  }

  findArrayMaxSizeInObject(attribute, searchPath, path, size) {
    const propertyNames = Object.getOwnPropertyNames(attribute);

    for (const name of propertyNames) {
      // const name = propertyNames[j];
      const tempAttribute = attribute[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;

      if (tempAttribute instanceof Object) {
        if (tempAttribute instanceof Array) {
          if (searchPath === currentPath) {
            if (tempAttribute.length > size) {
              return tempAttribute.length;
            }
          }
        } else {
          this.findArrayMaxSizeInObject(tempAttribute, searchPath, currentPath, size);
        }
      }
    }
    return 0;
  }

  // adding [] to array's type in the header of the table.
  replaceHeaderInHeaders(element, size) {
    if (size === 0) {
      return;
    }

    for (let i = 0; i < this.attHeaders.length; i++) {
      if (this.attHeaders[i] === element) {
        let elements = '';
        for (let j = 0; j < size; j++) {
          elements = elements === '' ? '' : elements + ' ';
          elements = elements + element + '[' + j + ']';
        }
        const splitElements = elements.split('');
        for (let k = 0; k < splitElements.length; k++) {
          if (k === 0) {

            this.attHeaders.splice(i + k, 1, splitElements[k]);
          } else {
            this.attHeaders.splice(i + k, 0, splitElements[k]);
          }
        }
        return;
      }
    }
  }

  // buiding values of nested objects and arrays
  buildObjectData(attributes, dataArray, path, oldValues = null) {
    let propertyNames = [];
    this.initHeaders = this.splitHeaderValues(this.initHeaders);
    if (oldValues === null) {
      propertyNames = this.initHeaders; // Object.getOwnPropertyNames(attributes);
    } else {
      propertyNames = Object.getOwnPropertyNames(attributes);
    }
    // localStorage.setItem('template_properties' JSON.stringify(propertyNames));
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      let name = propertyNames[i];
      const nameType = fieldsAndType [name];
      const value = name.split('.');
      if (value.length > 1) {
        name = value[0];
        value.shift();
      }
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (attribute === null || attribute === undefined) {
        if (this.findObjectInStoredArraySizes(currentPath) > 0) {
          this.buildArrayData(attribute, dataArray, currentPath);
        } else {
          this.featureAttrib.push(name);
          dataArray.push('');
        }
      } else {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (this.checkArrayAttributeSet(currentPath)) {
              this.allArrayAttributeSet.push(attribute);

              const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
              this.allArrayAttributeSetElemment.push(elt);
              dataArray.push(elt);
            } else if (this.checkCoordinateField(currentPath)) {
              const coordinate = {
                x: attribute[0],
                y: attribute[1],
                z: attribute[2],
                Accuracy: attribute[3]
              };
              this.allCoordinatesFields.push(coordinate);
              const elt = currentPath + '_' + this.allCoordinatesFieldsElemment.length;
              this.allCoordinatesFieldsElemment.push(elt);
              dataArray.push(elt);
            } else {
              this.buildArrayData(attribute, dataArray, currentPath);
            }
          } else {
            this.buildObjectData(attribute, dataArray, currentPath, value);
          }
        } else if (attribute instanceof Array) {
          if (this.checkArrayAttributeSet(currentPath)) {
            this.allArrayAttributeSet.push(attribute);

            const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
            this.allArrayAttributeSetElemment.push(elt);
            dataArray.push(elt);
          }
        } else {
          this.featureAttrib.push(name);
          if (this.checkIfValueInArray(name)) {
            let data = this.checkFiles(attributes[name]);
            if (this.checkIfIsUrl(data)) {
              this.linksArray.push(data);
            } else if (this.checkIfDate(data, name)) {
              data = data.split('T')[0];
            }
            dataArray.push(data);
          }
        }
      }
    }
  }

  // After displaying data in the table replace the coordinates with the links to be clicked.
  replaceCoordinateFields() {
    const size = this.allCoordinatesFields.length;
    const self = this;
    if (size > 0) {
      for (let i = 0; i < size; i++) {
        const idx = this.allCoordinatesFieldsElemment[i];
        const eltId = document.getElementById(idx);
        const a = document.createElement('a');

        if (eltId !== null) {
          eltId.innerHTML = '';
          const linkText = document.createTextNode('Coordinates');
          // a.id = this.getElementNumber(idx);
          a.appendChild(linkText);
          a.title = 'Coordinates';
          a.classList.add('button');
          a.classList.add('geomCoord');
          a.addEventListener('tap', (event) => {
            event.preventDefault();
          });

          eltId.appendChild(a);
        }

      }
    }
  }

  // display the coordinates dialog.
  openCoordinates(event) {
    event.preventDefault();
  }

  // display the coordinates dialog.
  openFieldCoordinates(event, i = -1) {
    event.preventDefault();

  }

  // After displaying data in the table replace the Array AttributeSet with the links to be clicked.
  replaceAttributeSetArrayFields() {
    const size = this.allArrayAttributeSet.length;
    if (size > 0) {
      for (let i = 0; i < size; i++) {
        const idx = this.allArrayAttributeSetElemment[i];
        const eltId = document.getElementById(idx);
        const a = document.createElement('a');

        if (eltId !== null) {
          eltId.innerHTML = '';
          const linkText = document.createTextNode(this.getElementText(idx));
          // a.id = this.getElementNumber(idx);
          a.appendChild(linkText);
          a.title = 'AttributeSet';
          a.classList.add('button');
          a.classList.add('geomCoord');
          a.addEventListener('tap', (event) => {
            event.preventDefault();
          });
          eltId.appendChild(a);
        }
      }
    }
  }

  openAttributeSetArrayDialog() {
    // this.$.attributesetArrayDialog.open();
  }

  createAttributesetArrayElementHeader(attr, headers = [], parent = '') {
    const properties = Object.keys(attr);
    for (const property of properties) {
      const element = attr[property];
      const name = parent !== '' ? parent + '.' + property : property;
      if (element instanceof Object) {
        this.createAttributesetArrayElementHeader(element, headers, name);
      } else {
        headers.push(name);
      }
    }
  }

  createAttributesetArrayElementData(attributes) {
    const size = attributes.length;
    const tempArray = [];
    this.arrayAttSetData = [];
    this.createAttributesetElementData(attributes, tempArray);
  }

  createAttributesetElementData(attributes, data = []) {
    const size = attributes.length;

    for (let i = 0; i < size; i++) {
      const obj = attributes[i];
      data = [];
      const tempProperties = Object.keys(obj);
      for (const tempProperty of tempProperties) {
        const element = obj[tempProperty];
        if (element instanceof Object) {
          if (element instanceof Array) {
            let eltData = '';
            const s = element.length;
            if (s > 0) {
              for (let k = 0; k < s; k++) {
                eltData += element[k] + '\n';
              }
            }
            data.push(eltData);
          } else {
            this.createAttributesetElementData(element, data);
          }
        } else {
          data.push(element);
        }
      }
      this.arrayAttSetData.push(data);
    }
  }

  getElementNumber(value) {
    let index = 0;

    const elts = value.split('_');
    if (elts.length > 0) {
      const n = elts[elts.length - 1];
      index = Number(n);
    }

    return index;
  }

  getElementText(value) {
    let name = value;

    const elts = value.split('_');
    if (elts.length > 0) {
      name = elts[0];
    }

    return name;
  }

    /*
    * check if value is a media file and return the complete URL.
    * @param: the value to check
    * @return: the URL or the string
    */
  checkFiles(value) {
    if (value instanceof Array && value.length > 0) {
      const firstVal = value[0];
      return this.checkFile(firstVal);
    } else {
      return value;
    }
  }

  checkFile(value) {
    const convValue = String(value);
    const splitValues = convValue.split('.');
    const types = ['jpg', 'png', 'jpeg', 'pdf', 'gif', 'mp3', 'wav', 'raw', 'ogg', 'mp4', '3gp', 'avi', 'vob', 'flv'];
    let type = splitValues[splitValues.length - 1];
    type = type.toLowerCase();
    if (this.contains(type, types)) {
      return url(value);
    } else {
      return value;
    }
  }

  getIcon(item): string{
    let uri = '';
    if(item !== null && item !== undefined) {
      const values = item.split('.');

      const ext = values[values.length - 1];

      if(ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'gif' || ext === 'pdf'){
        uri = '/assets/images/photo_display.png';
      } else if(ext === 'mp4' || ext === '3gp' || ext === 'avi' || ext === 'vob' || ext === 'flv'){
        uri = '/assets/images/video_display.png'
      } else if(ext === 'mp3' || ext === 'wav' || ext === 'raw' || ext === 'ogg'){
        uri = '/assets/images/audio_play.png'
      }

      return uri;
    }
  }

  getFilesUrl(value) {
    const filesUrls = [];
    if (value instanceof Array && value.length > 0) {
      value.forEach(element => {
        filesUrls.push(this.checkFiles(element));
      });
    } else {
      return value;
    }
    return filesUrls;
  }

  // check if the value is date format or not.
  checkIfDate(value, name) {
    if (value !== null && value !== undefined && value !== '' && typeof value === 'string') {
      const colomValues = value.split(':');
      const slashValues = value.split('-');
      if (colomValues.length > 2 && slashValues.length > 2) {
        /*
        if (this.checkTypeDateInFeatureSchema(this.featureSchema.attributes, name)) {
          return true;
        }
        */
        return true;
      }
    }
    return false;
  }

  checkTypeDateInFeatureSchema(attributes, name, parent = ''): boolean {
    const dim = attributes.length;
    const value = false;
    if (dim > 0) {
      for (let i = 0; i < dim; i++) {
        if (attributes[i]._class === 'attributeset') {

          parent = (parent !== '') ? parent + '.' + attributes[i].name : attributes[i].name;
          return this.checkTypeDateInFeatureSchema(attributes[i].attributes, name, parent);
        } else {
          const isNested = parent !== '';
          const tempName = (isNested) ? parent + '.' + attributes[i].name : attributes[i].name;
          const tempGivenName = (isNested) ?  parent + '.' + name : name;

          if (tempName === tempGivenName && attributes[i].type === 'date') {
            return  true;
          }
        }
      }
    }
    return value;
  }

  // split array data build in string in to elements of an array.
  splitHeaderValues(headers) {
    const values = [];
    if (headers.length === 0) {
      return;
    }

    for (const header of headers) {
      const value = header.split('.');
      if (!this.contains(value[0], values)) {
        values.push(value[0]);
      }
    }
    return values;
  }

  // Check if the header items are the attributes of the template to display empty space in the features
  // collected before create that field.
  checkIfHeaderInAttributes() {
    if (this.featureAttrib.length > 0) {
      for (let i = 0; i < this.attHeaders.length; i++) {
        if (!this.checkAttributes(this.attHeaders[i]) && this.attHeaders[i] !== 'Geometry Type') {
          if (!this.splitArrayValues(this.attHeaders[i])) {
            if (this.attHeaders[0] === 'Geometry Type') {
              this.missIndexes.push(i - 1);
            } else {
              this.missIndexes.push(i);
            }
          }
        }
      }
    }
  }

  splitArrayValues(value) {
    const values = value.split('[');
    if (values.length > 1) {
      return true;
    }
    return false;
  }

  checkAttributes(value) {
    if (this.featureAttrib.length > 0) {
      for (const attrib of this.featureAttrib) {
        if (this.getNameOfHeader(value) === attrib) {
          return true;
        }
      }
      return false;
    }
  }

  checkIfValueInArray(value) {
    if (this.attHeaders.length > 0) {
      for (const attHeader of this.attHeaders) {
        if (this.getNameOfHeader(attHeader) === value) {
          return true;
        }
      }
      return false;
    }
  }

  getNameOfHeader(value) {
    const values = value.split('.');
    if (values.length > 0) {
      return values[values.length - 1];
    }
    return value;
  }

  contains(value, arr) {
    if (arr.length > 0) {
      for (const item of arr) {
        if (item === value) {
          return true;
        }
      }
      return false;
    }
  }

  // building the data of arrays(media files values).
  buildArrayData(attribute, dataArray, path = '') {
    const highestLength = this.findObjectInStoredArraySizes(path);
    let size = 0;
    if (attribute !== null && attribute !== undefined) {
      size = attribute.length;
    }

    if (size === 0 && highestLength === 0) {
      dataArray.push('');
    }

    for (let i = 0; i < size; i++) {
      let data = attribute[i];

      if (data instanceof Object) {
        if (data instanceof Array) {
          this.buildArrayData(data, dataArray);
        } else {
          this.buildObjectData(data, dataArray, '');
        }
      } else {
        data = this.checkFiles(data);
        if (this.checkIfIsUrl(data)) {
          this.linksArray.push(data);
          dataArray.push(data);
        } else {
          dataArray.push(data);
        }
      }
    }
    if (highestLength > size) {
      const remainSize = highestLength - size;
      for (let j = 0; j < remainSize; j++) {
        dataArray.push('');
      }
    }
  }

  findObjectInStoredArraySizes(path) {
    for (const header of this.arrayHeadersAndSize) {
      if (header.name === path) {
        return header.size;
      }
    }
    return 0;
  }

  getHighestAttributeSize() {
    const size = this.instances.length;
    let attributesSize = 0;
    let highestAttributeIndex = -1;
    for (let i = 0; i < size; i++) {
      const attributes = this.instances[i]['attributes'];
      const tempSize = this.countObjectDataLength(attributes);
      if (tempSize > attributesSize) {
        attributesSize = tempSize;
        highestAttributeIndex = i;
      }
    }
    return highestAttributeIndex;
  }

  countObjectDataLength(attributes, path = '') {
    const propertyNames = Object.getOwnPropertyNames(attributes);
    const size = propertyNames.length;
    let currentSize = size;
    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = path + name;
      if (attribute instanceof Object) {
        if (attribute instanceof Array) {
          currentSize = currentSize + this.countArrayDataLength(attribute, currentPath);
        } else {
          currentSize = currentSize + this.countObjectDataLength(attribute, currentPath);
        }
      }
    }

    return currentSize;
  }

  countArrayDataLength(attribute, path = '') {
    const size = attribute.length;
    let currentSize = size;
    for (let i = 0; i < size; i++) {
      const data = attribute[i];
      if (data instanceof Object) {
        if (data instanceof Array) {
          currentSize = currentSize + this.countArrayDataLength(data);
        } else {
          currentSize = currentSize + this.countObjectDataLength(data);
        }
      }
    }
    return currentSize;
  }

  /*************************************************************/

  isEmpty(obj) {
    if (obj === null || obj === undefined) {
      return true;
    }

    for (const prop of Object.keys(obj)) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
    }

    return true && JSON.stringify(obj) === JSON.stringify({});
  }

  // manage the choices selected by user in advanced searching.
  submitSeletedItems(event, selectedColumns) {
    this.ColumsValues = [];
    this.columnsName = selectedColumns.selectedColumns;
    const datesValues = selectedColumns.datesValues;
    const advancedCriteria = selectedColumns.advancedRequest;
    this.sortedObject = selectedColumns.sortingObject;
    this.columnsValues = selectedColumns.ColumsValues;
    const aggregate = selectedColumns.aggregateBody;
    const output = selectedColumns.outputType;
    const reportTemplate = selectedColumns.reportTemplate;
    // let self = this;
    this.advancedSearchCriteria = null;
    this.aggregation = [];

    this.fromDate = datesValues.fromDate;
    this.toDate = datesValues.toDate;

    this.showAllColumns();
    // if (!this.isEmpty(advancedCriteria)) {
    if (aggregate !== null && aggregate.length > 0) {
      // this.advancedFeatureSearch(advancedCriteria);
      // this.buildSimpleReport('json' aggregate);
      this.aggregation = aggregate;
      if (output === 'view_web_app') {
        // this.aggregationSearch(aggregate);
      } else {
        // this.RequestReport(output, reportTemplate);
      }
      // this.advancedSearchCriteria = advancedCriteria;
    } else {
      this.fetchFeatures();
    }

  }

  showAllColumns() {
    // $('#featureData thead th #featureData tbody td').show();
  }

  // hiding unselected columns in the table.
  hideColumn(columnIndex) {
    const tbl = document.getElementById('featureData');
  }

  // display all the columns in the table.
  showColumn(columnIndex) {
  }

  hideColumn2(columnIndex) {
  }

  createCheckBoxItems() {
    this.tableheaderobject = null;
    const list = [];

    this.tableheaderobject = list;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  selectAllFeatures(event) {

    this.masterToggle();
    this.store.features = null;
    if (event.checked) {
      this.store.features = this.instances;
      /*
      this.dataSource.data.forEach(row => {
        const feature = this.instances.find(f => f.id === row.id);
        this.store.features.push(row);
      });
      */
    }
  }

  removeColumn() {
    if (this.columnsToDisplay.length) {
      this.columnsToDisplay.pop();
    }
  }

  shuffle() {
    let currentIndex = this.columnsToDisplay.length;
    while (0 !== currentIndex) {
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // Swap
      const temp = this.columnsToDisplay[currentIndex];
      this.columnsToDisplay[currentIndex] = this.columnsToDisplay[randomIndex];
      this.columnsToDisplay[randomIndex] = temp;
    }
  }

  sendSearchRequest() {

  }

  resetFields(){
    this.columnsValues = [];
  }

  valueExistObject(obj, value) {
    if (this.isEmpty(obj)) {
      return false;
    }

    const keys = Object.keys(obj);
    if (keys.length > 0) {
      for (const val of keys) {
        if (value === val) {
          return true;
        }
      }
    }
    return false;
  }

  setSDoingReport(event, status) {
    this.doingReport = false;
  }

  rebuildFields(projectObject) {
    const newObject = {};
    if (!this.isEmpty(projectObject)) {
      newObject['_id'] = 0;
      const keys = Object.keys(projectObject);
      for (let i = 0; i < keys.length; i++){
        if (keys[i] === 'geometry') {
          newObject['geometry'] = '$' + keys[i];
        } else {
          const elements = keys[i].split('.');
          const key = elements[elements.length - 1];
          newObject[key] = '$' + keys[i];
        }
      }
    }

    return newObject;
  }

  createProject2(projectObject) {
    const obj = {};
    if (!this.isEmpty(projectObject)) {
      const keys = Object.keys(projectObject);
      for (let i = 0; i < keys.length; i++){
        if (keys[i] === 'geometry') {
          obj['geometry'] = '$' + keys[i];
        } else {
          const elements = keys[i].split('.');
          const key = 'attributes.' + elements[elements.length - 1];
          obj[key] = '$' + elements[elements.length - 1];
        }
      }
    }

    return obj;
  }



   /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

}
