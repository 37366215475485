import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-activate-account-dialog',
  templateUrl: './activate-account-dialog.component.html',
  styleUrls: ['./activate-account-dialog.component.scss']
})
export class ActivateAccountDialogComponent implements OnInit {

  activateAccountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private userService: UserService,
    private dialogRef: MatDialogRef<ActivateAccountDialogComponent>
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.activateAccountForm = this.fb.group({
      act_token: ['', Validators.required]
    });
  }

  resendMail() {

  }

  onSubmit() {
    const activateAccount = this.activateAccountForm.value;
    const token = {
      activation_code: activateAccount.act_token
    };
    this.store.showLoading();
    this.userService.oldActivateAccount(token).subscribe(
      res => {
        this.store.hideLoading();
      },
      err => {
        this.store.hideLoading();
      }
    );
    this.dialogRef.close();
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
    event.stopPropagation();
  }
}
