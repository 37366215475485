import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatTable, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { StoreService } from 'src/app/services/store.service';
import { AttributeSet, AttributeSetArray } from 'src/app/share/feature/attributes';
import { Template } from 'src/app/share/template';
import { CRITICAL, ERROR, getSearchObject, getTemplate, getTemplateFieldsNameAndTypes, getToken, persistChoosenCRSType, persistSearchObject, SUCCESS, WARNING } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { DOCUMENT } from '@angular/common';

export interface Criteria {
  criteria: string;
}

export interface PlaceHolder {
  name: string;
  value: string;
}

const ELEMENT_DATA: Criteria[] = [];
const RPH_ELEMENT_DATA: PlaceHolder[] = [];

@Component({
  selector: 'app-view-data-criteria-dialog',
  templateUrl: './view-data-criteria-dialog.component.html',
  styleUrls: ['./view-data-criteria-dialog.component.scss']
})
export class ViewDataCriteriaDialogComponent implements OnInit {

  headers: string[] = [];
  templatelist: string[] = [];

  optrs: any[] = [
    {value: '=', text: '='},
    {value: '≠', text: '≠'},
    {value: '>', text: '>'},
    {value: '>=', text: '>='},
    {value: '<', text: '<'},
    {value: '<=', text: '<='},
    {value: 'contains', text: 'Contains'},
    {value: 'notcontain', text: 'not contain '}
  ];

  displayedColumns: string[] = ['select', 'criteria'];

  dataSource = new MatTableDataSource<Criteria>(ELEMENT_DATA);
  selection = new SelectionModel<Criteria>(true, []);
  @ViewChild(MatTable, {static: true}) criteriatable: MatTable<any>;

  rptdisplayedColumns: string[] = ['name', 'value'];
  rptdataSource = new MatTableDataSource<PlaceHolder>(RPH_ELEMENT_DATA);
  rptselection = new SelectionModel<PlaceHolder>(true, []);
  placeholdername: string;
  @ViewChild(MatTable, {static: true}) placeholderstable: MatTable<any>;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  template: Template;
  criteriaForm: FormGroup;
  fromDateEnabled = false;
  toDateEnabled = false;
  rep_temp: any;
  fromdate = '';
  criteriaList: string[];
  data1: any[];
  searchCriteria: any;
  columnItems: any[] = [];
  selectedColumns: any[] = [];
  criteriaObjects: any[] = [];
  columnsValues: any[] = [];
  selectedReportTemplateName: string;

  datesValues: any;
  criteriasArray: any[] = [] ;
  outPut: any;
  coordinateRef: any;
  fromDateValue: any;
  toDateValue: any;

  selectedSortColumn: any;
  sortingType: any;
  selectedSortColumn2: any;
  sortingType2: any;
  sortingObject: any;

  selectedCritColumn: any;
  selectedValColumn: any;
  selectedOperColumn: any;
  pendingStatus: number;
  templateId: string;
  publicValue: boolean;
  privateValue: boolean;
  selectedReportTemplate: any;
  reportsTemplates: any[];
  criteriaDelete: any[];
  value: any;
  descendingValue: boolean;
  ascendingValue: boolean;
  descendingValue2: boolean;
  ascendingValue2: boolean;
  pdfOrHtml: boolean;
  templateloaded: boolean;
  selectOrDeselectAll: boolean = true;

  events: string[] = [];

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private reportTempalteService: ReportTemplateService,
    @Inject(MAT_DIALOG_DATA) data,
    @Inject(DOCUMENT) private document: Document,
    private dialogRef: MatDialogRef<ViewDataCriteriaDialogComponent>
  ) {
    if (data !== null) {
      this.template = data.template;
      for(const head of data.headers){
        const val = {
          check: true,
          value: head
        };
        this.columnItems.push(val);
      }
      this.constructHeaderList(this.template);
    }
    this.fromDateEnabled = false;
    this.toDateEnabled = false;
    this.data1 = [];
    this.criteriaList = [];
    this.datesValues = {};
    this.templateloaded = true;
    this.coordinateRef = 'Geodetic';

    this.createForm();
    if (this.criteriatable) {
      this.dataSource.data = [];
      this.criteriatable.renderRows();
      this.criteriaDelete = [];
    } else {
      this.dataSource.data = [];
    }
    this.criteriaObjects = [];
    this.criteriaList = [];
    this.criteriasArray = [];
  }

  get criteriaValue() {
    let values = '';
    for (const item of this.criteriaList) {
      values = values.length > 0 ? values + '\n' + item : item;
    }
    return values;
  }

  createForm() {
    this.criteriaForm = this.fb.group({
      column_name: '',
      operator: '',
      column_value: '',
    });
  }

  fromDateChecked(val: boolean) {
    this.fromDateEnabled = val;
  }

  toDateChecked(val) {
    this.toDateEnabled = val;
  }

  showToDateValue(event) {
    this.toDateValue = event.value;
    if (this.toDateValue !== null && this.toDateValue !== undefined) {
      this.toDateValue = this.toDateValue.toISOString();
    }
  }

  showFromDateValue(event) {
    this.fromDateValue = event.value;
    if (this.fromDateValue !== null && this.fromDateValue !== undefined) {
      this.fromDateValue = this.fromDateValue.toISOString();
    }
  }

  showBoxMessage(msg, type = WARNING) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      if (type === SUCCESS) {
        this.messageBox.showSuccess();
      } else if (type === ERROR) {
        this.messageBox.showError();
      } else if (type === WARNING) {
        this.messageBox.showCritical();
      } else if (type === CRITICAL) {
        this.messageBox.showCritical();
      }
    });
  }

  closeDialog() {
    this.resetDialog();
    this.dialogRef.close();
  }

  exportData() {

  }

  resetAllObjects() {
    this.datesValues = {};
    this.searchCriteria = {};
    this.criteriaObjects = [];
    this.criteriasArray = [];
    this.outPut = '';
    this.dataSource.data = [];
  }

  eventListerner(event) {
    const key = event.key; // Or const {key} = event; in ES6+

    if (key === 'Escape') {
      this.closeDialog();
    }
  }

  ngOnInit() {
    document.addEventListener('keyup', event => this.eventListerner(event));

    this.coordinateRef = 'Geodetic';
    this.outPut = 'view_web_app';

    for (const column of this.columnItems) {
      this.selectedColumns.push(column.value);
    }

  }

  resetDialog() {
    this.dataSource.data = [];
    this.criteriatable.renderRows();
    this.initializeDialog();
    this.selection.clear();
  }

  addSearchCriteriaItem2(item: any) {
    const columnName = item.column_name;
    const operator = item.operator;
    const columnValue = item.column_value.trim();

    if (this.searchCriteria === null || this.searchCriteria === undefined) {
      this.searchCriteria = {};
    }

    switch (operator) {
      case  '=' :
        this.searchCriteria[columnName] = columnValue;
        break;
      case  '≠':
        this.searchCriteria[columnName] = {
          ne: columnValue
        };
        break;
      case '<':
        this.searchCriteria[columnName] = {
          lt: columnValue
        };
        break;
      case '>':
        this.searchCriteria[columnName] = {
          gt: columnValue
        };
        break;
     }
  }

  addCriteria() {
    const value = this.criteriaForm.value;
    const columnName = value.column_name.trim();
    const operator = value.operator;
    const columnValue = value.column_value.trim();
    const text = `${columnName} ${operator} ${columnValue}`;
    const index = this.criteriaList.indexOf(text);
    if (index >= 0) {
      this.showBoxMessage('The given criteria already exist');
      return;
    }
    this.criteriaList.push(text);
    const crit = {
      criteria: text
    };
    this.addSearchCriteriaItem(value);
    const data = this.dataSource.data;
    data.push(crit);
    this.dataSource.data = data;
    if (this.criteriatable !== null && this.criteriatable !== undefined) {
      this.criteriatable.renderRows();
    }
  }

  // for creating a search item and add it to the object.
  addSearchCriteriaItem(item: any) {
    const fieldName = item.column_name;
    const operator = item.operator;
    this.value = item.column_value.trim();

    this.searchCriteria = {};

    const type = this.getAttributeType(fieldName);

    if (type !== null) {
      switch (type) {
        case 'integer' :
          this.value = parseInt(this.value);
          break;

        case 'double' :
          this.value = parseFloat(this.value);
          break;

        case 'decimal' :
          this.value = parseFloat(this.value);
          break;

        case 'boolean':
          this.value = JSON.parse(this.value);
          break;

        case 'date' :
          const d = new Date(this.value);
          const val = {
            $dateFromString: {
              dateString: d.toISOString()
            }
          };

          const oper = this.getCompareType(operator);
          const value = {};
          value[oper]  = ['$attributes.' + fieldName + '.value', val];
          this.searchCriteria['$expr'] = value;
          break;

        default :

      }
    }

    if (type !== 'date') {
      switch (operator) {
        case '=' :
          this.searchCriteria[fieldName] = this.value;
          break;

        case '≠' :
          this.searchCriteria[fieldName] = {
            $ne: this.value
          };
          break;

        case '<' :
          this.searchCriteria[fieldName] = {
            $lt: this.value
          };
          break;

        case '<=' :
          this.searchCriteria[fieldName] = {
            $lte: this.value
          };
          break;

        case '>' :
          this.searchCriteria[fieldName] = {
            $gt: this.value
          };
          break;

        case '>=' :
          this.searchCriteria[fieldName] = {
            $gte: this.value
          };
          break;

        case 'notcontain' :
          const contValue = this.findContainInCriterias();
          if (contValue === null) {
            this.showBoxMessage(' add \'contains\' operator First.');
            return;
          }
          this.searchCriteria['$text'] = {
            $search: contValue + ' -' + this.value
          };
          break;

        case 'contains' :
          this.searchCriteria['$text'] = {
            $search: this.value
          };
          break;
      }
    }
    const criteria = {
      column : fieldName,
      operator,
      value: this.value
    };

    this.criteriaObjects.push(criteria);

    if (operator === 'notcontain') {
      for (let i = this.criteriasArray.length - 1; i >= 0; i--) {
        const obj = this.criteriasArray[i];
        if (typeof obj['$text'] === 'object') {
          this.criteriasArray.splice(i, 1);
        }
      }
    }
    // this.criteriaList.push(criteria);
    this.criteriasArray.push(this.searchCriteria);
  }

  handleSelectAll(event) {
    // event.preventDefault();
    this.selectOrDeselectAll = event.checked;
    if(event.checked === true){
      for(let i = 0; i < this.columnItems.length; i++) {
        const val = this.columnItems[i];
        this.selectedColumns.push(val.value);
        this.columnItems[i].check = true;
      }
    } else {
      this.selectedColumns = [];
      for(let i = 0; i < this.columnItems.length; i++) {
        this.columnItems[i].check = false;
      }
    }
    // event.stopPropagation();
  }

  handleSelect(value, event){
    event.preventDefault();
    if(value !== null && value !== undefined){
      let i = -1;
      let j = -1;
      if(value.check === true){
        i = this.selectedColumns.indexOf(value.value);
        this.selectedColumns.splice(i, 1);
        j = this.columnItems.indexOf(value);
        this.columnItems[j].check = false;
      } else {
        i = this.selectedColumns.indexOf(value.value);
        if(i === -1){
          this.selectedColumns.push(value.value);
          j = this.columnItems.indexOf(value);
          this.columnItems[j].check = true;
        }
      }
    }
    event.stopPropagation();
  }

  constructHeaderList(templ: Template) {
    if (templ !== null && templ !== undefined) {
      const name = 'name';
      const _class = '_class';
      const schema = templ.feature;
      const geometry = schema.geometry_type;
      if (geometry !== null && geometry !== undefined) {
        const val = {
          check: true,
          value: 'Coordinates'
        };
        //this.columnItems.push(val);

      }
      const attributes = schema.attributes;
      for (const attribute of attributes) {
        const typeAttr = attribute[_class];
        if (typeAttr === 'attribute' || typeAttr === 'arrayattributeset') {
          const val1 = {
            check: true,
            value: attribute[name]
          };
          //this.columnItems.push(val1);
          this.headers.push(attribute[name]);
        } else if (typeAttr === 'attributeset') {
          this.constructAttributesetHeaderList(attribute as AttributeSet, attribute[name]);
        }
      }
      // this.selectedColumns = [...this.columnItems];
    } else {
      //this.columnItems = [];
    }
  }

  constructAttributesetHeaderList(atributeset: AttributeSet, parent= '') {
    const name = 'name';
    const _class = '_class';

    for (const attribute of atributeset.attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute' || typeAttr === 'arrayattributeset') {
        const val = {
          check: true,
          value: `${parent}.${attribute[name]}`
        };
        //this.columnItems.push(val);
        this.headers.push(`${parent}.${attribute[name]}`);
      } else if (typeAttr === 'attributeset') {
        const nested_parent =  `${parent}.${attribute[name]}`;
        this.constructAttributesetHeaderList(attribute as AttributeSet, nested_parent);
      }
    }
  }

  constructAttributesetArrayHeaderList(atributeset: AttributeSetArray, parent= '') {
    const name = 'name';
    const _class = '_class';

    for (const attribute of atributeset.attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const val = {
          check: true,
          value: `${parent}.${attribute[name]}`
        };
        //this.columnItems.push(val);
        this.headers.push(`${parent}.${attribute[name]}`);
      } else if (typeAttr === 'attributeset') {
        const nested_parent =  parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        this.constructAttributesetHeaderList(attribute as AttributeSet, nested_parent);
      }
    }
  }

  removeCriteria2() {
    if (this.selection.selected.length > 0) {
      let data = this.dataSource.data;
      for (const s of this.selection.selected) {
        this.criteriaList = this.criteriaList.filter(res => res !== s.criteria);
        data = data.filter(res => res !== s);
      }
      this.dataSource.data = data;
      this.selection.clear();
      if (this.criteriatable !== undefined) {
        this.criteriatable.renderRows();
      }
    }
  }

  initializeDialog() {
    // console.log('Reset criteria table');
    this.dataSource.data = [];

    if (this.criteriatable) {
      this.dataSource.data = [];
      this.criteriatable.renderRows();
      this.criteriaDelete = [];
    }
    this.criteriaObjects = [];
    this.criteriaList = [];
    this.criteriasArray = [];
    this.selection.clear();
  }

  // remove the criteria in the selecting list.
  removeCriteria() {
    // console.log('Remove criteria');
    if (this.criteriaDelete.length < 1) {
      // this.toastr.error(, 'MyCumulus');
      this.showBoxMessage('select criteria to remove');
      return;
    }
    const deleteLen = this.criteriaDelete.length;
    // const objLen = this.criteriaObjects.length;

    for (let k = 0; k < this.criteriaDelete.length; k++){
      delete this.searchCriteria[this.criteriaDelete[k].column];
    }

    for (let i = deleteLen - 1; i >= 0; i--) {
      for (let j = 0; j < this.criteriaObjects.length; j++) {
        if (this.criteriaDelete[i].column === this.criteriaObjects[j].column) {
          this.criteriaObjects.splice(j, 1);
          this.criteriasArray.splice(j, 1);
        }
      }
      this.criteriaDelete.splice(i, 1);
    }

    if (this.selection.selected.length > 0) {
      let data = this.dataSource.data;
      for (const s of this.selection.selected) {
        this.criteriaList = this.criteriaList.filter(res => res !== s.criteria);
        data = data.filter(res => res !== s);
      }
      this.dataSource.data = data;
      this.selection.clear();
      if (this.criteriatable !== null && this.criteriatable !== undefined) {
        this.criteriatable.renderRows();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Criteria): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    const index = this.dataSource.data.indexOf(row);
    const criteria = this.criteriaObjects[index];
    if (this.criteriaDelete === null || this.criteriaDelete === undefined) {
      this.criteriaDelete = [];
    }
    if (this.selection.isSelected(row)) {
      if (index > -1) {
        this.criteriaDelete.push(criteria);
      }
    } else {
      const itemIndex = this.criteriaDelete.indexOf(criteria);
      if (itemIndex > -1) {
        this.criteriaDelete = this.criteriaDelete.splice(itemIndex, 1);
      }
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.criteria + 1}`;
  }

  handleClicked(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // set default values and open the dialog.
  open(data, template) {
    const previousSearch = getSearchObject();
    this.columnItems = [];
    this.selectedColumns = [];
    this.criteriaObjects = [];
    this.columnsValues = [];
    this.data1 = data;

    ///const attributes = template.feature.attributes;
    //this.buildColumnsValues(data);
    if (!this.isEmpty(previousSearch) && previousSearch.template === template.id) {
      this.searchCriteria = previousSearch.advancedRequest;
      this.datesValues = previousSearch.datesValues;
      this.criteriaObjects = previousSearch.criteriaObjects;
      this.criteriasArray = previousSearch.criteriasArray;
      this.outPut = previousSearch.outputType;

      if (!this.isEmpty(this.datesValues)) {
        if (this.datesValues.fromDate !== '' && this.datesValues.fromDate !== undefined) {
          this.fromDateValue = true;
        }
        if (this.datesValues.toDate !== '' && this.datesValues.toDate !== undefined) {
          this.toDateValue = true;
        } else {
          // this.$.toDate.checked = false;
          this.toDateValue = false;
        }
      }
      const elementNotSelected = previousSearch.selectedColumns;
      for (let j = 0; j < data.length; j++) {
        let status = false;
        if (elementNotSelected.length >= 0) {
          if (!this.elementInArray(j, elementNotSelected)) {
            status = true;
            this.selectedColumns.push(data[j]);
          }
        }
        const val = {
          check: status,
          value: data[j]
        };
        this.columnItems.push(val);
      }
    } else {
      this.searchCriteria = {};
      this.datesValues = {};
      this.criteriasArray = [];
      // this.$.fromDate.checked = false;
      // this.$.toDate.checked = false;
      // this.$.criteriaMemo.value = '';
      this.createColumnItemsInSelect();
      for (const item of data) {
        const val = {
          check: true,
          value: item
        };
        this.columnItems.push(val);
        this.selectedColumns.push(item);
      }
    }

    if (previousSearch.template === template.id) {
      const sortObj = previousSearch.sortingObject;
      if (sortObj !== null && Object.keys(sortObj).length > 0 && sortObj !== undefined) {
        if (sortObj.column !== '') {
          // this.selectedColumnIndex = this.columnsValues.indexOf(sortObj.column);
          this.selectedSortColumn = sortObj.column;
          this.sortingType = sortObj.sortingType;
        } else {
          this.selectedSortColumn = null;
        }

        if (sortObj.column2 !== '') {
          this.selectedSortColumn2 = sortObj.column2;
          this.sortingType2 = sortObj.sortingType2;
        } else {
          this.selectedSortColumn2 = null;
        }
      }
      const critLength = this.criteriaObjects.length;
      if (critLength > 0) {
        const objet = this.criteriaObjects[critLength - 1];
        this.selectedCritColumn = objet.column;
        this.selectedValColumn = '';
        this.selectedOperColumn = objet.operator;
      }
    } else {
      this.selectedSortColumn = null;
      this.selectedCritColumn = null;
      this.selectedSortColumn2 = null;

      this.selectedValColumn = '';
      this.selectedOperColumn = null;
    }
    this.template = template;
    this.templateId = template.id;

    if (this.publicValue === true) {
      // this.$.publicReport.checked = true;
      this.getUserPublicReportTemplates();
    } else {
      // this.$.privateReport.checked = true;
      this.getUserReportTemplates();
    }

    this.pendingStatus = 0;

    this.createColumnItemsInSelect();
  }

  // Is called when date selected from datePicker
  getDate(event, date) {
    if (date.type === 'from') {
      // this.$.fromdatepicker.value = date.date;
    } else if (date.type === 'to') {
      // this.$.todatepicker.value = date.date;
    }
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true && JSON.stringify(obj) === JSON.stringify({});
  }

  dismiss() {
    // this.$.searchDataDialog.close();
  }

  dismissDialog() {
    // const TestValue = this.$.DPDialog.$.pickerTo.date;
    // const test = this.$.DPDialog.$.pickerFrom.date;
  }

  getReportTemplateByName(name){
    let reportTemplate = null;

    this.reportsTemplates.forEach(repTemplate => {
      if(repTemplate.name === name){
        reportTemplate = repTemplate;
      }
    });

    return reportTemplate;
  }

  chooseReportTemplateById() {
    const chooseId = ''; // this.$.reportsTemplatesSelect.value;
    this.displayReportTemplatePlaceholders('');
    for (let i = 0; i < this.reportsTemplates.length; i++) {
      if (this.reportsTemplates[i].id === chooseId) {
        // alert(JSON.stringify(this.reportsTemplates[i]));
        this.selectedReportTemplate = this.reportsTemplates[i];
        this.displayReportTemplatePlaceholders(this.reportsTemplates[i]);
        break;
      }
    }
  }

  handlePublic(selectedElt) {
    if (selectedElt) {
      this.publicValue = true;
      this.privateValue = false;
      this.getUserPublicReportTemplates();
    } else {
      this.publicValue = false;
    }
    // $('.placeholdersValue').html('');
  }

  handlePrivate(selectedElt) {
    if (selectedElt) {
      this.privateValue = true;
      this.publicValue = false;
      this.getUserReportTemplates();
    } else {
      this.privateValue = false;
    }
   //  $('.placeholdersValue').html('');
  }

  getUserReportTemplates() {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getReportTemplates(token).subscribe(
      res => {
        console.log(res);
        this.reportsTemplates = res;

        // this.$.reportsTemplatesList.render();
        this.chooseReportTemplateById();
        this.templateloaded = true;
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.showBoxMessage(err);
      });
  }

  getUserPublicReportTemplates() {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getReportPublicTemplates(token).subscribe(
      res => {
        // console.log(res);
        this.reportsTemplates = res;
        this.templateloaded = true;
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.showBoxMessage(err);
      });
  }

  updatePlaceHolderTable(reportTemplate: any) {
    const placeholders = reportTemplate.place_holders;
    const data = [];
    if (placeholders !== null && placeholders !== undefined) {
      if (placeholders.length > 0) {
        for (const placeholder of placeholders) {
          const keys = Object.keys(placeholder);
          const name = keys[0];
          const value = placeholder[name];
          const obj = {
            name,
            value
          };
          data.push(obj);
        }
        if (this.placeholderstable !== null  && this.placeholderstable !== undefined) {
          this.rptdataSource.data = [];
        }
      }
    }
    this.rptdataSource.data = data;
    this.placeholderstable.renderRows();
  }

  updatePlaceHolderValue(event, element, value) {

    event.preventDefault();
    const val = event.target.data;
    console.log(element);
    // alert('value: ' + value);
    element['value'] = value;
    event.stopPropagation();
  }

  displayReportTemplatePlaceholders(reportTemplate) {
    let elements = '';
    if (reportTemplate === '') {
      // $('.placeholdersValue').html(elements);
      return;
    }

    const placeHolders = reportTemplate.place_holders;

    for (let i = 0; i < placeHolders.length; i++) {
      const placeHolder = placeHolders[i];
      const placeHolderName = Object.keys(placeHolder)[0];
      const placeHolderValue = (placeHolder[placeHolderName] != null) ? placeHolder[placeHolderName] : '';

      elements += '<tr>' +
                      '<td style="width: 27%; padding-right: 0px; text-align: right">' +
                        '<label style="margin-top: 42px; text-align: right; padding-right: 15px">' + placeHolderName + '</label>' +
                      '</td>' +

                      '<td>' +
                      '<paper-input ' +
                      'id="" ' +
                      'class="placehoderData" ' +
                      'key= "' +  placeHolderName + '" ' +
                      'value= "' +  placeHolderValue + '" ' +
                      'error-message$="" ' +
                      'bind-value="" ' +
                      'label="" always-float-label class="text-left" autofocus ' +
                      'style="text-align: left; margin-left: 9px; margin-right: 26px;" >' +
                      '</paper-input>' +
                      '</td>' +
                    '</tr>';

    }

    // $('.placeholdersValue').html(elements);
  }

  getColumnType() {
    let fieldName = 'v'; // this.$.columnItemsID.value;

    if (fieldName === 'Select Column') {
      return;
    }

    const type = this.getAttributeType(fieldName);

    if (type === 'date') {
      // this.$.valueDecorator.invalid = true;
      // this.valueErrorMessage = 'example: 2019-10-23';
      // this.toastr.error(, 'MyCumulus');
      this.showBoxMessage('Example date: 2019-10-23');
    } else {
      // this.$.valueDecorator.invalid = false;
    }
  }

  getCompareType(value) {
    if (value !== null) {
      switch (value) {
        case '=':
          return '$eq';
        case '≠' :
          return '$ne';
        case '<' :
          return '$lt';
        case '<=' :
          return '$lte';
        case '>' :
          return '$gt';
        case '>=' :
          return '$gte';
      }
    }

    return null;
  }

  findContainInCriterias() {
    const len = this.criteriaObjects.length;
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        if (this.criteriaObjects[i].operator === 'contains') {
          return this.criteriaObjects[i].value;
        }
      }
    }
    return null;
  }

  // getting the attribute type by the attribute name
  getAttributeType(name) {
    const fieldnameAndTypes = getTemplateFieldsNameAndTypes();
    return fieldnameAndTypes[name];

  }

  // constructing the criteria with the field name and the comparator operator.
  checkCriteria(fieldName, operator) {
    if (this.searchCriteria === null || this.searchCriteria === undefined) {
      return false;
    }
    const tempValue = this.searchCriteria[fieldName];
    if (tempValue !== null && tempValue !== undefined && tempValue !== '') {
      if (tempValue instanceof Object) {
        let temp = {};
        switch (operator) {
          case '≠':
            temp = tempValue.ne;
            break;

          case '<':
            temp = tempValue.lt;
            break;

          case '<=':
            temp = tempValue.lte;
            break;

          case '>':
            temp = tempValue.gt;
            break;

          case '>=':
            temp = tempValue.gte;
            break;
        }
        if (temp !== null && temp !== undefined && temp !== '') {
          return true;
        }
      } else {
        if (tempValue === this.value) {
          return true;
        }
      }
    }

    return false;
  }

  // manage the interface when change the criteria selection
  handleCriteriaSelected(event) {
    event.preventDefault();
    const criteriaObject = null; // this.$.criteriaObjectsList.itemForElement(event.target);
    if (criteriaObject === null || criteriaObject === undefined) {
      return;
    }

    const criteriaSelected = event.target.parentElement.checked;
    const len = this.criteriaDelete.length;
    for (let i = 0; i < len; i++) {
      if (this.criteriaDelete[i].column === criteriaObject.column) {
        if (!criteriaSelected) {
          this.criteriaDelete.splice(i, 1);
        }
        return;
      }
    }
    this.criteriaDelete.push(criteriaObject);
    event.stopPropagation();
  }

    // gets the index of value in array.
  getIndexOfValue(value) {
    if (value === null || value === undefined) {
      return;
    }
    return this.columnItems.indexOf(value);
  }

  ascendingChecked(selectedElt) {
    if (selectedElt) {
      this.descendingValue = false;
      this.ascendingValue = true;
    } else {
      this.ascendingValue = false;
    }
   // alert(`The column 1 ${this.selectedSortColumn}`);
  }

  descendingChecked(selectedElt) {
    if (selectedElt) {
      this.descendingValue = true;
      this.ascendingValue = false;
    } else {
      this.descendingValue = false;
    }
    // alert(`The column 1 ${this.selectedSortColumn}`);
  }

  ascendingChecked2(selectedElt) {
    if (selectedElt) {
      this.descendingValue2 = false;
      this.ascendingValue2 = true;
    } else {
      this.ascendingValue2 = false;
    }
    // alert(`The column 1 ${this.selectedSortColumn2}`);
  }

  descendingChecked2(selectedElt) {
    if (selectedElt) {
      this.descendingValue2 = true;
      this.ascendingValue2 = false;
    } else {
      this.descendingValue2 = false;
    }
    // alert(`The column 1 ${this.selectedSortColumn2}`);
  }

  handleDescendingSort(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.descendingValue = true;
      this.ascendingValue = false;
      // this.$.sortAscending.checked = false;
    } else {
      this.descendingValue = false;
      // this.$.sortDescending.checked = false;
    }
    event.stopPropagation();
  }

  handleAscendingSort(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.ascendingValue = true;
      this.descendingValue = false;
    } else {
      this.ascendingValue = false;
    }
    event.stopPropagation();
  }

  handleDescendingSort2(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.descendingValue2 = true;
      this.ascendingValue2 = false;
    } else {
      this.descendingValue2 = false;
    }
    event.stopPropagation();
  }

  handleAscendingSort2(event, selectedElt) {
    event.preventDefault();
    if (selectedElt) {
      this.ascendingValue2 = true;
      this.descendingValue2 = false;
    } else {
      this.ascendingValue2 = false;
    }
    event.stopPropagation();
  }

  // allow to select "To" date for the search criteria by date
  handleToDate(event) {
    event.preventDefault();
    const eltSelected = event.target.parentElement.checked;
    if (eltSelected) {
      this.toDateValue = true;
    } else {
      this.toDateValue = false;
    }
    event.stopPropagation();
  }

  outReportTemplateChanged(selectedRepTem) {

    this.selectedReportTemplate= this.getReportTemplateByName(selectedRepTem);
    this.updatePlaceHolderTable(this.selectedReportTemplate);
  }

  // manage the interface when the user checked From Date
  handleFromDate(event) {
      event.preventDefault();
      const itemSelected = event.target.parentElement.checked;
      if (itemSelected) {
        this.fromDateValue = true;
        // this.$.changeFromDiv.style.display = 'block';
        // this.$.datePickerDialog.dialogType = 'from';
        // this.$.datePickerDialog.open();
      } else {
        this.fromDateValue = false;
        // this.$.changeFromDiv.style.display = 'none';
        // this.$.fromdatepicker.disabled = true;
      }
      event.stopPropagation();
  }


  // build the list of columns' names from attributes.
  buildColumnsValues(attributes) {
    if (attributes === undefined || attributes.length === 0) {
      return;
    }

    for (let i = 0; i < attributes.length; i++) {
      const l = attributes[i].split('[').length;
      if (attributes[i] !== 'Coordinates' && l <= 1) {
        this.columnsValues.push(attributes[i]);
      }
    }
  }

  // displaying the list of columns' names.
  createColumnItemsInSelect() {
    const newItemNode0 = document.createElement('option');
    newItemNode0.selected = true;
    newItemNode0.text = 'Select Column';
    const newItemNode1 = document.createElement('option');
    const newItemNode3 = document.createElement('option');
    newItemNode1.selected = true;
    newItemNode3.selected = true;
    newItemNode1.text = 'Select Column';
    newItemNode3.text = 'Select Column';
    for (let i = 0; i < this.columnsValues.length; i++) {
      const newItemNode = document.createElement('option');
      newItemNode.className = this.columnsValues[i];
      newItemNode.value = this.columnsValues[i];
      newItemNode.text = this.columnsValues[i];
      const newItemNode2 = document.createElement('option');
      newItemNode2.className = this.columnsValues[i];
      newItemNode2.value = this.columnsValues[i];
      newItemNode2.text = this.columnsValues[i];

      const newItemNode4 = document.createElement('option');
      newItemNode4.className = this.columnsValues[i];
      newItemNode4.value = this.columnsValues[i];
      newItemNode4.text = this.columnsValues[i];
    }
  }

  /**
   * building the object of the colums' name to show and the search criteria to apply to the data
   */
  submitResearchData() {
    const sortingData = {};
    let sortVal1 = 1;
    let sortVal2 = 1;
    let outputType;
    if (this.selectedColumns.length === 0) {
      // this.toastr.error(, 'MyCumulus');
      this.showBoxMessage('select at least one column');
      return;
    }

    if (this.fromDateValue) {
      const fromValue = this.fromDateValue; // this.$.fromdatepicker.value;
      this.datesValues['fromDate'] = fromValue;
    } else {
      delete this.datesValues.fromDate;
    }

    if (this.toDateValue) {
      const toValue = this.toDateValue; // this.$.todatepicker.value;
      this.datesValues['toDate'] = toValue;
    } else {
      delete this.datesValues.toDate;
    }

    let columnValue = this.selectedSortColumn;  // this.$.sortColumnId.value;
    let columnValue2 = this.selectedSortColumn2;  // this.$.sortColumnId2.value;

    if (columnValue === undefined || columnValue === '') {
      columnValue = '';
    } else if (this.ascendingValue === false && this.descendingValue === false) {
      // this.toastr.error(, 'MyCumulus');
      this.showBoxMessage('Please select sort type.');
      return;
    }

    if (columnValue2 === undefined || columnValue2 === '') {
      columnValue2 = '';
    } else if (this.ascendingValue2 === false && this.descendingValue2 === false) {
      // this.toastr.error(, 'MyCumulus');
      this.showBoxMessage('Please select sort type.');
      // this.$.searchMessageBox.showCritical();
      return;
    }

    this.sortingObject = {
      column: columnValue,
      column2: columnValue2
    };

    if (columnValue !== undefined || columnValue !== '') {
      if (this.ascendingValue) {
        this.sortingObject.sortingType = 'ascending';
        sortVal1 = 1;
      } else if (this.descendingValue) {
        this.sortingObject.sortingType = 'descending';
        sortVal1 = -1;
      }
    }

    if (columnValue2 !== undefined || columnValue2 !== '') {
      if (this.ascendingValue2) {
        this.sortingObject.sortingType2 = 'ascending';
        sortVal2 = 1;
      } else if (this.descendingValue2) {
        this.sortingObject.sortingType2 = 'descending';
        sortVal2 = -1;
      }
    }
    if (columnValue !== undefined && columnValue !== '') {
      const newKey = 'attributes.' + columnValue + '.value';
      sortingData[newKey] = sortVal1;
    }

    if (columnValue2 !== undefined && columnValue2 !== '') {
      const newK = 'attributes.' + columnValue2 + '.value';
      sortingData[newK] = sortVal2;
    }

    outputType = this.outPut;

    const arrayCrit = this.criteriasArray.slice();
    const hiddenColumns = this.getColumnsToHide(this.selectedColumns);
    const aggregateBody = this.buildAggregation(arrayCrit.slice());

    if (aggregateBody.length === 0) {
      const tempObj = {
        $match: {
          template_id: this.template.id
        }
      };

      aggregateBody.push(tempObj);
    }

    if (!this.isEmpty(sortingData)) {
      aggregateBody.push({
        $sort: sortingData
      });
    }

    const templates = [];
    const placeHolder = {};
    const holders = [];

    let reportTemplatePlaceholders;
    if (this.selectedReportTemplate !== undefined && this.selectedReportTemplate !== null) {
      reportTemplatePlaceholders = this.selectedReportTemplate.place_holders;
      const data = this.rptdataSource.data;
      if (data !== null && data !== undefined) {
        for (const el of data) {
          const  key = el.name;
          let val = el.value;
          const obj = {};
          if (val === null || val === undefined || val === '') {
            val = '';
          }
          obj[key] = val;
          holders.push(obj);
        }
      }
      const template = {
        template_id: this.selectedReportTemplate.id,
        place_holders: holders
      };
      templates.push(template);
    }

    const templateId = getTemplate().id;

    let reportTempType = '';
    if (this.privateValue) {
      reportTempType = 'private';
    } else {
      reportTempType = 'public';
    }
    const reportObject = {
      selectedReport: this.selectedReportTemplate,
      templateType: reportTempType,
      holders: this.rptdataSource.data
    };

    const objectSend = {
      selectedColumns: hiddenColumns,
      advancedRequest: this.searchCriteria,
      datesValues: this.datesValues,
      template: templateId,
      criteriaObjects: this.criteriaObjects,
      sortingObject: this.sortingObject,
      ColumsValues: this.selectedColumns,
      criteriasArray: this.criteriasArray,
      aggregateBody,
      outputType,
      reportObject: reportObject,
      reportTemplate: templates
    };
    persistSearchObject(objectSend);
    const result = {
      event: 'search',
      advanced_request: objectSend
    };
    this.resetDialog();
    this.dialogRef.close(result);
  }

  buildAggregation(dataArray) {
    const aggregate = [];
    const andValue = [];
    const matchValue = {};

    const tempObj = {
      template_id: this.template.id
    };

    andValue.push(tempObj);

    if (dataArray.length > 0) {
      for (let i = 0; i < dataArray.length; i++) {
        const obj = dataArray[i];
        const key = Object.keys(obj)[0];

        const keyValues = key.split('.');

        if (key !== '$text' && key !== '$expr' && keyValues[0] !== 'attributes') {
          const newKey = 'attributes.' + key + '.value';

          obj[newKey] = obj[key];
          delete obj[key];
        }
        andValue.push(obj);
      }
    }

    if (this.fromDateValue) {
      if (this.datesValues.fromDate !== null) {
        const d = new Date(this.datesValues.fromDate);
        // const val = {
        //   $dateFromString: {
        //     dateString: d.toISOString(),
        //   }
        // };
        const val = {
          $dateFromString: {
            dateString: this.datesValues.fromDate,
          }
        };
        const value = {
          $expr: {
            $gte : ['$created_at', val]
          }
        };
        andValue.push(value);
      }
    }

    if (this.toDateValue) {
      if (this.datesValues.toDate !== null) {
        const d = new Date(this.datesValues.toDate);
        d.setDate(d.getDate() + 1);
        // const val = {
        //   $dateFromString: {
        //     dateString: d.toISOString(),
        //   }
        // };

        const val = {
          $dateFromString: {
            dateString: this.datesValues.toDate,
          }
        };

        const value = {
          $expr: {
            $lte : ['$created_at', val]
          }
        };
        andValue.push(value);
      }
    }
    if (andValue !== undefined && andValue !== null) {
      aggregate.push({
        $match: {
          $and: andValue
        }
      });
    }
    return aggregate;
  }

  // getting the index of the attributes names to hide in the table.
  getColumnsToHide(arrayToShow) {
    const hideColumns = [];
    if (arrayToShow.length === 0) {
      for (let i = 0; i < this.columnItems.length; i++) {
        hideColumns.push(this.columnItems.indexOf(this.columnItems[i]));
      }
      return hideColumns;
    }

    for (let i = 0; i < this.columnItems.length; i++) {
      if (!this.elementInArray(this.columnItems[i].value, arrayToShow)) {
        hideColumns.push(this.columnItems.indexOf(this.columnItems[i]));
      }
    }

    return hideColumns;
  }

  // check if the array contains
  elementInArray(element, array) {
    if (array.length === 0 || element === null) {
      return false;
    }

    for (let i = 0; i < array.length; i++) {
      if (array[i] === element) {
        return true;
      }
    }
    return false;
  }

  // check if an array of objects contains a specific element.
  elementInObjectArray(element, array) {
    if (array.length === 0 || element === null) {
      return;
    }
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === element) {
        return true;
      }
    }
    return false;
  }
}
