import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';
import { TokenResult } from '../share/result';
import { map, catchError } from 'rxjs/operators';
import { Token } from '../share/token';
import { getPrivateApiAURL } from '../share/utils';
import { environment } from 'src/environments/environment';

export const emailSenderUsername = environment.EmailSenderAdministrator.username;
export const emailSenderPassword = environment.EmailSenderAdministrator.password;

@Injectable({
  providedIn: 'root'
})
export class EmailSenderService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  loginMCEmailSenderAdmin(): Observable<Token> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json'
      })
    };
    const body = {
      credentials: {
        name: emailSenderUsername,
        password: emailSenderPassword
      },
      remember: false
    };
    return this.http.post<TokenResult>(getPrivateApiAURL() + 'mycumulusadmins/login', body, httpOptions)
    .pipe(map(
      res => {
        return res.token;
      }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  sendEmail(token, to, email, emailTemplateId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    const body = {
      email_address: to,
      body: email,
      type_email: emailTemplateId
    };
    return this.http.post<any>(getPrivateApiAURL() + 'email', body, httpOptions)
    .pipe(map(
      res => {
        return res.result;
      }
    ))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
