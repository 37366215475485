import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-activated',
  templateUrl: './activated.component.html',
  styleUrls: ['./activated.component.scss']
})
export class ActivatedComponent implements OnInit {
  constructor(
    private userService: UserService,
  ) {}

  ngOnInit() {
    var baseUrl = document.URL;
    var values = baseUrl.split('?');
    var importantValues = values[1].split('&')

    var body = {
      temporary_user: true,
      activation_code: "",
      company: "",
      country: "",
      industry: "",
    };

    for(var i = 0; i < importantValues.length; i++)
    {
      var tempValues = importantValues[i].split('=');
      switch (tempValues[0])
      {
        case 'token':
          body.activation_code = tempValues[1];
          break;

        case 'company':
          body.company = tempValues[1];
          break;

        case 'country':
          body.country = tempValues[1];
          break;

        case 'industry':
          body.industry = tempValues[1];
          break;
      }
    }

    this.userService.activateAccount(body).subscribe(
      _res => {},
      _error => {}
    )
  }
}
