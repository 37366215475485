import { Injectable } from '@angular/core';
import { User } from '../share/user';
import { Project } from '../share/projects';
import { Feature } from '../share/feature/feature';
import { FieldSet } from '../share/form/fieldset';
import { Field } from '../share/form/field';
import { getToken, persistTemplate, persistProject, getlastMenu, removeTemplate, getAdminToken, getUser, isCollaborator } from '../share/utils';
import { Template } from '../share/template';
import { Observable, BehaviorSubject } from 'rxjs';
import { Token } from '../share/token';

export const MAP_MODE = 'MAP_MODE';
export const SCHEMA_MODE = 'SCHEMA_MODE';
export const DATA_MODE = 'DATA_MODE';
export const IMPORT_DATA_MODE = 'IMPORT_DATA_MODE';
export const RECORD_DATA_MODE = 'RECORD_DATA_MODE';


@Injectable({
  providedIn: 'root'
})
export class StoreService {
  user: User | any;
  projects: Project[];
  templates: Template[];
  project: Project;
  template: BehaviorSubject<Template>;
  selectedTemplate: BehaviorSubject<string>;
  selectedProject: BehaviorSubject<string>;
  selectedFieldSet: BehaviorSubject<string>;
  typeOfIMKLFile: BehaviorSubject<string>;
  typeOfExcelFile: BehaviorSubject<string>;
  typeOfShapeFile: BehaviorSubject<string>;
  changedMultipleformsonmap: BehaviorSubject<number>;
  feature: Feature;
  features: Feature[];
  parentFieldSet: FieldSet;
  fieldSet: FieldSet;
  fields: Field[];
  token: Token;
  isMCAdmin: boolean;
  isCollaborator: boolean;
  loading: boolean;
  updatingForm: boolean;
  showprojects: boolean;
  hideProjectsContainer: boolean;
  hideTemplatesContainer: boolean;
  showMessage: boolean;
  templateInfo: boolean;
  templateData: boolean;
  importtemplateData: boolean;
  recordtemplateData: boolean;
  templateDataMap: boolean;
  importDataInNewForm: boolean;
  importIKMLFile: boolean;
  importExcelFile: boolean;
  importShapeFile: boolean;
  showmultipleformsonmap: boolean;
  formMode: string;
  mapLabel: any[];
  currentFieldSet: FieldSet | null;
  typeProject: String;
  visibleProject: BehaviorSubject<string>;
  proClass: string;
  visibility: string;
  archived: boolean;
  foundProjects: any;
  copiedFeatures: Feature[];
  deleteFeaturesAfterPasting: boolean;
  selectedFoundTemplate: BehaviorSubject<Template>;
  selectedFoundProject: BehaviorSubject<Project>;
  listFoundTemplates: Template[];
  listFoundFeatures: Feature[];
  searchMode: boolean;
  destinationProject: Project;
  destinationTemplate: Template;
  selectedFeatures: Feature[];
  customExport: BehaviorSubject<boolean>;
  /*
  import { Observable } from 'rxjs';

  const foo = new Observable(subscriber => {
    subscriber.next(42);
  });
  import { Subject } from 'rxjs';

  const subject = new Subject<number>();

  subject.subscribe({
    next: (v) => // console.log(`observerA: ${v}`)
  });
  subject.subscribe({
    next: (v) => // console.log(`observerB: ${v}`)
  });
  subject.next(1);
  subject.next(2);
  */

  constructor() {
    this.initializeStore();
  }

  initializeStore() {
    this.user = getUser();
    this.project = null;
    this.template = new BehaviorSubject<Template>(null);
    this.selectedTemplate = new BehaviorSubject<string>('');
    this.selectedProject = new BehaviorSubject<string>('');
    this.selectedFieldSet = new BehaviorSubject<string>('');
    this.typeOfIMKLFile = new BehaviorSubject<string>('');
    this.typeOfExcelFile = new BehaviorSubject<string>('');
    this.typeOfShapeFile = new BehaviorSubject<string>('');
    this.changedMultipleformsonmap = new BehaviorSubject<number>(null);
    this.templates = null;
    this.projects = null;
    this.feature = null;
    this.features = null;
    this.typeProject = null;
    this.parentFieldSet = null;
    this.currentFieldSet = null;
    this.fieldSet = null;
    this.fields = null;
    this.token = getToken() != null ? getToken() : getAdminToken();
    this.isMCAdmin = (getAdminToken() !== null) && (getAdminToken() !== undefined);
    this.isCollaborator = isCollaborator(this.user);
    this.loading = false;
    this.updatingForm = false;
    this.showprojects = (getToken() !== null || getAdminToken() !== null) && (getToken() !== undefined || getAdminToken() !== undefined);
    this.hideProjectsContainer = false;
    this.hideTemplatesContainer = false;
    this.showMessage = false;
    this.templateInfo = false;
    this.templateData = false;
    this.templateDataMap = false;
    this.importtemplateData = false;
    this.recordtemplateData = false;
    this.mapLabel = null;
    this.formMode = DATA_MODE;
    this.importDataInNewForm = true;
    this.visibleProject = new BehaviorSubject<string>('private active project');
    this.visibility= 'private';
    this.archived = false;
    this.proClass = 'project';
    this.foundProjects = [];
    this.copiedFeatures = [];
    this.selectedFeatures = [];
    this.selectedFoundTemplate = new BehaviorSubject<Template>(null);
    this.selectedFoundProject = new BehaviorSubject<Project>(null);
    this.searchMode = false;
    this.listFoundTemplates = null;
    this.listFoundFeatures  = null;
    this.destinationProject = null;
    this.destinationTemplate = null;
    this.deleteFeaturesAfterPasting = false;
    this.customExport = new BehaviorSubject<boolean>(false);
  }

  showTemplates() {
    this.templateInfo       = false;
    this.templateData       = false;
    this.templateDataMap    = false;
    this.recordtemplateData = false;
    this.importtemplateData = false;
    this.showmultipleformsonmap = false;
  }

  showCustomerExport(val) {
    this.customExport.next(val);
  }

  setFieldToShowOnMap(fields) {
    this.mapLabel = [];
    // this.mapLabel = this.mapLabel.concat(fields);
    this.mapLabel = fields;
  }

  showTemplateFeatureDataMap() {
    this.hideTemplate();

    // this.templateDataMap = true;
    this.showmultipleformsonmap = true;
  }

  changeProClassVisArchi(proClass, vis, arch = false) {
    this.proClass = proClass;
    this.visibility = vis;
    this.archived = arch;
  }

  showImportDataInTemplate() {
    this.hideTemplate();
    this.importtemplateData = true;
  }

  showImportIMKLData(typeOfFile) {
    this.typeOfIMKLFile.next(typeOfFile);
    this.hideTemplate();

    this.importIKMLFile = true;
  }

  showImportExcelData(typeOfFile) {
    this.typeOfExcelFile.next(typeOfFile);
    this.hideTemplate();

    this.importExcelFile = true;
  }

  showImportShapeFile(typeOfFile: any) {
    this.typeOfShapeFile.next(typeOfFile);
    this.hideTemplate();
    this.importShapeFile = true;
  }

  showTemplateFeatureData() {
    this.hideTemplate();
    this.templateData = true;
  }

  showTemplateInfo() {
    this.hideTemplate();
    this.templateInfo = true;
  }

  showRecordTemplateData() {
    this.hideTemplate();
    this.recordtemplateData = true;
  }

  showMultipleFormsOnMap() {
    this.hideTemplate();
    this.showmultipleformsonmap = true;
  }

  hideTemplate() {
    this.templateInfo = false;
    this.templateData = false;
    this.templateDataMap = false;
    this.importtemplateData = false;
    this.recordtemplateData = false;
    this.showmultipleformsonmap = false;
    this.importIKMLFile = false;
    this.importExcelFile = false;
    this.importShapeFile = false;
    this.customExport.next(false);
  }

  showOrHideProjectsContainer(status) {
    this.hideProjectsContainer = status;
  }

  changeFormUpdatingState(value) {
    this.updatingForm = value;
  }

  showOrHideTemplatesContainer(status) {
    this.hideTemplatesContainer = status;
  }

  setFoundProject(strValue: string): any {

  }

  persistUser(u) {
    this.user = u;
  }

  login(token, u ) {
    this.token = token;
    this.user = u;
  }

  updateProject(proj) {
    this.project = this.projects.find(p => p.id === proj.id);
    const index = this.projects.indexOf(this.project);
    // this.projects = [];
    this.projects = [
      ...this.projects.slice(0, index),
      Object.assign({}, proj),
      ...this.projects.slice(index + 1)
    ].sort(
      (a, b) => {
        return this.equalsIgnoringCase(a.name, b.name);
      });
    // this.readProject(proj);
  }

  equalsIgnoringCase(text, other) {
    return text.localeCompare(other, undefined, { sensitivity: 'base' });
  }

  readTemplates(res) {
    this.templates = res.sort(
      (a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    
  }

  createTemplate(newTem) {
    persistTemplate(newTem);
    const tempExist = this.templates.findIndex( t => t.name === newTem.name);
    if (tempExist < 0) {
      this.templates = this.templates.concat(Object.assign({}, newTem)).sort(
      (a, b) => {
        return this.equalsIgnoringCase(a.name, b.name);
      });
    }

    this.template.next(newTem);
    if(this.proClass ===  "project") {
      this.showTemplateFeatureData();
    } else {
      this.showTemplateInfo();
    }
    
  }

  updateTemplate(newTemplate) {
    const templ = this.templates.find(t => t.id === newTemplate.id);
    const index = this.templates.indexOf(templ);
    this.template.next(newTemplate);
    // this.templates = [];
    this.templates = [
      ...this.templates.slice(0, index),
      Object.assign({}, newTemplate),
      ...this.templates.slice(index + 1)
    ].sort(
      (a, b) => {
        return this.equalsIgnoringCase(a.name,  b.name);
      });
    this.readTemplate(newTemplate);
  }

  changeRootFieldSet(fset) {
    this.fieldSet = fset;
  }

  editFieldSet(template) {
    this.parentFieldSet = null;
    this.fieldSet = null;

    this.template.next(template);
    this.template.subscribe(t => {
      this.fields = t.form.fields;
    });
  }

  addField(f) {
    let parent = this.parentFieldSet;
    if (parent === undefined || parent === null) {
      if (this.fields === null || this.fields === undefined) {
        this.fields = [];
      }
      parent = {
        ...parent,
        fields: this.fields.concat(Object.assign({}, f))
      };
    } else {
      parent.fields.concat(Object.assign({}, f));
    }
    this.parentFieldSet = parent;
    this.fields = this.fields.concat(Object.assign({}, f));
  }

  updateField(f) {

  }

  saveToken(tok) {
    this.token = tok;
  }

  showLoading() {
  this.loading = false;
  }

  hideLoading() {
    // // console.log('hiding progress bar');
    this.loading = true;
  }

  show_Projects() {
    this.showprojects = true;
  }

  hideProjects() {
    this.showprojects = false;
  }

  logout() {
    this.initializeStore();
    this.showprojects = false;
  }

  readProjects(projects) {
    if(projects !== null && this.projects !== undefined && projects.length === 0) {
      this.projects = [];
    }
    this.projects = projects.sort(
      (a, b) => {
        return this.equalsIgnoringCase(a.name, b.name);
    });
  }

  readProject(p: Project) {
    this.project = p;
    persistProject(p);
  }

  readProjectById(id: string) {
    this.project = this.projects.find(p => p.id === id);
    persistProject(this.project);
  }

  deleteTemplate(t) {
    removeTemplate();
    this.templates = this.templates.filter(temp => temp.id !== t.id);
    this.template.next(null);
    this.showTemplates();
  }

  deleteProject(p) {
    this.projects = this.projects.filter(project => p.id !== project.id);
    this.project = null;
    this.templates = null;
    this.templateInfo = false;
    this.templateData = false;
    this.templateDataMap = false;
    this.template.next(null);
  }

  createProject(p) {
    this.projects.push(p);
    this.projects = this.projects.sort(
    (a, b) => {
      /*if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;*/
      return this.equalsIgnoringCase(a.name, b.name);
    });
    this.readProject(p);
  }

  isSelectedProject(p): boolean {
      if (p.name === this.selectedProject.value) {
        return true;
      }
      return false;
  }

  isSelectedTemplate(t): boolean {
    if (t.name === this.selectedTemplate.value) {
      return true;
    }
    return false;
  }

  isSelectedFieldSet(f): boolean {
    if (f.name === this.selectedFieldSet.value) {
      return true;
    }
    return false;
  }

  readTemplate(templ) {
      this.template.next(templ);
      let fields = [];
      if (templ !== null && templ !== undefined && templ.form !== undefined && templ.form !== null) {
        fields = templ.form.fields;
      }
      const parentFieldSet = {
          ...this.parentFieldSet,
          fields
      };
      this.parentFieldSet = parentFieldSet;
      this.fieldSet = {
        ...this.fieldSet,
        fields
      };
      this.fields = fields;
      if (templ !== null && templ !== undefined) {
        persistTemplate(templ);
      } else {
        removeTemplate();
      }
  }
}
