import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { persistApiURL, URL,  getApiAURL, removeApiURL, getAtStartup } from 'src/app/share/utils';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-config-server-url',
  templateUrl: './config-server-url.component.html',
  styleUrls: ['./config-server-url.component.scss']
})
export class ConfigServerUrlComponent implements OnInit {

  configUrlForm: FormGroup;
  urlEmpty: boolean;
  @ViewChild('fform', {static: false}) audioFieldFormDirectives;
  setStartup: boolean;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ConfigServerUrlComponent>
  ) { }

  ngOnInit() {
    this.createForm();
    this.setStartup = getAtStartup();
  }

  createForm() {
    this.urlEmpty = false;
    let currentApiUrl = getApiAURL();
    if (URL === currentApiUrl) {
      currentApiUrl = '';
    }
    this.configUrlForm = this.fb.group({
      url: [currentApiUrl, [Validators.required, Validators.min(8)]]
    });
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
    event.stopPropagation();
  }

  setPrivateStartup(value: boolean){
    this.setStartup = value;
  }

  onSubmit() {
    this.urlEmpty = false;
    const value = this.configUrlForm.value;
    if (value) {
      let url = value['url'];
      if (url) {
        // Save the url in the localstorage
        if(url.trim().length <= 0) {
          this.urlEmpty = true;
          return;
        } else {
          persistApiURL(url.trim(), this.setStartup);
          this.dialogRef.close();
        }

      } else {
        removeApiURL();
      }
    } else {
      removeApiURL();
    }
    this.dialogRef.close();
  }

}
