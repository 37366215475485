import { Component, OnInit, Input, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { Template, NewTemplate } from 'src/app/share/template';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { TemplateService } from 'src/app/services/template.service';
import { getToken, getProject, getAdminToken } from 'src/app/share/utils';
import { FeatureSchema } from 'src/app/share/schema/schema';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { FileServiceService } from 'src/app/services/file-service.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { CreateStyleComponent } from '../create-style/create-style.component';
import { StyleServiceService } from 'src/app/services/style-service.service';
import { Style } from 'src/app/share/style';
import { FeatureService } from 'src/app/services/feature.service';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent implements OnInit {
  project: Project;
  template: Template;
  newTemplate: NewTemplate;
  templateDialogForm: FormGroup;
  loading: boolean = false;
  @Input() user: User;
  @Input() mode = 'Create';
  geomTypeList = ['Point', 'LineString', 'Polygon'];
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @ViewChild('imgIcon', {static: false}) img;

  name: string;
  description: string;
  geomtryType: string;
  geometryTemplate: boolean = false;
  styleName: Style;
  image: string;
  styleCaption = 'Style';

  @ViewChild('fform', { static: false}) templateFormDirective;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private fileService: FileServiceService,
    private dialog: MatDialog,
    private templateService: TemplateService,
    private featureService: FeatureService,
    private styleService: StyleServiceService,
    private dialogRef: MatDialogRef<CreateTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    if (data !== undefined && data !== null) {
      this.template = data.template;
      this.mode = data.mode;
    }
    if((this.mode === "Create") && (this.template === null || this.template === undefined) ) {
      this.template = new Template();
    }
    if(this.template.feature !== null && this.template.feature !== undefined &&
      this.template.feature.geometry_type !== null &&
      this.template.feature.geometry_type !== ""){
      this.geometryTemplate = true;
    } else {
      this.geometryTemplate = false;
    }
    this.project = getProject();
    this.createForm();
  }

  getGeometryTypeOnChange(obj) {
    const geometry = obj.value;
    if(geometry !== null && geometry !== undefined && geometry !== "") {
      if(geometry === "Point" || geometry === "LineString" || geometry === "Polygon"){
        //if(this.template && this.template.id) {
          this.geometryTemplate = true;
       // }
        
      }
    } else {
      this.geometryTemplate = false;
    }
  }

  ngOnInit() {
    if((this.template != null && this.template !== undefined) 
      && (this.template.default_style_id !== null 
      && this.template.default_style_id !== undefined && this.template.default_style_id !== "null")) {
        this.getStyle(this.template.default_style_id);
    }
  }

  createForm() {
    if (this.mode === 'Create') {
      this.templateDialogForm = this.fb.group({
        name: ['', Validators.required],
        description: '',
        image_file_url: '',
        geometry_type: ''
      });
    } else {
      const feature = this.template.feature as FeatureSchema;
      const defaultStyleId = this.template.default_style_id;
      this.templateDialogForm = this.fb.group({
        name: [this.template.name, Validators.required],
        description: this.template.description,
        image: this.template.image_file_url,
        geometry_type: feature.geometry_type
      });
      this.image = this.template.image_file_url;
    }
  }

  getStyle(styleId) {
    let isMyCumulusAdministrator = false;
    let token = getToken();
    
    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator = true;
      }
    } 
    this.store.showLoading();
    this.styleService.getStyle(styleId, token, isMyCumulusAdministrator).subscribe(
      res => {
        if(res !== null) {
          this.styleName = res;
          this.styleCaption = 'Style';
        }
        this.store.hideLoading();
      },
      errmess => {
        this.styleName = null;
        this.template.default_style_id = null;
        this.showErrorMessage(errmess + "\n Default style is set to null.");
        this.store.hideLoading();
    });
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  invalidIconDrop(icon: boolean) {
    this.invalidIconDropped.emit(icon);
  }

  uploadFile(token, temp, isMyCumulusAdministrator = false){
    const content = {
      filename: this.getUniqueFilename(temp.name, this.getExtensionFromDataURI(this.img.image)),
      base64_encoded_content : this.img.image.split(',')[1]
    }

    this.store.showLoading();
    this.fileService.uploadPrivateFile(content, token, isMyCumulusAdministrator).subscribe(
      res => {
        const fileUrl = res.file.download_url;
        const newTemplate = {
          name: temp.name,
          description: temp.description,
          project_id: this.project.id,
          image_file_url: fileUrl,
          feature: {
            geometry_type: temp.geometry_type
          }
        };
        let tempToken;
        if(isMyCumulusAdministrator) {
          tempToken =  {
            key: "X-Admin-Auth-Token",
            value: token.key
          };
        } else {
          tempToken =  {
            key: "X-Auth-Token",
            value: token.key
          };
        }
        if(this.mode === 'Create'){
          this.templateService.createTemplate(newTemplate, this.project.id, tempToken, isMyCumulusAdministrator).subscribe(
            result => {
              this.store.createTemplate(result);
              this.template = result;
              this.store.showTemplateInfo();
              this.store.hideLoading();
              this.dialogRef.close();
            },
            errmess => {
              this.showErrorMessage(errmess);
              this.store.hideLoading();
          });
        } else {
          temp.image_file_url = fileUrl;
          this.templateService.updateTemplate(temp, this.project.id, tempToken, isMyCumulusAdministrator).subscribe(
            result => {
              this.store.updateTemplate(result);
              this.template = result;
              this.store.hideLoading();
              this.dialogRef.close();
            },
            errmess => {
              this.showErrorMessage(errmess);
              this.store.hideLoading();
          });
        }
      },
      err => {
        this.showErrorMessage(err);
        this.store.hideLoading();
      }
    );
  }

  getUniqueFilename(prefix, extension): string {
    return prefix.replace(/\s/g, '') + '_' + Date.now() + '.' + extension;
  };

  getExtensionFromDataURI(data){
    return data.split(';')[0].split('/')[1];
  };

  getIconFile(image){
    if(image.split(':')[1] === '' || image === '' || image === undefined){
      return '';
    } else {
      return this.template.image_file_url;
    }
  }

  removeDefaultStyle() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: `Are you sure to remove the style ${this.styleName.name} in the template ${this.template.name}?`,
        title: `Remove Style ${this.styleName.name}?`
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.template.default_style_id = null;
        this.styleName = null;
      }
    });
  }

  onSubmit() {
    const temp = this.templateDialogForm.value;
    let isMyCumulusAdministrator = false;
    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token) {
        isMyCumulusAdministrator = true;
      }
    }
    if(token === null || token === undefined) {
      this.showErrorMessage("No user connected");
      return;
    }
    
    const image = this.img.image;
    if (this.mode === 'Create') {
      this.newTemplate = {
        name: temp.name,
        description: temp.description,
        project_id: this.project.id,
        image_file_url: '',
        feature: {
          geometry_type: temp.geometry_type
        }
      };

      if(image !== '' && image.split(':')[0] !== 'https' && image.split(':')[0] !== 'http'){
        this.uploadFile(token, temp, isMyCumulusAdministrator);
      } else {
        const tempToken =  {
          key: "X-Auth-Token",
          value: token.key
        };
        this.store.showLoading();
        this.templateService.createTemplate(this.newTemplate, this.project.id, tempToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.store.createTemplate(res);
            this.template = res;
            this.store.showTemplateInfo();
            this.store.hideLoading();
            this.dialogRef.close();
          },
          errmess => {
            this.showErrorMessage(errmess.error.error);
            this.store.hideLoading();
        });
      }
    } else if (this.mode === 'Update') {
      this.template = {
        ...this.template,
        name: temp.name,
        description: temp.description,
        image_file_url: this.getIconFile(image)
      };
      const feature = {
        ...this.template.feature,
        geometry_type: temp.geometry_type
      };
      const oldType = this.template.feature.geometry_type;

      if (oldType !== temp.geometry_type) {
        this.loading = true;
        const token = getToken();
        this.featureService.countFeatures(this.template, this.project, token).subscribe(
          res => {
            const itemsnbr = res['number_of_features']
            if (itemsnbr > 0) {
              this.showErrorMessage('You cannot change the type of form containing collected data ');
              return;
            } else {
              const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '600px',
                data: {
                  message: "Your are updating the form's type, do you want to continue?",
                  title: 'Do you really want to update the type of the form?'
                }
              });
      
              dialogRef.afterClosed().subscribe(result  => {
                if (result) {
                  this.dialogRef.close();
                  
                  if(image !== '' && image.split(':')[0] !== 'https' && image.split(':')[0] !== 'http'){
                    this.uploadFile(token, this.template, isMyCumulusAdministrator);
                  } else {
                    const tempToken =  {
                      key: "X-Auth-Token",
                      value: token.key
                    };
                    
                    this.template.feature = feature;
                    this.templateService.updateTemplate(this.template, this.project.id, tempToken, isMyCumulusAdministrator).subscribe(
                      res => {
                        this.store.updateTemplate(res);
                        this.template = res;
                        this.store.hideLoading();
                      },
                      errmess => {
                        this.showErrorMessage(errmess);
                        this.store.hideLoading();
                    });
                  }
                } else {
                  this.store.hideLoading();
                  this.dialogRef.close();
                  return;
                } 
              });
            }
            this.loading = false;
          }, er => {
            console.log('Erreur: ', er);
            this.showErrorMessage('An error, occurs, please retry again !!!!');
            this.loading = false;
            return;
          }
        )
      } else {
        if(image !== '' && image.split(':')[0] !== 'https' && image.split(':')[0] !== 'http'){
          this.uploadFile(token, this.template, isMyCumulusAdministrator);
        } else {
          const tempToken =  {
            key: "X-Auth-Token",
            value: token.key
          };
          this.store.showLoading();
          this.templateService.updateTemplate(this.template, this.project.id, tempToken, isMyCumulusAdministrator).subscribe(
            res => {
              this.store.updateTemplate(res);
              this.template = res;
              this.store.hideLoading();
              this.dialogRef.close();
            },
            errmess => {
              this.showErrorMessage(errmess);
              this.store.hideLoading();
          });
        }
      }
    }
  }

  addDefaultStyleToTemplate(index = null, mode = 'Add') {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      template: this.template,
      style: this.styleName,
      mode: mode
    };

    const dialogRef = this.dialog.open(CreateStyleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined ){
        if(result.changeStyle) {
          if(result.style_id !== null && result.style_id !== undefined
            && result.style_id !== "" && result.style_id !== "null") {
              this.template.default_style_id = result.style_id;
              this.getStyle(result.style_id);
          } else {
            this.template.default_style_id = null;
            this.styleName = null;
          }
        }
      }
    });
    return;
  }
}


