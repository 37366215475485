import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { url } from 'src/app/share/utils';
import { FileServiceService } from 'src/app/services/file-service.service';
import { getAdminToken, getToken } from 'src/app/share/utils';

@Component({
  selector: 'app-image-drop',
  templateUrl: './image-drop.component.html',
  styleUrls: ['./image-drop.component.scss']
})
export class ImageDropComponent implements OnInit {

  @ViewChild('icon', {static: false}) img;
  @Input() image: string;
  @Input() helpMessage: string;
  @Input() width: number;
  @Input() height: number;

  @Output() invalidIconDropped = new EventEmitter();

  remove: boolean;

  constructor(
    private fileService: FileServiceService
  ) {
   }

  ngOnInit() {

    this.width = 100;
    this.height = 100;
    this.helpMessage = 'Set item icon by dragging and dropping and image in the space';
    // this.helpMessage = 'Set icon: drag image here.';
    this.image = this.setImage();
    this.remove = this.isImageExist('');
  }

  isImageExist(image) {
    if(image !== '' && image !== null && image !== undefined){
      return false;
    } else {
      return true;
    }
  }

  setImage() {
    if (this.image !== '' && this.image !== null && this.image !== undefined) {
      return url(this.image);
    } else {
      return '';
    }
  }

  dragStart(event: DragEvent) {
    event.preventDefault();
    console.log(' Drag start');
    event.stopPropagation();
  }

  dragEnter(event: DragEvent) {
    event.preventDefault();
    console.log(' Drag enter');
    event.stopPropagation();
  }

  dragOver(event: DragEvent) {
    event.preventDefault();
    //this.img.style.borderColor = '#424242';
    event.stopPropagation();
  }

  dragLeave(event: DragEvent) {
    //this.img.style.borderColor = '#9e9e9e';
  }

  drop(event: DragEvent) {

    console.log(' Drag drop');
    event.preventDefault();
    //this.img.style.borderColor = '#9e9e9e';

    let files = event.dataTransfer.files;
    if (files.length < 0) {
      return;
    }

    const file = files[0];
    if (!file.type.match(/image.*/)) {
      this.invalidIconDropped.emit(true);
      // this.toastr.error('Invalid file drop', 'MyCumulus');
      return;
    }

    let imag = this.img;
    imag.file = file;

    const reader = new FileReader();
    reader.onload = ((i) => {
      return (e) => {
        i.src = e.target.result;
        this.image = i.src;
      };
    })(imag);

    this.remove = true;
    reader.readAsDataURL(file);
    event.stopPropagation();
  }

  removeImage() {
    // let token = getToken();
    // if(token === null || token === undefined)
    //   token = getAdminToken();

    // if(this.image !== '' && this.image !== null && this.image !== undefined) {
    //   var vals = this.image.split('/');
    //   this.fileService.deletePublicFile(token, vals[vals.length - 2], vals[vals.length - 1]).subscribe(
    //     res => {
          this.image = '';
          this.remove = false;
          this.helpMessage = '';
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
    // }

  }

}
