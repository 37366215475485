import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  @Input() message: string;
  @Input() type: 'success';
  wrapperstatus: string;

  constructor(
  ) {
   }

  ngOnInit() {
    this.showSuccess();
  }

  showSuccess() {
    this.display('success');
  }

  showError() {
    this.display('error');
  }

  showCritical() {
    this.display('critical');
  }

  display(type) {
    this.type = type;
    this.show();
    setTimeout(() => {
      // this.message = '';
      this.hide();
    }, (10000));
  }

  show() {
    this.wrapperstatus = 'block';
  }

  hide() {
    this.wrapperstatus = 'none';
  }
}
