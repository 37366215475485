import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import { Feature } from 'src/app/share/feature/feature';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { FileServiceService } from 'src/app/services/file-service.service';
import { url, getToken, getProject, WARNING, SUCCESS, ERROR, CRITICAL, getFieldToShowOnMap, getCRSSetting } from 'src/app/share/utils';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrsService } from 'src/app/services/crs.service';
import { AttributeSet } from 'src/app/share/feature/attributes';
import { Template } from 'src/app/share/template';

export class Data {
  key: string;
  value: string;
  file: boolean;
  fileloading: boolean;
}
@Component({
  selector: 'app-map-item-dialog',
  templateUrl: './map-item-dialog.component.html',
  styleUrls: ['./map-item-dialog.component.scss']
})
export class MapItemDialogComponent implements OnInit {

  feature: Feature;
  items: Data[];
  file: BehaviorSubject<boolean>;
  medialink: string;
  imageFile: boolean;
  audioFile: boolean;
  videoFile: boolean;
  extension: string;
  filetype: string;
  fileloading: boolean;
  fields = [];
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  selectedImage: string;

  constructor(
    public store: StoreService,
    private conversionCoordinateService: CrsService,
    @Inject(MAT_DIALOG_DATA) data,
    private fileService: FileServiceService,
    private dialogRef: MatDialogRef<MapItemDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {
      this.items = [];
      if (data !== null && data !== undefined) {
        this.feature = data.feature;
        
      }
  }

  ngOnInit() {
    this.initializeAttributes();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  initializeAttributes() {
    const fields = getFieldToShowOnMap();
    if (this.feature === null || this.feature === undefined) {
      return;
    }
    
    const geom = this.feature.geometry;

    if(geom.type === 'Point') {
      const crs = getCRSSetting();
      if(!(crs === null || crs === undefined || crs === '' || crs === 'Geodetic')) {
        this.constructAsyncCoord(geom.coordinates, crs, this.items);
      } else {
        const geomItems = this.constructCoordinate(geom.coordinates);
        this.items = [
          ...geomItems
        ]
        this.constructNonGeomData();
      }
    } else {
      this.constructNonGeomData();
    }
  }

  showImage(image: string): void {
    if (this.selectedImage) {
      this.selectedImage = null;
    } else {
      this.selectedImage = image;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  setFieldToShow(feature: Feature) {
    const templs = this.store.templates.filter(t => t.id == feature.template_id);

    if(templs && templs.length > 0) {
      const selectedTemplate = templs[0];
      this.saveFieldsAndLabels(selectedTemplate.feature.attributes, selectedTemplate);
      const labels = [];
      const keys = Object.keys(this.fields);
      for (const key of keys) {
        const value = this.fields[key];
        if (value['template_to_show'] !== null) {
          const fieldname = value['label'];
          labels.push(fieldname);
        }
      }
      this.store.setFieldToShowOnMap(labels);
    }

  }

  saveFieldsAndLabels(attributes: any[], template: Template) {
    this.fields = [];
    this.fields.push({
      template_to_show: template.id
    });
    this.saveFields(attributes);
  }

  saveFields(attributes: any, parent= '') {
    const name = 'name';
    const _class = '_class';
    for (const attribute of attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const value = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        const label = {
          label: value
        };
        this.fields.push(label);
      } else if (typeAttr === 'attributeset') {
        const nested_parent = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        this.saveFields((attribute as AttributeSet).attributes, nested_parent);
      }
    }
  }

  constructNonGeomData() {
    let attributes = {};
    this.convertObjectToTabular(null, this.feature.attributes, attributes);
    
    this.setFieldToShow(this.feature);
    const keys = this.store.mapLabel;
    if(keys) {
      for (const key of keys) {
        if (key === null || key === undefined || key === 'template_to_show') {
        } else {
          let value = attributes[key];
          if (!(value instanceof Object) ) {
            const item = {
              key,
              value,
              file: false,
              fileloading: false
            };
            this.items.push(item);
          } else if (value instanceof Array) {
            let val = '';
            if (value.length > 0) {
              val = value[0];
              if (this.isFileValue(val)) {
                this.fileloading = true;
                this.medialink = val;
                const item = {
                  key,
                  value: val,
                  file: this.isFileValue(val),
                  fileloading: true
                };
                this.items.push(item);
                this.getPrivateFile(val, item);
              } else {
                const item = {
                  key,
                  value: val,
                  file: false,
                  fileloading: false
                };
                this.items.push(item);
              }
            } else {
              value = '';
              const item = {
                key,
                value,
                file: false,
                fileloading: false
              };
              this.items.push(item);
            }
          }
        }
      }
    }
  }

  convertObjectToTabular(parentKey, obj, feature) {
    const keys = Object.keys(obj);
    for (const k of keys) {
      const value = obj[k];
      let key = k;
      if (parentKey && parentKey.length > 0) {
        key = parentKey + '.' + k;
      }
      
      if (!(value instanceof Object) ) {
        feature[key] = value;
      } else if (value instanceof Array) {
        feature[key] = value;
      } else {
        this.convertObjectToTabular(key, value, feature);
      }
    }

  }

  constructAsyncCoord(coordinates, crs, items) {
    const token = getToken();
      this.store.showLoading();
      // const coords =  [
      //     {
      //         "latitude": coordinates[0].toFixed(2),
      //         "longitude": coordinates[1].toFixed(2),
      //         "altitude": coordinates[2].toFixed(2),
      //         "accuracy": "?"
      //     }
      // ];
      const coords =  [
        {
            "latitude": coordinates[0],
            "longitude": coordinates[1],
            "altitude": coordinates[2],
            "accuracy": "?"
        }
      ];
      this.conversionCoordinateService.convertCRS(token, crs, coords).subscribe(
        result => {
          let res = {};
          if(result.length > 0) {
            res = result[0];
            const key = 'X, Y, Z';
            const value = `${res['X'].toFixed(2)}, ${res['Y'].toFixed(2)}, ${res['Z'].toFixed(2)}`
            const coordValue = {
              key: key,
              value: value,
              file: false,
              fileloading: false
            }
            
            items.push(coordValue);
          }
          this.constructNonGeomData();
          
          this.store.hideLoading();
      },
      err => {
        // // console.log(err);
        this.store.hideLoading();
      }
    );
  }

  constructCoordinate(coordinates): any[] {
    const geomItems = [];
    const crs = getCRSSetting();
    if(crs === null || crs === undefined || crs === '' || crs === 'Geodetic') {
      const key = 'Lat, Long, Alt';
      const value = `${coordinates[0].toFixed(8)}, ${coordinates[1].toFixed(8)}, ${coordinates[2].toFixed(8)}`
      const coordValue = {
        key: key,
        value: value,
        file: false,
        fileloading: false
      }
      // const x = {
      //   key: 'Latitude',
      //   value: coordinates[0],
      //   file: false,
      //   fileloading: false
      // }
      // geomItems.push(x);
      // const y = {
      //   key: 'Longitude',
      //   value: coordinates[1],
      //   file: false,
      //   fileloading: false
      // }
      // geomItems.push(y);
      // const z = {
      //   key: 'Altitude',
      //   value: coordinates[2],
      //   file: false,
      //   fileloading: false
      // }
      geomItems.push(coordValue);
    }
    return geomItems;
  }

  isFileValue(item) {
    return this.ifMediaIsImage(item) || this.ifMediaIsVideo(item) || this.ifMediaIsAudio(item);
  }

  isFile(item) {
    if (item instanceof Object) {
      if (item instanceof Array) {
        this.file.next(this.ifMediaIsImage(item[0]) || this.ifMediaIsVideo(item[0]) || this.ifMediaIsAudio(item[0]));
        // this.getPrivateFile(item[0]);
        return this.file;
      }
    } else {
      this.file.next(this.ifMediaIsImage(item) || this.ifMediaIsVideo(item) || this.ifMediaIsAudio(item));
      // this.getPrivateFile(item);
      return this.file;
    }
    return false;
  }

  getValue(data): string {
    if (this.checkIfDate(data)) {
      return data.split('T')[0];
    }
    return data;
  }
  // check if the value is date format or not.
  checkIfDate(value) {
    if (value !== null && value !== undefined && value !== '' && typeof value === 'string') {
      const colomValues = value.split(':');
      const slashValues = value.split('-');
      if (colomValues.length > 2 && slashValues.length > 2) {
        /*
        if (this.checkTypeDateInFeatureSchema(this.featureSchema.attributes, name)) {
          return true;
        }
        */
        return true;
      }
    }
    return false;
  }

  getPrivateFile(medialink, item) {
    this.checkMediaType();
    this.filetype = this.getMime();
    const token = getToken();
    const project = getProject();
    // this.store.showLoading();
    item.fileloading = true;
    const link = url(medialink);
    this.fileService.getPrivateFile(link, token, project).subscribe(
      res => {
        this.loadImage(res, item.key);
        if (this.imageFile) {
          this.loadImage(res, 'img_full');
        }
        item.fileloading = false;
        this.store.hideLoading();
      },
      err => {
        // this.fileloading = false;
        this.showBoxMessage(err);
        item.fileloading = false;
        this.store.hideLoading();
      });
  }

  showBoxMessage(msg, type = WARNING) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      if (type === SUCCESS) {
        this.messageBox.showSuccess();
      } else if (type === ERROR) {
        this.messageBox.showError();
      } else if (type === WARNING) {
        this.messageBox.showCritical();
      } else if (type === CRITICAL) {
        this.messageBox.showCritical();
      }
    });
  }

  getMediaFromAPI() {
    const token = getToken();
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
  }

  getMime(): string {
    let t = '';
    if (this.audioFile) {
      t = `audio/${this.extension}`;
    } else if (this.videoFile) {
      t = `video/${this.extension}`;
    }
    return t;
  }

  checkMediaType() {
    const fileAndType = this.medialink.split('.');
    if (fileAndType !== null && fileAndType.length > 1) {
      this.extension = fileAndType[fileAndType.length - 1];

      if (this.ifMediaIsImage(this.medialink)) {
        this.imageFile = true;
        this.audioFile = false;
        this.videoFile = false;
      } else if (this.ifMediaIsVideo(this.medialink)) {
        this.imageFile = false;
        this.audioFile = false;
        this.videoFile = true;
      } else if (this.ifMediaIsAudio(this.medialink)) {
        this.imageFile = false;
        this.audioFile = true;
        this.videoFile = false;
      }
    }
  }

  ifMediaIsImage(value) {
    if(typeof(value) !== 'string') {
      return false;
    }
    const reg = value.match(/\.(jpeg|jpg|gif|png)$/);
    return(reg !== null);
  }

  ifMediaIsAudio(value) {
    if(typeof(value) !== 'string') {
      return false;
    }
   const reg = value.match(/\.(mp3|wav|raw|ogg)$/);
   return(reg !== null);
  }

  ifMediaIsVideo(value) {
    if(typeof(value) !== 'string') {
      return false;
    }
    const reg = value.match(/\.(mp4|3gp|avi|vob|flv|mpeg)$/);
    return(reg !== null);
  }

  createFile() {
    //  // console.log('');
  }

  loadImage(response, key) {
    let image = null ;
    if (this.imageFile) {
      image = document.getElementById(key);
    } else if (this.audioFile) {
      image = document.getElementById(key);
    } else if (this.videoFile) {
      image = document.getElementById(key);
    }
    if (this.audioFile || this.videoFile) {
      image.src = window.URL.createObjectURL(response);
      image.load();
      image.loop = true;
      this.medialink = window.URL.createObjectURL(response);
    } else {
      const reader = new FileReader();
      reader.onload = ( (i) => {
        return function(e) {
          if (i != null ) {
            i.src = e.target.result;
          }
        };
      })(image);
      reader.readAsDataURL(response);
    }
    // this.fileloading = false;
  }

}
