import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FieldSet, FieldsetData } from 'src/app/share/form/fieldset';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { Field } from 'src/app/share/form/field';

@Component({
  selector: 'app-fieldset-data',
  templateUrl: './fieldset-data.component.html',
  styleUrls: ['./fieldset-data.component.scss']
})
export class FieldsetDataComponent implements OnInit {

  @Input() fieldset: FieldSet;

  @Output() saveData = new EventEmitter<FieldsetData>();
  @Output() closeForm = new EventEmitter<any>();

  fieldsetData: FieldsetData;
  fieldsetDataForm: FormGroup;

  @Input() parent: FieldSet;
  @Input() mode = 'Create';

  @ViewChild('fform', { static: false}) fieldsetFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  multiple = true;

  constructor(
    private fb: FormBuilder,
    private store: StoreService
  ) { }

  ngOnInit() {
    console.log(JSON.stringify(this.fieldset));
    this.createForm();
  }

  createForm() {
    const obj = {};
    for (const f of this.fieldset.fields) {
      const name = f.name;
      const isRequired = f.required;
      const defaultValue = f.default_value ? f.default_value : '';
      obj[name] = defaultValue;
    }

    this.fieldsetDataForm = this.fb.group(obj);
  }

  onSubmit() {
    const data = this.fieldsetDataForm.value;
    console.log(JSON.stringify(data));
    this.saveData.emit(data);
  }

  isFileField(f: Field): boolean {
    if (f._class === 'videofield' || f._class === 'photofield' || f._class === 'audiofield') {
      return true;
    }
    return false;
  }

  isNumberField(f: Field): boolean {
    if (f._class === 'integerfield' || f._class === 'autoincrementfield' || f._class === 'decimalfield') {
      return true;
    }
    return false;
  }

  isTextField(f: Field): boolean {
    if (f._class === 'stringfield' || f._class === 'notefield' || f._class === 'barcodefield') {
      return true;
    }
    return false;
  }

  isDateField(f: Field): boolean {
    if (f._class === 'datefield') {
      return true;
    }
    return false;
  }

  isTimeField(f: Field): boolean {
    if (f._class === 'timeField') {
      return true;
    }
    return false;
  }

  isBooleanField(f: Field): boolean {
    if (f._class === 'booleanField' ) {
      return true;
    }
    return false;
  }

  closeRecord(): void {
    this.closeForm.emit();
  }

  isFieldSet(f: Field): boolean {
    return f._class === 'fieldset' ||
      f._class === 'fieldsetarray' || f._class === 'arrayfieldset';
  }

}
