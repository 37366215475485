export class Document {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_by: string;
  updated_by: string;
  deleted_by: string;

  constructor() {
    this.id = '';
    this.created_at = '';
    this.updated_at = '';
    this.deleted_at = '';
    this.created_by = '';
    this.updated_by = '';
    this.deleted_by = '';
  }
}
