import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { removeCSRSetting, persistCRSSetting } from 'src/app/share/utils';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-setting-coordinates-reference-dialog',
  templateUrl: './setting-coordinates-reference-dialog.component.html',
  styleUrls: ['./setting-coordinates-reference-dialog.component.scss']
})
export class SettingCoordinatesReferenceDialogComponent implements OnInit {

  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  placeholdername: string;

  crsSettingForm: FormGroup;
  crsSetting: any;

  @ViewChild('fform', { static: false}) crsSettingFormDirective;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<SettingCoordinatesReferenceDialogComponent>
  ) {
    if (data !== undefined && data !== null) {
      const crs = data.crs;
      if (crs !== null && crs !== undefined) {
        this.crsSetting = this.crsList.find(c => c.value === crs);
      } else {
        this.crsSetting = this.crsList[0];
      }
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    let crsValue = '';
    if (this.crsSetting !== null && this.crsSetting !== undefined) {
      crsValue = this.crsSetting.value;
    }
    this.crsSettingForm = this.fb.group({
      crs: [crsValue, Validators.required],
    });
  }

  closeDialog(e) {
    e.preventDefault();
    const result = {
      event: 'Cancel'
    };
    this.dialogRef.close();
    e.stopPropagation();
  }

  onSubmit() {
    const data = this.crsSettingForm.value;
    this.crsSetting = data.crs;
    removeCSRSetting();
    persistCRSSetting(this.crsSetting);
    this.dialogRef.close();
  }

}

