import { Component, OnInit, ViewChild, Inject, AfterViewInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatTable } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { getToken, SUCCESS, WARNING, ERROR, CRITICAL, getAdminToken, getUser } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';

export interface PlaceHolder {
  name: string;
  default_value: string;
}

const ELEMENT_DATA: PlaceHolder[] = [];

@Component({
  selector: 'app-report-template-dialog',
  templateUrl: './report-template-dialog.component.html',
  styleUrls: ['./report-template-dialog.component.scss']
})
export class ReportTemplateDialogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'default_value', 'action'];
  dataSource = new MatTableDataSource<PlaceHolder>(ELEMENT_DATA);
  selection = new SelectionModel<PlaceHolder>(true, []);
  placeholdername: string;

  reportTemplateForm: FormGroup;
  reportTemplate: any;
  reportTemplateType: string;
  isReTeAdmin: boolean;

  mode = 'Create';
  formats = [
    { value: 'A4', text: 'A4' },
    { value: 'A3', text: 'A3' },
    { value: 'A2', text: 'A2' }
  ];

  orientations = [
    { value: 'portrait', text: 'Portrait' },
    { value: 'landscape', text: 'Landscape' }
  ];

  accesstypes = [
    { value: 'all', text: 'Public' },
    { value: 'mc_admins', text: 'MC Administrator' }
  ];

  @ViewChild('fform', { static: false}) reportTemplateFormirective;
  @ViewChild('placeholderslist', {static: false }) placeholderslist: MatTable<any>;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private reportTemplateService: ReportTemplateService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ReportTemplateDialogComponent>
  ) {
    if (data !== undefined && data !== null) {
      console.log(data);
      this.reportTemplate = data.reportTemplate;
      this.mode = data.mode;
      this.reportTemplateType = data.reportTemplateType;

    }
    // console.log(this.reportTemplate);

  }

  ngAfterViewInit() {
    if(this.placeholderslist) {
      this.placeholderslist.renderRows();
    }

  }

  ngOnInit() {
    this.createForm();
    const user = getUser();
    let userType = user.user_type;

    if(userType === undefined || userType === null)
      userType = user.admin_type;

    if(userType === 'report_template_administrator'){
      this.isReTeAdmin = true;
    } else {
      this.isReTeAdmin = false;
    }
  }

  createForm() {
    console.log('Template creation form');
    if (this.mode === 'Create') {
      this.reportTemplateForm = this.fb.group({
        name: ['', Validators.required],
        default_page_size: 'A4',
        orientation: 'portrait',
        visible_to: '',
        place_holders: []
      });
      this.dataSource.data = [];
    } else {
      const holders = this.reportTemplate.place_holders;
      if (holders !== null && holders !== undefined && holders.length > 0) {
        let data = this.dataSource.data;
        data = [];
        for (const obj of holders) {
          const keys = Object.keys(obj);
          for (const key of keys) {
            const holder = {
              name: key,
              default_value: obj[key]
            };
            data.push(holder);
          }
        }
        this.dataSource.data = data;
      }
      this.reportTemplateForm = this.fb.group({
        name: [this.reportTemplate.name, Validators.required],
        default_page_size: [this.reportTemplate.default_page_size, Validators.required],
        orientation: [this.reportTemplate.orientation, Validators.required],
        visible_to: [this.reportTemplate.visible_to, Validators.required],
        place_holders: '',
      });
    }

  }

  closeDialog(event) {
    event.preventDefault();

    const result = {
      event: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  showBoxMessage(msg, type = WARNING) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      if (type === SUCCESS) {
        this.messageBox.showSuccess();
      } else if (type === ERROR) {
        this.messageBox.showError();
      } else if (type === WARNING) {
        this.messageBox.showCritical();
      } else if (type === CRITICAL) {
        this.messageBox.showCritical();
      }
    });
  }

  defaultValueChanged(event, element, value){

    event.preventDefault();
    element['default_value'] = value;
    event.stopPropagation();
  }

  onSubmit() {
    let placeHolders = null;
    const holders = this.dataSource.data;
    if (holders !== null  && holders !== undefined && holders.length >= 0) {
      placeHolders = this.createPlaceholderList(holders);
    }

    if (this.mode === 'Create') {
      this.reportTemplate = this.reportTemplateForm.value;
      this.reportTemplate.place_holders = placeHolders;
    } else {
      const newData = this.reportTemplateForm.value;
      if (this.reportTemplate.name !== newData.name) {
        this.reportTemplate.name = newData.name;
      }
      if (this.reportTemplate.default_page_size !== newData.default_page_size) {
        this.reportTemplate.default_page_size = newData.default_page_size;
      }
      if (this.reportTemplate.orientation !== newData.orientation) {
        this.reportTemplate.orientation = newData.orientation;
      }
      if (this.reportTemplate.place_holders !== placeHolders) {
        this.reportTemplate.place_holders = placeHolders;
      }
    }
    if ( this.reportTemplateType === 'user_report_template') {
      this.createOrUpdateReportTemplate();
    }  else if (this.reportTemplateType === 'mc_admin_report_template') {
      this.createOrUpdateMCAdminReportTemplate();
    }
  }


  createOrUpdateReportTemplate() {
    const token = getToken();
    this.store.showLoading();
    if (this.mode === 'Create') {
      this.reportTemplateService.createReportTemplate(this.reportTemplate, token).subscribe(
        res => {
          const result = {
            event: 'Add',
            data: res
          };
          this.dialogRef.close(result);
          this.store.hideLoading();
        },
        err => {
          // this.toastr.error(err, 'MyCumulus');
          this.showBoxMessage(err);
          this.store.hideLoading();
        }
      );
    } else {
      this.reportTemplateService.updateReportTemplate(this.reportTemplate, token).subscribe(
        res => {
          const result = {
            event: 'Update',
            data: res
          };
          this.dialogRef.close(result);
          this.store.hideLoading();
        },
        err => {
          this.showBoxMessage(err);
          this.store.hideLoading();
        }
      );
    }
  }

  createOrUpdateMCAdminReportTemplate() {
    const token = getAdminToken();
    this.store.showLoading();
    if (this.mode === 'Create') {
      this.reportTemplateService.createMCAdminReportTemplate(this.reportTemplate, token).subscribe(
        res => {
          const result = {
            event: 'Add',
            data: res
          };
          this.dialogRef.close(result);
          this.store.hideLoading();
        },
        err => {
          // this.toastr.error(err, 'MyCumulus');
          this.showBoxMessage(err);
          this.store.hideLoading();
        }
      );
    } else {
      this.reportTemplateService.updateMCAdminReportTemplate(this.reportTemplate.id, this.reportTemplate, token).subscribe(
        res => {
          const result = {
            event: 'Update',
            data: res
          };
          this.dialogRef.close(result);
          this.store.hideLoading();
        },
        err => {
          // this.toastr.error(err, 'MyCumulus');
          this.showBoxMessage(err);
          this.store.hideLoading();
        }
      );
    }
  }

  OpenDeletePlaceHolder(index){

    this.dataSource.data.splice(index, 1);
    this.placeholderslist.renderRows();
  }

  addPlaceHolder(event) {
    event.preventDefault();
    let data = this.dataSource.data;
    const index = data.findIndex(el => el.name === this.placeholdername);
    if (index >= 0) {
      // this.toastr.error(, 'MyCumulus');
      this.showBoxMessage('This placeholder name is already added');
      return;
    }
    // console.log('data', data);
    // console.log(this.placeholdername);

    if (this.placeholdername === null || this.placeholdername === undefined || this.placeholdername === '') {
      event.stopPropagation();
      return;
    }
    const placeholder = {
      name: this.placeholdername,
      default_value: ''
    };

    data.push(placeholder);
    this.placeholdername = '';
    this.dataSource.data = data;
    this.placeholderslist.renderRows();
    event.stopPropagation();
  }

  createPlaceholderList(placeholderNameAndDefaults: PlaceHolder[]) {
    const holders = [];
    for (const pl of placeholderNameAndDefaults) {
      const val = {};
      val[pl.name] = pl.default_value;
      holders.push(val);
    }

    return holders;
  }

}
