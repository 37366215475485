import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResult } from '../share/result';


@Injectable({
  providedIn: 'root'
})
export class ProcessHttpmsgService {
  /*
   * export class ErrorResult {
        status: number;
        uri: string;
        error: Error;
      }

      export class Error {
        code: number;
        message: string;
      }
   */
  constructor() { }

  public handleError(err: ErrorResult | any) {
    let errMsg: string;
    const error = err['error'];
    const status = err['status'];
    if (status === 0) {
      errMsg = err['message'];
    } else if (error) {
      const mycumulusError = error['error'];
      if (mycumulusError) {
        errMsg = `${mycumulusError['message']}`;
      } else {
        const features = error['features'];
        errMsg = features ? features : error;
      }
    } else {
      errMsg = 'Error in the request';
    }

    if(errMsg.length > 0 && errMsg != "undefined") {
      return throwError(errMsg);
    } else
    {
      return throwError(err);
    }
  }
}
