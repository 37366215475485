import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { Field } from 'src/app/share/form/field';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-constraint-dialog',
  templateUrl: './constraint-dialog.component.html',
  styleUrls: ['./constraint-dialog.component.scss']
})
export class ConstraintDialogComponent implements OnInit, AfterViewInit {
  addConstraintForm: FormGroup;
  constraint: any;
  parent: Field;
  @Input() field: Field;
  @Input() constraints: any[];
  choiceOrMultChoice: boolean;
  mode: string;
  index: number;
  valuePlaceHolder: string;
  valueType: string;
  dataInvalid = false;

  constraintTypes = [
    {value: 'minimum', text: 'Minimum'},
    {value: 'maximum', text: 'Maximum'}
  ];

  constructor(
    private dialogRef: MatDialogRef<ConstraintDialogComponent>,
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.choiceOrMultChoice = true;
    if (data !== undefined && data !== null) {
      this.field = data.field;
      this.constraints = data.constraints;
      this.mode = data.mode;
      this.index = data.constraintIndex;
    }
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.dataInvalid = false;
    this.choiceOrMultChoice = true;
    if (this.field._class === 'autoincrementintegerfield' || this.field._class === 'integerfield' || this.field._class === 'decimalfield') {
      this.constraintTypes = [
        {value: 'minimum', text: 'Minimum'},
        {value: 'maximum', text: 'Maximum'},
        {value: 'choice', text: 'Choice'}
      ];
    } else {
      this.constraintTypes = [
        {value: 'length', text: 'Length'},
        {value: 'choice', text: 'Single choice list'},
        {value: 'multiple_choice', text: 'Multiple choice list'}
      ];
    }
    this.valuePlaceHolder = 'Value';
    this.valueType = 'text';
    this.createForm();
  }

  createForm() {
    let klass = '';
    let values = null;
    let message = '*';
    if (this.mode === 'Update') {
      const tempconstraint = this.constraints[this.index];
      if (tempconstraint !== null && tempconstraint !== undefined) {
        klass = tempconstraint['_class'];
        if (Object.keys(tempconstraint).indexOf('value') >= 0) {
          values = tempconstraint['value'];
        } else {
          if (tempconstraint['values'] instanceof Object) {
            values = tempconstraint['values'].join(';');
          } else {
            values = tempconstraint['values'];
          }
        }
        message = tempconstraint['error_message'];
      }
      // console.log(`_class: ${klass}, value(s): ${values}, message: ${message} `);
    } else {
      if(this.choiceOrMultChoice) {
        klass = 'choice';
      }
    }
    if (this.choiceOrMultChoice) {
      this.addConstraintForm = this.fb.group({
        _class: [klass, Validators.required],
        values: [values, Validators.required],
        error_message: [message, Validators.required]
      });
      this.valuePlaceHolder = 'Separate each value with a semicolon (;).';
    } else {
      this.addConstraintForm = this.fb.group({
        _class: [klass, Validators.required],
        value: [values, Validators.required],
        error_message: message
      });
      this.valuePlaceHolder = 'Value';
    }
  }

  constraintSelected(value) {
    if (value === 'choice' || value === 'multiple_choice') {
      this.valuePlaceHolder = 'Values separated by semi colon';
    } else if (value === 'length' || value === 'minimum' || value === 'maximum') {
      this.valuePlaceHolder = 'Value';
      this.valueType = 'number';
    }
  }

  onSubmit() {
    this.dataInvalid = false;
    let constraint = {};
    const data = this.addConstraintForm.value;
    if (data['_class'] === 'length' ||
        data['_class'] === 'minimum' ||
        data['_class'] === 'maximum') {
      let value = data['value'];
      let values = data['values'];

      if (value === null || value === undefined) {
        if (values) {
          values = values.split(";");
          const newValues = [];
          for (const val of values) {
            if (val && val.length > 0) {
              newValues.push(val.trim());
            }
          }
          if (this.field._class === 'autoincrementintegerfield' || this.field._class === 'integerfield') {
              for (const val of newValues) {
                if (!this.isInteger(val)) {
                  const val_parts = val.split('>');
                  if(val_parts && val_parts.length > 1) {
                    if(!this.isInteger(val_parts[1])) {
                      this.dataInvalid = true;
                      return;
                    }
                  } else {
                    this.dataInvalid = true;
                    return;
                  }
                }
              }
          }
          if (this.field._class === 'decimalfield') {
            for (const val of newValues) {
              if (!this.isDecimal(val)) {
                const val_parts = val.split('>');
                if(val_parts && val_parts.length > 1) {
                  if(!this.isDecimal(val_parts[1])) {
                    this.dataInvalid = true;
                    return;
                  }
                } else {
                  this.dataInvalid = true;
                  return;
                }
                
              }
            }
          }
          value = newValues.join(';');
        }
      }
      constraint = {
        _class: data['_class'],
        value: `${value}`,
        error_message: data['error_message']
      };
    } else {
      let value = data['value'];
      let values = data['values'];
      if (value === null || value === undefined) {
        if (values) {
          values = values.split(';');
          const newValues = [];
          for (const val of values) {
            if (val && val.trim().length > 0) {
              newValues.push(val.trim());
            }
          }
          if (this.field._class === 'autoincrementintegerfield' || this.field._class === 'integerfield') {
            for (const val of newValues) {
              if (!this.isInteger(val)) {
                const val_parts = val.split('>');
                if(val_parts && val_parts.length > 1) {
                  if(!this.isInteger(val_parts[1])) {
                    this.dataInvalid = true;
                    return;
                  }
                } else {
                  this.dataInvalid = true;
                  return;
                }
              }
            }
          }
          if (this.field._class === 'decimalfield') {
            for (const val of newValues) {
              if (!this.isDecimal(val)) {
                const val_parts = val.split('>');
                if(val_parts && val_parts.length > 1) {
                  if(!this.isDecimal(val_parts[1])) {
                    this.dataInvalid = true;
                    return;
                  }
                } else {
                  this.dataInvalid = true;
                  return;
                }
              }
            }
          }
          data['values'] = newValues.join(';');
        }
      }
      constraint = data;
    }
    /*
    if (constraint['_class'] === 'length' ||
        constraint['_class'] === 'minimum' ||
        constraint['_class'] === 'maximum' ) {
      const val = constraint['value'];
      if (val === null || val === undefined) {
        return;
      }
      constraint['value'] = `${val}`;
    }
    */
    const result = {
      event: 'Add',
      constraint
    };
    if (this.mode === 'Update') {
      result['event'] = 'Update';
    }
    this.dialogRef.close(result);
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
    event.stopPropagation();
  }

  isInteger(val): boolean {
    if(val === "*") {
      return true;
    }
    const convData = Number.parseInt(val);
    return Number.isInteger(convData);
  }

  isDecimal(val): boolean {
    if(val === "*") {
      return true;
    }
    if (Number.isNaN(Number.parseFloat(val))) {
      return false;
    }
    return true;
  }
}
