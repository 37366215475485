import { Component, OnInit, Input, ViewChild, Inject, HostListener } from '@angular/core';
import { DecimalField } from 'src/app/share/form/decimal-field';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-create-decimal-field',
  templateUrl: './create-decimal-field.component.html',
  styleUrls: ['./create-decimal-field.component.scss']
})
export class CreateDecimalFieldComponent implements OnInit {
  decimalField: DecimalField;
  decimalFieldForm: FormGroup;
  @Input() parent: FieldSet;
  @Input() mode = 'Create';
  eventActionslist = [
    'OnClick->Input(Thermometer-ETI)',
    'OnClick->Input(LaserDistanceMeter-Bosch_PLR_50C)',
    'OnClick->Input(CompassHeading)',
    'OnLocationChange->Area()',
    'OnLocationChange->Length()',
    'OnLocationChange->GnssAccuracy()',
    'OnLocationChange->PoleHeight()',
    'OnLocationChange->AntennaHeight()',
    'OnLocationChange->ApcOffset()',
    'OnLocationChange->Gnss.AvgHRMS',
    'OnLocationChange->Gnss.AvgVRMS',
    'OnLocationChange->Gnss.HRMS',
    'OnLocationChange->Gnss.VRMS',
    'OnLocationChange->Gnss.HDOP',
    'OnLocationChange->Gnss.PDOP',
    'OnLocationChange->Gnss.VDOP',
    'OnLocationChange->Gnss.Position().X',
    'OnLocationChange->Gnss.Position().Y',
    'OnLocationChange->Gnss.Position().Z',
    'OnStakeOut->dS',
    'OnStakeOut->dX',
    'OnStakeOut->dY',
    'OnStakeOut->dZ',
    'OnStakeOut->GnssAccuracy()',
    'OnStakeOut->PoleHeight()',
    'OnStakeOut->AntennaHeight()',
    'OnStakeOut->ApcOffset()',
    'OnRD_Message->Gain',
    'OnRD_Message->Depth',
    'OnRD_Message->Current',
    'OnRD_Message->Decibels',
    'OnRD_Message->FaultSignal',
    
  ];

  decimalDefaultValueExpression = [{name: 'Pi', value: '=Pi()'}];

  @ViewChild('fform', { static: false}) decimalFieldFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateDecimalFieldComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.decimalField = data.field;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    if (this.mode === 'Create') {
      this.decimalFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        default_value: '',
        default_value_expression: '',
        event_actions: [],
        enabled: true,
        use_previous_value: false,
        required: false,
        show_value_on_map: false
      });
    } else {
      this.decimalFieldForm = this.fb.group({
        name: [this.decimalField.name, Validators.required],
        label: [this.decimalField.label, Validators.required],
        description: this.decimalField.description,
        default_value: this.decimalField.default_value,
        default_value_expression: this.decimalField.default_value_expression,
        event_actions: this.decimalField.event_actions,
        enabled: this.decimalField.enabled,
        use_previous_value: this.decimalField.use_previous_value,
        required: this.decimalField.required,
        show_value_on_map: this.decimalField.show_value_on_map
      });
    }
  }

  onNameChange() {
    if (this.decimalFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.decimalFieldForm.controls.name.setValue(removeSpecialCharacters(this.decimalFieldForm.value.label));
    if (this.decimalFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  closeDialog(event) {
    event.preventDefault();
    const result = {
      action: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  onSubmit() {
    // this.decimalField = this.decimalFieldForm.value;
    if (this.decimalField) {
      this.decimalField = {
        ...this.decimalField,
        ...this.decimalFieldForm.value
      };
    } else {
      this.decimalField = this.decimalFieldForm.value;
    }

    this.decimalField._class = 'decimalfield';
    if (this.decimalField.name === this.parent.name) {
      this.showErrorMessage('Field cannot take the same name as the active fieldset');
      return;
    }
    if (this.decimalField.event_actions !== null && this.decimalField.event_actions !== undefined) {
      this.decimalField.event_actions = [this.decimalField.event_actions];
    }
    const result = {
      action: 'Create',
      field: this.decimalField
    };
    if (this.mode === 'Create') {
      if (isFieldAlreadyExist(this.decimalField, this.parent)) {
        this.showErrorMessage('Another field with the same name/label already exists');
        return;
      }
      this.store.addField(this.decimalField);
    } else if (this.mode === 'Update') {
      result.action = 'Update';
    }
    this.dialogRef.close(result);
  }
}
