import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectService } from 'src/app/services/project.service';
import { getAdminToken, getToken } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { FileServiceService } from 'src/app/services/file-service.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
  @Input() project: Project;
  private addedProject: Project;
  projectDialogForm: FormGroup;
  image: string;
  name: string;
  description: string;
  project_class: string;
  proClass: string;
  archived: boolean;
  visibility: string;
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = 'Create';
  @ViewChild('pjtIcon', {static: false}) img;
  @ViewChild('fform', { static: false}) projectFormDirective;
  private messageBox: MessageBoxComponent;
  projectTypelist = [
    { value: 'project', text: 'Project' },
    { value: 'domain', text: 'Domain' },
    { value: 'template', text: 'Template' }
  ];

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fileService: FileServiceService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateProjectComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
      this.mode = data.mode;
      this.name = data.project.name;
      this.description = data.project.description;
      this.project_class = data.project.project_class;
      this.archived = data.project.archived;
      this.proClass = this.project_class;
      if(this.project_class === "template") {
        this.proClass = "Project Template";
      }
    }
    this.projectService.projectAdded.subscribe(project => this.onProjectAdded(project));
    this.projectService.projectUpdated.subscribe(project => this.onProjectUpdated(project));
    this.createForm();
  }

  showErroMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  showSuccessMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showSuccess();
    });
  }

  ngOnInit() {
  }

  initializeFormData(p: Project) {
    this.name = p.name;
    this.description = p.description;
  }

  createForm() {
    let name = '';
    let des = '';
    this.project_class = this.store.proClass;

    let archived = false;
    this.visibility = 'private';
    if (this.project !== undefined && this.project !== null) {
      name = this.project.name;
      des = this.project.description;
      this.project_class = this.project['project_class'];
      archived = this.project['archived'];
      this.visibility= this.project['visibility'];
      this.image = this.project.image_file_url;
    }
    this.projectDialogForm = this.fb.group({
      name: [name, Validators.required],
      description: des,
      project_class: this.project_class,
      visibility: this.visibility,
      archived: archived
    });
  }

  uploadFile(token, project, isMyCumulusAdministrator = false){
    const content = {
      filename: this.getUniqueFilename(project.name, this.getExtensionFromDataURI(this.img.image)),
      base64_encoded_content : this.img.image.split(',')[1]
    }
    this.fileService.uploadPrivateFile(content, token, isMyCumulusAdministrator).subscribe(
      res => {
        const fileUrl = res.file.download_url;
        project.image_file_url = fileUrl;
        let proToken;
        if(isMyCumulusAdministrator) {
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }
        
        if(this.mode === 'Create'){
          this.projectService.createProject(project, proToken, isMyCumulusAdministrator).subscribe(
            res => {
              this.store.createProject(res);
              this.onProjectAdded(res);
              this.store.templates = [];
              this.store.showTemplates();
              this.dialogRef.close();
              this.store.hideLoading();
            },
            errmess => {
              // console.log(errmess);
              this.showErroMessage(errmess);
              this.store.hideLoading();
          });
        } else {
          this.projectService.updateProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
            res => {
              this.store.updateProject(res);
              this.onProjectUpdated(res);
              this.dialogRef.close();
              this.store.hideLoading();
            },
            errmess => {
              this.store.hideLoading();
              this.showErroMessage(errmess);
              // this.toastr.error('Error while updating project: ' + errmess, 'MyCumulus' );
          });
        }
      },
      err => {
        this.showErroMessage(err);
        this.store.hideLoading();
      }
    );
  }

  getUniqueFilename(prefix, extension): string {
    return prefix.replace(/\s/g, '') + '_' + Date.now() + '.' + extension;
  };

  getExtensionFromDataURI(data){
    return data.split(';')[0].split('/')[1];
  };

  getIconFile(image){
    if(image.split(':')[1] === '//placehold.it/100x100/ffffff/9e9e9e' || image === '' || image === undefined){
      return '';
    } else {
      return this.project.image_file_url;
    }
  }

  onSubmit() {
    const temp = this.projectDialogForm.value;
    let isMyCumulusAdministrator = false;

    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    } 
    const proToken = {
      'key': 'key',
      'value': token.key
    }
    this.store.showLoading();
    const image = this.img.image;
    if (this.mode  === 'Create') {
      this.project = temp;

      if(image !== '' && image.split(':')[0] !== 'https' && image.split(':')[0] !== 'http'){
        this.uploadFile(token, temp, isMyCumulusAdministrator);
      } else {
        this.projectService.createProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.store.createProject(res);
            this.onProjectAdded(res);
            this.store.templates = [];
            this.store.showTemplates();
            this.dialogRef.close();
            this.store.hideLoading();
          },
          errmess => {
            // console.log(errmess);
            this.showErroMessage(errmess);
            this.store.hideLoading();
        });
      }
    } else if (this.mode === 'Update') {
      this.project.name = temp.name;
      this.project.description = temp.description;
      this.project.image_file_url = this.getIconFile(image);
      if(image !== '' && image.split(':')[0] !== 'https' && image.split(':')[0] !== 'http'){
        this.uploadFile(token, this.project, isMyCumulusAdministrator);
      } else {
        this.projectService.updateProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.store.updateProject(res);
            this.onProjectUpdated(res);
            this.dialogRef.close();
            this.store.hideLoading();
          },
          errmess => {
            this.store.hideLoading();
            this.showErroMessage(errmess);
            // this.toastr.error('Error while updating project: ' + errmess, 'MyCumulus' );
        });
      }
    }

    // console.log(JSON.stringify(this.project));
  }

  openCreateProject() {
    this.dialog.open(CreateProjectComponent, {width: '500px', height: 'auto'});
  }

  onProjectAdded(project: Project) {
    // this.toastr.success('Your project has been successfully created.', 'MyCumulus');
  }

  onProjectUpdated(project: Project) {

  }

  openEditProject(p) {
    this.project = p;
    this.dialog.open(CreateProjectComponent, {width: '500px', height: 'auto'});
  }

  uploadProjectIcon() {
    // Send image icon and then come continue operation
  }

  removeProjectIcon() {
    this.image = '';
  }

  invalidIconDrop(icon: boolean) {
    this.invalidIconDropped.emit(icon);
    // this.toastr.error(, 'MyCumulus');
    this.showErroMessage('drop only images on the icon space');
  }

  /**
   * This method allow to change the new value provided by the user to
   * be the new data of project
   * TODO: Change the code to use way of extending object, (use of spread)
   */
  changedProperties() {
    let data = {};

    if (this.name !== this.project.name) {
      data['name'] = this.name;
    }

    if (this.description !== this.project.description) {
      data['description'] =  this.description;
    }

    if (this.image !== this.project.image_file_url) {
      if (this.image === '') {
        data['image_file_url'] = null;
      } else {
        data['image_file_url'] = this.image.split(',')[1];
      }
    }

    return data;
  }

}
