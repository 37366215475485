import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTable, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { getCurrentDomain, getTemplate, getTemplateFieldsNameAndTypes, removeSpecialCharacters, url } from 'src/app/share/utils';
import { GeomTypeCoordinatesDialogComponent } from '../geom-type-coordinates-dialog/geom-type-coordinates-dialog.component';
import { MediaShowingDialogComponent } from '../media-showing-dialog/media-showing-dialog.component';
import { Feature } from 'src/app/share/feature/feature';
import { EditGeomOrCoordValuesComponent } from '../edit-geom-or-coord-values/edit-geom-or-coord-values.component';

const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-attributesetarray-dialog',
  templateUrl: './attributesetarray-dialog.component.html',
  styleUrls: ['./attributesetarray-dialog.component.scss']
})
export class AttributesetarrayDialogComponent implements OnInit, AfterViewInit {
  attributesetarray: any[];
  currentAttributeSetStructure: any;
  attName: string;
  path: string;
  headers =  [];
  names = [];
  geodetic: boolean;
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  feature: Feature;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private storeService: StoreService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AttributesetarrayDialogComponent>) {
    if (data !== undefined && data !== null) {
      this.attributesetarray = data.attributesetarray;
      this.path = data.path;
      this.attName = data.attname;
      this.feature = data.feature;
      console.log(this.feature);
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.createData();
  }

  displayEditGPSCoordinates(column, element, index) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus = true;

    const geomType = element[column];
    const coordinates = element[column];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
      dialogConfig.data = {
        feature: this.feature,
        column: `${this.path} ${column}`,
        isCoordAttributes: true,
        coordAttributes: coordinates,
        is_fieldset_array: true,
        fsa_value_index : index
      };

      const editCoordRef = this.dialog.open(EditGeomOrCoordValuesComponent, dialogConfig);
      editCoordRef.afterClosed().subscribe(result => {
        if (result !== null && result !== undefined) {
          this.dialogRef.close(result);
        }
      });
    } else {
      this.showErrorMessage('This coordinates field does not contains value');
    }
  }

  showErrorMessage(val) {
  }

  displayGPSCoordinates(column, element) {
    const coordinates = element[column];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
    }
    this.openCoordinateDialog(null, coordinates, gpscoord);
    return;
  }

  openCoordinateDialog(geometryType, coordinates, gpscoord = true) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      geometry_type: geometryType,
      gpscoord,
      coordinates
    };
    const dialogRef = this.dialog.open(GeomTypeCoordinatesDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  checkIfCoordinatesField(column) {
    const col = removeSpecialCharacters(column);
    for ( const obj of this.currentAttributeSetStructure) {
      if (obj['name'] === col) {
        if (obj['type'] === 'coordinates') {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  /*
   // building the attributes header
   buildAttributesHeader(attribs, prefix = '') {
    const tempAttributesHeaderArray = [];
    this.initHeaders = [];
    this.buildAttributesetHeader(attribs, tempAttributesHeaderArray);

    for (const item of tempAttributesHeaderArray) {
        this.initHeaders.push(item);
    }
    return tempAttributesHeaderArray;
  }
*/
  // building the attributeset with nested attributes header
  buildAttributesetHeader(attributes, headerArray, prefix = '') {

    const prefixEmpty = prefix === '' || prefix === null || prefix === undefined;

    const size = attributes.length;

    for (let i = 0; i < size; i++) {
      const attribute = attributes[i];
      if (attribute._class === 'attributeset') {
        prefix = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        this.buildAttributesetHeader(attribute.attributes, headerArray, prefix);
      } else if (attribute._class === 'arrayattributeset') {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      } else {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      }
    }
  }

  createData() {
    const data = [];
    if (this.attributesetarray === null || this.attributesetarray === undefined || this.attributesetarray.length === 0) {
      return;
    }
    const template = getTemplate();
    const featureSchema = template.feature;
    const form = template.form;
    let attributes = featureSchema['attributes'];
    let fields = form.fields;
    const parts = this.path.split('.');
    const attnames = this.attName.split('.');

    let attributeset = null;
    let fieldset = null;
    if(parts && parts.length > 0) {
      // attributeset = attributes[parts[0]];
      for(let y = 0; y < fields.length; y++) {
        const absAtt = attributes[y];
        const absField = fields[y];
        if(absField.label === parts[0]) {
          attributeset = absAtt;
          fieldset = absField;
          break;
        }
      }
      if(parts.length > 1) {
        for(let i=1; i < parts.length; i++) {
          const key = parts[i];
          attributeset = attributeset[key];
          fieldset = fieldset[attnames[i]];
        }
      }
    }

    // const listGeometryHeader = Object.keys(this.attributesetarray[0]['geometry']);
    // const listDataHeader = Object.keys(attributeset);
    this.headers =  [];
    this.names = [];
    this.buildAttributesetHeader(attributeset.attributes, this.names, '');
    this.buildAttributesetHeaderLabel(fieldset.fields, this.headers, '');
    if (this.headers !== null && this.headers !== undefined && this.headers.length > 0) {
      this.displayedColumns = this.headers;
      // for (const h of headers) {
      //   this.displayedColumns.push(h);
      // }
    }

    this.attributesetarray.forEach(inst => {
      const dataItem = this.createDataArray(inst, '');
      data.push(dataItem);
    });

    const currentTemplate = this.storeService.template.getValue();
    const features = currentTemplate.feature;
    const schema = features.attributes;
    const paths = this.path.split('.');
    this.currentAttributeSetStructure = schema;
    if (paths instanceof Object) {
      if (paths.length > 0) {
        for (const itemKey of paths) {
          for (const obj of this.currentAttributeSetStructure) {
            if (obj['name'] === itemKey) {
              if (obj['attributes'] !== null && obj['attributes'] !== undefined) {
                this.currentAttributeSetStructure = obj['attributes'];
              }
            }
          }
        }
      }
    }
    this.dataSource.data = data;
    this.table.renderRows();
  }

  buildAttributesetHeaderLabel(fields, headerArray, prefix = '') {

    const prefixEmpty = prefix === '' || prefix === null || prefix === undefined;

    const size = fields.length;

    for (let i = 0; i < size; i++) {
      const field = fields[i];

      if (field._class === 'fieldset') {
        prefix = prefixEmpty ? field.label : prefix + '.' + field.label;
        this.buildAttributesetHeader(field.attributes, headerArray, prefix);
      } else if (field._class === 'arrayfieldset') {

        const curHeader = prefixEmpty ? field.label : prefix + '.' + field.label;
        headerArray.push(curHeader);
      } else {
        const curHeader = prefixEmpty ? field.label : prefix + '.' + field.label;
        headerArray.push(curHeader);
      }
    }
  }

  createHeaderArray(attributeset, parent = ''): string[] {
    let result = [];
    const keys = Object.keys(attributeset);
    for (const key of keys) {
      const absAttribute = attributeset[key];
      const path = parent === '' ? key : `${parent}.${key}`;
      if (absAttribute instanceof Object) {
        if (absAttribute instanceof Array) {
          // TODO: Implement Attributeset array here
          result.push(path);
        } else {
          result = result.concat(this.createHeaderArray(attributeset[key], path));
        }
      } else {
        result.push(path);
      }
    }

    return result;
  }

  createDataArray(attributeset: any, parent = ''): any {

    let result = {};
    const keys = Object.keys(attributeset);
    for (const key of keys) {
      const absAttribute = attributeset[key];
      const path = parent === '' ? key : `${parent}.${key}`;
      console.log(path);
      console.log(absAttribute);
      if (absAttribute instanceof Object) {
        if (absAttribute instanceof Array) {
          const i = this.names.indexOf(path);
          result[this.headers[i]] = absAttribute;
        } else {
          const tempResult = this.createDataArray(attributeset[key], path);
          result = Object.assign(result, tempResult);
        }
      } else {
        const i = this.names.indexOf(path);
        result[this.headers[i]] = attributeset[key];
      }
    }

    return result;
  }

  isDataIsFile(data): boolean {
    const temp = this.checkFiles(data);
    return this.checkIfIsUrl(temp);
  }

  checkIfIsUrl(data) {
    const pat = /^https?:\/\//i;
    let values = [];

    if (data === undefined || data === null) {
      return false;
    }
    let str;
    if ((data instanceof Object) && (data instanceof Array) && data.length > 0) {
      str = data[0];
    } else {
      str = data;
    }
    if (isNaN(str)) {
      if (typeof(str) === 'string') {
        values = str.split('/');
      }
    }
    const currentDomain = getCurrentDomain();
    if (pat.test(str) && values.includes(currentDomain) && values.includes('v1') && values.includes('files')) {
      return true;
    }
    return false;
  }

   /*
    * check if value is a media file and return the complete URL.
    * @param: the value to check
    * @return: the URL or the string
    */
   checkFiles(value) {
    if (value instanceof Array && value.length > 0) {
      const firstVal = value[0];
      return this.checkFile(firstVal);
    } else {
      return value;
    }
  }

  checkFile(value) {
    const convValue = String(value);
    const splitValues = convValue.split('.');
    const types = ['jpg', 'png', 'jpeg', 'pdf', 'gif', 'mp3', 'wav', 'raw', 'ogg', 'mp4', '3gp', 'avi', 'vob', 'flv'];
    let type = splitValues[splitValues.length - 1];
    type = type.toLowerCase();
    if (this.contains(type, types)) {
      return url(value);
    } else {
      return value;
    }
  }

  openMediaShowingDialog(event, link) {
    event.preventDefault();
    const medialink = this.checkFile(link);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.data = {
      medialink
    };

    const dialogRef = this.dialog.open(MediaShowingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // if (result.event === 'Add') {
      // }
    });
    event.stopPropagation();
  }

  contains(value, arr) {
    if (arr.length > 0) {
      for (const item of arr) {
        if (item === value) {
          return true;
        }
      }
      return false;
    }
  }

  getIcon(item): string{
    let uri = '';
    if(item !== null && item !== undefined) {
      const values = item.split('.');

      const ext = values[values.length - 1];

      if(ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'gif' || ext === 'pdf'){
        uri = '/assets/images/photo_display.png';
      } else if(ext === 'mp4' || ext === '3gp' || ext === 'avi' || ext === 'vob' || ext === 'flv'){
        uri = '/assets/images/video_display.png'
      } else if(ext === 'mp3' || ext === 'wav' || ext === 'raw' || ext === 'ogg'){
        uri = '/assets/images/audio_play.png'
      }

      return uri;
    }
  }

  getFilesUrl(value) {
    const filesUrls = [];
    if (value instanceof Array && value.length > 0) {
      value.forEach(element => {
        filesUrls.push(this.checkFiles(element));
      });
    } else {
      return value;
    }
    return filesUrls;
  }

  displayItemValue(value) {
      if (this.checkIfDate(value)) {
        return value.split('T')[0];
      }
      return value;
  }

  checkIfDate(value) {
    if (value !== null && value !== undefined && value !== '' && typeof value === 'string') {
      const colomValues = value.split(':');
      const slashValues = value.split('-');
      if (colomValues.length > 2 && slashValues.length > 2) {
        return true;
      }
    }
    return false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
