import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { FieldsetArray } from 'src/app/share/form/fieldset-array';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Component({
  selector: 'app-create-fieldset-array',
  templateUrl: './create-fieldset-array.component.html',
  styleUrls: ['./create-fieldset-array.component.scss']
})
export class CreateFieldsetArrayComponent implements OnInit {
  fieldsetArray: FieldsetArray;
  fieldsetArrayForm: FormGroup;
  index: number;
  @Input() parent: FieldSet;
  @Input() mode = 'Create';

  @ViewChild('fform', { static: false}) fieldsetArrayFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateFieldsetArrayComponent>
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.fieldsetArray = data.fieldset;
      this.index = data.index;
      this.mode = data.mode;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    /*
    this.fieldsetArrayForm = this.fb.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      enabled: [true],
      description: ''
    });
    */
    if (this.mode === 'Create') {
      this.fieldsetArrayForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        enabled: [true]
      });
    } else if (this.mode === 'Update') {
      this.fieldsetArrayForm = this.fb.group({
        name: [this.fieldsetArray.name, Validators.required],
        label: [this.fieldsetArray.label, Validators.required],
        description: this.fieldsetArray.description,
        enabled: this.fieldsetArray.enabled
      });
    }
  }

  onNameChange() {
    if (this.fieldsetArrayForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.fieldsetArrayForm.controls.name.setValue(removeSpecialCharacters(this.fieldsetArrayForm.value.label));
    if (this.fieldsetArrayForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  closeDialog(event) {
    event.preventDefault();
    const result = {
      action: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopProgation();
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      this.messageBox.showCritical();
    });
  }

  onSubmit() {
    const formData = this.fieldsetArrayForm.value;
    if (this.mode === 'Create') {
      this.fieldsetArray = formData;
      this.fieldsetArray._class = 'arrayfieldset' ;
      this.fieldsetArray.fields = [];

    } else if (this.mode === 'Update') {
        const fields = this.fieldsetArray.fields;
        this.fieldsetArray = formData;
        this.fieldsetArray._class = 'arrayfieldset';
        this.fieldsetArray.fields = fields;
    }

    if (this.fieldsetArray.name === this.parent.name) {
      this.showErrorMessage('Field cannot take the same name as the active fieldset');
      return;
    }

    const result = {
      action: 'Create',
      field: this.fieldsetArray
    };
    if (this.mode === 'Create') {
      if (isFieldAlreadyExist(this.fieldsetArray, this.parent)) {
        this.showErrorMessage('Another field with the same name/label already exists');
        return;
      }
      this.store.addField(this.fieldsetArray);
    } else if (this.mode === 'Update') {
      result.action = 'Update';
    }
    this.dialogRef.close(result);

  }

}
