import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from 'src/app/services/user.service';
import { TemplatesPermissionsDialogComponent } from '../templates-permissions-dialog/templates-permissions-dialog.component';
import { getToken, ERROR, WARNING, CRITICAL, SUCCESS, getProject } from 'src/app/share/utils';
import { Project } from 'src/app/share/projects';
import { ProjectMemberService } from 'src/app/services/project-member.service';
import { Collaborator } from 'src/app/share/user';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { AddMemberDialogComponent } from '../add-member-dialog/add-member-dialog.component';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';

export interface PeriodicElement {
  project_member: string;

}

const ELEMENT_DATA: Collaborator[] = [];


@Component({
  selector: 'app-project-member-dialog',
  templateUrl: './project-member-dialog.component.html',
  styleUrls: ['./project-member-dialog.component.scss']
})
export class ProjectMemberDialogComponent implements OnInit {
  project: Project;
  actions = ['Permissions', 'Remove Members'];
  displayedColumns: string[] = ['select', 'project_members', 'forms'];

  dataSource = new MatTableDataSource<Collaborator>(ELEMENT_DATA);
  selection = new SelectionModel<Collaborator>(true, []);
  searchValue: string;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (!!content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private userService: UserService,
    private projectMemberService: ProjectMemberService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
    }
    // console.log(JSON.stringify(this.project));
    this.loadmembers();
  }

  loadmembers() {
    const token = getToken();
    this.store.showLoading();
    this.projectMemberService.getListOfMemberOfProject(token, this.project.id).subscribe(
      res => {
        let userslist = this.dataSource.data;
        userslist = res.filter(u => u.id !== this.project.created_by);
        this.dataSource.data = userslist;
        this.store.hideLoading();
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.showBoxMessage(err);
        this.store.hideLoading();
      }
    );
  }

  showBoxMessage(msg, type = WARNING) {
    this.message = msg;
    this.showMessage = true;
    setTimeout(() => {
      if (type === SUCCESS) {
        this.messageBox.showSuccess();
      } else if (type === ERROR) {
        this.messageBox.showError();
      } else if (type === WARNING) {
        this.messageBox.showCritical();
      } else if (type === CRITICAL) {
        this.messageBox.showCritical();
      }
    });
  }

  ngOnInit() {
  }

  openFormsPermissionsDialog(element) {
    let collaborators: Collaborator[] = [element];

    this.assignPermissions(collaborators);
  }

  assignPermissions(users){
    if(users.length <= 0){
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data =  {
      project: this.project,
      user: users
    };

    const dialogRef = this.dialog.open(TemplatesPermissionsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {

        }
      }
    );
  }

  addMemberToProject() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data =  {
      project: this.project,
      addedMembers: this.dataSource.data
    };

    const dialogRef = this.dialog.open(AddMemberDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined && result.event === 'memberAdded') {
        this.submitAddMember();
      }
    });
  }

  submitAddMember() {
    this.loadmembers();
  }

  openUpdateProjectDialog() {

  }

  openConfirmDeleteUserDialog() {
    if (this.selection.selected.length <= 0) {
      // this.toastr.error(', 'MyCumulus');
      this.showBoxMessage('No User is selected, please select users to be removed');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '700px',
      data: {
        message: 'Do you really want to remove access of the selected users to the project?',
        title: 'Delete user(s) from project'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        for (const user of this.selection.selected) {
          this.store.showLoading();
          this.projectMemberService.removeProjectMembers(user, this.project, token).subscribe(
            res => {
              this.store.hideLoading();
              this.showBoxMessage('Users removed with success', SUCCESS);
              this.loadmembers();
              // this.toastr.success(, 'MyCumulus');
            },
            err => {
              this.store.hideLoading();
              this.showBoxMessage(err);
              // this.toastr.error('Error while deleting the users' + errmes, 'MyCumulus');
            }
          );
        }
      }
    });
  }

  openActionDialog(action) {
    if (action === 'Remove Members') {
      this.openConfirmDeleteUserDialog();
    } else if(action === 'Permissions'){
      this.assignPermissions(this.selection.selected);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Collaborator): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }
}
