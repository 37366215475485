import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable, MatDialog, MatDialogConfig } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CollaboratorsDialogComponent } from '../dialogs/collaborators-dialog/collaborators-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { getToken, getUser, isTokenValid, getAdminToken } from 'src/app/share/utils';
import { User } from 'src/app/share/user';
import { ReportTemplateDialogComponent } from '../dialogs/report-template-dialog/report-template-dialog.component';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { StoreService } from 'src/app/services/store.service';


export interface ReportTemplate {
  id: string;
  name: string;
  created_at: string;
  place_holders: any[];
  orientation: string;
  default_page_size: string;
  template_preview_url: string;
  user_id: string;
  visible_to: string;
  description: string;
}

const ELEMENT_DATA: ReportTemplate[] = [];

@Component({
  selector: 'app-report-templates',
  templateUrl: './report-templates.component.html',
  styleUrls: ['./report-templates.component.scss']
})

export class ReportTemplatesComponent implements OnInit {

  user: User;
  isReportAdmin: boolean;
  displayedColumns: string[] = ['name', 'created_at', 'actions'];

  dataSource = new MatTableDataSource<ReportTemplate>(ELEMENT_DATA);
  selection = new SelectionModel<ReportTemplate>(true, []);
  searchValue: string;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private reportTemplateService: ReportTemplateService,
  ) { }

  ngOnInit() {
    this.user = getUser();
    const token = getToken();
    this.store.showLoading();

    if(this.user.user_type === 'admin_user' || this.user.user_type === 'collaborator'){
      this.isReportAdmin = true;
    } else {
      this.isReportAdmin = false;
    }
    if (token !== null && token !== undefined) {
      this.reportTemplateService.getReportTemplates(token).subscribe(res => {
        let data = this.dataSource.data;
        data = [];
        for (const rep_temp of res) {
          const reportTemplate = {
            id: rep_temp.id,
            name: rep_temp.name,
            created_at: rep_temp.created_at,
            place_holders: rep_temp.place_holders,
            orientation: rep_temp.orientation,
            default_page_size: rep_temp.default_page_size,
            template_preview_url: rep_temp.template_preview_url,
            user_id: rep_temp.user_id,
            visible_to: rep_temp.visible_to,
            description: rep_temp.description
          };
          data.push(reportTemplate);
        }
        this.dataSource.data = data;
        this.store.hideLoading();
      },
      err => {
        // this.toastr.error(err, 'MyCumulus');
        this.store.hideLoading();
      });
    }
  }

  openUpdateReportTemplateDialog(element) {
    const dialogRef = this.dialog.open(ReportTemplateDialogComponent, {
      width: '600px',
      data: {
        mode: 'Update',
        reportTemplate: element,
        reportTemplateType: 'user_report_template'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result.event === 'Update') {
        const reportTemplate = result.data;
        let data = this.dataSource.data;
        const index = data.findIndex(res => res.id === reportTemplate.id);
        if (index >= 0) {
          const el = {
            id: reportTemplate.id,
            name: reportTemplate.name,
            created_at: reportTemplate.created_at,
            place_holders: reportTemplate.place_holders,
            orientation: reportTemplate.orientation,
            default_page_size: reportTemplate.default_page_size,
            template_preview_url: reportTemplate.template_preview_url,
            user_id: reportTemplate.user_id,
            visible_to: reportTemplate.visible_to,
            description: reportTemplate.description
          };
          const deletedData = data.splice(index, 1, el);
          // data.push(el);
          this.dataSource.data = data;
          this.table.renderRows();
        }
      }
    });
  }

  /**
   * This method allow to create new collaborator user
   */
  openCreateReportTemplateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      reportTemplateType: 'user_report_template',
      mode: 'Create'
    };
    const dialogRef = this.dialog.open(ReportTemplateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        // User created add it to the table and refresh the table
        const reportTemplateData = result.data;

        this.store.showLoading();
        const data = this.dataSource.data;
        data.push(reportTemplateData);
        this.dataSource.data = data;
        this.table.renderRows();
        this.store.hideLoading();


      } else if (result.event === 'Cancel') {
        console.log('Report template creation cancelled');
        this.store.hideLoading();
      }
    });
  }


  OpenDeleteReportTemplateConfirmation(element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        message: 'This report template will be definitely removed',
        title: `Delete Report Template "${element.name}"`
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();

        this.store.showLoading();
        this.reportTemplateService.deleteReportTemplate(element, token).subscribe(
          res => {
            const data = this.dataSource.data;
            const newData = data.filter(reportTemplate => reportTemplate.id !== res.id);
            this.dataSource.data = newData;
            this.table.renderRows();
            this.store.hideLoading();

            // this.toastr.success('Report template deleted with success', 'MyCumulus');
          },
          errmes => {
            this.store.hideLoading();

            // this.toastr.error('Error while deleting the users' + errmes, 'MyCumulus');
          }
        );
      }
    });
  }
}

