import { Document } from './document';
import { TemplateForm } from './form/template-form';
import { FeatureSchema } from './schema/schema';

export class Template extends Document {
  project_id: string;
  name: string;
  description: string;
  image_file_url: string;
  feature: FeatureSchema;
  form: TemplateForm;
  map_style: object;
  default_style_id: any;
}

export class NewTemplate {
  project_id: string;
  name: string;
  description: string;
  image_file_url: string;
  feature: object;
}

export class TemplateToDuplicate {
  template_id: string;
  project_id: string;
  name: string;
  description: string;
}
